import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    agencyKey,
    connectionKey,
    JsonBuilder,
    OptionUtils,
    parseList,
    parseString,
    SimpleJsonSerializer,
    statusKey,
    templateKey,
} from '../core';
import {
    ApiAgency,
    ApiAgencyJsonSerializer,
    ApiConnection,
    ApiConnectionJsonSerializer,
    ApiTemplate,
    ApiTemplateJsonSerializer,
} from '../models';

export class AutoImportSettings {

    constructor(
        readonly agency: Option<ApiAgency> = None,
        readonly connection: Option<ApiConnection> = None,
        readonly template: Option<ApiTemplate> = None,
        readonly bookingStatus: List<string> = List(),
    ) {
    }

    getAgency(): Option<ApiAgency> {
        return this.agency;
    }

    getBookingStatus(): List<string> {
        return this.bookingStatus;
    }

    getConnection(): Option<ApiConnection> {
        return this.connection;
    }

    getSimpleName(): string {
        return OptionUtils.applyOrReturnNonEmpty(
            this.connection.flatMap(x => x.getLabel()),
            this.agency.flatMap(x => x.getUser()),
            (a, b) => `${a} (${b})`)
            .getOrElse('Unknown');
    }

    getTemplate(): Option<ApiTemplate> {
        return this.template;
    }
}

export class ApiRequestSettingsJsonSerializer extends SimpleJsonSerializer<AutoImportSettings> {
    static instance: ApiRequestSettingsJsonSerializer = new ApiRequestSettingsJsonSerializer();

    protected fromJsonImpl(obj: any): AutoImportSettings {
        return new AutoImportSettings(
            ApiAgencyJsonSerializer.instance.fromJson(obj[agencyKey]),
            ApiConnectionJsonSerializer.instance.fromJson(obj[connectionKey]),
            ApiTemplateJsonSerializer.instance.fromJson(obj[templateKey]),
            parseList(obj[statusKey], parseString),
        );
    }

    protected toJsonImpl(value: AutoImportSettings, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(agencyKey, value.agency, ApiAgencyJsonSerializer.instance)
            .addOptionalSerializable(connectionKey, value.connection, ApiConnectionJsonSerializer.instance)
            .addOptionalSerializable(templateKey, value.template, ApiTemplateJsonSerializer.instance)
            .addIterable(statusKey, value.bookingStatus);
    }

}
