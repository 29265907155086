import {None, Option, Some} from 'funfix-core';
import {getDistance, isPointWithinRadius, sexagesimalToDecimal} from 'geolib';
import {parseNumber} from '../core';
import {Distance} from './distance';

export type SearchRadius = 'Radius-∞' | 'Radius-10' | 'Radius-25' | 'Radius-50';

export class LatLongLocation {

    constructor(readonly lat: Option<number> = None, readonly long: Option<number> = None) {
    }

    static buildFromParts(lat: readonly [number, number, number], latRef: string, long: readonly [number, number, number], longRef: string): LatLongLocation {
        return new LatLongLocation(
            LatLongLocation.parseSexToDec(`${lat[0]}° ${lat[1]}' ${lat[2]}" ${latRef}`),
            LatLongLocation.parseSexToDec(`${long[0]}° ${long[1]}' ${long[2]}" ${longRef}`));
    }

    static buildFromText(latlong: string): LatLongLocation {
        const [lat, long] = latlong.substring(1, length - 1).split(', ').map(x => parseNumber(x));
        return new LatLongLocation(lat, long);
    }

    static parseSearchRadius(s: string): Option<SearchRadius> {
        switch (s) {
            case 'Radius-∞':
            case 'Radius-10':
            case 'Radius-25':
            case 'Radius-50':
                return Some(s as SearchRadius);
            default:
                return None;
        }
    }

    static parseSexToDec(sex: string): Option<number> {
        try {
            return Some(sexagesimalToDecimal(sex));
        } catch (e) {
            console.error(`Error converting Sex to Dec (${sex})`, e);
            return None;
        }
    }

    // [123.3542432, 432.43256]
    getAsText(): Option<string> {
        return Option.map2(this.lat, this.long, (lat, long) => `[${lat}, ${long}]`);
    }

    getLatitude(): Option<number> {
        return this.lat;
    }

    getLongitude(): Option<number> {
        return this.long;
    }

    getPoints(): Option<{ latitude: number, longitude: number }> {
        return Option.map2(
            this.getLatitude(),
            this.getLongitude(),
            (lat, long) => ({latitude: lat, longitude: long}));
    }

    // Some Api's use short form
    getPointsAlternate(): Option<{ lat: number, lng: number }> {
        return Option.map2(
            this.getLatitude(),
            this.getLongitude(),
            (lat, lng) => ({lat, lng}));
    }

    getPointTuple(): Option<number[]> {
        return Option.map2(
            this.getLatitude(),
            this.getLongitude(),
            (lat, long) => [lat, long]);
    }

    getStraightLineDistance(other: LatLongLocation): Option<Distance> {
        return Option.map2(
            this.getPoints(),
            other.getPoints(),
            (a, b) => Distance.meters(getDistance(a, b)));
    }

    isNear(other: LatLongLocation, tolerance: Distance): Option<boolean> {
        return Option.map2(
            this.getPoints(),
            other.getPoints(),
            (a, b) => isPointWithinRadius(a, b, tolerance.meters));
    }
}

export class LatLongRoute {

    constructor(readonly start: Option<LatLongLocation>, readonly end: Option<LatLongLocation>) {
    }

    getEndLatLongLocation(): Option<LatLongLocation> {
        return this.end;
    }

    getEndLatLongPoints(): Option<{ latitude: number, longitude: number }> {
        return this.getEndLatLongLocation().flatMap(x => x.getPoints());
    }

    getStartLatLongLocation(): Option<LatLongLocation> {
        return this.start;
    }

    getStartLatLongPoints(): Option<{ latitude: number, longitude: number }> {
        return this.getStartLatLongLocation().flatMap(x => x.getPoints());
    }

    // Accuracy is within 1 meter
    getStraightLineDistance(): Option<Distance> {
        return Option.map2(
            this.getStartLatLongPoints(),
            this.getEndLatLongPoints(),
            (a, b) => Distance.meters(getDistance(a, b)));
    }
}
