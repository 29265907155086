import {None, Option} from 'funfix-core';
import {
    addressKey,
    cityKey,
    countryKey,
    JsonBuilder,
    JsonSerializer,
    OptionUtils,
    parseString,
    postcodeKey,
    stateKey,
} from '../core';

export class PhysicalLocation {

    constructor(
        readonly city: Option<string> = None,
        readonly state: Option<string> = None,
        readonly country: Option<string> = None,
        readonly address: Option<string> = None,
        readonly postcode: Option<string> = None,
    ) {
    }

    getAddress(): Option<string> {
        return this.address;
    }

    getCity(): Option<string> {
        return this.city;
    }

    getCountry(): Option<string> {
        return this.country;
    }

    /**
     * Single Line
     */
    getLocationString(): Option<string> {
        const result: string = OptionUtils.toList(this.address, this.city, this.state, this.country, this.postcode)
            .reduce((a, b) => a.trim() === '' ? b : a + ', ' + b, '');
        return Option.of(result)
            .filter(r => r.trim() !== '');
    }

    /**
     * 4 Lines
     */
    getMultilineLocationString(): Option<string> {
        const line1: Option<string> = this.address;
        const line2: Option<string> = this.city;
        const line3: Option<string> = Option.of(OptionUtils.toList(this.state, this.postcode)
            .reduce((a, b) => a.trim() === '' ? b : a.trim() + ', ' + b.trim(), ''))
            .filter(r => r.trim() !== '');
        const line4: Option<string> = this.country;

        return Option.of(OptionUtils.toList(line1, line2, line3, line4)
            .reduce((a, b) => a.trim() === '' ? b.trim() : a.trim() + '\n' + b.trim(), ''))
            .filter(r => r.trim() !== '');
    }

    getPostCode(): Option<string> {
        return this.postcode;
    }

    getState(): Option<string> {
        return this.state;
    }

    isEmpty(): boolean {
        return this.city.isEmpty()
            && this.state.isEmpty()
            && this.country.isEmpty()
            && this.address.isEmpty()
            && this.postcode.isEmpty();
    }

    isUsefulLocation(): boolean {
        return this.address.nonEmpty();
    }
}

export class PhysicalLocationJsonSerializer<T extends PhysicalLocation> extends JsonSerializer<PhysicalLocation, T> {
    static instance: PhysicalLocationJsonSerializer<PhysicalLocation> = new PhysicalLocationJsonSerializer();

    fromJsonImpl(obj: any): PhysicalLocation {
        return new PhysicalLocation(
            parseString(obj[cityKey]),
            parseString(obj[stateKey]),
            parseString(obj[countryKey]),
            parseString(obj[addressKey]),
            parseString(obj[postcodeKey]));
    }

    protected toJsonImpl(location: PhysicalLocation, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(cityKey, location.city)
            .addOptional(stateKey, location.state)
            .addOptional(countryKey, location.country)
            .addOptional(addressKey, location.address)
            .addOptional(postcodeKey, location.postcode);
    }
}
