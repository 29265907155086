import {Either, Option} from 'funfix-core';
import {List, Map} from 'immutable';
import {EitherUtils, MapUtils} from '../core';
import {Person, PersonJsonSerializer} from './person';

export class PersonCache {
    constructor(readonly people: List<Person>) {
    }

    private byCompanyId: Map<number,
        List<Person>> = MapUtils.buildGroupedMapFromListOptional(this.people, e => e.companyId);
    private byId: Map<number, Person> = MapUtils.buildMapFromListOptional(
        this.people,
        e => e.id,
    );
    private byName: Map<string,
        List<Person>> = MapUtils.buildGroupedMapFromListOptional(this.people, e =>
        e.getFullName(),
    );
    private byNameLower: Map<string,
        List<Person>> = MapUtils.buildGroupedMapFromListOptional(this.people, e =>
        e.getFullName().map(x => x.toLowerCase()),
    );

    getByCompanyId(n: number): PersonCache {
        return new PersonCache(this.byCompanyId.get(n, List()));
    }

    getByCompanyIdEither(n: number): Either<string, List<Person>> {
        return EitherUtils.toEither(Option.of(this.byCompanyId.get(n, List())), 'Company does not exist');
    }

    getById(n: number): Option<Person> {
        return Option.of(this.byId.get(n));
    }

    getByIdEither(n: number): Either<string, Person> {
        return EitherUtils.toEither(Option.of(this.byId.get(n)), 'Person does not exist');
    }

    getByLowerCaseName(s: string): PersonCache {
        return new PersonCache(this.byNameLower.get(s.toLowerCase(), List()));
    }

    getByName(s: string): PersonCache {
        return new PersonCache(this.byName.get(s, List()));
    }

    getByNameContains(s: string): PersonCache {
        return new PersonCache(
            this.byName
                .keySeq()
                .filter(x => x.includes(s))
                .map(k => this.byName.get(k, List()))
                .reduce((acc, o) => acc.concat(o), List()),
        );
    }

    getByNameContainsEither(s: string): Either<string, List<Person>> {
        // FYI the error with the concat here is more of a warning in this context
        // If we reduce with the second param being a List then we end up returning an empty list
        // if there is no data which defeats the purpose of the Either as instead of hitting the Left
        // we are always getting a Right(List())
        return EitherUtils.toEither(
            Option.of(
                this.byName
                    .keySeq()
                    .filter(x => x.includes(s))
                    .map(k => this.byName.get(k, List()))
                    .reduce((acc, o) => acc.concat(o), List()),
            ),
            'Person does not exist',
        );
    }

    getByNameEither(s: string): Either<string, List<Person>> {
        return EitherUtils.toEither(Option.of(this.byName.get(s, List())), 'Person does not exist');
    }

    getByNameLowerEither(s: string): Either<string, List<Person>> {
        return EitherUtils.toEither(Option.of(this.byNameLower.get(s, List())), 'Person does not exist');
    }

    take(n: number): PersonCache {
        return new PersonCache(this.people.take(n));
    }

    toJsonArray(): ReadonlyArray<object> {
        return PersonJsonSerializer.instance.toJsonArray(this.people);
    }

    update(companiesToUpdate: List<Person>): PersonCache {
        const toUpdateMap = MapUtils.buildMapFromListOptional(
            companiesToUpdate,
            e => e.id,
        );
        return new PersonCache(
            toUpdateMap
                .concat(this.byId)
                .valueSeq()
                .toList(),
        );
    }
}
