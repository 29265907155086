import {Either, None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    contactKey,
    descriptionsKey,
    EitherUtils,
    idKey,
    JsonBuilder,
    locationKey,
    mappingKey,
    mediaKey,
    nameKey,
    optionsKey,
    OptionUtils,
    parseListSerializable,
    parseNumber,
    parseString,
    ratingKey,
    SimpleJsonSerializer,
    subproductsKey,
    translationsKey,
    typeKey,
} from '../core';
import {Contact, ContactJsonSerializer} from './contact';
import {DescriptionTranslations, DescriptionTranslationsJsonSerializer} from './description-translations';
import {Descriptions, DescriptionsJsonSerializer} from './descriptions';
import {GpsLocation, GpsLocationJsonSerializer} from './gps-location';
import {Image, imageComparator} from './image';
import {Mapping, MappingJsonSerializer} from './mapping';
import {MediaLibrary, MediaLibraryJsonSerializer} from './media-library';
import {Product, ProductJsonSerializer} from './product';
import {ProductScore} from './product-rating';
import {Rating, RatingUtils} from './rating';
import {Video} from './video';

export class ProductOptionLike {
    buildProductOption(): Option<ProductOption> {
        return Option.of(
            new ProductOption(
                this.getId(),
                this.getOptionName(),
                this.getDescriptions(),
                this.getTranslations(),
                this.getOptionType(),
                this.getRating(),
                this.getLocation(),
                this.getContact(),
                this.getMediaLibrary(),
                this.getMapping(),
                this.getSubproducts(),
            ),
        );
    }

    buildProductOptionEither(): Either<string, ProductOption> {
        return EitherUtils.toEither(this.buildProductOption(), 'Failed to build product option');
    }

    getContact(): Option<Contact> {
        return None;
    }

    getDescriptions(): Option<Descriptions> {
        return None;
    }

    getId(): Option<number> {
        return None;
    }

    getImages(): List<Image> {
        return OptionUtils.toList(this.getMediaLibrary()).flatMap(x => x.getImages())
            .sort((a, b) => imageComparator.compare(a, b));
    }

    getImageUrls(): List<string> {
        return OptionUtils.flattenList(this.getImages().map(x => x.uri))
            .map(x => x.getHref());
    }

    getLocation(): Option<GpsLocation> {
        return None;
    }

    getLongDescription(): Option<string> {
        return None;
    }

    getMapping(): List<Mapping> {
        return List();
    }

    getMediaLibrary(): Option<MediaLibrary> {
        return None;
    }

    getOptionName(): Option<string> {
        return None;
    }

    getOptionType(): Option<string> {
        return None;
    }

    getProductImageCount(): number {
        return this.getMediaLibrary()
            .map(x => x.getImages().size)
            .getOrElse(0);
    }

    getProductVideoCount(): number {
        return this.getMediaLibrary()
            .map(x => x.getImages().size)
            .getOrElse(0);
    }

    getRating(): Option<number> {
        return None;
    }

    getShortDescription(): Option<string> {
        return None;
    }

    getSubproducts(): List<Product> {
        return List();
    }

    getTranslations(): Option<DescriptionTranslations> {
        return None;
    }

    getVideos(): List<Video> {
        return OptionUtils.toList(this.getMediaLibrary())
            .flatMap(x => x.getVideos());
    }

    isEmpty(): boolean {
        return (
            this.getId().isEmpty() &&
            this.getOptionName().isEmpty() &&
            this.getOptionType().isEmpty() &&
            this.getDescriptions().forAll(d => d.isEmpty()) &&
            this.getMediaLibrary().forAll(d => d.isEmpty()) &&
            this.getRating().isEmpty() &&
            this.getLocation().forAll(d => d.isEmpty()) &&
            this.getContact().forAll(d => d.isEmpty()) &&
            this.getMapping().isEmpty()
        );
    }
}

export class ProductOption extends ProductOptionLike {
    constructor(
        readonly id: Option<number> = None,
        readonly name: Option<string> = None,
        readonly descriptions: Option<Descriptions> = None,
        readonly translations: Option<DescriptionTranslations> = None,
        readonly type: Option<string> = None,
        readonly rating: Option<number> = None,
        readonly location: Option<GpsLocation> = None,
        readonly contact: Option<Contact> = None,
        readonly mediaLibrary: Option<MediaLibrary> = None,
        readonly mapping: List<Mapping> = List(),
        readonly subproducts: List<Product> = List(),
    ) {
        super();
    }

    calculateUpdates(productOption: ProductOption): ProductOption {
        return new ProductOption(
            this.getId(),
            this.getOptionName(),
            OptionUtils.applyOrReturnNonEmpty(this.getDescriptions(), productOption.getDescriptions(), (a, b) => a.calculateUpdates(b)),
            this.getTranslations(),
            this.getOptionType(),
            this.getRating().orElse(productOption.getRating()),
            this.getLocation().orElse(productOption.getLocation()),
            OptionUtils.applyOrReturnNonEmpty(this.getContact(), productOption.getContact(), (a, b) => a.calculateUpdates(b)),
            this.getMediaLibrary().orElse(productOption.getMediaLibrary()),
            this.mapping,
        );
    }

    getContact(): Option<Contact> {
        return super.getContact();
    }

    getDescriptions(): Option<Descriptions> {
        return this.descriptions;
    }

    getId(): Option<number> {
        return this.id;
    }

    getLocation(): Option<GpsLocation> {
        return super.getLocation();
    }

    getLongDescription(): Option<string> {
        return this.getDescriptions()
            .flatMap(x => x.getLong());
    }

    getMapping(): List<Mapping> {
        return this.mapping;
    }

    getMediaLibrary(): Option<MediaLibrary> {
        return this.mediaLibrary;
    }

    getOptionName(): Option<string> {
        return this.name;
    }

    getOptionType(): Option<string> {
        return this.type;
    }

    getOverallRating(): Rating {
        return this.getRelevantRatingMinimum();
    }

    getProductScore(): ProductScore {
        return new ProductScore(
            this.getMediaLibrary()
                .map(x => x.getImageRating())
                .getOrElse('Black'),
            this.getMediaLibrary()
                .map(x => x.getImageCountRating())
                .getOrElse('Black'),
            this.getLocation()
                .map(x => x.getRating())
                .getOrElse('Black'),
            'Gold',
            'Gold',
            'Gold',
            'Gold',
            this.getMediaLibrary()
                .map(x => x.getVideoRating())
                .getOrElse('Black'),
            this.getContact()
                .map(x => x.getCompanyContactRating())
                .getOrElse('Black'),
        );
    }

    getRating(): Option<number> {
        return super.getRating();
    }

    private getRelevantRatingAverage(): Rating {
        return RatingUtils.average(this.getRelevantRatings());
    }

    private getRelevantRatingMinimum(): Rating {
        return RatingUtils.average(this.getRelevantRatings());
    }

    private getRelevantRatings(): List<Rating> {
        const score = this.getProductScore();
        return List.of(score.imageRating, score.imageCountRating, score.locationRating, score.videoRating, score.contactRating);
    }

    getShortDescription(): Option<string> {
        return this.descriptions.flatMap(x => x.short);
    }

    getSubproducts(): List<Product> {
        return this.subproducts;
    }

    getTranslations(): Option<DescriptionTranslations> {
        return this.translations;
    }

    withoutMappings(): ProductOption {
        return new ProductOption(
            this.id,
            this.name,
            this.descriptions,
            this.translations,
            this.type,
            this.rating,
            this.location,
            this.contact,
            this.mediaLibrary,
            List(),
            this.subproducts,
        );
    }

    withSubproducts(subproducts: List<Product>): ProductOption {
        return new ProductOption(
            this.id,
            this.name,
            this.descriptions,
            this.translations,
            this.type,
            this.rating,
            this.location,
            this.contact,
            this.mediaLibrary,
            this.mapping,
            subproducts,
        );
    }
}

export class ProductOptionJsonSerializer extends SimpleJsonSerializer<ProductOption> {
    static instance: ProductOptionJsonSerializer = new ProductOptionJsonSerializer();

    fromJsonImpl(obj: any): ProductOption {
        return new ProductOption(
            parseNumber(obj[idKey]),
            parseString(obj[nameKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[descriptionsKey]),
            DescriptionTranslationsJsonSerializer.instance.fromJson(obj[translationsKey]),
            parseString(obj[typeKey]),
            parseNumber(obj[ratingKey]),
            GpsLocationJsonSerializer.instance.fromJson(obj[locationKey]),
            ContactJsonSerializer.instance.fromJson(obj[contactKey]),
            MediaLibraryJsonSerializer.instance.fromJson(obj[mediaKey]),
            parseListSerializable(obj[mappingKey], MappingJsonSerializer.instance),
            parseListSerializable(obj[subproductsKey], ProductJsonSerializer.instance),
        );
    }

    protected toJsonImpl(option: ProductOption, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, option.id)
            .addOptional(nameKey, option.name)
            .addOptionalSerializable(descriptionsKey, option.descriptions, DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(translationsKey, option.translations, DescriptionTranslationsJsonSerializer.instance)
            .addOptional(typeKey, option.type)
            .addOptional(ratingKey, option.rating)
            .addOptionalSerializable(locationKey, option.location, GpsLocationJsonSerializer.instance)
            .addOptionalSerializable(contactKey, option.contact, ContactJsonSerializer.instance)
            .addOptionalSerializable(mediaKey, option.mediaLibrary, MediaLibraryJsonSerializer.instance)
            .addIterableSerializable(mappingKey, option.mapping, MappingJsonSerializer.instance)
            .addIterableSerializable(subproductsKey, option.subproducts, ProductJsonSerializer.instance);
    }
}
