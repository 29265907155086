import {Option} from 'funfix-core';
import {Video} from './video';

export class VideoForGallery {
    constructor(
        readonly entryId: Option<number>,
        readonly productId: Option<number>,
        readonly video: Option<Video>,
        readonly title: Option<string>,
        readonly description: Option<string>,
    ) {
    }
}
