import {None, Option, Some} from 'funfix-core';

import {List} from 'immutable';
import {
    costKey,
    currencyFromIdKey,
    currencyToIdKey,
    fakeIdxKey,
    JsonBuilder,
    OptionUtils,
    priceKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {EnumConstantMap} from './enum-constant';
import {Financials} from './financials';
import {ProposalLike} from './proposal';
import {ProposalEntry} from './proposal-entry';

export class DBFinancials implements Validatable {

    constructor(
        readonly cost: Option<number> = None,
        readonly price: Option<number> = None,
        readonly currencyFromId: Option<number>,
        readonly currencyToId: Option<number>,
        readonly fakeIdx: Option<number>,
    ) { }

    static fromEntries(entries: List<ProposalEntry>, currencyMap: EnumConstantMap): List<DBFinancials> {
        return OptionUtils.flattenList(entries.map((x, idx) => DBFinancials.fromEntry(x, idx, currencyMap)));
    }

    static fromEntry(entry: ProposalEntry, fakeIdx: number, currencyMap: EnumConstantMap): Option<DBFinancials> {
        return entry.getFinancials().map(f => DBFinancials.fromFinancials(f, Some(fakeIdx), currencyMap));
    }

    static fromFinancials(f: Financials, fakeIdx: Option<number>, currencyMap: EnumConstantMap): DBFinancials {
        return new DBFinancials(
            f.getCost(),
            f.getPrice(),
            currencyMap.getIdByOptName(f.currencyCost),
            currencyMap.getIdByOptName(f.currencySell),
            fakeIdx);
    }

    static fromProposal(proposal: ProposalLike, currencyMap: EnumConstantMap): Option<DBFinancials> {
        return proposal.getFinancials().map(f => DBFinancials.fromFinancials(f, None, currencyMap));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateInt('currencyFromId', this.currencyFromId.toString())),
            Some(ValidationUtils.validateInt('currencyToId', this.currencyToId.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBFinancialsJsonSerializer extends SimpleJsonSerializer<DBFinancials> {
    static instance: DBFinancialsJsonSerializer = new DBFinancialsJsonSerializer();

    fromJsonImpl(obj: any): DBFinancials {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(financialsProposal: DBFinancials, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(currencyFromIdKey, financialsProposal.currencyFromId)
            .addOptional(currencyToIdKey, financialsProposal.currencyToId)
            .addOptional(costKey, financialsProposal.cost)
            .addOptional(priceKey, financialsProposal.price)
            .addOptional(fakeIdxKey, financialsProposal.fakeIdx);
    }

}
