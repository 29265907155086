import * as _s from 'underscore.string';
import validator from 'validator';
import {ValidationResult} from './validation-result';

export class ValidationUtils {

    static validateChar(fieldName: string, s: string): ValidationResult {
        const ascii = `${fieldName} contains unicode, this may cause data corruption issues`;

        return this.validateStringLength(fieldName, s, 1)
            .warnIf(!validator.isAscii(s), ascii);
    }

    static validateEmail(fieldName: string, s: string, len: number): ValidationResult {
        if (!validator.isEmail(s)) {
            return ValidationResult.warn(`${fieldName}:${s} is not a valid email address`);
        }
        return ValidationResult.empty;
    }

    // TODO: number in JS is different to float in SQL, we should validate that.
    // Note: Floats default to 53 in SQL
    static validateFloat(fieldName: string, s: string, len: number = 53): ValidationResult {
        return ValidationResult.empty;
    }

    static validateInt(fieldName: string, s: string): ValidationResult {
        if (!validator.isInt(s, { allow_leading_zeroes: false, gt: -2147483649, lt: 2147483648 })) {
            return ValidationResult.error(`${fieldName}:${s} contains number larger then SQL int`);
        }
        return ValidationResult.empty;
    }

    static validateNchar(fieldName: string, s: string): ValidationResult {
        return this.validateStringLength(fieldName, s, 1);
    }

    static validateNvarchar(fieldName: string, s: string, len: number): ValidationResult {
        return this.validateStringLength(fieldName, s, len);
    }

    // TODO: validator.js only handles mobile numbers not landlines
    static validatePhone(fieldName: string, s: string, len: number): ValidationResult {
        return ValidationUtils.validateStringLength(fieldName, s, len);
    }

    private static validateStringLength(fieldName: string, s: string, len: number): ValidationResult {
        if (s.length > len) {
            return ValidationResult.warn(
                `${fieldName} too long(${s.length}), will truncate to ${len} characters`);
        }

        return ValidationResult.empty;
    }

    // Titles cannot have new lines or tabs
    static validateTitle(fieldName: string, s: string): ValidationResult {
        const nl = `${fieldName} contains new lines, will be replaced with spaces`;
        const tab = `${fieldName} contains new tabs, will be replaced with spaces`;

        return ValidationResult.empty
            .warnIf(_s.contains(s, '\n'), nl)
            .warnIf(_s.contains(s, '\t'), tab);
    }

    static validateTitleNvarchar(fieldName: string, s: string, len: number): ValidationResult {
        return ValidationUtils.validateNvarchar(fieldName, s, len)
            .merge(ValidationUtils.validateTitle(fieldName, s));
    }

    static validateTitleVarchar(fieldName: string, s: string, len: number): ValidationResult {
        return ValidationUtils.validateVarchar(fieldName, s, len)
            .merge(ValidationUtils.validateTitle(fieldName, s));
    }

    // Assumed nvarchar
    static validateUrl(fieldName: string, s: string, len: number): ValidationResult {
        return ValidationUtils.validateStringLength(fieldName, s, len)
            .warnIf(!validator.isURL(s), `${fieldName}:${s} is not a valid url`);
    }

    static validateVarchar(fieldName: string, s: string, len: number): ValidationResult {
        const ascii = `${fieldName} contains unicode, this may cause data corruption issues`;

        return this.validateStringLength(fieldName, s, len)
            .warnIf(!validator.isAscii(s), ascii);
    }
}
