import { None, Option } from 'funfix-core';
import {
    defaultsKey,
    descriptionKey,
    idKey,
    JsonBuilder,
    nameKey,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
} from '../core';
import { ProposalTemplateDefaults, ProposalTemplateDefaultsJsonSerializer } from './proposal-template-defaults';

export class ProposalTemplate {
    constructor(
        readonly id: Option<number> = None,
        readonly name: Option<string> = None,
        readonly description: Option<string> = None,
        readonly defaults: Option<ProposalTemplateDefaults> = None,
    ) { }

    getDefaults(): Option<ProposalTemplateDefaults> {
        return this.defaults;
    }

    getDescription(): Option<string> {
        return this.description;
    }

    getId(): Option<number> {
        return this.id;
    }

    getName(): Option<string> {
        return this.name;
    }

    isEmpty(): boolean {
        return this.id.isEmpty() &&
            this.name.isEmpty() &&
            this.description.isEmpty();
    }
}

export class ProposalTemplateJsonSerializer extends SimpleJsonSerializer<ProposalTemplate> {
    static instance: ProposalTemplateJsonSerializer = new ProposalTemplateJsonSerializer();

    fromJsonImpl(obj: any): ProposalTemplate {
        return new ProposalTemplate(
            parseNumber(obj[idKey]),
            parseString(obj[nameKey]),
            parseString(obj[descriptionKey]),
            ProposalTemplateDefaultsJsonSerializer.instance.fromJson(obj[defaultsKey]),
        );
    }

    protected toJsonImpl(proposalTemplate: ProposalTemplate, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(idKey, proposalTemplate.id)
            .addOptional(nameKey, proposalTemplate.name)
            .addOptional(descriptionKey, proposalTemplate.description)
            .addOptionalSerializable(defaultsKey, proposalTemplate.defaults, ProposalTemplateDefaultsJsonSerializer.instance);
    }
}
