export const maxKey = 'max';
export const minKey = 'min';
export const permissionsKey = 'permissions';
export const nameKey = 'name';
export const userKey = 'user';
export const accessAllProductsKey = 'access_all_products';
export const accessAllProposalsKey = 'access_all_proposals';
export const accessAllApisKey = 'access_all_apis';
export const accessAllTemplatesKey = 'access_all_templates';
export const manageCompaniesKey = 'manage_companies';
export const shortCodeKey = 'short_code';
export const passwordKey = 'password';
export const extraParam1Key = 'extra_param_1';
export const labelKey = 'label';
export const identityKey = 'identity';
export const uuidKey = 'uuid';
export const lastActiveKey = 'last_active';
export const joinedKey = 'joined';
export const personKey = 'person';
export const firstNameKey = 'first_name';
export const lastNameKey = 'last_name';
export const paxTypeKey = 'pax_type';
export const stateKey = 'state';
export const cityKey = 'city';
export const countryKey = 'country';
export const qsidKey = 'qsid';
export const idKey = 'id';
export const titleKey = 'title';
export const descriptionKey = 'description';
export const proposalTemplatesKey = 'proposal_templates';
export const templatesKey = 'templates';
export const relationshipsKey = 'relationships';
export const connectionsKey = 'connections';
export const templateIdKey = 'template_id';
export const presentAsKey = 'present_as';
export const emailKey = 'email';
export const phoneKey = 'phone';
export const secondaryPhoneKey = 'secondary_phone';
export const websiteKey = 'website';
export const imageKey = 'image';
export const zoomKey = 'zoom';
export const retrievedFromKey = 'retrieved_from';
export const colourKey = 'colour';
export const contactKey = 'contact';
export const facebookKey = 'facebook';
export const twitterKey = 'twitter';
export const instagramKey = 'instagram';
export const googleKey = 'google';
export const pintrestKey = 'pintrest';
export const tripAdvisorKey = 'trip_advisor';
export const locationKey = 'location';
export const mappingKey = 'mapping';
export const aboutKey = 'about';
export const referenceKey = 'reference';
export const inclusionsKey = 'inclusions';
export const exclusionsKey = 'exclusions';
export const noteKey = 'note';
export const notesKey = 'notes';
export const remoteKey = 'remote';
export const highKey = 'high';
export const lowKey = 'low';
export const thumb600Key = 'thumb600';
export const thumb100Key = 'thumb100';
export const thumb50Key = 'thumb50';
export const localKey = 'local';
export const termsKey = 'terms';
export const priceHeaderKey = 'price_header';
export const priceDescriptionKey = 'price_description';
export const welcomeKey = 'welcome';
export const travellersKey = 'travellers';
export const companyKey = 'company';
export const warningKey = 'warning';
export const agenciesKey = 'agencies';
export const startKey = 'start';
export const endKey = 'end';
export const createdTimeKey = 'created_time';
export const modifiedTimeKey = 'modified_time';
export const helpfulHintsKey = 'helpful_hints';
export const importantContactsKey = 'important_contacts';
export const agentKey = 'agent';
export const agentDetailsKey = 'agent_details';
export const budgetKey = 'budget';
export const peopleKey = 'people';
export const entryKey = 'entry';
export const entriesKey = 'entries';
export const addressKey = 'address';
export const postcodeKey = 'postcode';
export const logoKey = 'logo';
export const socialKey = 'social';
export const optionsKey = 'options';
export const proposalKey = 'proposal';
export const proposalsKey = 'proposals';
export const gpsKey = 'gps';
export const latitudeKey = 'latitude';
export const mapLatitudeKey = 'map_latitude';
export const markerLatitudeKey = 'marker_latitude';
export const mapLongitudeKey = 'map_longitude';
export const markerLongitudeKey = 'marker_longitude';
export const gpsLatitudeKey = gpsKey + '_' + latitudeKey;
export const longitudeKey = 'longitude';
export const gpsLongitudeKey = gpsKey + '_' + longitudeKey;
export const notationKey = 'notation';
export const degreesKey = 'degrees';
export const secKey = 'sec';
export const gpsLatitudeNotationKey = gpsLatitudeKey + '_' + notationKey;
export const gpsLatitudeMinKey = gpsLatitudeKey + '_' + minKey;
export const gpsLatitudeDegreesKey = gpsLatitudeKey + '_' + degreesKey;
export const gpsLatitudeSecKey = gpsLatitudeKey + '_' + secKey;
export const gpsLongitudeNotationKey = gpsLongitudeKey + '_' + notationKey;
export const gpsLongitudeDegreesKey = gpsLongitudeKey + '_' + degreesKey;
export const gpsLongitudeSecKey = gpsLongitudeKey + '_' + secKey;
export const gpsLongitudeMinKey = gpsLongitudeKey + '_' + minKey;
export const timeKey = 'time';
export const waypointKey = 'waypoint';
export const typeKey = 'type';
export const fieldKey = 'field';
export const caseSensitiveKey = 'case_sensitive';
export const productTypeKey = 'product_type';
export const entryTypeKey = 'entry_type';
export const linkKey = 'link';
export const fileKey = 'file';
export const pickupKey = 'pickup';
export const dropoffKey = 'dropoff';
export const imagesKey = 'images';
export const imagesToProcessKey = 'images_to_process';
export const videosKey = 'videos';
export const uriKey = 'uri';
export const urlKey = 'url';
export const heroKey = 'hero';
export const folderKey = 'folder';
export const fileExtensionKey = 'file_extension';
export const orderKey = 'order';
export const keywordKey = 'keyword';
export const keywordsKey = 'keywords';
export const confidenceKey = 'confidence';
export const keywordMapKey = 'keyword_map';
export const classificationsKey = 'classifications';
export const classificationKey = 'classification';
export const extraNotesKey = 'extra_notes';
export const languageKey = 'language';
export const mediaKey = 'media';
export const ratingKey = 'rating';
export const sortingKey = 'sorting';
export const sequenceKey = 'sequence';
export const transportKey = 'transport';
export const customKey = 'custom';
export const productKey = 'product';
export const productsKey = 'products';
export const suppliersKey = 'suppliers';
export const proposalCompaniesKey = 'proposal_companies';
export const destinationsKey = 'destinations';
export const statesKey = 'states';
export const countriesKey = 'countries';
export const mappingsKey = 'mappings';
export const productOptionKey = 'product_option';
export const confirmedKey = 'confirmed';
export const travellingKey = 'travelling';
export const quoteKey = 'quote';
export const statusKey = 'status';
export const meetingPointKey = 'meeting_point';
export const subproductsKey = 'subproducts';
export const optionSubproductsKey = 'option_subproducts';
export const itineraryKey = 'itinerary';
export const cancellationPolicyKey = 'cancellation_policy';
export const classKey = 'class';
export const flightInfoKey = 'flight_info';
export const terminalKey = 'terminal';
export const arrivalKey = 'arrival';
export const departureKey = 'departure';
export const durationKey = 'duration';
export const airlineKey = 'airline';
export const pnrKey = 'pnr';
export const seatsKey = 'seats';
export const mealsKey = 'meals';
export const equipmentKey = 'equipment';
export const stopsKey = 'stops';
export const operatedByKey = 'operatedBy';
export const reservationCodeKey = 'reservation_code';
export const passengerKey = 'passenger';
export const receiptKey = 'reciept';
export const mapKey = 'map';
export const pdfKey = 'pdf';
export const markerKey = 'marker';
export const markersKey = 'markers';
export const travelModeKey = 'travel_mode';
export const legsKey = 'legs';
export const stepsKey = 'steps';
export const requiredCheckInKey = 'required_check_in';
export const shortKey = 'short';
export const longKey = 'long';
export const bytesKey = 'bytes';
export const widthKey = 'width';
export const heightKey = 'height';
export const hashKey = 'hash';
export const copyrightKey = 'copyright';
export const captureDateKey = 'capture_date';
export const imageAtUrlHashKey = 'image_at_url_hash';
export const analysisKey = 'analysis';
export const dpiKey = 'dpi';
export const descriptionsKey = 'descriptions';
export const imageDataKey = 'image_data';
export const coreImageDataKey = 'core_image_data';
export const coreImageIdKey = 'core_image_id';
export const urlsKey = 'urls';
export const messagesKey = 'messages';
export const messageKey = 'message';
export const deletedKey = 'deleted';
export const toKey = 'to';
export const fromKey = 'from';
export const resultsKey = 'results';
export const topicKey = 'topic';
export const maneuverKey = 'maneuver';
export const directionsKey = 'directions';
export const extraContentKey = 'extra_content';
export const translationsKey = 'translations';
export const configurationKey = 'configuration';
export const metaKey = 'meta';
export const instructionsKey = 'instructions';
export const distanceKey = 'distance';
export const startLatitudeKey = 'startLatitudeKey';
export const startLongitudeKey = 'startLongitudeKey';
export const endLatitudeKey = 'endLatitudeKey';
export const endLongitudeKey = 'endLongitudeKey';
export const typeIdKey = 'type_id';
export const stateIdKey = 'state_id';
export const regionKey = 'region_key';
export const cityIdKey = 'city_id';
export const countryIdKey = 'country_id';
export const productIdKey = 'product_id';
export const optcodeKey = 'opt_code';
export const ownerKey = 'owner';
export const accessorsKey = 'accessors';
export const apiKey = 'api';
export const apiKeyKey = 'api_key';
export const productOptionIdKey = 'product_option_id';
export const productNameKey = 'product_name';
export const optionIdKey = 'option_id';
export const fallbackXmlKey = 'fallback_xml';
export const optionNameKey = 'option_name';
export const prodFakeIdxKey = 'prod_fake_idx';
export const optFakeIdxKey = 'opt_fake_idx';
export const langIdKey = 'lang_id';
export const lengthIdKey = 'length_id';
export const proposalCompanyIdKey = 'proposal_company_id';
export const participantIdKey = 'participant_id';
export const participantNameKey = 'participant_name';
export const websitesKey = 'websites';
export const partialMatchesKey = 'partial_matches';
export const fullMatchesKey = 'full_matches';
export const proposalAdditionalPeopleIdKey = 'proposal_additional_people_id';
export const proposalIdKey = 'proposal_id';
export const personIdKey = 'person_id';
export const activeKey = 'active';
export const currencyIdKey = 'currency_id';
export const currencyKey = 'currency';
export const proposalCompanyFinancialsIdKey = 'proposal_company_financials_id';
export const defaultCostCurrencyIdKey = 'default_cost_currency_id';
export const defaultSellCurrencyIdKey = 'default_sell_currency_id';
export const commissionInclusiveExclusiveKey = 'commission_inclusive_exclusive';
export const commissionPercentageKey = 'commission_percentage';
export const proposalEntryCustomIdKey = 'proposal_entry_custom_id';
export const fakeIdxKey = 'fake_idx';
export const parentFakeIdxKey = 'parent_fake_idx';
export const contentCollectionIdKey = 'content_collection_id';
export const textKey = 'text';
export const noteIdxKey = 'note_idx';
export const customTitleKey = 'custom_title';
export const customDescriptionKey = 'custom_description';
export const financialsCurrencyIdKey = 'proposal_financials_currency_id';
export const proposalFinancialsCompanyIdKey = 'proposal_financials_company_id';
export const currencyTypeKey = 'currency_type';
export const currencyFromIdKey = 'currency_from_id';
export const currencyToIdKey = 'currency_to_id';
export const proposalCompanyFinancialIdKey = 'proposal_company_financial_id';
export const proposalFinancialIdKey = 'proposal_financial_id';
export const proposalEntryFinancialIdKey = 'proposal_entry_financial_id';
export const conversionRateKey = 'conversion_rate';
export const conversionDateKey = 'conversion_date';
export const proposalEntryIdKey = 'proposal_entry_id';
export const commissonKey = 'commission';
export const costKey = 'cost';
export const costCurrencyIdKey = 'cost_currency_id';
export const costSellAmountKey = 'cost_sell_amount';
export const priceKey = 'price';
export const priceCurrencyIdKey = 'price_currency_id';
export const companyIdKey = 'company_id';
export const didgigoUserNameKey = 'didgigo_username';
export const faxKey = 'fax';
export const salutationKey = 'salutation';
export const entryTypeIdKey = 'entry_type_id';
export const transportIdKey = 'transport_id';
export const customIdKey = 'custom_id';
export const entryStatusIdKey = 'entry_status_id';
export const startDateTimeKey = 'start_datetime';
export const endDateTimeKey = 'end_datetime';
export const showPriceKey = 'show_price';
export const pickupTimeKey = 'pickup_time';
export const fromCurrencyKey = 'from_currency';
export const toCurrencyKey = 'to_currency';
export const dateKey = 'to_currency';
export const dropoffTimeKey = 'dropoff_time';
export const pickupRemarkKey = 'pickup_remark';
export const dropoffRemarkKey = 'dropoff_remark';
export const entryReferenceKey = 'entry_reference';
export const confirmationReferenceKey = 'confirmation_reference';
export const entryClassKey = 'entry_class';
export const entryDescriptionKey = 'entry_description';
export const entryTitleKey = 'entry_title';
export const finishDateTimeKey = 'finish_datetime';
export const agentIdKey = 'agent_id';
export const languageIdKey = 'language_id';
export const proposalTypeIdKey = 'proposal_type_id';
export const proposalTypeKey = 'proposal_type';
export const statusIdKey = 'status_id';
export const nextActionIdKey = 'next_action_id';
export const nextActionDateKey = 'next_action_date';
export const proposalTitleKey = 'proposal_title';
export const proposalDescriptionKey = 'proposal_description';
export const startCountryIdKey = 'start_country_id';
export const startStateIdKey = 'start_state_id';
export const startCityIdKey = 'start_city_id';
export const startAddressKey = 'start_address';
export const estimatedBudgetMinKey = 'estimated_budget_min';
export const estimatedBudgetMaxKey = 'estimated_budget_max';
export const numberKidsKey = 'number_kids';
export const numberAdultsKey = 'number_adults';
export const welcomeTitleKey = 'welcome_title';
export const welcomeDescriptionKey = 'welcome_description';
export const welcomeImageKey = 'welcome_image';
export const entrySortingKey = 'entry_sorting';
export const agentIntroKey = 'agent_intro';
export const flightDetailsKey = 'flight_details';
export const notesSummaryKey = 'notes_summary';
export const primaryPaxStringKey = 'primary_pax_string';
export const proposalEntryTransportIdKey = 'proposal_entry_transport_id';
export const transportTitleKey = 'transport_title';
export const transportDescriptionKey = 'transport_description';
export const transportTypeKey = 'transport_type';
export const streetAddressKey = 'street_address';
export const finishCountryIdKey = 'finish_country_id';
export const finishStateIdKey = 'finish_state_id';
export const finishCityIdKey = 'finish_city_id';
export const finishAddressKey = 'finish_address';
export const additionalPeopleKey = 'additional_people';
export const proposalFinancialsKey = 'proposal_financials';
export const customNotesKey = 'custom_notes';
export const entryNotesKey = 'entry_notes';
export const entryFinancialsKey = 'entry_financials';
export const transportEntriesKey = 'transport_entries';
export const customEntriesKey = 'custom_entries';
export const proposalFinancialsCurrencyIdKey = 'proposal_financials_currency_id';
export const rateKey = 'rate';
export const ratesKey = 'rates';
export const currencySellKey = 'currency_sell';
export const currencyCostKey = 'currency_cost';
export const commissionPercentKey = 'commission_percent';
export const commissionInclusiveKey = 'commission_inclusive';
export const financialsKey = 'financials';
export const apiServiceIdKey = 'api_service_id';
export const shortDescriptionKey = 'short_description';
export const gettingThereKey = 'getting_there';
export const distanceFromCityCenterKey = 'distance:city_center';
export const distanceFromAirportKey = 'distance:airport';
export const frenchKey = 'french';
export const germanKey = 'german';
export const koreanKey = 'korean';
export const polishKey = 'polish';
export const danishKey = 'danish';
export const italianKey = 'italian';
export const dutchKey = 'dutch';
export const spanishKey = 'spanish';
export const swedishKey = 'swedish';
export const chineseKey = 'chinese';
export const moduleKey = 'module';
export const showAgentIntroKey = 'show_agent_intro';
export const showFlightDetailsKey = 'show_flight_details';
export const showNotesSummaryKey = 'show_notes_summary';
export const entryOrderKey = 'entry_order';
export const defaultsKey = 'defaults';
export const presentAsDefaultsKey = 'present_as_defaults';
export const agencyKey = 'agency';
export const templateKey = 'template';
export const connectionKey = 'connection';
export const secondsKey = 'seconds';
export const settingsKey = 'settings';
export const roleKey = 'role';
export const usingAsKey = 'usingAs';
export const agentCodeKey = 'agent_code';
export const proposalAutoImportSecondsKey = 'proposal_auto_import_seconds';
export const productAutoImportSecondsKey = 'product_auto_import_seconds';
export const apiIdKey = 'api_id';
export const codeKey = 'code';
export const maskedKey = 'masked';
export const totalKey = 'total';
export const discountKey = 'discount';
export const chargeKey = 'charge';
export const feesKey = 'fees';
export const iataKey = 'iata';
export const icaoKey = 'icao';
export const numberKey = 'number';
export const segmentKey = 'segment';
export const segmentsKey = 'segments';
export const cabinKey = 'cabin';
export const mealKey = 'meal';
export const smokingKey = 'smoking';
export const latKey = 'lat';
export const timezoneKey = 'timezone';
export const jetKey = 'jet';
export const regionalKey = 'regional';
export const itinerariesKey = 'itineraries';
export const aircraftKey = 'aircraft';
export const modelKey = 'model';
export const carKey = 'car';
export const driverKey = 'driver';
export const discountsKey = 'discounts';
export const guestsKey = 'guests';
export const roomsKey = 'rooms';
export const adultsKey = 'adults';
export const kidsKey = 'kids';
export const deckKey = 'deck';
export const roomKey = 'room';
export const shipKey = 'ship';
export const carrierKey = 'carrier';
export const vesselKey = 'vessel';
export const petsKey = 'pets';
export const accommodationsKey = 'accommodations';
export const lengthKey = 'length';
export const vehiclesKey = 'vehicles';
export const trailersKey = 'trailers';
export const nationalityKey = 'nationality';
export const minorsKey = 'minors';
export const passportKey = 'passport';
export const visaKey = 'visa';
export const homeKey = 'home';
export const mobileKey = 'mobile';
export const relationshipKey = 'relationship';
export const suburbKey = 'suburb';
export const manufacturerKey = 'manufacturer';
export const osKey = 'os';
export const deviceKey = 'device';
export const contactsKey = 'contacts';
