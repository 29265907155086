import {Option} from 'funfix-core';
import {List} from 'immutable';

export class Duration {

    constructor(
        readonly seconds: number) {
    }

    static empty = new Duration(0);

    static days(n: number): Duration {
        return Duration.hours(n * 24);
    }

    static hours(n: number): Duration {
        return Duration.minutes(n * 60);
    }

    static minutes(n: number): Duration {
        return new Duration(n * 60);
    }

    add(d: Duration): Duration {
        return new Duration(d.seconds + this.seconds);
    }

    getDurationString(): string {
        if (this.isUnderOneMinute()) {
            return '< 1m';
        }

        const hours = this.getNonZero(this.getHours()).map(x => x.toFixed(0) + 'h');
        const minutes = this.getNonZero(this.getMinutes()).map(x => x.toFixed(0) + 'm');

        return List.of(hours, minutes).reduce((a, b) => b.isEmpty() ? a : a + ' ' + b.get(), '');
    }

    getHours(): number {
        return Math.floor(this.seconds / 3600);
    }

    getMinutes(): number {
        return Math.floor(this.seconds % 3600 / 60);
    }

    private getNonZero(n: number): Option<number> {
        return Option.of(n).filter(x => x >= 1);
    }

    private getPlural(n: number): string {
        return n === 1 ? '' : 's';
    }

    getSeconds(): number {
        return Math.floor(this.seconds % 3600 % 60);
    }

    isUnderOneMinute(): boolean {
        return this.seconds < 60;
    }
}
