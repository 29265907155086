import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    imageKey,
    JsonBuilder,
    latitudeKey,
    longitudeKey,
    markersKey,
    parseListSerializable,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
    zoomKey,
} from '../core';
import {MapMarker, MapMarkerJsonSerializer} from './map-marker';

export class GMap {
  constructor(
    readonly latitude: Option<number> = None,
    readonly longitude: Option<number> = None,
    readonly zoom: Option<number> = None,
    readonly image: Option<string> = None,
    readonly marker: List<MapMarker> = List(),
  ) {
  }

  getImage(): Option<string> {
    return this.image;
  }

  getLatitude(): Option<number> {
    return this.latitude;
  }

  getLongitude(): Option<number> {
    return this.longitude;
  }

  getMarker(): List<MapMarker> {
    return this.marker;
  }

  getZoom(): Option<number> {
    return this.zoom;
  }

  isEmpty(): boolean {
    return this.getMarker()
      .every(m => m.isEmpty());
  }

  isUseful(): boolean {
    return this.getLatitude().nonEmpty()
      && this.getLongitude().nonEmpty()
      && this.getZoom().nonEmpty() &&
      !this.isEmpty();
  }
}

export class GoogleMapJsonSerializer extends SimpleJsonSerializer<GMap> {
  static instance: GoogleMapJsonSerializer = new GoogleMapJsonSerializer();

  fromJsonImpl(obj: any): GMap {
    return new GMap(
      parseNumber(obj[latitudeKey]),
      parseNumber(obj[longitudeKey]),
      parseNumber(obj[zoomKey]),
      parseString(obj[imageKey]),
      parseListSerializable(obj[markersKey], MapMarkerJsonSerializer.instance),
    );
  }

  protected toJsonImpl(value: GMap, builder: JsonBuilder): JsonBuilder {
    return builder
      .addOptional(latitudeKey, value.latitude)
      .addOptional(longitudeKey, value.longitude)
      .addOptional(zoomKey, value.zoom)
      .addOptional(imageKey, value.image)
      .addIterableSerializable(markersKey, value.marker, MapMarkerJsonSerializer.instance);
  }
}
