import {None, Option} from 'funfix-core';
import {
    companyKey,
    JsonBuilder,
    mappingKey,
    ownerKey,
    parseString,
    productKey,
    SimpleJsonSerializer,
    StringSearchType,
    StringUtils,
    warningKey,
} from '../core';
import {Company, CompanyJsonSerializer} from './company';
import {Mapping, MappingJsonSerializer} from './mapping';
import {Product, ProductJsonSerializer} from './product';

export class MappingDetails {
    constructor(
        readonly mapping: Option<Mapping> = None,
        readonly product: Option<Product> = None,
        readonly supplier: Option<Company> = None,
        readonly owner: Option<Company> = None,
        readonly warning: Option<string> = None,
    ) {
    }

    getApiReference(): Option<string> {
        return this.mapping.flatMap(x => x.optcode);
    }

    getMappingOwnerName(): Option<string> {
        return this.owner.flatMap(x => x.name);
    }

    getOwnerName(): Option<string> {
        return this.owner.flatMap(x => x.name);
    }

    getProductCity(): Option<string> {
        return this.product.flatMap(x => x.location).flatMap(x => x.getCity());
    }

    getProductCountry(): Option<string> {
        return this.product.flatMap(x => x.location).flatMap(x => x.getCountry());
    }

    getProductId(): Option<number> {
        return this.mapping.flatMap(x => x.productId);
    }

    getProductName(): Option<string> {
        return this.product.flatMap(x => x.name);
    }

    getProductType(): Option<string> {
        return this.product.flatMap(x => x.type);
    }

    getSupplierName(): Option<string> {
        return this.supplier.flatMap(x => x.name);
    }

    hasWarning(): boolean {
        return this.warning.nonEmpty();
    }

    isProductProposalCompanyOwned(): boolean {
        return this.supplier.exists(x => x.isProposalCompany());
    }

    isProposalCompanyOwner(id: number): boolean {
        return this.isProductProposalCompanyOwned()
            && this.supplier.exists(x => x.getId().contains(id));
    }

    // Searches all relevant fields
    matchesFuzzySearch(comparison: string, type: StringSearchType, caseSensitive: boolean = false): boolean {
        return this.getApiReference().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type))
            || this.getProductName().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type))
            || this.getMappingOwnerName().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type))
            || this.getSupplierName().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type))
            || this.getProductCountry().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type))
            || this.getProductCity().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type))
            || this.getProductId().exists(str => StringUtils.stringSearchMatch(caseSensitive, str.toString(), comparison, type))
            || this.getProductType().exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type));
    }
}

export class MappingDetailsJsonSerializer extends SimpleJsonSerializer<MappingDetails> {
    static instance: MappingDetailsJsonSerializer = new MappingDetailsJsonSerializer();

    fromJsonImpl(obj: any): MappingDetails {
        return new MappingDetails(
            MappingJsonSerializer.instance.fromJson(obj[mappingKey]),
            ProductJsonSerializer.instance.fromJson(obj[productKey]),
            CompanyJsonSerializer.instance.fromJson(obj[companyKey]),
            CompanyJsonSerializer.instance.fromJson(obj[ownerKey]),
            parseString(obj[warningKey]),
        );
    }

    protected toJsonImpl(mapping: MappingDetails, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptionalSerializable(mappingKey, mapping.mapping, MappingJsonSerializer.instance)
            .addOptionalSerializable(productKey, mapping.product, ProductJsonSerializer.instance)
            .addOptionalSerializable(ownerKey, mapping.owner, CompanyJsonSerializer.instance)
            .addOptionalSerializable(companyKey, mapping.supplier, CompanyJsonSerializer.instance)
            .addOptional(warningKey, mapping.warning);
    }
}
