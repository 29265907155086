import {None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {
    CollectionUtils,
    JsonBuilder,
    Language,
    Languages,
    MediaService,
    metaKey,
    OptionUtils,
    proposalKey,
    SimpleJsonSerializer,
    StringUtils,
    today,
    tomorrow,
} from '../core';
import {CompanyLike} from './company';
import {Contact, ContactModel} from './contact';
import {Distance} from './distance';
import {Image} from './image';
import {ImageForGallery} from './image-for-gallery';
import {MapMarkerWithMeta} from './map-marker';
import {Metadata, MetadataJsonSerializer} from './metadata';
import {PhysicalLocation} from './physical-location';
import {Product} from './product';
import {Proposal, ProposalJsonSerializer} from './proposal';
import {EntryTypeFilter, ProposalEntry} from './proposal-entry';
import {ProposalEntryWithMeta} from './proposal-entry-with-meta';
import {Social} from './social';
import {VideoForGallery} from './video-for-gallery';

export class ProposalWithMeta {
    constructor(
        readonly proposal: Option<Proposal> = None,
        readonly meta: Option<Metadata> = None,
    ) {
    }

    // Id Equality. Used by Angular for change detection. Its not true equality....
    public equals(obj: ProposalWithMeta): boolean {
        return OptionUtils.exists2(obj.getProposalId(), this.getProposalId(), (a, b) => a === b);
    }

    getAccommodationNear(entry: ProposalEntryWithMeta): Option<ProposalEntryWithMeta> {
        const entries = entry.getProposalWithMeta().getProposal().getSortedEntries();
        const idx = entries.indexOf(entry.getEntry());

        if (idx === -1) {
            return None;
        }

        // Destinations come before the hotel
        const entriesBefore = entry.getEntry().isGeneralDestination() ?
            entries.reverse().takeUntil(x => x === entry.getEntry()) :
            entries.take(idx);

        const firstAccom = Option.of(entriesBefore.reverse().find(e => e.isAccommodation()));
        return firstAccom
            .filter(x => x.getLatLongLocation().flatMap(l => entry.getStraightLineDistance(l)).exists(d => d.isWithin(Distance.km(200))))
            .map(x => this.getProposalEntryWithMeta(x));
    }

    // Note: Only returns a company if the agent is part of present as or owner company
    getAgentCompany(): Option<CompanyLike> {
        const companies = this.getCompaniesInBooking();
        return this.getProposal()
            .getAgent()
            .flatMap(x => Option.of(companies.find(c => OptionUtils.equals(c.getId(), x.companyId))));
    }

    getAgentContactCard(): Option<ContactModel> {
        const company = this.getAgentCompany();
        return this.getProposal()
            .getAgent()
            .map(a =>
                new ContactModel(
                    a.getFullName().orElse(company.flatMap(c => c.getName())),
                    a.getImage(),
                    true,
                    'Agent',
                    'person',
                    a.getLocation().orElse(company.flatMap(c => c.getLocation())).getOrElse(new PhysicalLocation()),
                    OptionUtils.applyOrReturnNonEmpty(a.getContact(), company.flatMap(c => c.getContact()), (x, y) => x.merge(y))
                        .getOrElse(new Contact()),
                    company.flatMap(c => c.getSocial()).getOrElse(new Social())))
            .filter(x => this.isAgentPhoneVisible() || this.isAgentEmailVisible());
    }

    getAllUsefulSubproductAndNormalMapMarkersOnDay(moment: Moment): List<MapMarkerWithMeta> {
        return this.getUsefulMapMarkersOnDay(moment)
            .concat(this.getUsefulSubproductMapMarkersWithMetaOnDay(moment));
    }

    getCompaniesInBooking(): Set<CompanyLike> {
        return OptionUtils.toSet(this.getProposal().getPresentAsCompany(), this.getProposal().getCompany());
    }

    getCompanyContactCard(): Option<ContactModel> {
        return this.getProposal().getCompany().map(c =>
            new ContactModel(
                c.getName(),
                c.getLogo().flatMap(x => x.getHref()),
                false,
                'Company',
                'business',
                c.getLocation().getOrElse(new PhysicalLocation()),
                c.getContact().getOrElse(new Contact()),
                c.getSocial().getOrElse(new Social())))
            .filter(x => this.isDisplayCompanyPhoneVisible() || this.isDisplayCompanyAddressVisible());
    }

    getContactCards(): List<ContactModel> {
        return OptionUtils.toList(
            this.getAgentContactCard(),
            this.getCompanyContactCard(),
            this.getPresentAsContactCard().filter(x => this.getProposal().isDisplayingAsChildCompany()))
            .filter(x => !x.isUseless());
    }

    getDestinationNear(entry: ProposalEntryWithMeta): Option<ProposalEntryWithMeta> {
        const entries = entry.getProposalWithMeta().getProposal().getSortedEntries();
        const idx = entries.indexOf(entry.getEntry());

        if (idx === -1) {
            return None;
        }

        // Destinations come before all entries in that area
        const entriesBefore = entries.takeUntil(x => x === entry.getEntry());

        const destination = Option.of(entriesBefore.reverse().find(e => e.isGeneralDestination()));
        return destination
            .filter(x => x.getLatLongLocation().flatMap(l => entry.getStraightLineDistance(l)).exists(d => d.isWithin(Distance.km(200))))
            .map(x => this.getProposalEntryWithMeta(x));
    }

    getDisplayAboutLabel(): string {
        return this.getMeta().getTranslatedLabelForField('About', 'About', this.getProposal().getStatus());
    }

    getDisplayAddressLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Address', 'Address', this.getProposal().getStatus());
    }

    getDisplayAgencyAddress(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getLocation().flatMap(v => v.address));
    }

    getDisplayAgencyCity(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getLocation().flatMap(v => v.city));
    }

    getDisplayAgencyCountry(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getLocation().flatMap(v => v.country));
    }

    getDisplayAgencyEmail(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getContact().flatMap(v => v.email));
    }

    getDisplayAgencyLogo(): Option<Image> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getLogo());
    }

    getDisplayAgencyName(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getName());
    }

    getDisplayAgencyNameLabel(): string {
        return this.getMeta().getTranslatedLabelForField('AgencyName', 'Agency', this.getProposal().getStatus());
    }

    getDisplayAgencyPhone(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getContact().flatMap(v => v.phone));
    }

    getDisplayAgencyState(): Option<string> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getLocation().flatMap(v => v.state));
    }

    getDisplayAgentEmail(): Option<string> {
        return this.getProposal().getAgent().flatMap(x => x.contact.flatMap(v => v.email));
    }

    getDisplayAgentName(): Option<string> {
        return this.getProposal().getConsult()
            .orElse(this.getProposal().getAgent().flatMap(x => x.getFullNameWithSalutation()));
    }

    getDisplayAgentPhone(): Option<string> {
        return this.getProposal().getAgent().flatMap(x => x.contact.flatMap(v => v.phone));
    }

    getDisplayAlternatePhoneLabel(): string {
        return this.getMeta().getTranslatedLabelForField('AlternatePhone', 'Alternate Phone', this.getProposal().getStatus());
    }

    getDisplayApiReference(): Option<string> {
        return this.getProposal().getReference();
    }

    getDisplayApiReferenceLabel(): string {
        return this.getMeta().getTranslatedLabelForField('ApiReference', 'Booking Reference', this.getProposal().getStatus());
    }

    getDisplayAppLink(type: 'Epresentation' = 'Epresentation'): Option<string> {
        switch (type) {
            case 'Epresentation':
            default:
                return this.getQuickstartId().map(q => `https://tripigo.app.link/ROgCdNffiU?qsid=${q}`);
        }
    }

    getDisplayAppLinkLabel(): string {
        return this.getMeta().getTranslatedLabelForField('AppLink', 'View in App', this.getProposal().getStatus());
    }

    getDisplayButtonLetsGetStartedLabel(): string {
        return this.getMeta().getTranslatedLabelForField('ButtonLetsGetStarted', 'LET\'S GET STARTED', this.getProposal().getStatus());
    }

    getDisplayCityLabel(): string {
        return this.getMeta().getTranslatedLabelForField('City', 'City', this.getProposal().getStatus());
    }

    getDisplayCompanyAddress(): Option<string> {
        return this.getProposal().getCompany().flatMap(x => x.getLocation()).flatMap(x => x.getAddress());
    }

    getDisplayCompanyPhone(): Option<string> {
        return this.getProposal().getCompany().flatMap(x => x.getContact()).flatMap(x => x.getPhone());
    }

    getDisplayCountryLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Country', 'Country', this.getProposal().getStatus());
    }

    getDisplayDayPageTitleForDate(m: Moment): string {
        return this.getRelativeDateString(m);
    }

    getDisplayDaysString(): Option<string> {
        return this.getMeta().getDayString(
            this.getProposal().getStartDateTime(),
            this.getProposal().getEndDateTime());
    }

    getDisplayDetailsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Details', 'Details', this.getProposal().getStatus());
    }

    getDisplayEdocLinkLabel(): string {
        return this.getMeta().getTranslatedLabelForField('EdocLink', 'View E-Docs', this.getProposal().getStatus());
    }

    getDisplayEmailLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Email', 'Email', this.getProposal().getStatus());
    }

    getDisplayEntryFilterAccommodationLabel(): string {
        return this.getMeta().getTranslatedLabelForField('EntryFilterAccommodation', 'Accommodation', this.getProposal().getStatus());
    }

    getDisplayEntryFilterAllLabel(): string {
        return this.getMeta().getTranslatedLabelForField('EntryFilterAll', 'All', this.getProposal().getStatus());
    }

    getDisplayEntryFilterFlightsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('EntryFilterFlights', 'Flights', this.getProposal().getStatus());
    }

    getDisplayEntryFilterToursLabel(): string {
        return this.getMeta().getTranslatedLabelForField('EntryFilterTours', 'Tours', this.getProposal().getStatus());
    }

    getDisplayEntryFilterTransportLabel(): string {
        return this.getMeta().getTranslatedLabelForField('EntryFilterTransport', 'Transport', this.getProposal().getStatus());
    }

    getDisplayExclusionsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Exclusions', 'Exclusions', this.getProposal().getStatus());
    }

    getDisplayHelpfulHints(): Option<string> {
        return this.getProposal().getHelpfulHints();
    }

    getDisplayHelpfulHintsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('HelpfulHints', 'Helpful Hints', this.getProposal().getStatus());
    }

    getDisplayImportantContacts(): Option<string> {
        return this.getProposal().getImportantContacts();
    }

    getDisplayImportantContactsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('ImportantContacts', 'Important Contacts', this.getProposal().getStatus());
    }

    getDisplayInclusionsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Inclusions', 'Inclusions', this.getProposal().getStatus());
    }

    getDisplayMessageTravellersLabel(): string {
        return this.getMeta().getTranslatedLabelForField('MessageTravellers', 'Message travellers', this.getProposal().getStatus());
    }

    getDisplayMoreInfoLabel(): string {
        return this.getMeta().getTranslatedLabelForField('MoreInfo', 'More Info', this.getProposal().getStatus());
    }

    getDisplayNavigateNowLabel(): string {
        return this.getMeta().getTranslatedLabelForField('NavigateNow', 'Navigate now', this.getProposal().getStatus());
    }

    getDisplayNightsString(): Option<string> {
        return this.getMeta().getNightString(
            this.getProposal().getStartDateTime(),
            this.getProposal().getEndDateTime());
    }

    getDisplayPaxAdultCount(): Option<number> {
        return this.getProposal().getTravellers().flatMap(x => x.numberOfAdults);
    }

    getDisplayPaxChildCount(): Option<number> {
        return this.getProposal().getTravellers().flatMap(x => x.numberOfKids);
    }

    getDisplayPaxNames(): List<string> {
        return OptionUtils.flattenList(
            this.getProposal()
                .getPeople()
                .map(x => x.getFullNameWithSalutation()),
        );
    }

    getDisplayPaxNamesString(): Option<string> {
        const displayPaxNames = this.getDisplayPaxNames();

        if (displayPaxNames.isEmpty()) {
            return None;
        }
        return Some(StringUtils.smartTextJoin(displayPaxNames));
    }

    getDisplayPdfLink(): Option<string> {
        return this.getProposal().pdf.flatMap(x => x.link);
    }

    getDisplayPdfLinkLabel(): string {
        return this.getMeta().getTranslatedLabelForField('PdfLink', 'View PDF', this.getProposal().getStatus());
    }

    getDisplayPhoneLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Phone', 'Phone', this.getProposal().getStatus());
    }

    getDisplayPostcodeLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Postcode', 'Postcode', this.getProposal().getStatus());
    }

    getDisplayPriceDescription(): Option<string> {
        return this.getProposal().getPriceDescription();
    }

    getDisplayPriceHeader(): Option<string> {
        return this.getProposal().getPriceHeader();
    }

    getDisplayPriceLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Price', 'Price', this.getProposal().getStatus());
    }

    getDisplayProposalAbout(): Option<string> {
        return this.getProposal().getAbout();
    }

    getDisplayProposalDateRange(): Option<string> {
        return this.getMeta().formatShortDateRange(
            this.getProposal().getStartDateTime(),
            this.getProposal().getEndDateTime(),
            'DSD',
            this.getProposalLanguage(),
            'ProposalDateRange',
            this.getProposal().getStatus());
    }

    getDisplayProposalDayRange(): Option<string> {
        return this.getMeta().formatShortDateRange(
            this.getProposal().getStartDateTime(),
            this.getProposal().getEndDateTime(),
            '2D',
            this.getProposalLanguage(),
            'ProposalDayRange',
            this.getProposal().getStatus());
    }

    getDisplayProposalDescription(): Option<string> {
        return this.getProposal().getDescription();
    }

    getDisplayProposalExclusions(): Option<string> {
        return this.getProposal().getExclusions();
    }

    getDisplayProposalHelpfulHintsPageTitle(): string {
        return this.getMeta().getTranslatedTitleForPage('Helpful Hints');
    }

    getDisplayProposalImportantContactsPageTitle(): string {
        return this.getMeta().getTranslatedTitleForPage('Important Contacts');
    }

    getDisplayProposalInclusions(): Option<string> {
        return this.getProposal().getInclusions();
    }

    getDisplayProposalMonthRange(): Option<string> {
        return this.getMeta().formatShortDateRange(
            this.getProposal().getStartDateTime(),
            this.getProposal().getEndDateTime(),
            '3M',
            this.getProposalLanguage(),
            'ProposalMonthRange',
            this.getProposal().getStatus());
    }

    getDisplayProposalNotes(): Option<string> {
        return this.getProposal().getNotes();
    }

    getDisplayProposalTermsPageTitle(): string {
        return this.getMeta().getTranslatedTitleForPage('Terms');
    }

    getDisplaySearchLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Search', 'Search', this.getProposal().getStatus());
    }

    getDisplaySendMessageToAgentLabel(): string {
        return this.getMeta().getTranslatedLabelForField('SendMessageToAgent', 'Message Agent', this.getProposal().getStatus());
    }

    getDisplaySendMessageToGroupLabel(): string {
        return this.getMeta().getTranslatedLabelForField('SendMessageToGroup', 'Open group chat', this.getProposal().getStatus());
    }

    getDisplaySendPrivateMessageToLabel(): string {
        return this.getMeta().getTranslatedLabelForField('SendPrivateMessage', 'Send private message to', this.getProposal().getStatus());
    }

    getDisplaySpeciallyPreparedForLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SpeciallyPreparedFor',
                'Specially prepared for',
                this.getProposal().getStatus(),
            );
    }

    getDisplayStateLabel(): string {
        return this.getMeta().getTranslatedLabelForField('State', 'State', this.getProposal().getStatus());
    }

    getDisplayTermsAndConditions(): Option<string> {
        return this.getProposal().getTerms();
    }

    getDisplayTermsLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Terms', 'Terms and Conditions', this.getProposal().getStatus());
    }

    getDisplayTitleAboutPage(): string {
        return this.getMeta().getTranslatedTitleForPage('About');
    }

    getDisplayTitleAdminPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Admin');
    }

    getDisplayTitleAgentPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Agent');
    }

    getDisplayTitleContactPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Contact');
    }

    getDisplayTitleDayByDayPage(): string {
        return this.getMeta().getTranslatedTitleForPage('DayByDay', Some('Day-by-day'));
    }

    getDisplayTitleHomePage(): string {
        return this.getMeta().getTranslatedTitleForPage('Home');
    }

    getDisplayTitleInfoPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Info');
    }

    getDisplayTitleItinerariesPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Itineraries');
    }

    getDisplayTitleItineraryPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Itinerary');
    }

    getDisplayTitleMapPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Map');
    }

    getDisplayTitleMediaPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Media');
    }

    getDisplayTitleMessagesPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Messages');
    }

    getDisplayTitlePricePage(): string {
        return this.getMeta().getTranslatedTitleForPage('Price');
    }

    getDisplayTitlePricingPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Pricing');
    }

    getDisplayTitleSnapshotPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Snapshot');
    }

    getDisplayTitleTermsAndConditionsPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Terms', Some('Terms and Conditions'));
    }

    getDisplayTitleTermsPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Terms');
    }

    getDisplayTitleTodayPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Today');
    }

    getDisplayTitleTomorrowPage(): string {
        return this.getMeta().getTranslatedTitleForPage('Tomorrow');
    }

    getDisplayTitleWelcomePage(): string {
        return this.getMeta().getTranslatedTitleForPage('Welcome');
    }

    getDisplayWebsiteLabel(): string {
        return this.getMeta().getTranslatedLabelForField('Website', 'Website', this.getProposal().getStatus());
    }

    getDisplayWelcomeDescription(): Option<string> {
        return this.getProposal().getWelcomeDescription().map(x => this.processTemplateStrings(x));
    }

    getDisplayWelcomeImage(): Option<Image> {
        return this.getProposal().getWelcomeImage();
    }

    getDisplayWelcomeTitle(): Option<string> {
        return this.getProposal().getWelcomeTitle();
    }

    getDisplayWelcomeTitleNonEmpty(): string {
        return this.getProposal().getWelcomeTitle()
            .getOrElse(this.getDisplayYourTripLabel());
    }

    getDisplayYourTripLabel(): string {
        return this.getMeta().getTranslatedLabelForField('YourTrip', 'Your Trip', this.getProposal().getStatus());
    }

    getDistanceToNearAccommodation(entry: ProposalEntryWithMeta): Option<Distance> {
        return this.getAccommodationNear(entry)
            .flatMap(x => x.getLatLongLocation().flatMap(l => entry.getStraightLineDistance(l)));
    }

    getDistanceToNearAccommodationForSubproduct(entry: ProposalEntryWithMeta, parent: ProposalEntryWithMeta): Option<Distance> {
        return this.getAccommodationNear(parent)
            .flatMap(x => x.getLatLongLocation().flatMap(l => entry.getStraightLineDistance(l)));
    }

    getDistanceToNearDestination(entry: ProposalEntryWithMeta): Option<Distance> {
        return this.getDestinationNear(entry)
            .flatMap(x => x.getLatLongLocation().flatMap(l => entry.getStraightLineDistance(l)));
    }

    getEpresentationTemplateId(): number {
        return this.getMeta().getNumberParameter('Epresentation', 2);
    }

    getImagesForGallery(count: number): List<ImageForGallery> {
        return this.getProposal().getEntries().flatMap(x => x.getImagesForGallery(count))
            .map(imageForGallery => imageForGallery.withImage(
                imageForGallery.image.map(i => Image.fromString(MediaService.getProductImage(i, 'low').getHref()))));
    }

    getImportantContactsMap(): List<readonly [string, List<readonly [string, string]>]> {
        if (this.getDisplayImportantContacts().isEmpty()) {
            return List();
        }
        const text = this.getDisplayImportantContacts().get();
        const sections = text.split('\n  ');
        return List(sections.map(s => {
            const lines: string[] = s.split('\n');
            const heading = Option.of(lines[0]).map(x => x.replace(':', '').trim());
            lines.shift();
            const details: List<readonly [string, string]> =
                List(lines.map(x => {
                    const data = x.split('\t');
                    return [data[0], data[1]] as const;
                }));
            if (heading.isEmpty()) {
                return None;
            }

            return Some([heading.get(), details] as const);
        })).filter(x => x.nonEmpty())
            .map(x => x.get());
    }

    getMapMarkersWithMeta(): List<MapMarkerWithMeta> {
        return CollectionUtils.distinctOptionList(this.getSortedEntries(), x => x.getProductId())
            .flatMap(x => x.getMapMarkersWithMeta());
    }

    getMeta(): Metadata {
        return this.meta.getOrElse(new Metadata());
    }

    getNextEntry(eid: number): Option<ProposalEntryWithMeta> {
        const sortedEntries = this.getSortedEntries();
        const idx = sortedEntries.findIndex(x => x.getId().contains(eid));
        if (idx === -1 || idx === sortedEntries.size - 1) {
            return None;
        } else {
            return Option.of(sortedEntries.get(idx + 1));
        }
    }

    getPresentAsCompanyId(): Option<number> {
        return this.getProposal().getPresentAsCompany().flatMap(x => x.getId());
    }

    getPresentAsContactCard(): Option<ContactModel> {
        return this.getProposal().getPresentAsCompany().map(c =>
            new ContactModel(
                c.getName(),
                c.getLogo().flatMap(x => x.getHref()),
                false,
                'Company',
                'business',
                c.getLocation().getOrElse(new PhysicalLocation()),
                c.getContact().getOrElse(new Contact()),
                c.getSocial().getOrElse(new Social())));
    }

    getPrevEntry(eid: number): Option<ProposalEntryWithMeta> {
        const sortedEntries = this.getSortedEntries();
        const idx = sortedEntries.findIndex(x => x.getId().contains(eid));
        if (idx === -1 || idx === 0) {
            return None;
        } else {
            return Option.of(sortedEntries.get(idx - 1));
        }
    }

    getProposal(): Proposal {
        return this.proposal.getOrElse(new Proposal());
    }

    getProposalEntryById(i: number): Option<ProposalEntryWithMeta> {
        const entry = Option.of(
            this.getProposal()
                .getEntries()
                .find(v => v.getId().contains(i)),
        );
        return entry.map(e => new ProposalEntryWithMeta(
            Some(e),
            Some(this),
        ));
    }

    getProposalEntryProductByEntryId(i: number): Option<Product> {
        return this.getProposalEntryById(i).flatMap(x => x.getProduct());
    }

    getProposalEntryWithMeta(e: ProposalEntry): ProposalEntryWithMeta {
        return new ProposalEntryWithMeta(Some(e), Some(this));
    }

    getProposalEntryWithMetaByProductId(i: number): Option<ProposalEntryWithMeta> {
        return this.getProposal().getEntryByProductId(i)
            .map(x => this.getProposalEntryWithMeta(x));
    }

    getProposalId(): Option<number> {
        return this.getProposal().getId();
    }

    getProposalLanguage(): Option<Language> {
        return this.getProposal()
            .getLanguage()
            .flatMap(l => Languages.parseLanguage(l));
    }

    getQuickstartId(): Option<string> {
        return this.getProposalId().map(x => btoa(`PR${x}`));
    }

    private getRelativeDateString(d: Moment): string {
        if (d.isSame(today(), 'd')) {
            return this.getDisplayTitleTodayPage();
        } else if (d.isSame(tomorrow(), 'd')) {
            return this.getDisplayTitleTomorrowPage();
        } else {
            return this.getMeta().formatDate(d, 'DayTitle', 'Do MMMM', this.getProposalLanguage(), None);
        }
    }

    getSortedEntries(filter: EntryTypeFilter = 'All', textFilter: string = ''): List<ProposalEntryWithMeta> {
        return this.getProposal()
            .getSortedEntries()
            .filter(x => x.matchesTypeFilter(filter) && x.matchesFuzzyTextFilter(textFilter))
            .map(e => this.getProposalEntryWithMeta(e));
    }

    getSortedEntriesForWhiteList(filter: Set<EntryTypeFilter> = Set.of('All'), textFilter: string = ''): List<ProposalEntryWithMeta> {
        return this.getProposal()
            .getSortedEntries()
            .filter(x => x.matchesTypeFilterSet(filter) && x.matchesFuzzyTextFilter(textFilter))
            .map(e => this.getProposalEntryWithMeta(e));
    }

    getSortedEntriesOnDay(moment: Moment, filter: Set<EntryTypeFilter> = Set.of('All')): List<ProposalEntryWithMeta> {
        return this.getSortedEntriesForWhiteList(filter)
            .filter(x => x.getEntry().isDuring(moment) && x.isUsefulMarkerOnMap());
    }

    getUsefulMapMarkersOnDay(moment: Moment): List<MapMarkerWithMeta> {
        return this.getSortedEntries()
            .filter(x => x.getEntry().isDuring(moment) && x.isUsefulMarkerOnMap())
            .flatMap(x => x.getMapMarkersWithMeta())
            .filter(x => !x.isEmpty());
    }

    getUsefulSubproductMapMarkersWithMetaOnDay(moment: Moment): List<MapMarkerWithMeta> {
        return this.getSortedEntries()
            .filter(x => x.getEntry().isDuring(moment))
            .flatMap(x => x.getSubproductMapMarkersWithMeta())
            .filter(x => !x.isEmpty());
    }

    getVideosForGallery(count: number): List<VideoForGallery> {
        return CollectionUtils.shuffleList(this.getProposal().getEntries().flatMap(x => x.getVideosForGallery(count)));
    }

    hasAccommodationNear(entry: ProposalEntryWithMeta): boolean {
        return this.getAccommodationNear(entry).isEmpty();
    }

    hasVisibleFieldForPricePage(): boolean {
        return this.isPriceHeaderVisible()
            || this.isPriceDescriptionVisible()
            || this.isProposalInclusionsVisible()
            || this.isProposalExclusionsVisible();
    }

    isAboutLabelVisible(): boolean {
        return this.getMeta().isLabelVisible('About', this.getProposal().getStatus());
    }

    isAboutPageVisible(): boolean {
        return this.getDisplayProposalAbout().nonEmpty() && this.getMeta().isPageVisible('About');
    }

    isAccommodationFilterVisible(): boolean {
        return !this.getSortedEntries('Accommodation').isEmpty();
    }

    isAgencyLogoVisible(): boolean {
        return !this.getProposal().getPresentAsCompany().flatMap(x => x.getLogo()).flatMap(x => x.uri).isEmpty() &&
            this.getMeta().isFieldVisible('AgencyLogo', this.getProposal().getStatus());
    }

    isAgencyNameLabelVisible(): boolean {
        return this.getMeta().isLabelVisible('AgencyName', this.getProposal().getStatus());
    }

    isAgencyNameVisible(): boolean {
        return !this.getDisplayAgencyName().isEmpty() &&
            this.getMeta().isFieldVisible('AgencyName', this.getProposal().getStatus());
    }

    isAgentEmailVisible(): boolean {
        return !this.getDisplayAgentEmail().isEmpty() &&
            this.getMeta().isFieldVisible('AgentEmail', this.getProposal().getStatus());
    }

    isAgentNameVisible(): boolean {
        return !this.getDisplayAgentName().isEmpty() &&
            this.getMeta().isFieldVisible('AgentName', this.getProposal().getStatus());
    }

    isAgentPhoneVisible(): boolean {
        return !this.getDisplayAgentPhone().isEmpty() &&
            this.getMeta().isFieldVisible('AgentPhone', this.getProposal().getStatus());
    }

    isApiReferenceLabelVisible(): boolean {
        return this.getMeta().isLabelVisible('ApiReference', this.getProposal().getStatus());
    }

    isApiReferenceVisible(): boolean {
        return this.getDisplayApiReference().nonEmpty() &&
            this.getMeta().isFieldVisible('ApiReference', this.getProposal().getStatus());
    }

    isAppLinkVisible(): boolean {
        return this.getDisplayAppLink('Epresentation').nonEmpty()
            && this.getMeta().isFieldVisible('AppLink', this.getProposal().getStatus());
    }

    isDestinationFilterVisible(): boolean {
        return !this.getSortedEntries('Destination').isEmpty();
    }

    isDisplayAgencyAddressVisible(): boolean {
        return this.getDisplayAgencyAddress().nonEmpty()
            && this.getMeta().isFieldVisible('AgencyAddress', this.getProposal().getStatus());
    }

    isDisplayAgencyCityVisible(): boolean {
        return this.getDisplayAgencyAddress().nonEmpty()
            && this.getDisplayAgencyCity().nonEmpty()
            && this.getMeta().isFieldVisible('AgencyAddress', this.getProposal().getStatus());
    }

    isDisplayAgencyCountryVisible(): boolean {
        return this.getDisplayAgencyAddress().nonEmpty()
            && this.getDisplayAgencyCountry().nonEmpty()
            && this.getMeta().isFieldVisible('AgencyAddress', this.getProposal().getStatus());
    }

    isDisplayAgencyNameVisible(): boolean {
        return this.getDisplayAgencyName().nonEmpty()
            && this.getMeta().isFieldVisible('AgencyName', this.getProposal().getStatus());
    }

    isDisplayAgencyPhoneVisible(): boolean {
        return this.getDisplayAgencyPhone().nonEmpty()
            && this.getMeta().isFieldVisible('AgencyPhone', this.getProposal().getStatus());
    }

    isDisplayAgencyStateVisible(): boolean {
        return this.getDisplayAgencyAddress().nonEmpty()
            && this.getDisplayAgencyState().nonEmpty()
            && this.getMeta().isFieldVisible('AgencyAddress', this.getProposal().getStatus());
    }

    isDisplayAgentEmailVisible(): boolean {
        return this.getProposal().getConsult().isEmpty()
            && this.getDisplayAgentEmail().nonEmpty()
            && this.getMeta().isFieldVisible('AgentEmail', this.getProposal().getStatus());
    }

    isDisplayAgentNameVisible(): boolean {
        return this.getDisplayAgentName().nonEmpty()
            && this.getMeta().isFieldVisible('AgentName', this.getProposal().getStatus());
    }

    isDisplayAgentPhoneVisible(): boolean {
        return this.getProposal().getConsult().isEmpty()
            && this.getDisplayAgentPhone().nonEmpty()
            && this.getMeta().isFieldVisible('AgentPhone', this.getProposal().getStatus());
    }

    isDisplayCompanyAddressVisible(): boolean {
        return this.getDisplayCompanyAddress().nonEmpty()
            && this.getMeta().isFieldVisible('CompanyAddress', this.getProposal().getStatus());
    }

    isDisplayCompanyPhoneVisible(): boolean {
        return this.getDisplayCompanyPhone().nonEmpty()
            && this.getMeta().isFieldVisible('CompanyPhone', this.getProposal().getStatus());
    }

    isFlightFilterVisible(): boolean {
        return !this.getSortedEntries('Flights').isEmpty();
    }

    isHelpfulHintsPageVisible(): boolean {
        return !this.getDisplayHelpfulHints().isEmpty()
            && this.getMeta().isPageVisible('Helpful Hints');
    }

    isHomePageVisible(): boolean {
        return this.getMeta().isPageVisible('Home');
    }

    isImportantContactsPageVisible(): boolean {
        return !this.getDisplayImportantContacts().isEmpty()
            && this.getMeta().isPageVisible('Important Contacts');
    }

    isItineraryPageVisible(): boolean {
        return this.getMeta().isPageVisible('Itinerary');
    }

    isMapPageVisible(): boolean {
        return this.getMeta().isPageVisible('Map');
    }

    isMediaPageVisible(): boolean {
        return this.getMeta().isPageVisible('Media');
    }

    isMessageAgentVisible(): boolean {
        return this.getMeta().isFieldVisible('MessageAgent', this.getProposal().getStatus());
    }

    isMessageGroupVisible(): boolean {
        return this.getMeta().isFieldVisible('MessageGroup', this.getProposal().getStatus());
    }

    isPaxAdultCountVisible(): boolean {
        return this.getDisplayPaxAdultCount().exists(x => x > 0) &&
            this.getMeta().isFieldVisible('PaxAdultCount', this.getProposal().getStatus());
    }

    isPaxChildCountVisible(): boolean {
        return this.getDisplayPaxChildCount().exists(x => x > 0) &&
            this.getMeta().isFieldVisible('PaxChildCount', this.getProposal().getStatus());
    }

    isPaxNamesVisible(): boolean {
        return !this.getDisplayPaxNames().isEmpty() &&
            this.getMeta().isFieldVisible('PaxNames', this.getProposal().getStatus());
    }

    isPdfLinkVisible(): boolean {
        return this.getDisplayPdfLink().nonEmpty() &&
            this.getMeta().isFieldVisible('PdfLink', this.getProposal().getStatus());
    }

    isPriceDescriptionVisible(): boolean {
        return this.getDisplayPriceDescription().nonEmpty() &&
            this.getMeta().isFieldVisible('PriceDescription', this.getProposal().getStatus());
    }

    isPriceHeaderVisible(): boolean {
        return this.getDisplayPriceHeader().nonEmpty() &&
            this.getMeta().isFieldVisible('PriceHeader', this.getProposal().getStatus());
    }

    isPricePageVisible(): boolean {
        return this.hasVisibleFieldForPricePage()
            && this.getMeta().isPageVisible('Price');
    }

    isPrivateMessageVisible(): boolean {
        return this.getMeta().isFieldVisible('MessagePrivate', this.getProposal().getStatus());
    }

    isProposalAboutVisible(): boolean {
        return this.getDisplayProposalAbout().nonEmpty() &&
            this.getMeta().isFieldVisible('About', this.getProposal().getStatus());
    }

    isProposalDateRangeVisible(): boolean {
        return this.getDisplayApiReference().nonEmpty() &&
            this.getMeta().isFieldVisible('ProposalDateRange', this.getProposal().getStatus());
    }

    isProposalDescriptionVisible(): boolean {
        return this.getDisplayProposalDescription().nonEmpty() &&
            this.getMeta().isFieldVisible('Description', this.getProposal().getStatus());
    }

    isProposalExclusionsVisible(): boolean {
        return this.getDisplayProposalExclusions().nonEmpty() &&
            this.getMeta().isFieldVisible('Exclusions', this.getProposal().getStatus());
    }

    isProposalInclusionsVisible(): boolean {
        return this.getDisplayProposalInclusions().nonEmpty() &&
            this.getMeta().isFieldVisible('Inclusions', this.getProposal().getStatus());
    }

    isProposalNotesVisible(): boolean {
        return this.getDisplayProposalNotes().nonEmpty() &&
            this.getMeta().isFieldVisible('Notes', this.getProposal().getStatus());
    }

    isSelfDriveFilterVisible(): boolean {
        return !this.getSortedEntries('SelfDrive').isEmpty();
    }

    isSnapshotPageVisible(): boolean {
        return this.getMeta().isPageVisible('Snapshot');
    }

    isTermsPageVisible(): boolean {
        return !this.getDisplayTermsAndConditions().isEmpty()
            && this.getMeta().isPageVisible('Terms');
    }

    isTourFilterVisible(): boolean {
        return !this.getSortedEntries('Tours').isEmpty();
    }

    isWelcomeDescriptionVisible(): boolean {
        return this.getDisplayWelcomeDescription().nonEmpty() &&
            this.getMeta().isFieldVisible('WelcomeDescription', this.getProposal().getStatus());
    }

    isWelcomeTitleVisible(): boolean {
        return this.getDisplayWelcomeTitle().nonEmpty()
            && this.getMeta().isFieldVisible('WelcomeTitle', this.getProposal().getStatus());
    }

    // HACK HACK HACK HACK HACK
    processTemplateStrings(text: string): string {
        return text.replace('@Agent_FirstName', this.getProposal().getAgent().flatMap(x => x.getFirstName()).getOrElse(''))
            .replace('@Agent_LastName', this.getProposal().getAgent().flatMap(x => x.getLastName()).getOrElse(''))
            .replace('@Company_Name', this.getProposal().getOwner().flatMap(x => x.getName()).getOrElse(''))
            .replace('@Proposal_Reference', this.getDisplayApiReference().getOrElse(''));
    }
}

export class ProposalWithMetaJsonSerializer extends SimpleJsonSerializer<ProposalWithMeta> {
    static instance: ProposalWithMetaJsonSerializer = new ProposalWithMetaJsonSerializer();

    fromJsonImpl(obj: any): ProposalWithMeta {
        return new ProposalWithMeta(
            ProposalJsonSerializer.instance.fromJson(obj[proposalKey]),
            MetadataJsonSerializer.instance.fromJson(obj[metaKey]));
    }

    protected toJsonImpl(proposalWithMeta: ProposalWithMeta, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptionalSerializable(proposalKey, proposalWithMeta.proposal, ProposalJsonSerializer.instance)
            .addOptionalSerializable(metaKey, proposalWithMeta.meta, MetadataJsonSerializer.instance);
    }
}

// Used to load proposals from API v0 unto API v1
export class ProposalWithMetaFallbackToProposalJsonSerializer extends SimpleJsonSerializer<ProposalWithMeta> {
    static instance: ProposalWithMetaJsonSerializer = new ProposalWithMetaJsonSerializer();

    fromJsonImpl(obj: any): ProposalWithMeta {
        if (obj[proposalKey] === undefined) {
            return new ProposalWithMeta(Some(ProposalJsonSerializer.instance.fromJsonImpl(obj)), None);
        }

        return new ProposalWithMeta(
            ProposalJsonSerializer.instance.fromJson(obj[proposalKey]),
            MetadataJsonSerializer.instance.fromJson(obj[metaKey]));
    }

    protected toJsonImpl(proposalWithMeta: ProposalWithMeta, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptionalSerializable(proposalKey, proposalWithMeta.proposal, ProposalJsonSerializer.instance)
            .addOptionalSerializable(metaKey, proposalWithMeta.meta, MetadataJsonSerializer.instance);
    }
}
