import {Option} from 'funfix-core';
import {Image} from './image';

export class ImageForGallery {
    constructor(
        readonly entryId: Option<number>,
        readonly productId: Option<number>,
        readonly image: Option<Image>,
        readonly title: Option<string>,
        readonly description: Option<string>,
    ) {
    }

    withImage(image: Option<Image>): ImageForGallery {
        return new ImageForGallery(
            this.entryId,
            this.productId,
            this.image,
            this.title,
            this.description);
    }
}
