import {None, Option} from 'funfix-core';
import {Map} from 'immutable';
import {
    defaultsKey,
    idKey,
    JsonBuilder,
    languageKey,
    logoKey,
    nameKey,
    parseMap,
    parseNumber,
    parseString,
    presentAsDefaultsKey,
    presentAsKey,
    relationshipsKey,
    SimpleJsonSerializer,
    templateKey,
    translationsKey,
    typeKey,
} from '../core';
import {
    Company,
    CompanyJsonSerializer,
    CompanyLike,
    CompanySummary,
    ProposalTemplateDefaults,
    ProposalTemplateDefaultsJsonSerializer,
    Relationships,
    RelationshipsJsonSerializer,
} from '../models';

export class CompanySummaryForTemplate extends CompanySummary {
    constructor(
        id: Option<number> = None,
        type: Option<string> = None,
        name: Option<string> = None,
        logo: Option<string> = None,
        relationships: Option<Relationships> = None,
        language: Option<string> = None,
        readonly companyDefaults: Option<ProposalTemplateDefaults> = None,
        readonly presentAs: Option<Company> = None,
        readonly presentAsCompanyDefaults: Option<ProposalTemplateDefaults> = None,
        readonly templateDefaults: Option<ProposalTemplateDefaults> = None,
        readonly translations: Map<string, string>,
    ) {
        super(id, type, name, logo, relationships, language, companyDefaults);
    }

    static buildFromCompanyLike(owner: CompanyLike, presentAs: CompanyLike, templateId: number, translations: Map<string, string>): CompanySummaryForTemplate {
        return new CompanySummaryForTemplate(
            owner.getId(),
            owner.getType(),
            owner.getName(),
            owner.getLogoHref(),
            None,
            presentAs.getLanguage().orElse(owner.getLanguage()),
            owner.getProposalTemplateDefaults(),
            presentAs.buildCompanyWithoutDefaults(),
            presentAs.getProposalTemplateDefaults(),
            owner.getProposalTemplateDefaultsById(templateId),
            translations);
    }
}

export class CompanySummaryForTemplateJsonSerializer extends SimpleJsonSerializer<CompanySummaryForTemplate> {
    static instance: CompanySummaryForTemplateJsonSerializer = new CompanySummaryForTemplateJsonSerializer();

    protected constructor() {
        super();
    }

    fromJsonImpl(obj: any): CompanySummaryForTemplate {
        return new CompanySummaryForTemplate(
            parseNumber(obj[idKey]),
            parseString(obj[typeKey]),
            parseString(obj[nameKey]),
            parseString(obj[logoKey]),
            RelationshipsJsonSerializer.instance.fromJson(obj[relationshipsKey]),
            parseString(obj[languageKey]),
            ProposalTemplateDefaultsJsonSerializer.instance.fromJson(obj[defaultsKey]),
            CompanyJsonSerializer.instance.fromJson(obj[presentAsKey]),
            ProposalTemplateDefaultsJsonSerializer.instance.fromJson(obj[presentAsDefaultsKey]),
            ProposalTemplateDefaultsJsonSerializer.instance.fromJson(obj[templateKey]),
            parseMap(obj[translationsKey], parseString),
        );
    }

    protected toJsonImpl(company: CompanySummaryForTemplate, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, company.id)
            .addOptional(nameKey, company.name)
            .addOptional(typeKey, company.type)
            .addOptional(logoKey, company.logo)
            .addOptionalSerializable(relationshipsKey, company.relationships, RelationshipsJsonSerializer.instance)
            .addOptional(languageKey, company.language)
            .addOptionalSerializable(
                presentAsKey,
                company.presentAs,
                CompanyJsonSerializer.instance)
            .addOptionalSerializable(
                defaultsKey,
                company.companyDefaults,
                ProposalTemplateDefaultsJsonSerializer.instance)
            .addOptionalSerializable(
                presentAsDefaultsKey,
                company.presentAsCompanyDefaults,
                ProposalTemplateDefaultsJsonSerializer.instance)
            .addOptionalSerializable(
                templateKey,
                company.proposalTemplateDefaults,
                ProposalTemplateDefaultsJsonSerializer.instance,
            )
            .addMap(translationsKey, company.translations, x => x);
    }
}
