import {Either, None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    agentKey,
    companyKey,
    EitherUtils,
    extraParam1Key,
    idKey,
    JsonBuilder,
    parseListSerializable,
    parseNumber,
    parseString,
    passwordKey,
    presentAsKey,
    settingsKey,
    SimpleJsonSerializer,
    userKey,
} from '../core';
import {ApiConnection} from './api-connection';
import {AutoImportProposals, ProposalAutoImportSettingsJsonSerializer} from './auto-import-proposals';
import {AutoImportSettings} from './auto-import-settings';
import {Company, CompanyJsonSerializer} from './company';
import {Person, PersonJsonSerializer} from './person';
import {ProposalTemplateDefaults} from './proposal-template-defaults';

export class ApiAgency {
    constructor(
        readonly id: Option<number> = None,
        readonly user: Option<string> = None,
        readonly password: Option<string> = None,
        readonly company: Option<Company> = None,
        readonly agent: Option<Person> = None,
        readonly presentAsCompany: Option<Company> = None,
        readonly proposalAutoImportSettings: List<AutoImportProposals> = List(),
        readonly extraParam1: Option<string> = None,
    ) {
    }

    static forAgentCompanyMapping(
      login: Option<string>,
      password: Option<string>,
      company: Option<Company>,
      presentAsCompany: Option<Company>,
      ): ApiAgency {
        return new ApiAgency(
            None,
          login,
          password,
          company,
          None,
          presentAsCompany,
        );
    }

    getAgencyId(): Option<number> {
        return this.id;
    }

    getAgent(): Option<Person> {
        return this.agent;
    }

    getApiRequestSettings(n: number, connection: ApiConnection): List<AutoImportSettings> {
        return this.proposalAutoImportSettings
            .filter(x => x.getSeconds().contains(n))
            .map(x => new AutoImportSettings(
                Some(this),
                Some(connection.withAgencies(List())),
                x.getTemplate(),
                x.getBookingStatus(),
            ));
    }

    getCompany(): Option<Company> {
        return this.company;
    }

    getCompanyDefaults(): Option<ProposalTemplateDefaults> {
        return this.company.flatMap(x => x.getProposalTemplateDefaults());
    }

    getCompanyId(): Option<number> {
        return this.company.flatMap(x => x.id);
    }

    getCompanyName(): Option<string> {
        return this.company.flatMap(x => x.name);
    }

    getCompanyWithoutDefaults(): Option<Company> {
        return this.company.map(x => x.withoutDefaults());
    }

    getExtraParam1(): Option<string> {
        return this.extraParam1;
    }

    getExtraParam1Either(): Either<string, string> {
        return EitherUtils.toEither(this.extraParam1, 'Missing Extra Param 1');
    }

    getPassword(): Option<string> {
        return this.password;
    }

    getPasswordEither(): Either<string, string> {
        return EitherUtils.toEither(this.password, 'Missing password');
    }

    getPresentAsCompany(): Option<Company> {
        return this.presentAsCompany;
    }

    getPresentAsCompanyDefaults(): Option<ProposalTemplateDefaults> {
        return this.presentAsCompany.flatMap(x => x.getProposalTemplateDefaults());
    }

    getPresentAsCompanyId(): Option<number> {
        return this.presentAsCompany.flatMap(x => x.id);
    }

    getPresentAsCompanyIdEither(): Either<string, number> {
        return EitherUtils.toEither(this.getPresentAsCompanyId(), 'Agency is missing present as id');
    }

    getPresentAsCompanyName(): Option<string> {
        return this.presentAsCompany.flatMap(x => x.name);
    }

    getPresentAsCompanyWithoutDefaults(): Option<Company> {
        return this.presentAsCompany.map(x => x.withoutDefaults());
    }

    getSummaryForLabel(): string {
        return Option.map2(this.getPresentAsCompanyName(), this.user, (n, u) => `${u} (${n})`)
            .orElse(this.getPresentAsCompanyName())
            .orElse(this.user)
            .getOrElse('');
    }

    getUser(): Option<string> {
        return this.user;
    }

    getUserNameEither(): Either<string, string> {
        return EitherUtils.toEither(this.user, 'Missing user');
    }

    isAccessibleByCompany(n: number): boolean {
        return this.getPresentAsCompanyId().contains(n) || this.getCompanyId().contains(n);
    }

    isEmpty(): boolean {
        return this.getUser().isEmpty()
            && this.getPassword().isEmpty()
            && this.getCompany().isEmpty()
            && this.getAgent().isEmpty()
            && this.getPresentAsCompany().isEmpty();
    }

}

export class ApiAgencyJsonSerializer extends SimpleJsonSerializer<ApiAgency> {
    static instance = new ApiAgencyJsonSerializer();

    protected fromJsonImpl(json: any): ApiAgency {
        return new ApiAgency(
            parseNumber(json[idKey]),
            parseString(json[userKey]),
            parseString(json[passwordKey]),
            CompanyJsonSerializer.instance.fromJson(json[companyKey]),
            PersonJsonSerializer.instance.fromJson(json[agentKey]),
            CompanyJsonSerializer.instance.fromJson(json[presentAsKey]),
            parseListSerializable(json[settingsKey], ProposalAutoImportSettingsJsonSerializer.instance),
            parseString(json[extraParam1Key]),
        );
    }

    protected toJsonImpl(value: ApiAgency, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, value.id)
            .addOptional(userKey, value.user)
            .addOptional(passwordKey, value.password)
            .addOptionalSerializable(companyKey, value.company, CompanyJsonSerializer.instance)
            .addOptionalSerializable(agentKey, value.agent, PersonJsonSerializer.instance)
            .addOptionalSerializable(presentAsKey, value.presentAsCompany, CompanyJsonSerializer.instance)
            .addIterableSerializable(settingsKey, value.proposalAutoImportSettings, ProposalAutoImportSettingsJsonSerializer.instance)
            .addOptional(extraParam1Key, value.extraParam1);
    }
}
