/**
 * Follows the Gang of 4 builder pattern to build
 * an immutable json representation of a give model.
 *
 * Simply create, add fields, then call build to finalize.
 *
 * new JsonBuilder
 *      .add("MeaningOfLife", 42)
 *      .build()
 *
 * will return
 *
 * {
 *  "MeaningOfLife": 42
 * }
 */
import {Option} from 'funfix-core';
import {Collection, Map} from 'immutable';
import {Moment} from 'moment';
import {JsonSerializer} from './json-serializer';
import {convertCollectionToArray, convertMapToObj, convertSerializableMapToObj} from './object-utils';
import {Url} from './url';
import {Primitive} from './utils';

/**
 * - Depth first.
 * - NOT STACK SAFE
 * - Strictly evaluated
 */
export class JsonBuilder {

    private obj: any = {};

    /**
     * Add primitive key value pair
     *
     * for adding Classes, see addSerializable
     */
    add(key: string, value: Primitive): JsonBuilder {
        this.obj[key] = value;
        return this;
    }

    /**
     * Add an optional primitive.
     *
     * Will not include the key value pair in the json if None
     *
     * for adding Classes, see addOptionalSerializable
     */
    addIterable(key: string, collection: Collection<any, Primitive>): JsonBuilder {
        if (!collection.isEmpty()) {
            this.obj[key] = collection.toArray();
        }

        return this;
    }

    /**
     * Handles collection fields.
     *
     * This includes sets/lists etc.
     *
     * This will remove the key/value pair entirely if the list is empty.
     *
     * See addSerializable for more info
     */
    addIterableSerializable<T>(
        key: string,
        collection: Collection<any, T>,
        serializer: JsonSerializer<any, T>): JsonBuilder {

        const arr = convertCollectionToArray(collection, serializer);

        if (arr.length !== 0) {
            this.obj[key] = arr;

        }

        return this;
    }

    /**
     * Handles map fields.
     *
     * See addSerializable for more info
     */
    addMap<T>(key: string, map: Map<any, T>, f: (t: T) => any): JsonBuilder {
        if (map.size !== 0) {
            this.obj[key] = convertMapToObj(map, f);
        }

        return this;
    }

    /**
     * Handles map fields.
     *
     * See addSerializable for more info
     */
    addMapSerializable<T>(key: string, map: Map<any, T>, serializer: JsonSerializer<any, T>): JsonBuilder {
        if (map.size !== 0) {
            this.obj[key] = convertSerializableMapToObj(map, serializer);
        }

        return this;
    }

    /**
     * Add arbritary object. Make sure you know what you are doing
     * @param key
     * @param value
     */
    addObject(key: string, value: object): JsonBuilder {
        this.obj[key] = value;
        return this;
    }

    /**
     * Add an optional primitive.
     *
     * Will not include the key value pair in the json if None
     *
     * for adding Classes, see addOptionalSerializable
     */
    addOptional<T>(key: string, value: Option<Primitive>): JsonBuilder {
        if (value !== undefined) {
            value.forEach(v => this.add(key, v));
        }
        return this;
    }

    /**
     * Add an optional Date.
     *
     * Will not include the key value pair in the json if None
     *
     * for adding Classes, see addOptionalSerializable
     */
    addOptionalDate<T>(key: string, value: Option<Moment>): JsonBuilder {
        return this.addOptional(key, value.map(d => d.toISOString()));
    }

    /**
     * Handles optional fields.
     *
     * This will remove the key/value pair entirely if the value is None.
     *
     * See addSerializable for more info
     */
    addOptionalSerializable<T>(key: string, value: Option<T>, serializer: JsonSerializer<any, T>): JsonBuilder {
        if (value !== undefined) {
            value.forEach(v => this.addSerializable(key, v, serializer));
        }
        return this;
    }

    /**
     * Add an optional URL.
     *
     * Will not include the key value pair in the json if None
     *
     * for adding Classes, see addOptionalSerializable
     *
     * Clone due to fucked up behaviour where calling "href()"
     * mutates the underlying object which breaks equality in tests
     */
    addOptionalURI<T>(key: string, value: Option<Url>): JsonBuilder {
        return this.addOptional(key, value.map(d => d.getHref()));
    }

    /**
     * Add serializable.
     *
     * Will use the given serializer add a key, value pair with the serialized child
     */
    addSerializable<T>(key: string, value: T, serializer: JsonSerializer<any, T>): JsonBuilder {
        const object = serializer.toJson(value);
        if (JSON.stringify(object) !== '{}') {
            this.obj[key] = object;
        }
        return this;
    }

    /**
     * Return a new object
     */
    build(): object {
        return this.obj;
    }
}
