import { None, Option, Some } from 'funfix-core';
import { Moment } from 'moment';
import { dateKey, fromCurrencyKey, JsonBuilder, parseDate, parseNumber, parseString, rateKey, SimpleJsonSerializer, toCurrencyKey } from '../core';

export class FinancialsRate {

    constructor(
        readonly fromCurrency: Option<string> = None,
        readonly toCurrency: Option<string> = None,
        readonly rate: Option<number> = None,
        readonly date: Option<Moment> = None,
    ) { }

}

export class FinancialsRateJsonSerialiser extends SimpleJsonSerializer<FinancialsRate> {
    static instance: FinancialsRateJsonSerialiser = new FinancialsRateJsonSerialiser();

    fromJsonImpl(obj: any): FinancialsRate {
        return new FinancialsRate(
            parseString(obj[fromCurrencyKey]),
            parseString(obj[toCurrencyKey]),
            parseNumber(obj[rateKey]),
            parseDate(obj[dateKey]),
        );
    }

    protected toJsonImpl(financialsRate: FinancialsRate, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(fromCurrencyKey, financialsRate.fromCurrency)
            .addOptional(toCurrencyKey, financialsRate.toCurrency)
            .addOptional(rateKey, financialsRate.rate)
            .addOptionalDate(dateKey, financialsRate.date);

    }
}
