import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    addressKey,
    cityIdKey,
    countryIdKey,
    exclusionsKey,
    fakeIdxKey,
    gpsLatitudeDegreesKey,
    gpsLatitudeMinKey,
    gpsLatitudeNotationKey,
    gpsLatitudeSecKey,
    gpsLongitudeDegreesKey,
    gpsLongitudeMinKey,
    gpsLongitudeNotationKey,
    gpsLongitudeSecKey,
    inclusionsKey,
    JsonBuilder,
    latitudeKey,
    logoKey,
    longitudeKey,
    meetingPointKey,
    nameKey,
    OptionUtils,
    parentFakeIdxKey,
    participantIdKey,
    phoneKey,
    postcodeKey,
    proposalCompanyIdKey,
    ratingKey,
    SimpleJsonSerializer,
    stateIdKey,
    tripAdvisorKey,
    typeIdKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
    websiteKey,
} from '../core';
import {LocationIds} from './location-ids';
import {PhysicalLocation} from './physical-location';
import {Product, ProductJsonSerializer} from './product';

export class DBProduct implements Validatable {

    constructor(
        readonly underlying: Option<Product> = None,
        readonly name: string,
        readonly typeId: number,
        readonly supplierId: Option<number> = None,
        readonly ratingId: Option<number> = None,
        readonly address: Option<string> = None,
        readonly cityId: Option<number> = None,
        readonly stateId: Option<number> = None,
        readonly countryId: Option<number> = None,
        readonly postcode: Option<string> = None,
        readonly website: Option<string> = None,
        readonly tripadvisor: Option<string> = None,
        readonly latitude: Option<string> = None,
        readonly longitude: Option<string> = None,
        readonly productLogo: Option<string> = None,
        readonly phone: Option<string> = None,
        readonly email: Option<string> = None,
        readonly gpsDmsLatNotation: Option<string> = None,
        readonly gpsDmsLatDegrees: Option<number> = None,
        readonly gpsDmsLatMin: Option<number> = None,
        readonly gpsDmsLatSec: Option<number> = None,
        readonly gpsDmsLongNotation: Option<string> = None,
        readonly gpsDmsLongDegrees: Option<number> = None,
        readonly gpsDmsLongMin: Option<number> = None,
        readonly gpsDmsLongSec: Option<number> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly meetingPoint: Option<string> = None,
        readonly fakeIdx: Option<number> = None,
        readonly parentFakeIdx: Option<number> = None) {
    }

    // CAN THROW EXCEPTIONS;
    static buildFromProduct(
        product: Product,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        fakeIdx: Option<number> = None,
        parentFakeIdx: Option<number> = None,
    ): List<DBProduct> {
        if (product.type.isEmpty()) {
            console.log(ProductJsonSerializer.instance.toJson(product));
            throw new Error('Empty product type');
        }

        if (this.getProductTypeId(product.type.get()).isEmpty()) {
            throw new Error(`Could not map type(${product.type.get()}) to type id`);
        }

        // if (product.supplier.flatMap(s => s.getId()).isEmpty()) {
        //     console.log(ProductJsonSerializer.instance.toJson(product));
        //     throw new Error('Empty product supplier id');
        // }

        if (parentFakeIdx.nonEmpty() && !product.subproducts.isEmpty()) {
            throw new Error('Deep nesting of subproducts not supported');
        }

        const location = product.location.map(x => locationIdFinder(x)).getOrElse(new LocationIds());

        // tl;dr if this is the parent it will be 1,2,3,4
        // If this is a child of 1 it will be 1001,1002,1003,1004
        return List.of(
            new DBProduct(
                Some(product),
                product.name.get(), // UNSAFE: Will crash if empty, but this is intended
                this.getProductTypeId(product.type.get()).get(), // UNSAFE: Will crash if empty, but this is intended
                product.getSupplier().flatMap(x => x.getId()),
                product.rating.flatMap(t => this.getProductRatingId(t)),
                product.location.flatMap(l => l.address),
                location.cityId,
                location.stateId,
                location.countryId,
                product.location.flatMap(l => l.postcode),
                product.contact.flatMap(l => l.website),
                product.social.flatMap(l => l.tripAdvisor),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.latitude)
                    .map(l => l.toString()),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.longitude)
                    .map(l => l.toString()),
                product.logo.flatMap(l => l.uri)
                    .map(u => u.getHref()),
                product.contact.flatMap(l => l.phone),
                product.contact.flatMap(l => l.email),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.notation),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.degrees),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.min),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.sec),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.notation),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.degrees),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.min),
                product.location.flatMap(l => l.gpsCoords)
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.sec),
                product.inclusions,
                product.exclusions,
                product.meetingPoint,
                fakeIdx,
                parentFakeIdx,
            ),
        ).concat(DBProduct.buildFromProducts(product.subproducts.filter(x => x.getId().isEmpty() && Product), locationIdFinder, fakeIdx));
    }

    static buildFromProductForUpdate(
        product: Product,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        cachedProduct: Product,
    ): List<DBProduct> {
        if (product.getProductType().isEmpty() && cachedProduct.getProductType().isEmpty()) {
            throw new Error('Product type not specified and no fallback available');
        }

        if (this.getProductTypeId(product.getProductType().get()).isEmpty()) {
            throw new Error(`Could not map "${product.getProductType().get()}" to a type id`);
        }

        const location = product.location.map(x => locationIdFinder(x)).getOrElse(new LocationIds());

        // tl;dr if this is the parent it will be 1,2,3,4
        // If this is a child of 1 it will be 1001,1002,1003,1004
        return List.of(
            new DBProduct(
                Some(product),
                product.getProductName()
                    .getOrElse(cachedProduct.getProductName().get()), // UNSAFE: Will crash if empty, but this is intended
                this.getProductTypeId(product.getProductType().getOrElse(cachedProduct.getProductType().get())).get(), // UNSAFE: Will crash if empty, but this is intended
                product.getSupplier()
                    .flatMap(x => x.getId()),
                product.getRating()
                    .flatMap(t => this.getProductRatingId(t)),
                product.getLocation()
                    .flatMap(l => l.getAddress()),
                location.getCityId(),
                location.getStateId(),
                location.getCountryId(),
                product.getLocation()
                    .flatMap(l => l.getPostCode()),
                product.getContact()
                    .flatMap(l => l.getWebsite()),
                product.getSocial()
                    .flatMap(l => l.tripAdvisor),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.latitude)
                    .map(l => l.toString()),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.longitude)
                    .map(l => l.toString()),
                product.getLogo()
                    .flatMap(l => l.uri)
                    .map(u => u.getHref()),
                product.getContact()
                    .flatMap(l => l.getPhone()),
                product.getContact()
                    .flatMap(l => l.getEmail()),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.notation),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.degrees),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.min),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLatitude)
                    .flatMap(l => l.sec),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.notation),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.degrees),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.min),
                product.getLocation()
                    .flatMap(l => l.getGpsCoords())
                    .flatMap(g => g.gpsLongitude)
                    .flatMap(l => l.sec),
                product.getInclusions(),
                product.getExclusions(),
                product.getMeetingPoint(),
                None,
                None,
            ),
        );
    }

    // This is poorly written... Especially the indexing.
    // We do not currently support multiple levels of subproducts in regards to indexing as there could be overlap
    static buildFromProducts(
        products: List<Product>,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        parentFakeIdx: Option<number> = None,
    ): List<DBProduct> {
        return products.flatMap((x, idx) => DBProduct.buildFromProduct(
            x,
            locationIdFinder,
            OptionUtils.applyOrReturnNonEmpty(parentFakeIdx, Some(idx), (a, b) => a * 1000 + b),
            parentFakeIdx));
    }

    static getProductRatingId(value: number): Option<number> {
        switch (value) {
            case 5:
                return Some(1);
            case 4:
                return Some(3);
            case 2:
                return Some(4);
            case 4.5:
                return Some(5);
            case 3.5:
                return Some(6);
            case 3:
                return Some(7);
            case 2.5:
                return Some(8);
        }

        return None;
    }

    static getProductTypeId(type: string): Option<number> {
        switch (type) {
            case 'Accommodation':
                return Some(33);
            case 'Hotellier':
                return Some(33);
            case 'Destination':
                return Some(24);
            case 'Multiday':
                return Some(3902);
            case 'Day Tour':
                return Some(3903);
            case 'Day Tour / Attraction':
                return Some(3903);
        }

        return None;
    }

    getSummary(): string {
        return OptionUtils.toList<any>(
            Some(this.name),
            Some(this.typeId),
            this.supplierId,
            this.ratingId,
            this.address,
            this.cityId,
            this.stateId,
            this.countryId,
            this.postcode,
            this.website,
            this.tripadvisor,
            this.latitude,
            this.longitude,
            this.phone,
            this.productLogo,
            this.email,
            this.gpsDmsLatNotation,
            this.gpsDmsLatMin,
            this.gpsDmsLatSec,
            this.gpsDmsLatDegrees,
            this.gpsDmsLongNotation,
            this.gpsDmsLongDegrees,
            this.gpsDmsLongMin,
            this.gpsDmsLongSec,
            this.inclusions,
            this.exclusions,
            this.meetingPoint)
            .reduce((a, b) => `${a} - ${b}`, '');
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateTitleNvarchar('name', this.name, 100)),
            Some(ValidationUtils.validateInt('typeId', this.typeId.toString())),
            this.supplierId.map(n => ValidationUtils.validateInt('supplierId', n.toString())),
            this.ratingId.map(n => ValidationUtils.validateInt('ratingId', n.toString())),
            this.address.map(n => ValidationUtils.validateNvarchar('ratingId', n, 100)),
            this.cityId.map(n => ValidationUtils.validateInt('cityId', n.toString())),
            this.stateId.map(n => ValidationUtils.validateInt('stateId', n.toString())),
            this.countryId.map(n => ValidationUtils.validateInt('countryId', n.toString())),
            this.postcode.map(n => ValidationUtils.validateNvarchar('postcode', n, 20)),
            this.website.map(n => ValidationUtils.validateUrl('website', n, 255)),
            this.tripadvisor.map(n => ValidationUtils.validateUrl('tripadvisor', n, 255)),
            this.latitude.map(n => ValidationUtils.validateFloat('latitude', n)),
            this.longitude.map(n => ValidationUtils.validateFloat('longitude', n)),
            this.phone.map(n => ValidationUtils.validatePhone('phone', n, 400)),
            this.productLogo.map(n => ValidationUtils.validateNvarchar('productLogo', n, 400)),
            this.email.map(n => ValidationUtils.validateEmail('email', n, 400)),
            this.gpsDmsLatNotation.map(n => ValidationUtils.validateChar('gpsDmsLatNotation', n)),
            this.gpsDmsLatMin.map(n => ValidationUtils.validateFloat('gpsDmsLatMin', n.toString())),
            this.gpsDmsLatSec.map(n => ValidationUtils.validateFloat('gpsDmsLatSec', n.toString())),
            this.gpsDmsLatDegrees.map(n => ValidationUtils.validateFloat('gpsDmsLatDegrees', n.toString())),
            this.gpsDmsLongNotation.map(n => ValidationUtils.validateChar('gpsDmsLongNotation', n)),
            this.gpsDmsLongDegrees.map(n => ValidationUtils.validateFloat('gpsDmsLongDegrees', n.toString())),
            this.gpsDmsLongMin.map(n => ValidationUtils.validateFloat('gpsDmsLongMin', n.toString())),
            this.gpsDmsLongSec.map(n => ValidationUtils.validateFloat('gpsDmsLongSec', n.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBProductJsonSerializer extends SimpleJsonSerializer<DBProduct> {
    static instance: DBProductJsonSerializer = new DBProductJsonSerializer();

    fromJsonImpl(obj: any): DBProduct {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(product: DBProduct, builder: JsonBuilder): JsonBuilder {
        const company = product.underlying.flatMap(p => p.supplier);
        const propCompany = company.filter(c => c.type.contains('Proposal Company'));
        const supplier = company.filter(c => c.type.contains('Supplier'));
        return builder
            .add(nameKey, product.name)
            .add(typeIdKey, product.typeId)
            .addOptional(addressKey, product.address)
            .addOptional(cityIdKey, product.cityId)
            .addOptional(stateIdKey, product.stateId)
            .addOptional(countryIdKey, product.countryId)
            .addOptional(postcodeKey, product.postcode)
            .addOptional(ratingKey, product.ratingId)
            .addOptional(websiteKey, product.website)
            .addOptional(phoneKey, product.phone)
            .addOptional(logoKey, product.productLogo)
            .addOptional(tripAdvisorKey, product.tripadvisor)
            .addOptional(latitudeKey, product.latitude)
            .addOptional(longitudeKey, product.longitude)
            .addOptional(gpsLatitudeNotationKey, product.gpsDmsLatNotation)
            .addOptional(gpsLatitudeDegreesKey, product.gpsDmsLatDegrees)
            .addOptional(gpsLatitudeMinKey, product.gpsDmsLatMin)
            .addOptional(gpsLatitudeSecKey, product.gpsDmsLatSec)
            .addOptional(gpsLongitudeNotationKey, product.gpsDmsLongNotation)
            .addOptional(gpsLongitudeDegreesKey, product.gpsDmsLongDegrees)
            .addOptional(gpsLongitudeMinKey, product.gpsDmsLongMin)
            .addOptional(gpsLongitudeSecKey, product.gpsDmsLongSec)
            .addOptional(proposalCompanyIdKey, propCompany.flatMap(c => c.id))
            .addOptional(participantIdKey, supplier.flatMap(c => c.id))
            .addOptional(fakeIdxKey, product.fakeIdx)
            .addOptional(parentFakeIdxKey, product.parentFakeIdx)
            .addOptional(inclusionsKey, product.inclusions)
            .addOptional(exclusionsKey, product.exclusions)
            .addOptional(meetingPointKey, product.meetingPoint);
    }
}
