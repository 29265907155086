import {Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    classificationKey,
    fakeIdxKey,
    JsonBuilder,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {EnumConstantMap} from './enum-constant';
import {ProposalEntry} from './proposal-entry';

export class DbEntrySelectedClassification implements Validatable {

    constructor(
        readonly classification: number,
        readonly fakeIdx: Option<number>,
    ) {
    }

    static buildFromEntries(
        entries: List<ProposalEntry>,
        entryClassifications: EnumConstantMap): List<DbEntrySelectedClassification> {
        return entries.flatMap((x, idx) => DbEntrySelectedClassification.buildFromEntry(x, Some(idx), entryClassifications));
    }

    static buildFromEntry(entry: ProposalEntry, fakeIdx: Option<number>, entryClassifications: EnumConstantMap): List<DbEntrySelectedClassification> {
        return OptionUtils.flattenList(entry.getClassifications()
            .map(x => entryClassifications.getIdByName(x)))
            .map(classification => new DbEntrySelectedClassification(classification, fakeIdx));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateInt('classification', this.classification.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbEntrySelectedClassificationJsonSerializer extends SimpleJsonSerializer<DbEntrySelectedClassification> {
    static instance: DbEntrySelectedClassificationJsonSerializer = new DbEntrySelectedClassificationJsonSerializer();

    fromJsonImpl(obj: any): DbEntrySelectedClassification {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(mapping: DbEntrySelectedClassification, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(classificationKey, mapping.classification)
            .addOptional(fakeIdxKey, mapping.fakeIdx);
    }
}
