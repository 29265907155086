import {None, Option, Some} from 'funfix-core';
import {List, Map} from 'immutable';
import * as moment from 'moment';
import {Timeframed} from '../models';
import {ComparisonUtils} from './comparison-utils';

export const shortDateFormat = 'D MMM YYYY';

export const zeroTimeDate = new Date(0);
export const zeroTimeMoment = moment(0);

// These are probably wrong. Needs testing
export class DateUtils {

    static dateFormats: Map<string, string> = Map({
        'DSDT': 'ddd, D MMM YYYY hh:mm A',
        'DSDT24': 'ddd, D MMM YYYY HH:mm',
        'DSD': 'ddd, D MMM YYYY',
        '2D3M4YZ24T': 'D MMM YYYY HH:mm',
        '2D3M4YZT': 'D MMM YYYY hh:mm A',
        '2D3M4Y': 'D MMM YYYY',
        'DLDT': 'LLLL',
        'DLD': 'dddd, MMMM D, YYYY',
        'T': 'hh:mm A',
        'T24': 'HH:mm',
        'TAM': 'hh:mmA',
        'DDMMM': 'D MMM',
        'DOMMM': 'Do MMM',
        'DO4M': 'Do MMMM',
        '3D': 'ddd', // Mon
        '3M': 'MMM',
        '2D': 'D', // 02
        '2DO': 'Do', // 2nd
        '4Y': 'YYYY',
        'DAY': 'dddd', // Monday
    });

    static getYearsBetween(start: Option<moment.Moment>, end: Option<moment.Moment>): Option<number> {
        return Option.map2(start, end, (s, e) => {
            const startOfEnd = e.clone();
            const startOfStart = s.clone();
            return Math.abs(startOfEnd.diff(startOfStart, 'years'));
        });
    }

}

export function getDatesInRange(start: Option<moment.Moment>, end: Option<moment.Moment>): Option<List<moment.Moment>> {
    // The moment needs to be formatted and turned into a string and then returned as a moment in order to work properly
    return Option.map2(start, end, (s, e) => {
        const startDateTime = moment(s)
            .utc()
            .clone()
            .add(1, 'days');
        const dates = [];
        // tslint:disable-next-line
        while (startDateTime.isSameOrBefore(e)) {
            dates.push(moment(startDateTime.format()).utc());
            startDateTime.add(1, 'days');
        }
        return List(dates);
    });
}

export function getNights(start: Option<moment.Moment>, end: Option<moment.Moment>): Option<number> {
    return Option.map2(start, end, (s, e) => {
        const startOfEnd = e.clone().startOf('day');
        const startOfStart = s.clone().startOf('day');
        return Math.abs(startOfEnd.diff(startOfStart, 'days'));
    });
}

export function getEndFromNights(start: Option<moment.Moment>, nights: Option<number>): Option<moment.Moment> {
    return Option.map2(start, nights, (s, n) => {
        const startAtMidnight = s.clone().startOf('day');
        return startAtMidnight.add(n, 'days');
    });
}

export function getEndFromDays(start: Option<moment.Moment>, days: Option<number>): Option<moment.Moment> {
    return Option.map2(start, days, (s, n) => {
        const startAtMidnight = s.clone().startOf('day');
        return startAtMidnight.add(n - 1, 'days');
    });
}

export function getDays(start: Option<moment.Moment>, end: Option<moment.Moment>): Option<number> {
    return getNights(start, end).map(n => n + 1);
}

// Note: Slowish, would be better off with a foldLeft
export function computeStartTime(times: List<Timeframed>): Option<moment.Moment> {
    return times
        .map(t => t.getStartDateTime())
        .sort((a, b) => ComparisonUtils.optionMomentComparator.compare(a, b))
        .first(None);
}

// Note: Slowish, would be better off with a foldLeft
export function computeEndTime(times: List<Timeframed>): Option<moment.Moment> {
    return times
        .map(t => t.getEndDateTime())
        .sort((a, b) => ComparisonUtils.optionMomentComparator.reverse().compare(a, b))
        .filter(x => x.nonEmpty())
        .first(None);
}

export function getDateRange(start: Option<moment.Moment>, end: Option<moment.Moment>): Option<string> {
    if (start.isEmpty()) {
        return None;
    } else if (end.isEmpty()) {
        return Option.of(start.get().format(shortDateFormat));
    } else {
        return Option.of(getShortestDateRange(start.get(), end.get()));
    }
}

export function getShortestDateRange(start: moment.Moment, end: moment.Moment): string {
    if (!end.isSame(start, 'years')) {
        return start.format(shortDateFormat) + ' - ' + end.format(shortDateFormat);
    } else if (!end.isSame(start, 'months')) {
        return start.format('D MMM') + ' - ' + end.format(shortDateFormat);
    } else if (end.diff(start, 'days') === 0) {
        return start.format(shortDateFormat);
    } else {
        return start.format('D') + ' - ' + end.format(shortDateFormat);
    }
}

export function now(): moment.Moment {
    return moment().local();
}

export function today(): moment.Moment {
    return now().clone().utc(true).startOf('day');
}

export function tomorrow(): moment.Moment {
    return today().clone().add(1, 'd');
}

export function isToday(m: moment.Moment): boolean {
    return today().isSame(m, 'd');
}

export function isTomorrow(m: moment.Moment): boolean {
    return tomorrow().isSame(m, 'd');
}

export function isThisMonth(m: moment.Moment): boolean {
    return today().isSame(m, 'month');
}

export function isWithinTheNextXMonths(m: moment.Moment, months: number): boolean {
    const thisMonth = today().startOf('months');
    const monthsFromNow = thisMonth.clone().add(months, 'months');
    return isDayBetween(Some(thisMonth), Some(monthsFromNow), m);
}

export function isThisYear(m: moment.Moment): boolean {
    return today().isSame(m, 'year');
}

export function isTomorrowBetween(start: Option<moment.Moment>, end: Option<moment.Moment>): boolean {
    return isDayBetween(start, end, tomorrow());
}

export function isTodayBetween(start: Option<moment.Moment>, end: Option<moment.Moment>): boolean {
    return isDayBetween(start, end, today());
}

// Check if "time" is before the "checkFor"
export function isBefore(time: Option<moment.Moment>, checkFor: Option<moment.Moment>, granularity: moment.unitOfTime.StartOf): boolean {
    return Option.map2(time, checkFor, (t, c) => t.isBefore(c, granularity)).contains(true);
}

// Check if "time" is after the "checkFor"
export function isAfter(time: Option<moment.Moment>, checkFor: Option<moment.Moment>, granularity: moment.unitOfTime.StartOf): boolean {
    return Option.map2(time, checkFor, (t, c) => t.isBefore(c, granularity)).contains(true);
}

export function isMidnight(m: moment.Moment): boolean {
    return m.hour() === 0 && m.minute() === 0;
}

export function isDayBetween(start: Option<moment.Moment>, end: Option<moment.Moment>, day: moment.Moment): boolean {
    return isBetweenRange(start, end, day, 'd') ||
        start.exists(s => day.isSame(s, 'd')) ||
        end.exists(s => day.isSame(s, 'd'));
}

export function isMomentBetween(start: Option<moment.Moment>, end: Option<moment.Moment>, time: moment.Moment): boolean {
    return isBetweenRange(start, end, time, 'ms') ||
        (start.exists(s => time.isSame(s, 'ms')) && end.isEmpty()) ||
        (end.exists(s => time.isSame(s, 'ms')) && end.isEmpty());
}

function isBetweenRange(
    start: Option<moment.Moment>,
    end: Option<moment.Moment>,
    day: moment.Moment,
    granularity: moment.unitOfTime.StartOf): boolean {
    return Option.map2(start, end, (s, e) =>
        day.isSameOrBefore(e, granularity)
        && day.isSameOrAfter(s, granularity)).contains(true);
}
