import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    defaultsKey,
    idKey,
    JsonBuilder,
    languageKey,
    logoKey,
    nameKey,
    parseListSerializable,
    parseNumber,
    parseString,
    proposalTemplatesKey,
    relationshipsKey,
    SimpleJsonSerializer,
    typeKey,
} from '../core';
import {
    Company,
    CompanyLike,
    Image,
    ProposalTemplate,
    ProposalTemplateDefaults,
    ProposalTemplateDefaultsJsonSerializer,
    ProposalTemplateJsonSerializer,
    Relationships,
    RelationshipsJsonSerializer,
} from '../models';

export class CompanySummary extends CompanyLike {
    constructor(
        readonly id: Option<number> = None,
        readonly type: Option<string> = None,
        readonly name: Option<string> = None,
        readonly logo: Option<string> = None,
        readonly relationships: Option<Relationships> = None,
        readonly language: Option<string> = None,
        readonly proposalTemplateDefaults: Option<ProposalTemplateDefaults> = None,
        readonly proposalTemplates: List<ProposalTemplate> = List(),
    ) {
        super();
    }

    static getSummaries(companies: List<CompanyLike>): List<CompanySummary> {
        return companies.map(c => CompanySummary.getSummary(c));
    }

    static getSummary(company: CompanyLike): CompanySummary {
        if (company instanceof CompanySummary) {
            return company;
        }
        return new CompanySummary(company.getId(), company.getType(), company.getName());
    }

    asCompany(): Company {
        return this.buildCompany().getOrElse(new Company());
    }

    getId(): Option<number> {
        return this.id;
    }

    getLanguage(): Option<string> {
        return this.language;
    }

    getLogo(): Option<Image> {
        return this.logo.map(x => Image.fromString(x));
    }

    getName(): Option<string> {
        return this.name;
    }

    getProposalTemplateDefaults(): Option<ProposalTemplateDefaults> {
        return this.proposalTemplateDefaults;
    }

    getProposalTemplates(): List<ProposalTemplate> {
        return this.proposalTemplates;
    }

    getRelationships(): Option<Relationships> {
        return this.relationships;
    }

    getType(): Option<string> {
        return this.type;
    }
}

export class CompanySummaryJsonSerializer extends SimpleJsonSerializer<CompanySummary> {
    static instance: CompanySummaryJsonSerializer = new CompanySummaryJsonSerializer();

    protected constructor() {
        super();
    }

    fromJsonImpl(obj: any): CompanySummary {
        return new CompanySummary(
            parseNumber(obj[idKey]),
            parseString(obj[typeKey]),
            parseString(obj[nameKey]),
            parseString(obj[logoKey]),
            RelationshipsJsonSerializer.instance.fromJson(obj[relationshipsKey]),
            parseString(obj[languageKey]),
            ProposalTemplateDefaultsJsonSerializer.instance.fromJson(obj[defaultsKey]),
            parseListSerializable(obj[proposalTemplatesKey], ProposalTemplateJsonSerializer.instance),
        );
    }

    protected toJsonImpl(company: CompanySummary, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, company.id)
            .addOptional(typeKey, company.type)
            .addOptional(nameKey, company.name)
            .addOptional(logoKey, company.logo)
            .addOptionalSerializable(relationshipsKey, company.relationships, RelationshipsJsonSerializer.instance)
            .addOptional(languageKey, company.language)
            .addOptionalSerializable(
                defaultsKey,
                company.proposalTemplateDefaults,
                ProposalTemplateDefaultsJsonSerializer.instance,
            )
            .addIterableSerializable(proposalTemplatesKey, company.proposalTemplates, ProposalTemplateJsonSerializer.instance);
    }
}
