import {Either, Option} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {EitherUtils, MapUtils, StringSearchType} from '../core';
import {Mapping, MappingJsonSerializer} from './mapping';

export class MappingCache {
    constructor(readonly mapping: List<Mapping>,
                readonly byRef: Map<string, List<Mapping>> = MapUtils.buildGroupedMapFromListOptional(mapping, e => e.getOptCodeString())) {
    }

    findBy(
        cid: number,
        comparison: string,
        type: StringSearchType,
        caseSensitive: boolean = false): Option<Mapping> {
        return Option.of(
            this.mapping.find(m => m.canUse(cid) && m.matchesSearch(caseSensitive, comparison, type)));
    }

    getByCompanyId(n: number): MappingCache {
        return new MappingCache(this.mapping.filter(x => x.canUse(n)));
    }

    getByOwner(n: number): MappingCache {
        return new MappingCache(this.mapping.filter(x => x.isOwner(n)));
    }

    getByRef(ref: string, cid: number): Option<Mapping> {
        const prods = Option.of(this.byRef.get(ref)).getOrElse(List<Mapping>());
        const owned = Option.of(prods.find(p => p.isOwner(cid)));

        return owned
            .orElseL(() => Option.of(prods.find(x => x.canUse(cid))));
    }

    getByRefEither(s: string, cid: number): Either<string, Mapping> {
        const either = EitherUtils.toEither(this.getByRef(s, cid), `Ref: ${s} not found`);
        return EitherUtils.leftMap(either, l => {
            const refs = Option.of(this.byRef.get(s));
            if (refs.exists(x => !x.isEmpty())) {
                return `Ref: ${s} found, but not accessible to company ${cid}, check relationships`;
            }
            return l;
        });
    }

    getByRefPartial(ref: string): MappingCache {
        return new MappingCache(this.mapping.filter(x => x.getOptCodeString().exists(s => s.startsWith(ref))));
    }

    getMissing(toCheckAgainst: Set<string>): Set<string> {
        return toCheckAgainst.subtract(this.byRef.keySeq().toSet());
    }

    getProductId(ref: string, cid: number): Option<number> {
        return this.getByRef(ref, cid).flatMap(x => x.productId);
    }

    getProductIdEither(ref: string, cid: number): Either<string, number> {
        return this.getByRefEither(ref, cid)
            .flatMap(x => EitherUtils.toEither(x.productId, 'Mapping record missing product id'));
    }

    toJsonArray(): ReadonlyArray<object> {
        return MappingJsonSerializer.instance.toJsonArray(this.mapping);
    }

    update(mappingsToUpdate: List<Mapping>): MappingCache {
        return new MappingCache(this.mapping.concat(mappingsToUpdate));
    }
}
