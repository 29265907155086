import {None, Option, Some} from 'funfix-core';
import {Map} from 'immutable';
import {
    chineseKey,
    danishKey,
    dutchKey,
    frenchKey,
    germanKey,
    italianKey,
    JsonBuilder,
    koreanKey,
    Language,
    parseStringMap,
    polishKey,
    SimpleJsonSerializer,
    spanishKey,
    swedishKey,
} from '../core';

// TODO: Handle case sensitivity
export class PhraseTranslations {
    constructor(
        public french: Map<string, string> = Map(),
        public spanish: Map<string, string> = Map(),
        public danish: Map<string, string> = Map(),
        public italian: Map<string, string> = Map(),
        public dutch: Map<string, string> = Map(),
        public swedish: Map<string, string> = Map(),
        public chinese: Map<string, string> = Map(),
        public german: Map<string, string> = Map(),
        public korean: Map<string, string> = Map(),
        public polish: Map<string, string> = Map()) {
    }

    translate(s: string, language: Language): Option<string> {
        switch (language) {
            case 'English':
                return Some(s);
            case 'German':
                return Option.of(this.german.get(s));
            case 'Dutch':
                return Option.of(this.dutch.get(s));
            case 'Swedish':
                return Option.of(this.swedish.get(s));
            case 'Danish':
                return Option.of(this.danish.get(s));
            case 'Italian':
                return Option.of(this.italian.get(s));
            case 'Spanish':
                return Option.of(this.spanish.get(s));
            case 'Chinese':
                return Option.of(this.chinese.get(s));
            case 'French':
                return Option.of(this.french.get(s));
            case 'Korean':
                return Option.of(this.korean.get(s));
            case 'Polish':
                return Option.of(this.polish.get(s));
            default:
                console.warn('Cannot yet translate: ' + language);
                return None;
        }
    }
}

export class PhraseTranslationsJsonSerializer extends SimpleJsonSerializer<PhraseTranslations> {
    static instance: PhraseTranslationsJsonSerializer = new PhraseTranslationsJsonSerializer();

    fromJsonImpl(obj: any): PhraseTranslations {
        return new PhraseTranslations(
            parseStringMap(obj[frenchKey]),
            parseStringMap(obj[spanishKey]),
            parseStringMap(obj[danishKey]),
            parseStringMap(obj[italianKey]),
            parseStringMap(obj[dutchKey]),
            parseStringMap(obj[swedishKey]),
            parseStringMap(obj[chineseKey]),
            parseStringMap(obj[germanKey]),
            parseStringMap(obj[koreanKey]),
            parseStringMap(obj[polishKey]));
    }

    protected toJsonImpl(value: PhraseTranslations, builder: JsonBuilder): JsonBuilder {
        return builder
            .addMap(frenchKey, value.french, x => x)
            .addMap(spanishKey, value.spanish, x => x)
            .addMap(danishKey, value.danish, x => x)
            .addMap(italianKey, value.italian, x => x)
            .addMap(dutchKey, value.dutch, x => x)
            .addMap(swedishKey, value.swedish, x => x)
            .addMap(chineseKey, value.chinese, x => x)
            .addMap(germanKey, value.german, x => x)
            .addMap(koreanKey, value.korean, x => x)
            .addMap(polishKey, value.polish, x => x);
    }
}
