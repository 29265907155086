import {Option} from 'funfix-core';
import {List, Map} from 'immutable';
import {OptionUtils} from './option-utils';

export class CollectionUtils {

    static average(list: List<number>): number {
        return list.reduce((prev, curr) => prev + curr, 0) / list.size;
    }

    static collect<A, B>(list: List<A>, f: (a: A) => Option<B>): List<B> {
        return list.reduce((acc, v) => {
            const res = f(v);
            if (res.isEmpty()) {
                return acc;
            } else {
                return acc.push(res.get());
            }
        }, List());
    }

    static collectDistinct<A, B, C>(list: List<A>, f: (a: A) => Option<B>, grouper: (b: B) => C): List<B> {
        return CollectionUtils.distinctList(CollectionUtils.collect(list, f), grouper);
    }

    static collectDistinctOption<A, B, C>(list: List<A>, f: (a: A) => Option<B>, grouper: (b: B) => Option<C>): List<B> {
        return CollectionUtils.distinctOptionList(CollectionUtils.collect(list, f), grouper);
    }

    static collectFirst<A, B>(list: List<A>, f: (a: A) => Option<B>): Option<B> {
        return Option.of(CollectionUtils.collect(list, f).first());
    }

    static distinctList<A, B>(list: List<A>, groupBy: (a: A) => B): List<A> {
        return list
            .groupBy(x => groupBy(x))
            .toList()
            .flatMap(x => OptionUtils.toList(Option.of(x.first())));
    }

    static distinctOptionList<A, B>(list: List<A>, groupBy: (a: A) => Option<B>): List<A> {
        return list
            .groupBy(x => groupBy(x).getOrElse(null)) // This has to happen like this otherwise options may not be equal
            .toList()
            .flatMap(x => OptionUtils.toList(Option.of(x.first())));
    }

    // Gens a range. eg. geListOfIndexes(1, 2) == List(1, 2)
    static genListOfIndexes(start: number, count: number, allowEmpty: boolean = true): List<number> {
        if (!allowEmpty) {
            count = Math.max(count, 1);
        }
        return List(Array(count)).map((_, idx) => idx + start);
    }

    // Note: Not perfectly random, is rather biased, but is fine for reproducible shuffles
    static genSeededRandom(seed: number): () => number {
        return () => {
            seed = Math.sin(seed) * 10000;
            return seed - Math.floor(seed);
        };
    }

    // Splits into lists of max length.
    // eg. List of len 100 with countPerChunk 40 would create 2 lists of 40 and one of 20
    static grouped<T>(data: List<T>, countPerChunk: number): List<List<T>> {
        let chunks: List<List<T>> = List.of();

        let toProcess: List<T> = data;
        // tslint:disable-next-line
        while (!toProcess.isEmpty()) {
            chunks = chunks.push(toProcess.take(countPerChunk));
            toProcess = toProcess.skip(countPerChunk);
        }

        return chunks;
    }

    // Like grouped excepts will concat lists of lists while staying under the count per chunk
    static groupedLists<T>(data: List<List<T>>, countPerChunk: number): List<List<T>> {
        let chunks: List<List<T>> = List.of();

        let toProcess: List<List<T>> = data;
        // tslint:disable-next-line
        while (!toProcess.isEmpty()) {
            let currentChunk: List<T> = List.of();
            // tslint:disable-next-line
            while (currentChunk.size < countPerChunk) {
                if (toProcess.isEmpty()) {
                    break;
                } else {
                    const item: List<T> = toProcess.get(0) as List<T>;

                    if (item.size > countPerChunk) {
                        currentChunk = currentChunk.concat(...item.toArray());
                        toProcess = toProcess.skip(1);
                        break;
                    }
                    if (item.size + currentChunk.size <= countPerChunk) {
                        currentChunk = currentChunk.concat(...item.toArray());
                        // We cant skip toProcess, current item will be in next chunk
                    } else {
                        break;
                    }

                    toProcess = toProcess.skip(1);

                }
            }
            chunks = chunks.push(currentChunk);
        }

        return chunks;
    }

    static groupedListsBy<T>(data: List<T>, countPerChunk: number, grouper: (a: T) => string): List<List<T>> {
        const grouped = data.groupBy(a => grouper(a))
            .map(x => x.toList())
            .toList();
        return CollectionUtils.groupedLists(grouped, countPerChunk);
    }


    static groupReduceOption<A, B, C>(list: List<A>, groupBy: (a: A) => Option<B>, reducer: (acc: C, b: A) => C, defaultAcc: C): List<C> {
        return list
            .groupBy(x => groupBy(x).getOrElse(null)) // This has to happen like this otherwise options may not be equal
            .toList()
            .map(x => x.reduce((acc, b) => reducer(acc, b), defaultAcc));
    }

    static none<T>(collection: List<T>, f: (x: T) => boolean): boolean {
        return !collection
            .some(f);
    }

    static safeGet<K, V>(map: Map<K, V>, key: K): Option<V> {
        return Option.of(map.get(key));
    }

    // Randomly sorts the array, however, the same array with the same seed will produce the same result.
    static shuffle<T>(array: ReadonlyArray<T>, seed: number = Math.random()): ReadonlyArray<T> {

        const rngesus = CollectionUtils.genSeededRandom(seed);

        const newArray = [...array];
        for (let i = newArray.length - 1; i > 0; i--) {
            const j = Math.floor(rngesus() * (i + 1));
            [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
        }

        return newArray;
    }

    static shuffleList<T>(input: List<T>): List<T> {
        return List(CollectionUtils.shuffle(input.toArray()));
    }

    // Note: Probably fairly inefficient
    // Splits into even chunks
    // eg. [1, 2, 3, 4, 5] in 3 chunks would be
    // [[1, 4], [2, 5], [3]]
    static splitIntoChunks<T>(data: List<T>, chunks: number): List<List<T>> {
        return CollectionUtils.genListOfIndexes(0, chunks, true)
            .map(id => data.filter((c, charIdx) => charIdx % chunks === id));
    }

    static sumOptions<A, B>(list: List<A>, f: (a: A) => Option<number>): number {
        return CollectionUtils.collect(list, f).reduce((a, b) => a + b, 0);
    }

    static transform<A>(list: List<A>, f: (a: A) => A, pred: (a: A) => boolean): List<A> {
        return list.map(x => pred(x) ? f(x) : x);
    }

    /* tslint:enable */

}
