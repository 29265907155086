import {Either, None, Option, Some} from 'funfix-core';
import {Collection, List, Map, Set} from 'immutable';
import * as moment from 'moment';
import * as _s from 'underscore.string';
import {EitherUtils} from './either-utils';
import {JsonSerializer} from './json-serializer';
import {MapUtils} from './map-utils';
import {OptionUtils} from './option-utils';
import {Url} from './url';
import {Primitive} from './utils';

// WARNING: Global flag, this is application wide
let sanitize = true;

export function disableSanitization(): void {
    sanitize = false;
}

export function enableSanitization(): void {
    sanitize = true;
}

const stringsToRemove = Set.of('', 'null', '&nbsp;', 'n/a', 'to be defined', 'Unassigned');

export function parseJsonFromString(s: string): Option<object> {
    try {
        return Option.of(JSON.parse(s));
    } catch (err) {
        console.error('Error while parsing json string: ' + s.substring(0, 50) + '...');
        console.error(err);
        return None;
    }
}

export function parseJsonFromStringEither(s: string): Either<string, object> {
    try {
        return Either.right(JSON.parse(s));
    } catch (err) {
        return Either.left('Error while parsing json string: ' + s.substring(0, 50) + '...');
    }
}

export function parseNumber(obj: unknown): Option<number> {
    if (typeof obj === 'number') {
        return Some(obj)
            .filter(v => !isNaN(v) && isFinite(v));
    } else if (typeof obj === 'string') {
        return parseNumberFromString(obj);
    } else {
        return parseNumberFromAny(obj);
    }
}

export function parseNumberToEither<B>(obj: unknown, left: B): Either<B, number> {
    return EitherUtils.toEither(parseNumberFromAny(obj), left);
}

export function parseObjectToEither<B>(obj: unknown, left: B): Either<B, object> {
    return EitherUtils.toEither(parseObject(obj), left);
}

export function parseNumberFromString(s: string): Option<number> {
    return parseString(s)
        .flatMap(n => parseNumberFromAny(n));
}

export function parseNumberFromAny(obj: any): Option<number> {
    return Option.of(parseFloat(obj))
        .filter(v => !isNaN(v) && isFinite(v));
}

export function parseStringFromObjectKey(obj: unknown, key: string): Option<string> {
    return parseObject(obj)
        .flatMap((x: any) => parseString(x[key]));
}

export function parseString(obj: unknown): Option<string> {
    return Option.of(obj)
        .flatMap(x => {
            if (typeof x !== 'string') {
                return None;
            }

            if (sanitize) {
                return Some(removeDoubleNewLine(x.trim()));
            } else {
                return Some(x);
            }
        })
        .filter(s => !sanitize || !stringsToRemove.contains(s.toLowerCase()));
}

export function parseStringToEither<B>(obj: unknown, left: B): Either<B, string> {
    return EitherUtils.toEither(parseString(obj), left);
}

function removeDoubleNewLine(s: string): string {
    return s.split('').reduce((a, b) => shouldSkip(a, b) ? a : a + b, '');
}

function shouldSkip(s: string, c: string): boolean {
    return _s.endsWith(s, '\n') && (c === '\n' || c === '\r');
}

export function parseURL(obj: unknown): Option<Url> {
    return parseString(obj)
        .flatMap(u => Url.parse(u));
}

export function parseMapSerializable<T>(obj: unknown, serializer: JsonSerializer<T, T>): Map<string, T> {
    return parseMap(obj, x => serializer.fromJson(x));
}

// YUCK!!! We have to iterate the entries 3 times due to a lack of partial functions...
export function parseMap<T>(obj: unknown, f: (a: unknown) => Option<T>): Map<string, T> {
    if (typeof obj === 'object') {
        return Option.of(obj as { [key: string]: unknown })
            .map(o =>
                Map(o)
                    .mapEntries(e => [e[0], f(e[1])])
                    .filter(v => v.nonEmpty())
                    .mapEntries(e => [e[0], e[1].get()]))
            .getOrElse(Map<string, T>());
    }
    return Map();
}

export function parseMapFromPairs<A, B>(obj: unknown, fk: (a: unknown) => Option<A>, fv: (a: unknown) => Option<B>): Map<A, B> {
    const optPairs = parseList(obj, parseObject);

    return MapUtils.buildMapFromOptionalExtractors(optPairs, fk, fv);
}

export function parseStringMap<A, B>(obj: unknown): Map<string, string> {
    return parseMap(obj, parseString);
}

export function parseDate(obj: number | any, keepLocal: boolean = false): Option<moment.Moment> {
    return parseString(obj)
        .map(sqlTime => moment(sqlTime).utc(keepLocal));
}

export function parseDateWithFormat(obj: number | any, keepLocal: boolean = false, format: string): Option<moment.Moment> {
    return parseString(obj)
        .map(sqlTime => moment(sqlTime, format).utc(keepLocal));
}

export function parseBoolean(obj: unknown): Option<boolean> {
    if (typeof obj === 'string') {
        return Option.of(obj)
            .map(o => o === 'Y' || o === 'true');
    }
    return Option.of(obj)
        .filter(v => typeof v === 'boolean')
        .map(v => v as boolean);
}

export function parseObject(obj: unknown): Option<object> {
    return Option.of(obj)
        .filter(v => typeof v === 'object')
        .map(v => v as object);
}

export function parseObjectEither(obj: unknown): Either<string, object> {
    return EitherUtils.toEither(parseObject(obj), 'Failed to parse object');
}

/**
 * Converts json value to array of deserialize models.
 *
 * 1. Check we are actually an array (value not wrong type, undefined, or null)
 * 2. Return an empty array if 1. fails
 * 3. Deserialize all items in the array
 * 4. Remove all items that failed to deserialize
 *
 * It looks more complicated then it is because of handling typescript
 */
export function parseArray<T>(obj: unknown, f: (a: unknown) => Option<T>): ReadonlyArray<T> {
    if (typeof obj === 'string') {
        return parseArrayFromString(obj, f);
    }

    const array: ReadonlyArray<any> = Option.of(obj)
        .filter(v => v instanceof Array)
        .map(v => v as ReadonlyArray<any>)
        .getOrElse([]);

    return array
        .map(v => f(v))
        .filter(v => v.nonEmpty())
        .map(v => v.get());
}

export function parseArrayFromString<T>(obj: string, f: (a: unknown) => Option<T>): ReadonlyArray<T> {

    try {
        const json = JSON.parse(obj);

        const array: ReadonlyArray<any> = Option.of(json)
            .filter(v => v instanceof Array)
            .map(v => v as ReadonlyArray<any>)
            .getOrElse([]);

        return array
            .map(v => f(v))
            .filter(v => v.nonEmpty())
            .map(v => v.get());
    } catch (e) {
        return [];
    }

}

export function parseTuple2<T>(obj: unknown, f: (a: unknown) => Option<T>): Option<readonly [T, T]> {
    const array = parseArray(obj, f);
    return Option.map2(
        Option.of(array[0]),
        Option.of(array[1]),
        (a, b) => [a, b] as const);
}

export function parseTuple3<T>(obj: unknown, f: (a: unknown) => Option<T>): Option<readonly [T, T, T]> {
    const array = parseArray(obj, f);
    return Option.map3(
        Option.of(array[0]),
        Option.of(array[1]),
        Option.of(array[2]),
        (a, b, c) => [a, b, c] as const);
}

export function parseArrayPrimitive<T extends Primitive>(obj: unknown, f: (a: Primitive) => Option<T>): ReadonlyArray<T> {
    return parseArray(obj, x => {
        if (typeof x === 'string' || typeof x === 'number' || typeof x === 'boolean') {
            return f(x);
        }
        return None;
    });
}

export function parseArrayObject<T>(obj: unknown, f: (a: any) => Option<T>): ReadonlyArray<T> {
    return parseArray(obj, x => {
        if (typeof x === 'object') {
            return Option.of(x).flatMap(o => f(o as any));
        }
        return None;
    });
}

export function parseArrayEither<T>(obj: unknown, f: (a: unknown) => Option<T>): Either<string, ReadonlyArray<T>> {
    return EitherUtils.liftEither(obj, 'Failed to parse array')
        .map(x => parseArray(obj, f));
}

export function parseListEither<T>(obj: unknown, f: (a: unknown) => Option<T>): Either<string, List<T>> {
    return parseArrayEither(obj, f).map(x => List(x));
}

export function parseList<T>(obj: unknown, f: (a: unknown) => Option<T>): List<T> {
    return List(parseArray(obj, f));
}

export function parseSet<T>(obj: unknown, f: (a: unknown) => Option<T>): Set<T> {
    return Set(parseArray(obj, f));
}

export function parseSetEither<T>(obj: unknown, f: (a: unknown) => Option<T>): Either<string, Set<T>> {
    return parseArrayEither(obj, f).map(x => Set(x));
}

/**
 * Given a list of objects, focus on one field to return a flat list.
 *
 * Eg.
 *
 * [{'name': 'Foo'}, {'name': 'Bar'}]
 *
 * Focusing on name would be...
 *
 * ['Foo', 'Bar']
 */
export function parseFocusedList<T>(key: string, obj: unknown, f: (a: unknown) => Option<T>): List<T> {
    return List(parseArrayObject(obj, a => Option.of(a[key]).flatMap(f)));
}

export function parseListSerializable<A, B extends A>(obj: unknown, s: JsonSerializer<A, B>): List<A> {
    return List(parseArray(obj, a => s.fromJson(a)));
}

export function parseListSerializableEither<A, B extends A>(obj: unknown, s: JsonSerializer<A, B>): Either<string, List<A>> {
    return parseArrayEither(obj, a => s.fromJson(a)).map(x => List(x));
}

export function parseListSerializableFromStringOrArrayEither<A, B extends A>(
    obj: unknown, s: JsonSerializer<A, B>): Either<string, List<A>> {
    return parseArrayEither(obj, a => s.fromJson(a)).map(x => List(x));
}

export function parseListSerializableFromString<A, B extends A>(obj: string, s: JsonSerializer<A, B>): List<A> {
    return OptionUtils.toList(parseJsonFromString(obj))
        .flatMap(x => List(parseArray(x, a => s.fromJson(a))));
}

export function parseSetSerializable<A, B extends A>(obj: unknown, s: JsonSerializer<A, B>): Set<A> {
    return Set(parseArray(obj, a => s.fromJson(a)));
}

export function convertCollectionToArray<T>(collection: Collection<any, T>, s: JsonSerializer<T, T>): ReadonlyArray<object> {
    return collection.map(v => s.toJson(v))
        .filter(v => JSON.stringify(v) !== '{}')
        .toArray();
}

export function convertMapToObj<T>(collection: Map<string, T>, f: (t: T) => any): object {
    return collection.reduce((res: any, v: any, k: string) => {
        res[k] = f(v);
        return res;
    }, {});
}

export function convertSerializableMapToObj<T>(collection: Map<string, T>, s: JsonSerializer<T, T>): object {
    return convertMapToObj(collection, x => s.toJson(x));
}

/* tslint:disable: readonly-array */
export function stringifyCyclic(obj: unknown): string {
    const seen: unknown[] = [];
    return JSON.stringify(obj, (key, val) => {
        if (val != null && typeof val === 'object') {
            if (seen.indexOf(val) >= 0) {
                return;
            }
            seen.push(val);
        }
        return val;
    });
}

export function filterObject(filter: string, obj: any): object {
    if (filter.trim() === '') {
        return obj;
    }

    return Object.keys(obj).reduce(
        (res: any, key) => {
            if (key.toLowerCase().includes(filter)) {
                res[key] = obj[key];
            }

            if (typeof obj[key] === 'undefined' || obj[key] === 'null') {
                return res;
            }

            if (typeof obj[key] === 'object') {
                const filteredKeys = filterObject(filter, obj[key]);
                if (Object.keys(filteredKeys).length !== 0) {
                    res[key] = filteredKeys;
                }
                return res;
            }

            if (JSON.stringify(obj[key]).toLowerCase().includes(filter)) {
                res[key] = obj[key];
                return res;
            }

            return res;
        }, {});
}

/* tslint:enable readonly-array */

export function stringifyShallow(obj: unknown, arrayCap: number = 10, stringCap: number = 50): string {
    return JSON.stringify(obj, (k, v) => k !== undefined ? v.toString() : trimValue(k, v, arrayCap, stringCap));
}

export function mapObject(obj: any, fn: (a: any) => any): object {
    return Object.keys(obj).reduce(
        (res: any, key) => {
            res[key] = fn(obj[key]);
            return res;
        },
        {});
}

export function deepMap(obj: object, fn: (a: unknown) => any): object {
    const deepMapper: (a: any) => any = val => typeof val === 'object' ? deepMap(val, fn) : fn(val);
    if (Array.isArray(obj)) {
        return obj.map(deepMapper);
    }
    if (typeof obj === 'object') {
        return mapObject(obj, deepMapper);
    }
    return obj;
}

export function deepDeleteKeys(obj: object, toDel: string): object {
    const deepMapper: (a: any) => any = val => typeof val === 'object' ? deepDeleteKeys(val, toDel) : val;
    if (Array.isArray(obj)) {
        return obj.map(deepMapper);
    }
    if (typeof obj === 'object') {
        return deleteKeys(mapObject(obj, deepMapper), toDel);
    }
    return obj;
}

export function deleteKeys(obj: any, toDel: string): object {
    return Object.keys(obj)
        .filter(x => x !== toDel)
        .reduce((res: any, key) => {
                res[key] = obj[key];
                return res;
            },
            {});
}

// Ensures arrays/strings etc. are short when emmitting console logs....
// eg ["Foo", "Bar", "Baz"](...37)
// Meaning there are actually 37 entries in this array but we are only showing 3
function trimValue(k: string, obj: any, arrayCap: number, stringCap: number): string {
    if (Array.isArray(obj) && (obj as ReadonlyArray<any>).length > arrayCap) {
        const arr = obj as ReadonlyArray<any>;
        return JSON.stringify(arr.slice(0, arrayCap)) + `(...${arr.length})`;
    } else if (List.isList(obj) && (obj as List<any>).size > arrayCap) {
        const list = obj as List<any>;
        return JSON.stringify(list.take(stringCap)) + `(...${list.size})`;
    } else if (Set.isSet(obj) && (obj as Set<any>).size > stringCap) {
        const set = obj as Set<any>;
        return JSON.stringify(set.take(stringCap)) + `(...${set.size})`;
    } else if (typeof obj === 'string' && obj.length > stringCap) {
        return obj.substr(0, stringCap) + `(...${obj.length})`;
    } else {
        return obj;
    }
}
