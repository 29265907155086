import {Some} from 'funfix-core';
import {
    apiIdKey,
    companyIdKey,
    JsonBuilder,
    labelKey,
    OptionUtils,
    SimpleJsonSerializer,
    urlKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {ApiConnection} from './api-connection';

export class DbApiConnection implements Validatable {

    constructor(
        readonly label: string,
        readonly companyId: number,
        readonly url: string,
        readonly apiId: number,
    ) {
    }

    static fromConnectionWithApiId(connection: ApiConnection, apiId: number): DbApiConnection {
        return new DbApiConnection(
            connection.getLabel().get(),
            connection.getCompany().flatMap(x => x.getId()).get(),
            connection.getUrl().map(x => x.getHref()).get(),
            apiId,
        );
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateNvarchar('label', this.label, 50)),
            Some(ValidationUtils.validateInt('company', this.companyId.toString())),
            Some(ValidationUtils.validateNvarchar('url', this.url, 150)),
            Some(ValidationUtils.validateInt('apiId', this.apiId.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbApiConnectionJsonSeriailizer extends SimpleJsonSerializer<DbApiConnection> {
    static instance: DbApiConnectionJsonSeriailizer = new DbApiConnectionJsonSeriailizer();

    protected fromJsonImpl(json: any): DbApiConnection {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(value: DbApiConnection, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(labelKey, value.label)
            .add(companyIdKey, value.companyId) // Maybe change this key name
            .add(urlKey, value.url)
            .add(apiIdKey, value.apiId);
    }

}
