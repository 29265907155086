import {None, Option, Some} from 'funfix-core';
import {
    activeKey,
    conversionDateKey,
    conversionRateKey,
    currencyFromIdKey,
    currencyToIdKey,
    JsonBuilder,
    OptionUtils,
    proposalCompanyFinancialIdKey,
    proposalEntryFinancialIdKey,
    proposalFinancialIdKey,
    proposalFinancialsCurrencyIdKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';

export class DBFinancialsCurrencyRates implements Validatable {

    constructor(
        readonly proposalFinancialsCurrencyId: Option<number> = None,
        readonly currencyFromId: number,
        readonly currencyToId: number,
        readonly proposalCompanyFinancialId: Option<number> = None,
        readonly proposalFinancialId: Option<number> = None,
        readonly proposalEntryFinancialId: Option<number> = None,
        readonly conversionRate: number,
        readonly conversionDate: string,
        readonly active: string,
    ) { }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.proposalFinancialsCurrencyId.map(n => ValidationUtils.validateInt('proposalFinancialsCurrencyId', n.toString())),
            Some(ValidationUtils.validateInt('currencyFromId', this.currencyFromId.toString())),
            Some(ValidationUtils.validateInt('currencyToId', this.currencyToId.toString())),
            this.proposalCompanyFinancialId.map(n => ValidationUtils.validateInt('proposalCompanyFinancialId', n.toString())),
            this.proposalFinancialId.map(n => ValidationUtils.validateInt('proposalFinancialId', n.toString())),
            this.proposalEntryFinancialId.map(n => ValidationUtils.validateInt('proposalEntryFinancialId', n.toString())),
            Some(ValidationUtils.validateInt('conversionRate', this.conversionRate.toString())),
            Some(ValidationUtils.validateVarchar('conversionDate', this.conversionDate, 8)),
            Some(ValidationUtils.validateChar('active', this.active.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DBFinancialsCurrencyRatesJsonSerializer extends SimpleJsonSerializer<DBFinancialsCurrencyRates> {
    static instance: DBFinancialsCurrencyRatesJsonSerializer = new DBFinancialsCurrencyRatesJsonSerializer();

    fromJsonImpl(obj: any): DBFinancialsCurrencyRates {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(financialsCurrencyRates: DBFinancialsCurrencyRates, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(proposalFinancialsCurrencyIdKey, financialsCurrencyRates.proposalFinancialsCurrencyId)
            .add(currencyFromIdKey, financialsCurrencyRates.currencyFromId)
            .add(currencyToIdKey, financialsCurrencyRates.currencyToId)
            .addOptional(proposalCompanyFinancialIdKey, financialsCurrencyRates.proposalCompanyFinancialId)
            .addOptional(proposalFinancialIdKey, financialsCurrencyRates.proposalFinancialId)
            .addOptional(proposalEntryFinancialIdKey, financialsCurrencyRates.proposalEntryFinancialId)
            .add(conversionRateKey, financialsCurrencyRates.conversionRate)
            .add(conversionDateKey, financialsCurrencyRates.conversionDate)
            .add(activeKey, financialsCurrencyRates.active);
    }

}
