import { None, Option } from 'funfix-core';
import { JsonBuilder, parseString, terminalKey, timeKey } from '../core';
import { SimpleJsonSerializer } from '../core/json-serializer';

export class FlightWaypoint {
    constructor(
        public time: Option<string> = None,
        public terminal: Option<string> = None) { }
}

// TODO: Use Date instead.
export class FlightWaypointJsonSerializer extends SimpleJsonSerializer<FlightWaypoint> {
    static instance: FlightWaypointJsonSerializer = new FlightWaypointJsonSerializer();

    fromJsonImpl(obj: any): FlightWaypoint {
        return new FlightWaypoint(
            parseString(timeKey),
            parseString(terminalKey),
        );
    }

    protected toJsonImpl(value: FlightWaypoint, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(timeKey, value.time)
            .addOptional(terminalKey, value.terminal);
    }
}
