import {None, Option} from 'funfix-core';
import {addressKey, gpsKey, parseString, postcodeKey} from '../core';
import {JsonBuilder} from '../core/json-builder';
import {cityKey, countryKey, stateKey} from '../core/json-keys';
import {SimpleJsonSerializer} from '../core/json-serializer';
import {GpsCoordinates, GpsCoordinatesJsonSerializer} from './gps-coordinates';
import {LatLongLocation} from './lat-long-location';
import {PhysicalLocation} from './physical-location';
import {Rating} from './rating';

export class GpsLocation extends PhysicalLocation {
    constructor(
        city: Option<string> = None,
        state: Option<string> = None,
        country: Option<string> = None,
        address: Option<string> = None,
        postcode: Option<string> = None,
        readonly gpsCoords: Option<GpsCoordinates> = None) {
        super(city, state, country, address, postcode);
    }

    canGeocode(): boolean {
        return this.getAddress().nonEmpty() && this.getCity().nonEmpty();
    }

    getAddress(): Option<string> {
        return this.address;
    }

    getCity(): Option<string> {
        return this.city;
    }

    getCountry(): Option<string> {
        return this.country;
    }

    getGpsCoords(): Option<GpsCoordinates> {
        return this.gpsCoords;
    }

    getLatLongLocation(): Option<LatLongLocation> {
        return this.gpsCoords.map(x => x.getLatLongLocation());
    }

    getRating(): Rating {
        if (this.isGoldRating()) {
            return 'Gold';
        } else if (this.isAtLeastSilverRating()) {
            return 'Silver';
        } else if (this.isAtLeastBronzeRating()) {
            return 'Bronze';
        }
        return 'Black';
    }

    getState(): Option<string> {
        return this.state;
    }

    isAtLeastBronzeRating(): boolean {
        return this.getLocationString().exists(x => x.trim() !== '');
    }

    isAtLeastSilverRating(): boolean {
        return this.getAddress().nonEmpty()
            && this.getCity().nonEmpty()
            && this.getState().nonEmpty()
            && this.getCountry().nonEmpty()
            && this.getPostCode().nonEmpty();
    }

    isEmpty(): boolean {
        return super.isEmpty()
            && this.gpsCoords.forAll(c => c.isEmpty());
    }

    isGoldRating(): boolean {
        return !this.getLatLongLocation().isEmpty()
            && this.getAddress().nonEmpty()
            && this.getCity().nonEmpty()
            && this.getState().nonEmpty()
            && this.getCountry().nonEmpty()
            && this.getPostCode().nonEmpty();
    }
}

export class GpsLocationJsonSerializer extends SimpleJsonSerializer<GpsLocation> {
    static instance: GpsLocationJsonSerializer = new GpsLocationJsonSerializer();

    fromJsonImpl(obj: any): GpsLocation {
        return new GpsLocation(
            parseString(obj[cityKey]),
            parseString(obj[stateKey]),
            parseString(obj[countryKey]),
            parseString(obj[addressKey]),
            parseString(obj[postcodeKey]),
            GpsCoordinatesJsonSerializer.instance.fromJson(obj[gpsKey]));
    }

    protected toJsonImpl(location: GpsLocation, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(cityKey, location.city)
            .addOptional(stateKey, location.state)
            .addOptional(countryKey, location.country)
            .addOptional(addressKey, location.address)
            .addOptional(postcodeKey, location.postcode)
            .addOptionalSerializable(gpsKey, location.gpsCoords, GpsCoordinatesJsonSerializer.instance);
    }
}
