import { None, Option } from 'funfix-core';
import { List } from 'immutable';
import {
    confirmedKey,
    JsonBuilder,
    nameKey,
    parseListSerializable,
    parseString,
    proposalsKey, qsidKey, quoteKey,
    SimpleJsonSerializer, titleKey, travellingKey,
} from '../core';

export class RecentProposal {
    constructor(
        public title: Option<string> = None,
        public qsid: Option<string> = None) { }
}

export class RecentProposalJsonSerializer extends SimpleJsonSerializer<RecentProposal> {
    static instance: RecentProposalJsonSerializer = new RecentProposalJsonSerializer();

    fromJsonImpl(obj: any): RecentProposal {
        return new RecentProposal(
            parseString(obj[titleKey]),
            parseString(obj[qsidKey]),
        );
    }

    protected toJsonImpl(value: RecentProposal, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(titleKey, value.title)
            .addOptional(qsidKey, value.qsid);
    }
}

export class RecentProposalsByType {
    constructor(
        public quotes: List<RecentProposal> = List(),
        public travelling: List<RecentProposal> = List(),
        public confirmed: List<RecentProposal> = List()) { }
}

export class RecentProposalsByTypeJsonSerializer extends SimpleJsonSerializer<RecentProposalsByType> {
    static instance: RecentProposalsByTypeJsonSerializer = new RecentProposalsByTypeJsonSerializer();

    fromJsonImpl(obj: any): RecentProposalsByType {
        return new RecentProposalsByType(
            parseListSerializable(obj[quoteKey], RecentProposalJsonSerializer.instance),
            parseListSerializable(obj[travellingKey], RecentProposalJsonSerializer.instance),
            parseListSerializable(obj[confirmedKey], RecentProposalJsonSerializer.instance),
        );
    }

    protected toJsonImpl(value: RecentProposalsByType, builder: JsonBuilder): JsonBuilder {
        return builder
            .addIterableSerializable(quoteKey, value.quotes, RecentProposalJsonSerializer.instance)
            .addIterableSerializable(travellingKey, value.travelling, RecentProposalJsonSerializer.instance)
            .addIterableSerializable(confirmedKey, value.confirmed, RecentProposalJsonSerializer.instance);
    }
}

export class RecentProposals {
    constructor(
        public name: Option<string> = None,
        public proposals: Option<RecentProposalsByType> = None) { }
}

export class RecentProposalsJsonSerializer extends SimpleJsonSerializer<RecentProposals> {
    static instance: RecentProposalsJsonSerializer = new RecentProposalsJsonSerializer();

    fromJsonImpl(obj: any): RecentProposals {
        return new RecentProposals(
            parseString(obj[nameKey]),
            RecentProposalsByTypeJsonSerializer.instance.fromJson(obj[proposalsKey]),
        );
    }

    protected toJsonImpl(value: RecentProposals, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(nameKey, value.name)
            .addOptionalSerializable(proposalsKey, value.proposals, RecentProposalsByTypeJsonSerializer.instance);
    }
}
