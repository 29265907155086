import { List, Seq } from 'immutable';

/* tslint:disable */
const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis ac ex aliquet luctus. Integer in dapibus dui. Morbi tincidunt magna tempor leo viverra semper. Cras feugiat enim nisi. Aliquam aliquam risus ac ante congue bibendum. Praesent ullamcorper scelerisque augue, vel lacinia tortor hendrerit sed. Phasellus odio orci, suscipit vel bibendum sit amet, sollicitudin at lorem. Aenean mi nisl, accumsan consectetur enim nec, maximus placerat arcu. Sed vel arcu vel lorem luctus rhoncus. Nam iaculis enim leo, vel malesuada nibh pharetra convallis. Quisque vel sapien tellus. Sed eget arcu ultricies, lobortis dolor eu, fringilla ipsum. Integer eu nisi orci.

Sed rhoncus vel risus at scelerisque. Cras ut ornare lacus, vitae ullamcorper ligula. Suspendisse pulvinar nisl in eros euismod rutrum. Curabitur vitae dapibus tortor, varius pretium nulla. Duis at lorem dui. Fusce dictum, neque quis maximus luctus, risus lacus cursus purus, eget tincidunt ante ligula eget erat. Aliquam a nulla malesuada, tristique ipsum eu, luctus metus. Sed mattis mattis maximus. Vestibulum pharetra, quam non finibus rutrum, purus sem tristique lectus, vel euismod justo massa a nisl. Praesent posuere hendrerit diam, vel dictum augue euismod a. Quisque mauris erat, cursus at sagittis non, porttitor vel enim. Curabitur dictum mi id sapien volutpat blandit. Pellentesque ligula libero, pharetra nec tempor sed, sagittis faucibus sapien. Donec ligula lacus, viverra varius consequat ut, porttitor eu lectus. Ut varius ut lorem vitae vehicula. Suspendisse tempor mauris ac magna tristique malesuada.

Vivamus hendrerit est id dictum euismod. Curabitur leo tellus, egestas vitae erat a, condimentum consequat mi. Proin a mauris sit amet magna volutpat rhoncus. Mauris egestas, mauris quis facilisis ornare, dui augue commodo enim, non luctus nibh ligula a quam. Nam dolor risus, rhoncus in diam vel, facilisis mattis diam. Praesent suscipit, magna nec dignissim vestibulum, massa justo tincidunt dolor, commodo tincidunt augue orci a ligula. In a nisi sed arcu porta ultricies quis ut ex. Mauris interdum mollis porta. Aliquam feugiat turpis quis velit facilisis fringilla. Fusce ac bibendum diam, nec tristique sapien. Aliquam erat volutpat. Fusce lacinia lobortis dui a egestas. Nulla interdum lectus maximus laoreet luctus.

Praesent convallis, ligula ut mollis convallis, ex orci mattis nulla, et lobortis est massa vitae velit. Sed molestie lacinia diam sed tincidunt. Donec viverra magna et finibus facilisis. Sed nibh lacus, rutrum in ultrices ut, ornare ac dui. Praesent sit amet molestie nibh. Nulla ac neque velit. Fusce arcu purus, fermentum a nulla et, volutpat scelerisque nibh. Quisque sit amet purus sit amet metus convallis molestie. Aliquam ut tempor quam. Suspendisse et mi a ligula sagittis accumsan. Fusce nibh leo, fringilla eu nisl eget, luctus rhoncus felis.

Integer euismod tortor erat, vitae auctor mi dapibus sit amet. Vestibulum egestas eros quis nibh fringilla molestie sed sit amet nisl. Donec sagittis tempus leo, sed hendrerit arcu accumsan interdum. Integer condimentum suscipit ante, in dignissim tortor rhoncus id. Suspendisse potenti. Pellentesque lacus ante, lobortis eu elementum in, tincidunt id nunc. Suspendisse dapibus tempus arcu, vitae fermentum turpis vehicula efficitur. Aliquam sed magna tincidunt enim mollis ornare id ac massa. Donec non diam enim. In ultrices urna eget nulla tempus, id eleifend orci tristique. Sed rutrum, ipsum et tempus tincidunt, neque orci tempus ex, sit amet sodales tellus lacus vel lacus. Duis interdum ligula eu lobortis vestibulum. Vivamus scelerisque quam ut risus elementum, ullamcorper ultrices purus viverra.`;

/* tslint:enable */

export class Lorem {
    static generate(n: number): string {
        const loremSeq = Seq.Indexed(List.of(...(lorem as any as string).split('')));
        return loremSeq
            .concat(loremSeq)
            .take(n)
            .join('');
    }
}
