import { Option } from 'funfix-core';
import {
    descriptionKey,
    entryTypeKey,
    JsonBuilder,
    parseString,
    productTypeKey,
    SimpleJsonSerializer,
    transportTypeKey,
    typeKey,
} from '../core';

export class ServiceCodeAnalysis {
    constructor(
        readonly type: Option<string>,
        readonly description: Option<string>,
        readonly productType: Option<string>,
        readonly entryType: Option<string>,
        readonly transportType: Option<string>,
    ) {
    }

    withDescription(description: Option<string>): ServiceCodeAnalysis {
        return new ServiceCodeAnalysis(
            this.type,
            description,
            this.productType,
            this.entryType,
            this.transportType,
        );
    }
}

export class ServiceCodeAnalysisJsonSerializer extends SimpleJsonSerializer<ServiceCodeAnalysis> {
    static instance = new ServiceCodeAnalysisJsonSerializer();

    protected fromJsonImpl(json: any): ServiceCodeAnalysis {
        return new ServiceCodeAnalysis(
            parseString(json[typeKey]),
            parseString(json[descriptionKey]),
            parseString(json[productTypeKey]),
            parseString(json[entryTypeKey]),
            parseString(json[transportTypeKey]),
        );
    }

    protected toJsonImpl(value: ServiceCodeAnalysis, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(typeKey, value.type)
            .addOptional(descriptionKey, value.description)
            .addOptional(productTypeKey, value.productType)
            .addOptional(entryTypeKey, value.entryType)
            .addOptional(transportTypeKey, value.transportType);
    }
}
