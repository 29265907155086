import {None, Option, Some} from 'funfix-core';
import {
    agentKey,
    companyIdKey,
    companyKey,
    contactKey,
    firstNameKey,
    idKey,
    imageKey,
    JsonBuilder,
    JsonSerializer,
    lastNameKey,
    locationKey,
    OptionUtils,
    parseNumber,
    parseString,
    paxTypeKey,
    salutationKey,
    SimpleJsonSerializer,
} from '../core';
import {CompanyJsonSerializer, CompanyLike} from './company';
import {Contact, ContactJsonSerializer} from './contact';
import {PhysicalLocation, PhysicalLocationJsonSerializer} from './physical-location';

export type PaxType = 'adult' | 'child' | 'infant';

export class PersonLike {

    buildPerson(): Person {
        return new Person(
            this.getId(),
            this.getCompanyId(),
            this.getFirstName(),
            this.getLastName(),
            this.getLocation(),
            this.getContact(),
            this.getPaxType(),
            this.getImage(),
            this.getSalutation(),
        );
    }

    getCompanyId(): Option<number> {
        return None;
    }

    getContact(): Option<Contact> {
        return None;
    }

    getFirstName(): Option<string> {
        return None;
    }

    getId(): Option<number> {
        return None;
    }

    getImage(): Option<string> {
        return None;
    }

    getLastName(): Option<string> {
        return None;
    }

    getLocation(): Option<PhysicalLocation> {
        return None;
    }

    getPaxType(): Option<PaxType> {
        return None;
    }

    getSalutation(): Option<string> {
        return None;
    }

}

export class Person extends PersonLike {

    constructor(
        readonly id: Option<number> = None,
        readonly companyId: Option<number> = None,
        readonly firstName: Option<string> = None,
        readonly lastName: Option<string> = None,
        readonly location: Option<PhysicalLocation> = None,
        readonly contact: Option<Contact> = None,
        readonly paxType: Option<PaxType> = None,
        readonly image: Option<string> = None,
        readonly salutation: Option<string> = None,
    ) {
        super();
    }

    static toPaxType(s: string): Option<PaxType> {
        switch (s) {
            case 'adult':
                return Some<PaxType>('adult');
            case 'child':
                return Some<PaxType>('child');
            case 'infant':
                return Some<PaxType>('infant');
            default:
                return None;
        }
    }

    getCompanyId(): Option<number> {
        return this.companyId;
    }

    getContact(): Option<Contact> {
        return this.contact;
    }

    getEmail(): Option<string> {
        return this.getContact()
            .flatMap(x => x.getEmail());
    }

    getFirstName(): Option<string> {
        return this.firstName;
    }

    getFullName(): Option<string> {
        return Option.of(OptionUtils.toList(this.firstName, this.lastName).join(' '))
            .filter(x => x !== '');
    }

    getFullNameWithSalutation(): Option<string> {
        return Option.of(OptionUtils.toList(this.salutation, this.firstName, this.lastName).join(' '))
            .filter(x => x !== '');
    }

    getId(): Option<number> {
        return this.id;
    }

    getImage(): Option<string> {
        return this.image;
    }

    getLastName(): Option<string> {
        return this.lastName;
    }

    getLocation(): Option<PhysicalLocation> {
        return this.location;
    }

    getPaxType(): Option<PaxType> {
        return this.paxType;
    }

    getSalutation(): Option<string> {
        return this.salutation;
    }

    getSummary(): Option<Person> {
        return Option.map2(this.getFirstName(), this.getLastName(), (a, b) => {
            return new Person(
                this.getId(),
                None,
                this.getFirstName(),
                this.getLastName(),
                None,
                None,
                None,
                None);
        });
    }

    isAdult(): boolean {
        return this.getPaxType()
            .contains('adult');
    }

    isChild(): boolean {
        return this.getPaxType()
            .contains('child');
    }

    isInfant(): boolean {
        return this.getPaxType()
            .contains('infant');
    }
}

export class AgentDetails {

    constructor(
        readonly agent: Option<Person> = None,
        readonly company: Option<CompanyLike> = None) {
    }

    getAgent(): Option<Person> {
        return this.agent;
    }

    getCompany(): Option<CompanyLike> {
        return this.company;
    }

}

export class AgentDetailsJsonSerializer extends SimpleJsonSerializer<AgentDetails> {
    static instance: AgentDetailsJsonSerializer = new AgentDetailsJsonSerializer();

    fromJsonImpl(obj: any): AgentDetails {
        return new AgentDetails(
            PersonJsonSerializer.instance.fromJson(obj[agentKey]),
            CompanyJsonSerializer.instance.fromJson(obj[companyKey]),
        );
    }

    protected toJsonImpl(agent: AgentDetails, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(agentKey, agent.agent, PersonJsonSerializer.instance)
            .addOptionalSerializable(companyKey, agent.company, CompanyJsonSerializer.instance);
    }
}

export class PersonJsonSerializer<T extends Person> extends SimpleJsonSerializer<Person> {
    static instance: PersonJsonSerializer<Person> = new PersonJsonSerializer<Person>();

    fromJsonImpl(obj: any): Person {
        return new Person(
            parseNumber(obj[idKey]),
            parseNumber(obj[companyIdKey]),
            parseString(obj[firstNameKey]),
            parseString(obj[lastNameKey]),
            PhysicalLocationJsonSerializer.instance.fromJson(obj[locationKey]),
            ContactJsonSerializer.instance.fromJson(obj[contactKey]),
            parseString(obj[paxTypeKey]).flatMap(x => Person.toPaxType(x)),
            parseString(obj[imageKey]),
            parseString(obj[salutationKey]));
    }

    protected toJsonImpl(person: T, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(idKey, person.id)
            .addOptional(companyIdKey, person.companyId)
            .addOptional(firstNameKey, person.firstName)
            .addOptional(lastNameKey, person.lastName)
            .addOptionalSerializable(locationKey, person.location, PhysicalLocationJsonSerializer.instance)
            .addOptionalSerializable(contactKey, person.contact, ContactJsonSerializer.instance)
            .addOptional(paxTypeKey, person.paxType)
            .addOptional(imageKey, person.image)
            .addOptional(salutationKey, person.salutation);
    }
}

// Serializer that avoids location and contact info. Used in firebase
export class FilteredPersonJsonSerializer<T extends Person> extends JsonSerializer<Person, T> {
    static instance: PersonJsonSerializer<Person> = new PersonJsonSerializer();

    fromJsonImpl(obj: any): Person {
        return new Person(
            parseNumber(obj[idKey]),
            parseNumber(obj[companyIdKey]),
            parseString(obj[firstNameKey]),
            parseString(obj[lastNameKey]));
    }

    protected toJsonImpl(person: T, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(idKey, person.id)
            .addOptional(companyIdKey, person.id)
            .addOptional(firstNameKey, person.firstName)
            .addOptional(lastNameKey, person.lastName);
    }
}
