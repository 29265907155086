import { Set } from 'immutable';
import {
    accessAllApisKey,
    accessAllProductsKey,
    accessAllProposalsKey,
    accessAllTemplatesKey,
    JsonBuilder,
    manageCompaniesKey,
    parseNumber,
    parseSet,
    SimpleJsonSerializer,
} from '../core';

export class Relationships {
    constructor(
        readonly accessAllProducts: Set<number> = Set(),
        readonly accessAllProposals: Set<number> = Set(),
        readonly accessAllTemplates: Set<number> = Set(),
        readonly accessAllApis: Set<number> = Set(),
        readonly manageCompanies: Set<number> = Set(),
    ) {
    }
}

export class RelationshipsJsonSerializer extends SimpleJsonSerializer<Relationships> {
    static instance = new RelationshipsJsonSerializer();

    protected fromJsonImpl(json: any): Relationships {
        return new Relationships(
            parseSet(json[accessAllProductsKey], parseNumber),
            parseSet(json[accessAllProposalsKey], parseNumber),
            parseSet(json[accessAllTemplatesKey], parseNumber),
            parseSet(json[accessAllApisKey], parseNumber),
            parseSet(json[manageCompaniesKey], parseNumber),
        );
    }

    protected toJsonImpl(value: Relationships, builder: JsonBuilder): JsonBuilder {
        return builder
            .addIterable(accessAllProductsKey, value.accessAllProducts)
            .addIterable(accessAllProposalsKey, value.accessAllProposals)
            .addIterable(accessAllTemplatesKey, value.accessAllTemplates)
            .addIterable(accessAllApisKey, value.accessAllApis)
            .addIterable(manageCompaniesKey, value.manageCompanies);
    }
}
