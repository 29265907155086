import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    contentCollectionIdKey,
    fakeIdxKey,
    JsonBuilder,
    OptionUtils,
    productIdKey,
    SimpleJsonSerializer,
    textKey,
    Validatable,
    ValidationResult,
} from '../core';
import {ProductContentCollection} from './content-collection';
import {LocationContentCollection} from './location-content-collection';
import {Product} from './product';

const gettingThereId = 1156;
const distanceFromAirportId = 1;
const distanceFromCityCenterId = 441;

export class DbContentCollectionSubmissionsText implements Validatable {

    constructor(
        readonly productId: Option<number>,
        readonly fakeIdx: Option<number>,
        readonly contentCollectionId: number,
        readonly text: string) { }

    static fromLocationContentCollection(
        prodId: Option<number>,
        fakeIdx: Option<number>,
        loc: LocationContentCollection): List<DbContentCollectionSubmissionsText> {
        return OptionUtils.toList(
            loc.distanceFromAirport
                .map(x => new DbContentCollectionSubmissionsText(prodId, fakeIdx, distanceFromAirportId, (x.meters / 1000).toString())),
            loc.distanceFromCityCenter
                .map(x => new DbContentCollectionSubmissionsText(prodId, fakeIdx, distanceFromCityCenterId, (x.meters / 1000).toString())),
            loc.gettingThere.map(x => new DbContentCollectionSubmissionsText(prodId, fakeIdx, gettingThereId, x)));
    }

    static fromProduct(product: Product, fakeIdx: Option<number>): List<DbContentCollectionSubmissionsText> {
        return product.extraContent
            .map(c => DbContentCollectionSubmissionsText.fromProductContentCollection(product.id, fakeIdx, c))
            .getOrElse(List<DbContentCollectionSubmissionsText>())
            .concat(DbContentCollectionSubmissionsText.fromProducts(product.subproducts, fakeIdx));
    }

    static fromProductContentCollection(
        prodId: Option<number>,
        fakeIdx: Option<number>,
        content: ProductContentCollection): List<DbContentCollectionSubmissionsText> {
        return OptionUtils.toList(content.location)
            .flatMap(p => DbContentCollectionSubmissionsText.fromLocationContentCollection(prodId, fakeIdx, p));
    }

    static fromProducts(
        products: List<Product>,
        parentFakeIdx: Option<number> = None,
    ): List<DbContentCollectionSubmissionsText> {
        return products.flatMap((x, idx) => DbContentCollectionSubmissionsText.fromProduct(
            x,
            OptionUtils.applyOrReturnNonEmpty(parentFakeIdx, Some(idx), (a, b) => a * 1000 + b)));
    }

    validate(): ValidationResult {
        return new ValidationResult();
    }
}

export class DbContentCollectionSubmissionsTextJsonSerializer extends SimpleJsonSerializer<DbContentCollectionSubmissionsText> {

    static instance: DbContentCollectionSubmissionsTextJsonSerializer = new DbContentCollectionSubmissionsTextJsonSerializer();

    fromJsonImpl(obj: any): DbContentCollectionSubmissionsText {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(value: DbContentCollectionSubmissionsText, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(productIdKey, value.productId)
            .addOptional(fakeIdxKey, value.fakeIdx)
            .add(contentCollectionIdKey, value.contentCollectionId)
            .add(textKey, value.text);
    }
}
