import {Either, Left, None, Option, Right, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    additionalPeopleKey,
    classificationsKey,
    customEntriesKey,
    customNotesKey,
    EitherUtils,
    entriesKey,
    entryFinancialsKey,
    entryNotesKey,
    JsonBuilder,
    peopleKey,
    proposalFinancialsKey,
    proposalKey,
    SimpleJsonSerializer,
    transportEntriesKey,
} from '../core';
import {DbAdditionalPeople, DBAdditionalPeopleJsonSerializer} from './db-additional-people';
import {DbCustomEntry, DBCustomEntryJsonSerializer} from './db-custom-entry';
import {DbCustomNote, DBCustomNotesJsonSerializer} from './db-custom-note';
import {
    DbEntrySelectedClassification,
    DbEntrySelectedClassificationJsonSerializer,
} from './db-entry-selected-classification';
import {DBFinancials, DBFinancialsJsonSerializer} from './db-financials';
import {DBPeople, DBPeopleJsonSerializer} from './db-people';
import {DbProposal, DBProposalJsonSerializer} from './db-proposal';
import {DBProposalEntry, DBProposalEntryJsonSerializer} from './db-proposal-entry';
import {DBTransportEntry, DBTransportEntryJsonSerializer} from './db-transport-entry';
import {EnumConstantMap} from './enum-constant';
import {ProposalLike} from './proposal';
import {ProposalEntry} from './proposal-entry';

export class DbProposalResultSet {

    constructor(
        readonly people: List<DBPeople> = List(),
        readonly additionalPeople: List<DbAdditionalPeople> = List(),
        readonly proposalFinancials: Option<DBFinancials> = None,
        readonly customNotes: List<DbCustomNote> = List(),
        readonly entries: Either<string, List<DBProposalEntry>> = Right(List()),
        readonly entryCustomNotes: List<DbCustomNote> = List(),
        readonly customEntries: List<DbCustomEntry> = List(),
        readonly transportEntries: List<DBTransportEntry> = List(),
        readonly entryFinancials: List<DBFinancials> = List(),
        readonly entrySelectedClassifications: List<DbEntrySelectedClassification> = List(),
        readonly proposal: Either<string, DbProposal> = Left('No Data'),
    ) {
    }

    static empty = new DbProposalResultSet();

    static fromEntries(
        proposalId: number,
        entries: List<ProposalEntry>,
        currencyMap: EnumConstantMap,
        entryClassificationMapping: EnumConstantMap,
        proposalClassificationMapping: EnumConstantMap,
        transportTypeMapping: EnumConstantMap,
        languageMap: EnumConstantMap,
        proposalTypeMap: EnumConstantMap,
        prodOptfinder: (prodId: number) => Either<string, number>,
    ): DbProposalResultSet {
        return new DbProposalResultSet(
            List(),
            List(),
            None,
            List(),
            DBProposalEntry.fromEntries(entries, entryClassificationMapping, prodOptfinder),
            List(), // TODO: Add custom notes and investigate fake_idx stuff
            DbCustomEntry.fromEntries(entries),
            DBTransportEntry.fromEntries(entries, transportTypeMapping),
            DBFinancials.fromEntries(entries, currencyMap),
            DbEntrySelectedClassification.buildFromEntries(entries, entryClassificationMapping),
            Left(''),
        );

    }

    static fromProposal(
        proposal: ProposalLike,
        currencyMap: EnumConstantMap,
        entryClassificationMapping: EnumConstantMap,
        proposalClassificationMapping: EnumConstantMap,
        transportTypeMapping: EnumConstantMap,
        languageMap: EnumConstantMap,
        proposalTypeMap: EnumConstantMap,
        prodOptfinder: (prodId: number) => Either<string, number>,
    ): DbProposalResultSet {
        const companyId = proposal.getCompany().flatMap(x => x.getId()); // HACK
        return new DbProposalResultSet(
            companyId.map(i => DBPeople.fromPeople(i, proposal.getPeople())).getOrElse(List()),
            DbAdditionalPeople.fromPeople(proposal.getAdditionalPeople()),
            DBFinancials.fromProposal(proposal, currencyMap),
            DbCustomNote.fromNotes(None, proposal.getCustomNotes(), proposalClassificationMapping),
            DBProposalEntry.fromEntries(proposal.getEntries(), entryClassificationMapping, prodOptfinder),
            proposal.getEntries().flatMap((x, idx) => DbCustomNote.fromNotes(Some(idx), x.getExtraNotes(), proposalClassificationMapping)),
            DbCustomEntry.fromEntries(proposal.getEntries()),
            DBTransportEntry.fromEntries(proposal.getEntries(), transportTypeMapping),
            DBFinancials.fromEntries(proposal.getEntries(), currencyMap),
            DbEntrySelectedClassification.buildFromEntries(proposal.getEntries(), entryClassificationMapping),
            DbProposal.fromProposal(proposal, currencyMap, languageMap, proposalTypeMap));
    }

    static fromProposalForUpdate(
        proposal: ProposalLike,
        currencyMap: EnumConstantMap,
        entryClassificationMapping: EnumConstantMap,
        proposalClassificationMapping: EnumConstantMap,
        transportTypeMapping: EnumConstantMap,
        languageMap: EnumConstantMap,
        proposalTypeMap: EnumConstantMap,
        prodOptfinder: (prodId: number) => Either<string, number>,
    ): DbProposalResultSet {
        const companyId = proposal.getCompany().flatMap(x => x.getId()); // HACK
        return new DbProposalResultSet(
            companyId.map(i => DBPeople.fromPeople(i, proposal.getPeople())).getOrElse(List()),
            DbAdditionalPeople.fromPeople(proposal.getAdditionalPeople()),
            DBFinancials.fromProposal(proposal, currencyMap),
            DbCustomNote.fromNotes(None, proposal.getCustomNotes(), proposalClassificationMapping),
            DBProposalEntry.fromEntries(proposal.getEntries(), entryClassificationMapping, prodOptfinder),
            proposal.getEntries().flatMap((x, idx) => DbCustomNote.fromNotes(Some(idx), x.getExtraNotes(), proposalClassificationMapping)),
            DbCustomEntry.fromEntries(proposal.getEntries()),
            DBTransportEntry.fromEntries(proposal.getEntries(), transportTypeMapping),
            DBFinancials.fromEntries(proposal.getEntries(), currencyMap),
            DbEntrySelectedClassification.buildFromEntries(proposal.getEntries(), entryClassificationMapping),
            DbProposal.fromProposalForUpdate(proposal, currencyMap, languageMap, proposalTypeMap));
    }

    getError(): Option<string> {
        return EitherUtils.toOptionLeft(this.proposal)
            .orElse(EitherUtils.toOptionLeft(this.entries));
    }

    hasErrors(): boolean {
        return this.getError().nonEmpty();
    }
}

export class DbProposalResultSetJsonSerializer extends SimpleJsonSerializer<DbProposalResultSet> {
    static instance: DbProposalResultSetJsonSerializer = new DbProposalResultSetJsonSerializer();

    fromJsonImpl(obj: any): DbProposalResultSet {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(proposal: DbProposalResultSet, builder: JsonBuilder): JsonBuilder {
        return builder
            .addIterableSerializable(peopleKey, proposal.people, DBPeopleJsonSerializer.instance)
            .addIterableSerializable(additionalPeopleKey, proposal.additionalPeople, DBAdditionalPeopleJsonSerializer.instance)
            .addOptionalSerializable(proposalFinancialsKey, proposal.proposalFinancials, DBFinancialsJsonSerializer.instance)
            .addIterableSerializable(customNotesKey, proposal.customNotes, DBCustomNotesJsonSerializer.instance)
            .addIterableSerializable(entriesKey, proposal.entries.getOrElse(List()), DBProposalEntryJsonSerializer.instance)
            .addIterableSerializable(entryNotesKey, proposal.entryCustomNotes, DBCustomNotesJsonSerializer.instance)
            .addIterableSerializable(entryFinancialsKey, proposal.entryFinancials, DBFinancialsJsonSerializer.instance)
            .addIterableSerializable(customEntriesKey, proposal.customEntries, DBCustomEntryJsonSerializer.instance)
            .addIterableSerializable(transportEntriesKey, proposal.transportEntries, DBTransportEntryJsonSerializer.instance)
            .addIterableSerializable(classificationsKey, proposal.entrySelectedClassifications, DbEntrySelectedClassificationJsonSerializer.instance)
            .addOptionalSerializable(proposalKey, proposal.proposal.toOption(), DBProposalJsonSerializer.instance);
    }
}
