import {Either, Left, None, Option, Some} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {
    ComparisonUtils,
    Language,
    MediaService,
    parseNumber,
    parseNumberToEither,
    parseSetEither,
    parseString,
    parseStringMap,
    StringSearchType,
    Url,
} from '../core';
import {
    Api,
    ApiAgency,
    ApiAgencyJsonSerializer,
    ApiCache,
    ApiJsonSerializer,
    ApiRequestSettingsJsonSerializer,
    AutoImportSettings,
    Company,
    CompanyJsonSerializer,
    CompanySummary,
    CompanySummaryForTemplate,
    CompanySummaryForTemplateJsonSerializer,
    CompanySummaryJsonSerializer,
    ImageForGallery,
    MappingDetails,
    MappingDetailsJsonSerializer,
    Pdf,
    Product,
    ProductJsonSerializer,
    ProductOption,
    ProductSearch,
    ProductSearchJsonSerializer,
    Proposal,
    ProposalEntryWithMeta,
    ProposalJsonSerializer,
    ProposalWithMeta,
    ProposalWithMetaJsonSerializer,
    User,
    UserJsonSerializer,
} from '../models';
import {ApiBase} from './api-base';

const noMod = 'This should not modify anything !!!!';

const didgigoServer = 'https://didgigo.com';

export class DidgigoApi extends ApiBase {

    constructor(readonly apiKey: string, apiServer: string) {
        super(apiServer);
    }

    async convertToRoadbook(x: Proposal, modifiedBy: string): Promise<Either<string, Proposal>> {
        return this.processPostApiRequestSerialized(
            'proposal/convert/roadbook',
          ProposalJsonSerializer.instance,
          {proposal: ProposalJsonSerializer.instance.toJson(x)},
          modifiedBy,
        );
    }

    async createApiCompanyAgentMapping(
      apiAgency: ApiAgency,
      connectionId: number,
      modifiedBy: string,
    ): Promise<Either<string, number>> {
        return this.processPostApiRequest(
          `mapping/write/api-agent-company-mapping/${connectionId}`,
          {agency: ApiAgencyJsonSerializer.instance.toJson(apiAgency)},
          modifiedBy,
        );
    }

    async createMissingProductsAutoDetectSupplier(
      prods: List<Product>,
      modifiedBy: string): Promise<Either<string, List<number>>> {
        return this.processPostApiRequestListOptParsable(
          `products/create?otf=true`,
          v => parseNumber(v),
          {products: ProductJsonSerializer.instance.toJsonArray(prods)},
          modifiedBy,
        );
    }

    async createProduct(
      x: Product,
      modifiedBy: string): Promise<Either<string, Product>> {
        return this.processPostApiRequestSerialized(
          'product/create/',
          ProductJsonSerializer.instance,
          {product: ProductJsonSerializer.instance.toJson(x)},
          modifiedBy,
        );
    }

    async createProducts(x: List<Product>, modifiedBy: string): Promise<Either<string, List<Product>>> {
        return this.processPostApiRequestListSerialized(
          'products/create/',
          ProductJsonSerializer.instance,
          {products: ProductJsonSerializer.instance.toJsonArray(x)},
          modifiedBy,
        );
    }

    async createProductsDry(x: List<Product>, modifiedBy: string): Promise<Either<string, any>> {
        return this.processPostApiRequest(
          'products/create/dry',
          {products: ProductJsonSerializer.instance.toJsonArray(x)},
          modifiedBy,
        );
    }

    async createProposal(x: Proposal, modifiedBy: string, createPdf: boolean = false): Promise<Either<string, Proposal>> {
        return this.processPostApiRequestParsable(
          createPdf ? 'proposal/create?generate-pdf=true' : 'proposal/create',
          n => {
              const id = parseNumberToEither(n['id'], `Failed to parse returned proposal id ${n}`);
              const pdf = parseString(n['pdf']).map(v => new Pdf(Some(v)));
              return id.map(i => x
                .withId(Some(i))
                .withPdf(pdf));
          }
          , {proposal: ProposalJsonSerializer.instance.toJson(x)},
          modifiedBy,
        );
    }

    async createProposalCompany(x: Company, modifiedBy: string): Promise<Either<string, Company>> {
        return this.processPostApiRequestSerialized(
          'proposal-company/create/',
          CompanyJsonSerializer.instance,
          {proposal_company: CompanyJsonSerializer.instance.toJson(x)},
          modifiedBy,
        );
    }

    async createSupplier(x: Company, modifiedBy: string): Promise<Either<string, Company>> {
        return this.processPostApiRequestSerialized(
          'supplier/create/',
          CompanyJsonSerializer.instance,
          {supplier: CompanyJsonSerializer.instance.toJson(x)},
          modifiedBy,
        );
    }

    async createSuppliers(x: List<Company>, modifiedBy: string): Promise<Either<string, List<Company>>> {
        return this.processPostApiRequestListSerialized(
          'suppliers/create/',
          CompanyJsonSerializer.instance,
          {suppliers: CompanyJsonSerializer.instance.toJsonArray(x)},
          modifiedBy,
        );
    }

    deleteApiCompanyAgentMapping(connectionId: number): Promise<Either<string, number>> {
        return this.processGetApiRequest(`mapping/delete/api-agent-company-mapping/${connectionId}`);
    }

    async dryRunMissingProductsAutoDetectSupplier(x: List<Product>, modifiedBy: string): Promise<Either<string, List<Product>>> {
        return this.processPostApiRequestListSerialized(
          'products/create/missing/autodetect-supplier/dry',
          ProductJsonSerializer.instance,
          {products: ProductJsonSerializer.instance.toJsonArray(x)},
          modifiedBy,
        );
    }

    async editApiCompanyAgentMapping(
      apiAgency: ApiAgency,
      connectionId: number,
      modifiedBy: string,
    ): Promise<Either<string, number>> {
        return this.processPostApiRequest(
          `mapping/update/api-agent-company-mapping/${connectionId}`,
          {agency: ApiAgencyJsonSerializer.instance.toJson(apiAgency)},
          modifiedBy,
        );
    }

    getAgentById(id: number): Promise<Either<string, Company>> {
        return this.processGetApiRequestSerialized(`/agent/by-id/${id}`, CompanyJsonSerializer.instance);
    }

    getCompanyPhotoPath(company: Option<Company>): Either<string, string> {
        const logoUrl: Option<Url> = company.flatMap(x => x.getLogo().flatMap(v => v.uri));
        if (company.exists(x => x.isProposalCompany())) {
            return MediaService.getProposalCompanyLogo(logoUrl, 'low')
                .map(x => x.withOrigin(didgigoServer).getHref());
        } else if (company.exists(x => x.isSupplier())) {
            return MediaService.getSupplierLogo(logoUrl, 'low')
                .map(x => x.withOrigin(didgigoServer).getHref());
        }
        return Left('Cannot tell what type of company');
    }

    // Parent, Child, Language, Template
    async getCompanySummaryForTemplate(
      pid: number,
      cid: number,
      lid: number,
      tid: number,
    ): Promise<Either<string, CompanySummaryForTemplate>> {
        return this.processGetApiRequestSerialized(
          `company/summary/by-company/${pid}/${cid}/${lid}/${tid}`,
          CompanySummaryForTemplateJsonSerializer.instance);
    }

    async getCompanySummaryForTemplateFromAgency(
      cid: number,
      connid: number,
      login: string,
      lid: number,
      tid: number,
    ): Promise<Either<string, CompanySummaryForTemplate>> {
        return this.processGetApiRequestSerialized(
          `company/summary/by-agency/${cid}/${connid}/${login}/${lid}/${tid}`,
          CompanySummaryForTemplateJsonSerializer.instance);
    }

    async getDidgigoUser(email: string, password: string): Promise<Either<string, User>> {
        return this.processPostApiRequestSerialized(`accounts/didgigo-user`, UserJsonSerializer.instance, {
            email,
            password,
        }, noMod);
    }

    async getFallbackXmlForApiProductRef(ref: string, id: number): Promise<Either<string, Document>> {
        return this.processGetApiRequestDocument(`mapping/fallback-xml/${ref}/${id}`);
    }

    getHeaders(): object {
        return {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'X-Api-Key': this.apiKey,
        };
    }

    async getManagedCompanies(companyId: number): Promise<Either<string, List<CompanySummary>>> {
        return this.processGetApiRequestListSerialized(
          `proposal-companies/managed/${companyId}`,
          CompanySummaryJsonSerializer.instance,
        );
    }

    getPresentAsCompanyImagePath(proposal: ProposalWithMeta): Option<string> {
        return proposal.getProposal()
            .getPresentAsCompany()
            .flatMap(x => x.getLogo())
            .flatMap(x => MediaService.getProposalCompanyLogo(x.uri, 'low').toOption())
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    async getProductApiRequestSettingsForInterval(interval: number): Promise<Either<string, List<AutoImportSettings>>> {
        return this.processGetApiRequestListSerialized(
          `auto-import-settings/product/${interval}`,
          ApiRequestSettingsJsonSerializer.instance,
        );
    }

    async getProductByApiRef(ref: string, cid: number): Promise<Either<string, Product>> {
        return this.processGetApiRequestSerialized(`product/by-api-ref/${cid}/${ref}`, ProductJsonSerializer.instance);
    }

    async getProductByApiRefStartsWith(ref: string, cid: number): Promise<Either<string, Product>> {
        return this.processGetApiRequestSerialized(
          `product/by-api-ref/${cid}/${ref}?search_type=StartsWith`,
          ProductJsonSerializer.instance,
          );
    }

    async getProductById(id: number): Promise<Either<string, Product>> {
        return this.processGetApiRequestSerialized(`product/by-id/${id}`, ProductJsonSerializer.instance);
    }

    async getProductDescriptionsForLang(id: number, lang: Language): Promise<Either<string, Product>> {
        return this.processGetApiRequestSerialized(`product/for-lang/${id}/${lang}`, ProductJsonSerializer.instance);
    }

    async getProductDescriptionsForLangMultiple(
      cid: number, ids: Set<number>,
      lang: Language): Promise<Either<string, List<Product>>> {
        return this.processPostApiRequestListSerialized(
          `product/for-lang/multiple/${cid}/${lang}`,
          ProductJsonSerializer.instance,
          {ids: ids.toArray()},
          noMod,
        );
    }

    async getProductDescriptionsForLangMultipleByRefs(
      cid: number, apiRefs: Set<string>,
      lang: Language,
      searchType: StringSearchType): Promise<Either<string, Map<string, Product>>> {
        return this.processPostApiRequestMapSerialized(
          `product/for-lang/multiple/${cid}/${lang}?search_type=${searchType}`,
          ProductJsonSerializer.instance,
          {apirefs: apiRefs.toArray()},
          noMod,
        );
    }

    getProductImagePath(proposalEntry: ProposalEntryWithMeta, idx: number): Option<string> {
        const imageAtIdx = proposalEntry.getBestImages(idx + 1);
        return Option.of(imageAtIdx.get(idx))
            .map(x => MediaService.getProductImage(x, 'low'))
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    getProductImagePathForGallery(image: ImageForGallery): Option<string> {
        return image.image
            .map(x => MediaService.getProductImage(x, 'low'))
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    getProductImagePathForProduct(product: Product): Option<string> {
        return Option.of(product.getImages().first())
            .map(x => MediaService.getProductImage(x, 'low'))
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    getProductOptionImagePath(option: ProductOption, idx: number): Option<string> {
        const imageAtIdx = option.getImages();
        return Option.of(imageAtIdx.get(idx))
            .map(x => MediaService.getProductImage(x, 'low'))
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    async getProductsByName(name: string, cid: number): Promise<Either<string, List<Product>>> {
        return this.processGetApiRequestListSerialized(
          `products/by-name/${name}?proposal_company_id=${cid}`
          , ProductJsonSerializer.instance,
          );
    }

    async getProposalApiRequestSettingsForInterval(interval: number): Promise<Either<string, List<AutoImportSettings>>> {
        return this.processGetApiRequestListSerialized(
          `auto-import-settings/proposal/${interval}`,
          ApiRequestSettingsJsonSerializer.instance,
        );
    }

    async getProposalById(
      id: number,
      type: 'App' | 'eProposal' | 'Pdf' = 'App',
      templateid: Option<number> = None): Promise<Either<string, Proposal>> {
        return this.processGetApiRequestSerialized(this.getUriForProposal(id, type, templateid), ProposalJsonSerializer.instance);
    }

    async getProposalWithMetaById(
      id: number,
      type: 'App' | 'eProposal' | 'Pdf' = 'App',
      templateid: Option<number> = None): Promise<Either<string, ProposalWithMeta>> {
        return this.processGetApiRequestSerialized(
          this.getUriForProposalWithMeta(id, type, templateid),
          ProposalWithMetaJsonSerializer.instance,
        );
    }

    async getTranslationTable(pid: number, cid: number, lid: number): Promise<Either<string, Map<string, string>>> {
        return this.processGetApiRequest(`translations/${pid}/${cid}/${lid}`)
          .then(x => x.map(v => parseStringMap(v)));
    }

    private getUriForProposal(id: number, type: 'App' | 'eProposal' | 'Pdf', templateid: Option<number>): string {
        const templateIdParam = templateid.map(x => `&templateid=${x}`).getOrElse('');
        return `proposal/by-id/${id}?type=${type}${templateIdParam}`;
    }

    private getUriForProposalWithMeta(id: number, type: 'App' | 'eProposal' | 'Pdf', templateid: Option<number>): string {
        const templateIdParam = templateid.map(x => `&templateid=${x}`).getOrElse('');
        return `proposal/with-meta/${id}?type=${type}${templateIdParam}`;
    }

    getUserPhotoPath(user: User): Either<string, string> {
        const logoUrl: Option<Url> = user.person.flatMap(x => x.getImage()).flatMap(u => Url.parse(u));

        if (user.isSystemManager()) {
            return MediaService.getParticipantContantImage(logoUrl, 'low')
                .map(x => x.withOrigin(didgigoServer).getHref());
        }
        return MediaService.getAgentImage(logoUrl, 'low')
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    getWelcomeImagePath(proposal: ProposalWithMeta): Option<string> {
        return proposal.getDisplayWelcomeImage()
            .flatMap(x => MediaService.getWelcomeImage(x, 'low').toOption())
            .map(x => x.withOrigin(didgigoServer).getHref());
    }

    async listAllApis(): Promise<Either<string, List<Api>>> {
        return this.processGetApiRequestListSerialized('apis/all', ApiJsonSerializer.instance);
    }

    async listApisAccessibleToCompany(n: number): Promise<Either<string, ApiCache>> {
        const apis = await this.processGetApiRequestListSerialized(`apis/accessible/${n}`, ApiJsonSerializer.instance);
        return apis.map(api => new ApiCache(api.sort((a, b) => ComparisonUtils.optionStringComparator.compare(a.name, b.name))));
    }

    async listManagedProposalCompanies(n: number): Promise<Either<string, List<Company>>> {
        const companies = await this.processGetApiRequestListSerialized(`proposal-companies/managed/${n}`, CompanyJsonSerializer.instance);
        return companies.map(com => com.sort((a, b) => ComparisonUtils.optionStringComparator.compare(a.name, b.name)));
    }

    // In tourplan refs are optcodes, in other systems they are just called product codes or product references
    async listMissingMappingsForApiRefs(refs: Set<string>, type: string, companyid: number): Promise<Either<string, Set<string>>> {
        return this.processPostApiRequestParsable(
          `mappings/missing/${type}/${companyid}`,
          x => parseSetEither(x, parseString),
          {refs: refs.toJSON()},
          '',
        );
    }

    listProposalCompanies(): Promise<Either<string, List<Company>>> {
        return this.processGetApiRequestListSerialized('proposal-companies', CompanyJsonSerializer.instance);
    }

    async listProposalSummariesByCompany(cid: number, travellingSoonOnly: boolean): Promise<Either<string, List<Proposal>>> {
        return this.processGetApiRequestListSerialized(
          `proposals/summaries/${cid}?future=${travellingSoonOnly}`,
          ProposalJsonSerializer.instance,
        );
    }

    async listProposalSummariesByRefs(refs: Set<string>, cid: number): Promise<Either<string, List<Proposal>>> {
        return this.processPostApiRequestListSerialized(
          `proposals/summaries/${cid}`,
          ProposalJsonSerializer.instance,
          {refs: refs.toJSON()},
          '',
        );
    }

    async listProposalSummariesForApiReference(prodApiReference: string, cid: number): Promise<Either<string, List<Proposal>>> {
        return this.processGetApiRequestListSerialized(
          `proposals/summaries/with-reference/${cid}/${prodApiReference}`,
          ProposalJsonSerializer.instance,
        );
    }

    async listTourplanMappingRecordsAccessibleToCompany(
      cid: number,
      type: 'TPL' | 'AOT' = 'TPL',
      ): Promise<Either<string, List<MappingDetails>>> {
        return this.processGetApiRequestListSerialized(
          `mappings/by-company-access/${type}/${cid}`,
          MappingDetailsJsonSerializer.instance,
          );
    }

    async queueProposalPdf(
      id: number,
      modifiedBy: string,
    ): Promise<Either<string, Product>> {
        return this.processGetApiRequest(`pdf/generate/queue/proposal/${id}?modified_by=${modifiedBy}`);
    }

    async searchProducts(cid: number, params: List<ProductSearch>): Promise<Either<string, List<Product>>> {
        return this.processPostApiRequestListSerialized(
          `products/search/${cid}`,
          ProductJsonSerializer.instance,
          {query: ProductSearchJsonSerializer.instance.toJsonArray(params)},
          '',
          );
    }

    async streamProposalPdf(
      id: number,
      modifiedBy: string,
    ): Promise<Either<string, Product>> {
        return this.processGetApiRequest(`pdf/generate/stream/proposal/${id}?modified_by=${modifiedBy}`);
    }

    async updateApiAgentCompanyMapping(
      apiAgency: ApiAgency,
      connectionId: number,
      modifiedBy: string,
    ): Promise<Either<string, number>> {
        return this.processPostApiRequest(
          `mapping/update/api-agent-company-mapping/${connectionId}`,
          {agency: ApiAgencyJsonSerializer.instance.toJson(apiAgency)},
          modifiedBy,
        );
    }
}
