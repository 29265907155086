import { Either, Option } from 'funfix-core';
import { List, Map } from 'immutable';
import { EitherUtils, MapUtils } from '../core';
import { ProductOption } from './product-option';

export class ProductOptionCache {

    constructor(
        readonly productOptions: List<ProductOption>,
        readonly byId: Map<number, ProductOption> =
            MapUtils.buildMapFromListOptional(productOptions, e => e.getId()),
        readonly byType: Map<string, List<ProductOption>> =
            MapUtils.buildGroupedMapFromListOptional(productOptions, e => e.getOptionType()),
        readonly byName: Map<string, List<ProductOption>> =
            MapUtils.buildGroupedMapFromListOptional(productOptions, e => e.getOptionName()),
    ) { }

    getByIdEither(n: number): Either<string, ProductOption> {
        return EitherUtils.toEither(
            Option.of(
                this.byId.get(n),
            ),
            `ProductOption does not exist`,
        );
    }

    getByType(s: string): Either<string, List<ProductOption>> {
        return EitherUtils.toEither(
            Option.of(
                this.byType.get(s, List()),
            ),
            `ProductOption does not exist`,
        );
    }

    merge(other: ProductOptionCache): ProductOptionCache {
        return new ProductOptionCache(
            this.productOptions.concat(other.productOptions),
            this.byId.concat(other.byId),
            this.byType.concat(other.byType),
        );
    }

    update(prodOptsToUpdate: List<ProductOption>): ProductOptionCache {
        if (prodOptsToUpdate.isEmpty()) {
            return this;
        }
        return new ProductOptionCache(prodOptsToUpdate).merge(this);
    }

}
