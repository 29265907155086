import {None, Option} from 'funfix-core';
import {
    degreesKey,
    gpsLatitudeKey,
    gpsLongitudeKey,
    JsonBuilder,
    latitudeKey,
    longitudeKey,
    minKey,
    notationKey,
    parseNumber,
    parseString,
    secKey,
} from '../core';
import {SimpleJsonSerializer} from '../core/json-serializer';
import {LatLongLocation} from './lat-long-location';

export class GpsCoordinate {
    constructor(
        readonly notation: Option<string> = None,
        readonly degrees: Option<number> = None,
        readonly min: Option<number> = None,
        readonly sec: Option<number> = None,
    ) { }

    isEmpty(): boolean {
        return this.notation.isEmpty()
            && this.degrees.isEmpty()
            && this.min.isEmpty()
            && this.sec.isEmpty();
    }
}

export class GpsCoordinateJsonSerializer extends SimpleJsonSerializer<GpsCoordinate> {
    static instance: GpsCoordinateJsonSerializer = new GpsCoordinateJsonSerializer();

    fromJsonImpl(obj: any): GpsCoordinate {
        return new GpsCoordinate(
            parseString(obj[notationKey]),
            parseNumber(obj[degreesKey]),
            parseNumber(obj[minKey]),
            parseNumber(obj[secKey]));
    }

    protected toJsonImpl(coord: GpsCoordinate, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(notationKey, coord.notation)
            .addOptional(degreesKey, coord.degrees)
            .addOptional(minKey, coord.min)
            .addOptional(secKey, coord.sec);
    }
}

export class GpsCoordinates {
    constructor(
        readonly latitude: Option<number> = None,
        readonly longitude: Option<number> = None,
        readonly gpsLatitude: Option<GpsCoordinate> = None,
        readonly gpsLongitude: Option<GpsCoordinate> = None) { }

    getLatLongLocation(): LatLongLocation {
        return new LatLongLocation(this.latitude, this.longitude);
    }

    isEmpty(): boolean {
        return this.latitude.isEmpty()
            && this.longitude.isEmpty()
            && this.gpsLatitude.forAll(l => l.isEmpty())
            && this.gpsLongitude.forAll(l => l.isEmpty());
    }
}

export class GpsCoordinatesJsonSerializer extends SimpleJsonSerializer<GpsCoordinates> {
    static instance: GpsCoordinatesJsonSerializer = new GpsCoordinatesJsonSerializer();

    fromJsonImpl(obj: any): GpsCoordinates {
        return new GpsCoordinates(
            parseNumber(obj[latitudeKey]),
            parseNumber(obj[longitudeKey]),
            GpsCoordinateJsonSerializer.instance.fromJson(obj[gpsLatitudeKey]),
            GpsCoordinateJsonSerializer.instance.fromJson(obj[gpsLongitudeKey]));
    }

    protected toJsonImpl(gpsCoordinates: GpsCoordinates, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(latitudeKey, gpsCoordinates.latitude)
            .addOptional(longitudeKey, gpsCoordinates.longitude)
            .addOptionalSerializable(gpsLatitudeKey, gpsCoordinates.gpsLatitude, GpsCoordinateJsonSerializer.instance)
            .addOptionalSerializable(gpsLongitudeKey, gpsCoordinates.gpsLongitude, GpsCoordinateJsonSerializer.instance);
    }
}
