import {Either, Left, Option, Right} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {CollectionUtils, EitherUtils, MapUtils, OptionUtils, StringSearchType} from '../core';
import {Company, CompanyJsonSerializer, CompanyLike, CompanyStringSearchField} from './company';
import {CompanySummary} from './company-summary';
import {Product, ProductStringSearchField} from './product';
import {ProposalTemplate} from './proposal-template';

export class CompanyCache {
    constructor(readonly companies: List<CompanyLike>) {
    }

    private byId: Map<number, CompanyLike> =
        MapUtils.buildMapFromListOptional(this.companies, e => e.getId());
    private byName: Map<string, List<CompanyLike>> =
        MapUtils.buildGroupedMapFromListOptional(this.companies, e => e.getName());
    private byNameLower: Map<string, List<CompanyLike>> =
        MapUtils.buildGroupedMapFromListOptional(this.companies, e => e.getName().map(x => x.toLowerCase()));

    findBy(
        comparison: string,
        field: CompanyStringSearchField,
        type: StringSearchType,
        caseSensitive: boolean = false): Option<CompanyLike> {
        return Option.of(this.companies.find(c => c.matchesSearch(field, caseSensitive, comparison, type)));
    }

    getAccessibleProposalTemplates(companyIds: Set<number>): List<ProposalTemplate> {
        const lists = CollectionUtils.collect(companyIds.toList(), x => Option.of(this.byId.map(c => c.getProposalTemplates()).get(x)));
        return lists.flatMap(x => x);
    }

    getByFirstNameEither(s: string): Either<string, CompanyLike> {
        return EitherUtils.toEither(
            Option.of(this.byName.get(s, List<CompanyLike>()))
                .flatMap(x => Option.of(x.first())),
            `${s} does not exist in Didgigo`,
        );
    }

    getById(n: number): Option<CompanyLike> {
        return Option.of(this.byId.get(n));
    }

    getByIdEither(n: number): Either<string, CompanyLike> {
        return EitherUtils.liftEither(
            this.byId.get(n),
            'Company does not exist',
        );
    }

    getByIds(s: Set<number>): CompanyCache {
        return new CompanyCache(OptionUtils.flattenSet(s.map(x => this.getById(x))).toList());
    }

    getByLowerName(s: string): List<CompanyLike> {
        return this.byName.get(s, List());
    }

    getByName(s: string): CompanyCache {
        return new CompanyCache(this.byName.get(s, List()));
    }

    getByNameContainsEither(s: string): Either<string, List<CompanyLike>> {
        return EitherUtils.liftEither(
            this.byName.keySeq()
                .filter(x => x.includes(s))
                .map(k => this.byName.get(k, List()))
                .reduce((acc, o) => acc.concat(o), List()),
            'Company does not exist',
        );
    }

    getByNameEither(s: string): Either<string, List<CompanyLike>> {
        return EitherUtils.liftEither(
            this.byName.get(s, List()),
            'Company does not exist',
        );
    }

    getCompaniesByIds(s: Set<number>): List<Company> {
        return OptionUtils.flattenSet(s.map(x => this.getById(x))).toList()
            .map(x => x.buildCompany().getOrElse(new Company()));

    }

    getFirstByName(s: string): Option<CompanyLike> {
        return this.findBy(s, 'Name', 'Exact');
    }

    getLowercaseNames(): Set<string> {
        return this.byNameLower
            .keySeq()
            .toSet();
    }

    getManagedBy(n: number): CompanyCache {
        return this.getById(n)
            .map(x => this.getByIds(x.getManagedCompanies().add(n)))
            .getOrElse(new CompanyCache(List()));
    }

    getMissing(s: List<Company>): List<Company> {
        return s.filter(x => this.isMissing(x));
    }

    getMissingNames(s: Set<string>): Set<string> {
        return s.subtract(this.getNames());
    }

    getMissingNamesCaseInsensitive(s: Set<string>): Set<string> {
        return s
            .map(x => x.toLowerCase())
            .subtract(this.getNames());
    }

    getNames(): Set<string> {
        return this.byName.keySeq().toSet();
    }

    getNamesEither(): Either<string, Set<string>> {
        return EitherUtils.liftEither(this.getNames(), 'Company cache unpopulated');
    }

    isMissing(x: Company): boolean {
        if (x.getName().isEmpty()) {
            // If it doesnt have a name, we should not have got that far
            console.error('Company to search for had no name');
            return false;
        }

        return this.findBy(x.getName().get(), 'Name', 'Levenshtein-90').isEmpty();
    }

    populateSupplierDetailsForProduct(p: Product): Either<string, Product> {
        if (p.supplier.exists(x => x.name.nonEmpty())) {
            const supplierName = p.supplier.flatMap(x => x.name).get();
            const company = this.getFirstByName(supplierName);

            if (company.isEmpty()) {
                return Left(`${supplierName} does not exist in Didgigo`);
            }

            return Right(p.withSupplier(company.map(x => x.buildAsSupplier())));
        }
        return Left(`Missing supplier name for product ${p.name.getOrElse('')}`);
    }

    populateSupplierDetailsForProductEither(p: Product): Either<string, Product> {
        if (p.isProposalCompanyOwned()) {
            return Right(p);
        }

        if (p.supplier.exists(x => x.name.nonEmpty())) {
            const supplierName = p.supplier.flatMap(x => x.name).get();
            // We only care about the first match because the assumption is made that you're not going to have dupes as we should be setting dupes to inactive
            const company = EitherUtils.liftEither(this.searchBy(supplierName, 'Name', 'Exact', false).first(), `${supplierName} does not exist in Didgigo`);

            if (company.isLeft()) {
                return Right(p);
            }

            return Right(p.withSupplier(Option.of(company.get().buildAsSupplier())));
        }
        return Left(`Missing supplier name for product ${p.name.getOrElse('')}`);
    }

    populateSupplierDetailsForProducts(p: List<Product>): Either<string, List<Product>> {
        return EitherUtils.sequenceList(p.map(x => this.populateSupplierDetailsForProduct(x)));
    }

    populateSupplierDetailsForProductsEither(p: List<Product>): Either<string, List<Product>> {
        return EitherUtils.sequenceList(p.map(x => this.populateSupplierDetailsForProductEither(x)));
    }

    searchBy(
        comparison: string,
        field: ProductStringSearchField,
        type: StringSearchType,
        caseSensitive: boolean = false): List<CompanyLike> {
        return this.companies.filter(p => p.matchesSearch(field, caseSensitive, comparison, type));
    }

    take(limit: number): CompanyCache {
        return new CompanyCache(this.companies.take(limit));
    }

    toJsonArray(): readonly object[] {
        return CompanyJsonSerializer.instance.toJsonArray(this.companies.map(x => x.buildCompany().getOrElse(new Company())));
    }

    update(companiesToUpdate: List<CompanySummary>): CompanyCache {
        const toUpdateMap = MapUtils.buildMapFromListOptional(companiesToUpdate, e => e.id);
        return new CompanyCache(toUpdateMap.concat(this.byId).valueSeq().toList());
    }
}
