import {Either, None, Option} from 'funfix-core';
import {Collection, List, Set} from 'immutable';
import {Primitive} from './utils';

export class OptionUtils {

    // tl;dr, if both exist, apply function, otherwise return the first nonempty
    static applyOrReturnNonEmpty<T>(oa: Option<T>, ob: Option<T>, f: (x: T, y: T) => T): Option<T> {
        return Option.map2(oa, ob, (a, b) => f(a, b))
            .orElse(oa)
            .orElse(ob);
    }

    static applyOrReturnNonEmptyMultiple<T>(f: (x: T, y: T) => T, ...opts: Array<Option<T>>): Option<T> {
        return opts.reduce((a: Option<T>, b: Option<T>) => OptionUtils.applyOrReturnNonEmpty(a, b, f), None);
    }

    /**
     * Compare primitives
     * @param oa
     * @param ob
     */
    static equals<T extends Primitive>(oa: Option<T>, ob: Option<T>): boolean {
        if (oa.isEmpty() || ob.isEmpty()) {
            return false;
        }
        return oa.get() === ob.get();
    }

    static equalsOrBothEmpty<T extends Primitive>(oa: Option<T>, ob: Option<T>): boolean {
        if (oa.isEmpty() && ob.isEmpty()) {
            return true;
        }

        if (oa.isEmpty() || ob.isEmpty()) {
            return false;
        }
        return oa.get() === ob.get();
    }

    static exists2<A, B>(oa: Option<A>, ob: Option<B>, f: (a: A, b: B) => boolean): boolean {
        if (oa.isEmpty() || ob.isEmpty()) {
            return false;
        }
        return f(oa.get(), ob.get());
    }

    /**
     * Same as map2 except it's specialized for passing in functions that also return Option
     * flattening after the map
     */
    static flatMap2<A, B, C>(a: Option<A>, b: Option<B>, f: (a: A, b: B) => Option<C>): Option<C> {
        if (a.nonEmpty() && b.nonEmpty()) {
            return f(a.value, b.value);
        } else {
            return Option.empty();
        }
    }

    static flatMap3<A, B, C, D>(a: Option<A>, b: Option<B>, c: Option<C>, f: (a: A, b: B, c: C) => Option<D>): Option<D> {
        if (a.nonEmpty() && b.nonEmpty() && c.nonEmpty()) {
            return f(a.value, b.value, c.value);
        } else {
            return Option.empty();
        }
    }

    static flattenArray<V>(...list: Array<Option<V>>): V[] {
        return list
            .filter(o => !o.isEmpty())
            .map(o => o.get());
    }

    static flattenCollection<K, V>(collection: Collection<K, Option<V>>): Collection<K, V> {
        return collection
            .filter(o => !o.isEmpty())
            .map(o => o.get());
    }

    static flattenList<V>(list: List<Option<V>>): List<V> {
        return list
            .filter(o => !o.isEmpty())
            .map(o => o.get());
    }

    static flattenListEither<A, B>(list: List<Either<A, B>>): List<B> {
        return list
            .filter(o => o.isRight())
            .map(o => o.get());
    }

    static flattenSet<V>(set: Set<Option<V>>): Set<V> {
        return set
            .filter(o => !o.isEmpty())
            .map(o => o.get());
    }

    static map7<A1, A2, A3, A4, A5, A6, A7, R>(
        fa1: Option<A1>, fa2: Option<A2>, fa3: Option<A3>, fa4: Option<A4>, fa5: Option<A5>, fa6: Option<A6>, fa7: Option<A7>,
        f: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6, a7: A7) => R): Option<R> {

        return fa1.nonEmpty() && fa2.nonEmpty() && fa3.nonEmpty() && fa4.nonEmpty() && fa5.nonEmpty() && fa6.nonEmpty() && fa7.nonEmpty()
            ? Option.some(f(fa1.value, fa2.value, fa3.value, fa4.value, fa5.value, fa6.value, fa7.value))
            : Option.none();
    }

    static map8<A1, A2, A3, A4, A5, A6, A7, A8, R>(
        fa1: Option<A1>, fa2: Option<A2>, fa3: Option<A3>, fa4: Option<A4>, fa5: Option<A5>, fa6: Option<A6>, fa7: Option<A7>, fa8: Option<A8>,
        f: (a1: A1, a2: A2, a3: A3, a4: A4, a5: A5, a6: A6, a7: A7, a8: A8) => R): Option<R> {

        return fa1.nonEmpty() && fa2.nonEmpty() && fa3.nonEmpty() && fa4.nonEmpty() && fa5.nonEmpty() && fa6.nonEmpty() && fa7.nonEmpty() && fa8.nonEmpty()
            ? Option.some(f(fa1.value, fa2.value, fa3.value, fa4.value, fa5.value, fa6.value, fa7.value, fa8.value))
            : Option.none();
    }

    static toCollection<T>(items: Collection<any, Option<T>>): Collection<any, T> {
        return items
            .filter(v => !v.isEmpty())
            .map(v => v.get());
    }

    static toList<T>(...items: Array<Option<T>>): List<T> {
        return OptionUtils.toCollection(List.of(...items)).toList();
    }

    static toSet<T>(...items: Array<Option<T>>): Set<T> {
        return OptionUtils.toCollection(Set<Option<T>>(items)).toSet();
    }

    static toSetFromCollection<T>(items: Collection<any, Option<T>>): Set<T> {
        return OptionUtils.toCollection(items).toSet();
    }
}
