import {Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {
    getDatesInRange,
    getDays,
    getNights,
    isAfter,
    isBefore,
    isDayBetween,
    isMomentBetween,
    isTodayBetween,
    now,
    OptionUtils,
} from '../core';

export abstract class Timeframed {

    getAnalyticBeforeAfterString(): string {
        if (this.isCurrentlyBefore()) {
            return 'Before';
        } else if (this.isCurrentlyAfter()) {
            return 'After';
        } else if (this.isCurrentlyInProgress()) {
            return 'During';
        }
        return 'Unknown';
    }

    getDatesDuring(): List<Moment> {
        return getDatesInRange(this.getStartDateTime(), this.getEndDateTime())
            .getOrElse(List());
    }

    getDays(): Option<number> {
        return getDays(this.getStartDateTime(), this.getEndDateTime());
    }

    getDaysBeforeEnd(): Option<number> {
        return getDays(Some(now()), this.getEndDateTime()).map(t => Math.max(0, t));
    }

    getDaysBeforeStart(): Option<number> {
        return getDays(Some(now()), this.getStartDateTime()).map(t => Math.max(0, t));
    }

    getDaysSinceEnd(): Option<number> {
        return getDays(this.getEndDateTime(), Some(now())).map(t => Math.max(0, t));
    }

    getDaysSinceStart(): Option<number> {
        return getDays(this.getStartDateTime(), Some(now())).map(t => Math.max(0, t));
    }

    abstract getEndDateTime(): Option<Moment>;

    getEndDay(): Option<Moment> {
        return this.getEndDateTime().map(t => t.clone().startOf('day'));
    }

    getNights(): Option<number> {
        return getNights(this.getStartDateTime(), this.getEndDateTime());
    }

    abstract getStartDateTime(): Option<Moment>;

    getStartDay(): Option<Moment> {
        return this.getStartDateTime().map(t => t.clone().startOf('day'));
    }

    hasEnd(): boolean {
        return this.getEndDateTime()
            .nonEmpty();
    }

    hasNoTimes(): boolean {
        return this.getStartDateTime().isEmpty() && this.getEndDateTime().isEmpty();
    }

    hasStart(): boolean {
        return this.getStartDateTime()
            .nonEmpty();
    }

    isAfter(m: Moment): boolean {
        return isAfter(Some(m), this.getEndDateTime(), 'minutes');
    }

    isBefore(m: Moment): boolean {
        return isBefore(Some(m), this.getStartDateTime(), 'minutes');
    }

    isCurrentlyAfter(): boolean {
        return this.isAfter(now());
    }

    isCurrentlyBefore(): boolean {
        return this.isBefore(now());
    }

    isCurrentlyFirstDay(): boolean {
        return this.isFirstDay(now());
    }

    isCurrentlyInProgress(): boolean {
        return isTodayBetween(this.getStartDateTime(), this.getEndDateTime());
    }

    isCurrentlyLastDay(): boolean {
        return this.isLastDay(now());
    }

    isDuring(moment: Moment): boolean {
        return isDayBetween(this.getStartDateTime(), this.getEndDateTime(), moment);
    }

    isFirstDay(m: Moment): boolean {
        return this.getStartDateTime().exists(d => m.isSame(d, 'days'));
    }

    isFirstLastOrDuring(m: Moment): boolean {
        return this.isFirstOrLastDay(m) || this.isDuring(m);
    }

    isFirstOrLastDay(m: Moment): boolean {
        return this.isFirstDay(m) || this.isLastDay(m);
    }

    isLastDay(m: Moment): boolean {
        return this.getEndDay().exists(d => m.isSame(d, 'days'));
    }

    /**
     * Uses entry start/finish to decide whether it is a multiday.
     *
     * Not the same as a multiday tour
     */
    isMultipleDays(): boolean {
        const count = OptionUtils.toList(this.getStartDateTime(), this.getEndDateTime()).size;
        if (count < 2) {
            return false;
        }
        return Option.map2(this.getStartDateTime(), this.getEndDateTime(), (s, e) => s.isSame(e, 'days')).contains(true);
    }

    isSameDay(start: Moment, end: Moment): boolean {
        return start.isSame(end, 'day');
    }

    isSingleDay(): boolean {
        return !this.isMultipleDays();
    }

    startsAfter(m: Moment): boolean {
        return isAfter(Some(m), this.getStartDateTime(), 'minutes');
    }

    startsDuring(start: Moment, end: Moment): boolean {
        return this.getStartDateTime().exists(s => isMomentBetween(Some(start), Some(end), s));
    }
}
