import {None, Option} from 'funfix-core';
import {
    agencyKey,
    JsonBuilder,
    parseNumber,
    secondsKey,
    SimpleJsonSerializer,
    templateKey,
} from '../core';
import {ApiAgency, ApiAgencyJsonSerializer} from './api-agency';
import {ApiTemplate, ApiTemplateJsonSerializer} from './api-template';

export class AutoImportProducts {

    constructor(
        readonly seconds: Option<number> = None,
        readonly agency: Option<ApiAgency> = None,
        readonly template: Option<ApiTemplate> = None,
    ) {
    }

    getAgency(): Option<ApiAgency> {
        return this.agency;
    }

    getSeconds(): Option<number> {
        return this.seconds;
    }

    getTemplate(): Option<ApiTemplate> {
        return this.template;
    }

}

export class AutoImportProductsJsonSerializer extends SimpleJsonSerializer<AutoImportProducts> {
    static instance: AutoImportProductsJsonSerializer = new AutoImportProductsJsonSerializer();

    protected fromJsonImpl(obj: any): AutoImportProducts {
        return new AutoImportProducts(
            parseNumber(obj[secondsKey]),
            ApiAgencyJsonSerializer.instance.fromJson(obj[agencyKey]),
            ApiTemplateJsonSerializer.instance.fromJson(obj[templateKey]),
        );

    }

    protected toJsonImpl(value: AutoImportProducts, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(secondsKey, value.seconds)
            .addOptionalSerializable(agencyKey, value.agency, ApiAgencyJsonSerializer.instance)
            .addOptionalSerializable(templateKey, value.template, ApiTemplateJsonSerializer.instance);
    }

}
