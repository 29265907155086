import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {
    ComparisonUtils,
    entryKey,
    isMidnight,
    JsonBuilder,
    Language,
    metaKey,
    OptionUtils,
    SimpleJsonSerializer,
    StringUtils,
} from '../core';
import {Company} from './company';
import {Contact, ContactModel} from './contact';
import {Directions} from './directions';
import {Distance} from './distance';
import {Duration} from './duration';
import {GMap} from './gmap';
import {GpsLocation} from './gps-location';
import {Image} from './image';
import {LatLongLocation} from './lat-long-location';
import {MapMarker, MapMarkerWithMeta} from './map-marker';
import {Metadata} from './metadata';
import {PhysicalLocation} from './physical-location';
import {Product} from './product';
import {ProductOption} from './product-option';
import {EntryTypeFilter, ProposalEntry, ProposalEntryJsonSerializer} from './proposal-entry';
import {ProposalWithMeta, ProposalWithMetaJsonSerializer} from './proposal-with-meta';
import {Social} from './social';
import {Transport} from './transport';
import {Video} from './video';
import {Waypoint} from './waypoint';

export class ProposalEntryWithMeta {
    constructor(
        readonly entry: Option<ProposalEntry> = None,
        readonly proposal: Option<ProposalWithMeta> = None,
    ) {
    }

    static buildFromProduct(v: Product, language: Option<Language>): ProposalEntryWithMeta {
        return new ProposalEntryWithMeta(
            Some(ProposalEntry.buildFromProduct(v, language)),
            None,
        );
    }

    // Can be called from angular templates
    asOption(): Option<ProposalEntryWithMeta> {
        return Some(this);
    }

    // Id Equality. Used by Angular for change detection. Its not true equality....
    public equals(obj: ProposalEntryWithMeta): boolean {
        return OptionUtils.exists2(obj.getId(), this.getId(), (a, b) => a === b);
    }

    getBestImage(): Option<Image> {
        return this.getEntry().getBestImage();
    }

    getBestImages(count: number, includeSubproducts: boolean = false): List<Image> {
        if (includeSubproducts) {
            this.entry
                .map(x => x.getBestImages(count))
                .getOrElse(List())
                .zipAll(this.getEntry().getAllSubproducts().flatMap(x => x.getSortedImages(count)))
                .flatMap<Image>(x => x)
                .take(count);
        }

        return this.entry
            .map(x => x.getBestImages(count))
            .getOrElse(List());
    }

    getDirectionMarkers(): List<MapMarker> {
        return this.getEntry()
            .getProduct()
            .flatMap(p => p.getDirections())
            .map(d => d.getMarkers())
            .getOrElse(List());
    }

    getDisplayAddressLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Address',
                'Address',
                this.getEntryContext(),
            );
    }

    getDisplayBackToSnapshotLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'BackToSnapshot',
                'Back To Snapshot',
                this.getEntryContext(),
            );
    }

    getDisplayContactCards(): List<ContactModel> {
        return List.of(this.getProductContactCard())
            .filter(x => !this.getProposalWithMeta().getProposal().isQuote())
            .concat(this.getProposalWithMeta().getContactCards())
            .filter(x => !x.isUseless());
    }

    getDisplayDescriptionTabLabel(): string {
        if (this.getEntry().isSelfDrive()) {
            return this.getDisplaySelfDriveLabel();
        } else if (this.getEntry().isAccommodation()) {
            return this.getDisplayWhyStayLabel();
        } else if (this.getEntry().isTour()) {
            return this.getDisplayWhyGoLabel();
        } else if (this.getEntry().isDestination()) {
            return this.getDisplayWhyVisitLabel();
        }
        return this.getDisplayEntryLongDescriptionLabel();
    }

    getDisplayDistanceFromCityCentre(metric: boolean = true): Option<string> {
        return this.getProduct()
            .flatMap(x => x.getExtraContent())
            .flatMap(x => x.location)
            .flatMap(x => x.distanceFromCityCenter)
            .map(x => x.getDistanceString(metric));
    }

    getDisplayDistanceFromCityCentreLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'DistanceFromCityCentre',
                'Distance from City Centre',
                this.getEntryContext(),
            );
    }

    getDisplayDistanceFromMainAirport(metric: boolean = true): Option<string> {
        return this.getProduct()
            .flatMap(x => x.getExtraContent())
            .flatMap(x => x.location)
            .flatMap(x => x.distanceFromAirport)
            .map(x => x.getDistanceString(metric));
    }

    getDisplayDistanceFromMainAirportLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'DistanceFromMainAirport',
                'Distance from Main Airport',
                this.getEntryContext(),
            );
    }

    getDisplayEntryLongDescriptionLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'LongDescription',
                'What to Expect',
                this.getEntryContext(),
            );
    }

    getDisplayEntryShortDescriptionLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'ShortDescription',
                'Description',
                this.getEntryContext(),
            );
    }

    getDisplayImagesLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Images',
                'Images',
                this.getEntryContext(),
            );
    }

    getDisplayImportantInfoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'ImportantInfo',
                'Important Info',
                this.getProposalWithMeta()
                    .getProposal()
                    .getStatus(),
            );
    }

    getDisplayHealthStatusColour(fallback: string = '#555555'): string {
        return this.getEntry().getHealthInformation()
            .flatMap(x => x.getColor())
            .getOrElse(fallback);
    }

    getDisplayLanguage(): Option<Language> {
        return this.proposal.flatMap(x => x.getProposalLanguage());
    }

    getDisplayLatitudeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Latitude',
                'Latitude',
                this.getEntryContext(),
            );
    }

    getDisplayLocation(): Option<PhysicalLocation> {
        return this.getProductLocation().orElse(this.getSupplierLocation());
    }

    getDisplayLocationLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Location',
                'Location',
                this.getEntryContext(),
            );
    }

    getDisplayLongitudeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Longitude',
                'Longitude',
                this.getEntryContext(),
            );
    }

    getDisplayNotesLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Notes',
                'Notes',
                this.getEntryContext(),
            );
    }

    getDisplayNumberOfPassengers(): Option<string> {
        return this.getEntry()
            .getNumberOfPassengers();
    }

    getDisplayNumberOfPassengersLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Pax',
                'Pax',
                this.getEntryContext(),
            );
    }

    getDisplayNumberOfPassengersSeperator(): string {
        return this.getMeta()
            .getSeparatorForField(
                'Pax',
                ':',
                this.getEntryContext(),
            );
    }

    getDisplayOptionalTextLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField('OptionalText', 'Optional', this.getEntryContext());
    }

    getDisplayOptionsLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Options',
                'Options',
                this.getEntryContext(),
            );
    }

    getDisplayOptionsTabLabel(): string {
        if (this.getEntry().isAccommodation()) {
            return this.getDisplayRoomsLabel();
        }
        return this.getDisplayOptionsLabel();
    }

    getDisplayPleaseNoteLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'PleaseNote',
                'Please Note',
                this.getEntryContext(),
            );
    }

    getDisplayProductCompany(): Option<Company> {
        return this.getProduct()
            .flatMap(x => x.getSupplier());
    }

    getDisplayProductLatitude(): Option<number> {
        return this.getProduct()
            .flatMap(x => x.getLatitude());
    }

    getDisplayProductLongitude(): Option<number> {
        return this.getProduct()
            .flatMap(x => x.getLongitude());
    }

    getDisplayProposalEntryAddressField(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getAddress());
    }

    getDisplayProposalEntryAddressLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Address',
                'Address',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryArrivalLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Arrival',
                'Arrival',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryCancellationPolicy(): Option<string> {
        return this.getEntry()
            .getCancellationPolicy();
    }

    getDisplayProposalEntryCancellationPolicyLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'CancellationPolicy',
                'Cancellation Policy',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryCheckIn(): Option<string> {
        return this.getEntry()
            .getFirstNote('CHECK_IN');
    }

    getDisplayProposalEntryCheckInLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'CheckIn',
                'Check In',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryCheckInOut(): Option<string> {
        return this.getEntry()
            .getFirstNote('CHECKINOUT_INFO');
    }

    getDisplayProposalEntryCheckInOutLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'CheckInOut',
                'Check In/Out',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryCheckOut(): Option<string> {
        return this.getEntry()
            .getFirstNote('CHECK_OUT');
    }

    getDisplayProposalEntryCheckOutLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'CheckOut',
                'Check Out',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryCityField(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getCity());
    }

    getDisplayProposalEntryClass(): Option<string> {
        return this.getEntry()
            .getClass();
    }

    getDisplayProposalEntryClassLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Class',
                'Class',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryConfirmationReferenceLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'ConfirmationReference',
                'Confirmation Reference',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryCountryField(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getCountry());
    }

    getDisplayProposalEntryDateLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Date',
                'Date',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryDateRange(): Option<string> {
        return this.getMeta().formatShortDateRange(
            this.getEntry().getStartDateTime(),
            this.getEntry().getEndDateTime(),
            'DSD',
            this.getDisplayLanguage(),
            'EntryDateRange',
            this.getEntryContext());
    }

    getDisplayProposalEntryDayRange(): Option<string> {
        return this.getMeta().formatShortDateRange(
            this.getEntry().getStartDateTime(),
            this.getEntry().getEndDateTime(),
            '2D',
            this.getDisplayLanguage(),
            'EntryDayRange',
            this.getEntryContext());
    }

    getDisplayProposalEntryDays(): Option<number> {
        return this.getEntry()
            .getDays();
    }

    getDisplayProposalEntryDayString(): Option<string> {
        return this.getDisplayProposalEntryDays()
            .map(days => {
                const translateWord = StringUtils.pluralise('Day', days);
                const translated = this.getMeta().translate(translateWord, translateWord);
                return `${days} ${translated}`;
            });
    }

    getDisplayProposalEntryDepartureLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Departure',
                'Departure',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryDescription(): Option<string> {
        return this.getEntry()
            .getLongDescription();
    }

    getDisplayProposalEntryDirections(): Option<Directions> {
        return this.getProduct()
            .flatMap(x => x.getDirections());
    }

    getDisplayProposalEntryDropoffLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Dropoff',
                'Dropoff',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryDropoffRemark(): Option<string> {
        return this.getEntry()
            .getDropoffRemark();
    }

    getDisplayProposalEntryDropoffRemarkLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Dropoff Remark',
                'Remark',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryDropoffTime(): Option<Moment> {
        return this.getEntry()
            .getDropoffTime();
    }

    getDisplayProposalEntryDropoffTimeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Dropoff Time',
                'Dropoff Time',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryDropoffTimeSeparator(): string {
        return this.getMeta()
            .getSeparatorForField(
                'DropoffTime',
                ':',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryDropoffTimeText(): Option<string> {
        return this.getDisplayProposalEntryDropoffTime()
            .map(d => this.getMeta()
                .formatDate(d, 'DropoffTime', 'DSDT24', this.getProposalWithMeta().getProposalLanguage(), this.getEntryContext()));
    }

    getDisplayProposalEntryEmergencyContact(): Option<string> {
        return this.getEntry()
            .getFirstNote('EMERGENCY_CONTACT');
    }

    getDisplayProposalEntryEmergencyContactLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'EmergencyContact',
                'EmergencyContact',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryEndLabel(): string {
        if (this.isTransport()) {
            return this.getDisplayProposalEntryArrivalLabel();
        }
        if (this.isAccommodation()) {
            return this.getDisplayProposalEntryCheckOutLabel();
        }
        if (this.isDayTour() || this.isMultidayTour()) {
            return this.getDisplayProposalEntryTourEndLabel();
        }
        const timeCount = OptionUtils.toList(
            this.getDisplayProposalEntryStartTime(),
            this.getDisplayProposalEntryEndTime())
            .filter(t => !isMidnight(t)).size;
        const canUseDateKeyword = this.isLeisureEntry();
        if (timeCount === 1 && canUseDateKeyword) {
            return this.getDisplayProposalEntryDateLabel();
        }
        return this.getDisplayProposalEntryEndTimeLabel();
    }

    getDisplayProposalEntryEndRemark(): Option<string> {
        return this.getEntry()
            .getEndWaypoint()
            .flatMap(w => w.getDescription());
    }

    getDisplayProposalEntryEndTime(): Option<Moment> {
        return this.getEntry()
            .getEndWaypoint()
            .flatMap(w => w.getTime());
    }

    getDisplayProposalEntryEndTimeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Finish',
                'Finish',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryEndTimeSeperator(): string {
        return this.getMeta()
            .getSeparatorForField(
                'Finish',
                ':',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryEndTimeText(): Option<string> {
        return this.getDisplayProposalEntryEndTime()
            .map(d => this.getMeta()
                .formatDate(d, 'EndTime', 'DSDT24', this.getProposalWithMeta().getProposalLanguage(), this.getEntryContext()));
    }

    getDisplayProposalEntryEndWaypoint(): Option<Waypoint> {
        return this.getEntry()
            .getEndWaypoint();
    }

    getDisplayProposalEntryExclusions(): Option<string> {
        return this.getEntry()
            .getExclusions();
    }

    getDisplayProposalEntryExclusionsLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Exclusions',
                'Exclusions',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryExtras(): Option<string> {
        return this.getEntry()
            .getFirstNote('EXTRAS');
    }

    getDisplayProposalEntryExtrasLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Extras',
                'Extras',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryFullMultilineAddress(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getMultilineLocationString());
    }

    getDisplayProposalEntryFullSingleLineAddress(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getLocationString());
    }

    getDisplayProposalEntryGuideDetails(): Option<string> {
        return this.getEntry()
            .getFirstNote('GUIDE_DETAILS');
    }

    getDisplayProposalEntryGuideDetailsLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'GuideDetails',
                'Guide Details',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryIcon(): string {
        return this.getEntry()
            .getIcon();
    }

    getDisplayProposalEntryId(): Option<number> {
        return this.getEntry()
            .getId();
    }

    getDisplayProposalEntryImage(): Option<Image> {
        return this.getEntry()
            .getImage();
    }

    getDisplayProposalEntryImportantInfo(): Option<string> {
        return this.getEntry()
            .getFirstNote('IMPORTANT_INFO');
    }

    getDisplayProposalEntryInclusions(): Option<string> {
        return this.getEntry()
            .getInclusions();
    }

    getDisplayProposalEntryInclusionsLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Inclusions',
                'Inclusions',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryInternetInfo(): Option<string> {
        return this.getEntry()
            .getFirstNote('INTERNET_INFO');
    }

    getDisplayProposalEntryInternetInfoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'InternetInfo',
                'Internet Info',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryLocation(): Option<GpsLocation> {
        return this.getProduct()
            .flatMap(x => x.getLocation());
    }

    getDisplayProposalEntryLongDescription(): Option<string> {
        return this.getEntry()
            .getLongDescription()
            .orElse(this.getProduct().flatMap(x => x.getLongDescription()))
            .orElse(this.getEntry().getTransport().flatMap(x => x.description))
            .orElse(this.getEntry().getCustom().flatMap(x => x.description));
    }

    getDisplayProposalEntryMap(): Option<GMap> {
        return this.getProduct()
            .flatMap(x => x.getMap());
    }

    getDisplayProposalEntryMapLatitude(): Option<number> {
        return this.getDisplayProposalEntryMap()
            .flatMap(x => x.latitude);
    }

    getDisplayProposalEntryMapLongitude(): Option<number> {
        return this.getDisplayProposalEntryMap()
            .flatMap(x => x.longitude);
    }

    getDisplayProposalEntryMapZoom(): Option<number> {
        return this.getDisplayProposalEntryMap()
            .flatMap(x => x.zoom);
    }

    getDisplayProposalEntryMeetingPoint(): Option<string> {
        return this.getEntry()
            .getMeetingPoint();
    }

    getDisplayProposalEntryMeetingPointLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'MeetingPoint',
                'Meeting Point',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryMonthRange(): Option<string> {
        return this.getMeta().formatShortDateRange(
            this.getEntry().getStartDateTime(),
            this.getEntry().getEndDateTime(),
            '3M',
            this.getDisplayLanguage(),
            'EntryMonthRange',
            this.getEntryContext());
    }

    getDisplayProposalEntryNights(): Option<number> {
        return this.getEntry()
            .getNights();
    }

    getDisplayProposalEntryNightsOrDays(): Option<string> {
        if (this.getEntry().isAccommodation()) {
            return this.getDisplayProposalEntryNightString();
        }
        return this.getDisplayProposalEntryDayString();
    }

    getDisplayProposalEntryNightString(): Option<string> {
        return this.getDisplayProposalEntryDays()
            .map(days => {
                const translateWord = StringUtils.pluralise('Night', days);
                const translated = this.getMeta().translate(translateWord, translateWord);
                return `${days} ${translated}`;
            });
    }

    getDisplayProposalEntryNotes(): Option<string> {
        return this.getEntry()
            .getNotes();
    }

    getDisplayProposalEntryNotesLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Notes',
                'Notes',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryOption(): Option<ProductOption> {
        return this.getEntry()
            .getOption();
    }

    getDisplayProposalEntryOptionAmenities(): Option<string> {
        return this.getEntry()
            .getFirstNote('OPTION_AMENITIES');
    }

    getDisplayProposalEntryOptionAmenitiesLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'OptionAmenities',
                'Room Facilities',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntrySafetyInformation(): Option<string> {
        return this.getEntry()
            .getFirstNote('SAFETY_INFORMATION');
    }

    getDisplayProposalEntrySafetyInformationLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SafetyInformation',
                'Safety Information',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntrySafetyProcedures(): Option<string> {
        return this.getEntry()
            .getFirstNote('SAFETY_PROCEDURES');
    }

    getDisplayProposalEntrySafetyProceduresLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SafetyProcedures',
                'Safety Procedures',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryOptionExtraInfo(): Option<string> {
        return this.getEntry()
            .getFirstNote('OPTION_EXTRA_INFO');
    }

    getDisplayProposalEntryOptionExtraInfoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'OptionExtraInfo',
                'Extra Info',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryOptionPropertyInfo(): Option<string> {
        return this.getEntry()
            .getFirstNote('OPTION_PROPERTY_INFO');
    }

    getDisplayProposalEntryOptionPropertyInfoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'OptionPropertyInfo',
                'Property Info',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPassengers(): Option<string> {
        return this.getDisplayNumberOfPassengers();
    }

    getDisplayProposalEntryPassengersLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'NoPassengers',
                'Guests',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPhone(): Option<string> {
        const customNote = this.getEntry().getFirstNote('SUPPLIER_PHONE');
        const productPhone = this.getProductContact().flatMap(x => x.getPhone());
        const supplierPhone = this.getSupplierContact().flatMap(x => x.getPhone());
        return customNote.orElse(productPhone).orElse(supplierPhone);
    }

    getDisplayProposalEntryPhoneLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Phone',
                'Phone',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPickupLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Pickup',
                'Pickup',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPickUpRemark(): Option<string> {
        return this.getEntry()
            .getPickupRemark();
    }

    getDisplayProposalEntryPickUpRemarkLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Pickup Remark',
                'Remark',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPickupRemarkSeperator(): string {
        return this.getMeta()
            .getSeparatorForField(
                'PickupTime',
                ':',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPickupTime(): Option<Moment> {
        return this.getEntry()
            .getPickupTime();
    }

    getDisplayProposalEntryPickupTimeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'PickupTime',
                'Pickup Time',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPickupTimeSeperator(): string {
        return this.getMeta()
            .getSeparatorForField(
                'PickupTime',
                ':',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryPickupTimeText(): Option<string> {
        return this.getDisplayProposalEntryPickupTime()
            .map(d => this.getMeta()
                .formatDate(d, 'PickupTime', 'DSDT24', this.getProposalWithMeta().getProposalLanguage(), this.getEntryContext()));
    }

    getDisplayProposalEntryPostCodeField(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getPostCode());
    }

    getDisplayProposalEntryProductLocationCity(): Option<string> {
        return this.getProduct()
            .flatMap(x => x.getLocation())
            .flatMap(x => x.getCity());
    }

    getDisplayProposalEntryProductLocationCountry(): Option<string> {
        return this.getProduct()
            .flatMap(x => x.getLocation())
            .flatMap(x => x.getCountry());
    }

    getDisplayProposalEntryProductLocationState(): Option<string> {
        return this.getProduct()
            .flatMap(x => x.getLocation())
            .flatMap(x => x.getState());
    }

    getDisplayProposalEntryProductName(): Option<string> {
        return this.getProduct()
            .flatMap(x => x.getProductName());
    }

    getDisplayProposalEntryProductOptionName(): Option<string> {
        return this.getEntry()
            .getOption()
            .flatMap(x => x.getOptionName());
    }

    getDisplayProposalEntryReference(): Option<string> {
        return this.getEntry()
            .getReference();
    }

    getDisplayProposalEntryReferenceLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'EntryReference',
                'Reference',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryRemarkLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Dropoff Remark',
                'Remark',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryRemarks(): Option<string> {
        return this.getEntry()
            .getFirstNote('REMARKS');
    }

    getDisplayProposalEntryRentalTerms(): Option<string> {
        return this.getEntry()
            .getFirstNote('HireCar_Link');
    }

    getDisplayProposalEntrySchedule(): Option<string> {
        return this.getEntry()
            .getFirstNote('SCHEDULE');
    }

    getDisplayProposalEntryScheduleLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Schedule',
                'Schedule',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryShortDescription(): Option<string> {
        return this.getEntry().getShortDescription()
            .orElse(this.getProduct().flatMap(x => x.getShortDescription()))
            .orElse(this.getEntry().getTransport().flatMap(x => x.description))
            .orElse(this.getEntry().getCustom().flatMap(x => x.description));
    }

    // Handles the large amount of complexity around labelling various entry types
    getDisplayProposalEntryStartLabel(): string {
        if (this.isTransport()) {
            return this.getDisplayProposalEntryDepartureLabel();
        }
        if (this.isAccommodation()) {
            return this.getDisplayProposalEntryCheckInLabel();
        }
        if (this.isDayTour() || this.isMultidayTour()) {
            return this.getDisplayProposalEntryTourStartLabel();
        }
        const startTime = this.getDisplayProposalEntryStartTime();
        const endTime = this.getDisplayProposalEntryEndTime();
        const isSameDay = Option.map2(startTime, endTime, (s, e) => s.isSame(e, 'd'));
        const oneTime = OptionUtils.toList(startTime, endTime).size === 1;
        const timeCount = OptionUtils.toList(startTime, endTime).map(x => !isMidnight(x)).size;

        // Single day leisure entries dont have a start and end
        if (this.isLeisureEntry() && ((isSameDay && timeCount < 2) || oneTime)) {
            return this.getDisplayProposalEntryDateLabel();
        }
        return this.getDisplayProposalEntryStartTimeLabel();
    }

    getDisplayProposalEntryStartRemark(): Option<string> {
        return this.getEntry()
            .getStartWaypoint()
            .flatMap(w => w.getDescription());
    }

    getDisplayProposalEntryStartTime(): Option<Moment> {
        return this.getEntry()
            .getStartWaypoint()
            .flatMap(w => w.getTime());
    }

    getDisplayProposalEntryStartTimeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Start',
                'Start',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryStartTimeSeperator(): string {
        return this.getMeta()
            .getSeparatorForField(
                'Start',
                ':',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryStartTimeText(): Option<string> {
        return this.getDisplayProposalEntryStartTime()
            .map(d => this.getMeta()
                .formatDate(d, 'StartTime', 'DSDT24', this.getProposalWithMeta().getProposalLanguage(), this.getEntryContext(), true));
    }

    getDisplayProposalEntryStartWaypoint(): Option<Waypoint> {
        return this.getEntry()
            .getStartWaypoint();
    }

    getDisplayProposalEntryStateField(): Option<string> {
        return this.getDisplayLocation().flatMap(x => x.getState());
    }

    getDisplayProposalEntrySubtitle(): Option<string> {
        return this.getEntry()
            .getSubTitle();
    }

    getDisplayProposalEntrySupplierAmenities(): Option<string> {
        return this.getEntry()
            .getFirstNote('SUPPLIER_AMENITIES');
    }

    getDisplayProposalEntrySupplierAmenitiesLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SupplierAmenities',
                'Property Services',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntrySupplierExtraInfo(): Option<string> {
        return this.getEntry()
            .getFirstNote('SUPPLIER_EXTRA_INFO');
    }

    getDisplayProposalEntrySupplierExtraInfoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SupplierExtraInfo',
                'Supplier Extra Info',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntrySupplierPropertyInfo(): Option<string> {
        return this.getEntry()
            .getFirstNote('SUPPLIER_PROPERTY_INFO');
    }

    getDisplayProposalEntrySupplierPropertyInfoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SupplierPropertyInfo',
                'Supplier Property Info',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryTitle(): Option<string> {
        return this.getEntry()
            .getTitle();
    }

    getDisplayProposalEntryTotalDistance(): Option<Distance> {
        return this.getProduct()
            .flatMap(x => x.getDirections())
            .map(x => x.getTotalDistance());
    }

    getDisplayProposalEntryTotalDistanceNumber(isMetric: boolean): Option<number> {
        return this.getDisplayProposalEntryTotalDistance()
            .map(x => isMetric ? x.getKilometers() : x.getMiles());
    }

    getDisplayProposalEntryTotalTime(): Option<Duration> {
        return this.getProduct()
            .flatMap(x => x.getDirections())
            .map(x => x.getTotalDuration());
    }

    getDisplayProposalEntryTourEndLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'TourEnd',
                'Tour End',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryTourStartLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'TourStart',
                'Tour Start',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryTransitNotes(): Option<string> {
        return this.getEntry()
            .getFirstNote('TRANSIT_NOTES');
    }

    getDisplayProposalEntryTransitNotesLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'TransitNotes',
                'Transit Notes',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryTransport(): Option<Transport> {
        return this.getEntry()
            .getTransport();
    }

    getDisplayProposalEntryType(): Option<string> {
        return this.getEntry()
            .getType();
    }

    getDisplayProposalEntryUsefulMapMarkers(): List<MapMarker> {
        return this.getProduct()
            .flatMap(x => x.getMap())
            .map(x => x.marker.filter(v => !v.isEmpty()))
            .getOrElse(List());
    }

    getDisplayProposalEntryUsefulSubproductMapMarkers(): List<MapMarker> {
        return this.getSubproductsAsEntries()
            .flatMap(x => x.getDisplayProposalEntryUsefulMapMarkers());
    }

    getDisplayProposalEntryVoucherNumber(): Option<string> {
        return this.getEntry()
            .getFirstNote('VOUCHER_NUMBER');
    }

    getDisplayProposalEntryVoucherNumberLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'VoucherNumber',
                'Voucher Number',
                this.getEntryContext(),
            );
    }

    getDisplayProposalEntryWhatToBring(): Option<string> {
        return this.getEntry()
            .getFirstNote('WHAT_TO_BRING');
    }

    getDisplayProposalEntryWhatToBringLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'WhatToBring',
                'What To Bring',
                this.getEntryContext(),
            );
    }

    getDisplayReadMoreLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'ReadMore',
                'Read More',
                this.getEntryContext(),
            );
    }

    getDisplayRentalTermsLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'RentalTermsAndConditions',
                'Rental Terms and Conditions',
                this.getEntryContext(),
            );
    }

    getDisplayRoomsLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Rooms',
                'Rooms',
                this.getEntryContext(),
            );
    }

    getDisplaySelfDriveLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SelfDrive',
                'Self Drive',
                this.getEntryContext(),
            );
    }

    getDisplaySightsToSeeLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'SightsToSee',
                'Sights to see',
                this.getEntryContext(),
            );
    }

    getDisplaySnapshotText(): Option<string> {
        if (this.shouldUseSmallTitle()) {
            return this.getDisplayProposalEntryTitle();
        }
        return this.getDisplayProposalEntrySubtitle();
    }

    /**
     * Dont show title in bold for transport and custom
     */
    getDisplaySnapshotTitle(): Option<string> {
        return this.getDisplayProposalEntryTitle()
            .filter(t => !(this.shouldUseSmallTitle()));
    }

    getDisplayStarRating(): Option<number> {
        return this.getProduct().flatMap(x => x.getRating());
    }

    getDisplaySubproductsLabel(): string {
        if (this.isSelfDrive()) {
            return this.getDisplaySightsToSeeLabel();
        }

        return this.getDisplayThingsToDoLabel();
    }

    getDisplayThingsToDoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'ThingsToDo',
                'Things To Do',
                this.getEntryContext(),
            );
    }

    getDisplayVideosLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'Videos',
                'Videos',
                this.getEntryContext(),
            );
    }

    getDisplayWhyGoLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'WhyGo',
                'Why Go',
                this.getEntryContext(),
            );
    }

    getDisplayWhyStayLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'WhyStay',
                'Why Stay',
                this.getEntryContext(),
            );
    }

    getDisplayWhyVisitLabel(): string {
        return this.getMeta()
            .getTranslatedLabelForField(
                'WhyVisit',
                'Why Visit',
                this.getEntryContext(),
            );
    }

    getDistanceToNearAccommodation(): Option<Distance> {
        return this.getProposalWithMeta().getDistanceToNearAccommodation(this);
    }

    getDistanceToNearAccommodationForSubproduct(parent: ProposalEntryWithMeta): Option<Distance> {
        return this.getProposalWithMeta().getDistanceToNearAccommodationForSubproduct(this, parent);
    }

    getDistanceToNearAccommodationForSubproductLabel(parent: ProposalEntryWithMeta): Option<string> {
        return this.getNearAccommodationForSubproduct(parent).flatMap(x => x.getDisplayProposalEntryTitle());
    }

    getDistanceToNearAccommodationForSubproductText(parent: ProposalEntryWithMeta, metric: boolean): Option<string> {
        return this.getDistanceToNearAccommodationForSubproduct(parent)
            .map(x => '~' + x.getDistanceString(metric));
    }

    getDistanceToNearAccommodationLabel(): Option<string> {
        return this.getNearAccommodation().flatMap(x => x.getDisplayProposalEntryTitle());
    }

    getDistanceToNearAccommodationText(metric: boolean): Option<string> {
        return this.getDistanceToNearAccommodation()
            .map(x => '~' + x.getDistanceString(metric));
    }

    getDistanceToNearDestination(): Option<Distance> {
        return this.getProposalWithMeta().getDistanceToNearDestination(this);
    }

    getDistanceToNearDestinationLabel(): Option<string> {
        return this.getNearDestination().flatMap(x => x.getDisplayProposalEntryTitle());
    }

    getDistanceToNearDestinationText(metric: boolean): Option<string> {
        return this.getDistanceToNearDestination()
            .map(x => '~' + x.getDistanceString(metric));
    }

    private getDistanceToParent(parentEntry: ProposalEntryWithMeta): Option<Distance> {
        return this.getLatLongLocation().flatMap(l => parentEntry.getStraightLineDistance(l));
    }

    getDistanceToParentLabel(parentEntry: ProposalEntryWithMeta): Option<string> {
        return parentEntry.isSelfDrive()
            ? Some(this.getMeta().getTranslatedStringParameter('Start', 'Start'))
            : parentEntry.getDisplayProposalEntryTitle();
    }

    getDistanceToParentText(parentEntry: ProposalEntryWithMeta, metric: boolean): Option<string> {
        return this.getDistanceToParent(parentEntry)
            .map(x => '~' + x.getDistanceString(metric));
    }

    getDistanceToUserLabel(): string {
        return this.getMeta().translate('Your Location', 'Your Location');
    }

    getEntry(): ProposalEntry {
        return this.entry
            .getOrElse(new ProposalEntry());
    }

    private getEntryContext(): Option<string> {
        return this.getEntry()
            .getStatus();
    }

    /**
     * ORDER MATTERS: Classifications related if else first, then by type last
     */
    getEntryPageTitleNonTranslated(): string {
        if (this.getDisplayProposalEntryTitle().exists(t => t.length < 17)) {
            return this.getDisplayProposalEntryTitle().get();
        } else if (this.isFlight()) {
            return 'Flight';
        } else if (this.isPrivateTransfer()) {
            return 'Private transfer';
        } else if (this.isTransferToAccommodation() || this.isTransferToAirport()) {
            return 'Transfer';
        } else if (this.isAccommodation()) {
            return 'Accommodation';
        } else if (this.isRail()) {
            return 'Rail';
        } else if (this.isSelfDrive()) {
            return 'Self-drive';
        } else if (this.isHireVehicle()) {
            return 'Hire vehicle';
        } else if (this.isBoat()) {
            return 'Boat transport';
        } else if (this.isTransport()) {
            return 'Transport';
        } else if (this.isMultidayTour()) {
            return 'Multiday tour';
        } else if (this.isDayTour()) {
            return 'Day tour';
        } else if (this.isDestination()) {
            return 'Destination';
        }
        return 'Custom';
    }

    getEntryPageTitleTranslated(): string {
        const title = this.getEntryPageTitleNonTranslated();
        return this.getMeta().translate(title, title);
    }

    getId(): Option<number> {
        return this.getEntry()
            .getId();
    }

    getImageCount(): number {
        return this.getEntry()
            .getProduct()
            .map(x => x.getImageCount())
            .getOrElse(0);
    }

    getImages(): List<Image> {
        return this.getEntry()
            .getProduct()
            .map(x => x.getImages())
            .getOrElse(List());
    }

    getLatLongLocation(): Option<LatLongLocation> {
        return this.getEntry().getLatLongLocation();
    }

    getMapMarkers(): List<MapMarker> {
        return this.getEntry()
            .getProduct()
            .flatMap(p => p.getMap())
            .map(m => m.getMarker())
            .getOrElse(List());
    }

    getMapMarkersWithMeta(parent: Option<ProposalEntryWithMeta> = None): List<MapMarkerWithMeta> {
        // Self drives use start/end markers
        if (this.isSelfDrive()) {
            return List();
        }

        return this.getEntry()
            .getProduct()
            .flatMap(p => p.getMap())
            .map(d => d.getMarker())
            .getOrElse(List<MapMarker>())
            .map(x => x.withMeta(this, parent));
    }

    getMeta(): Metadata {
        return this.getProposalWithMeta()
            .getMeta();
    }

    getNearAccommodation(): Option<ProposalEntryWithMeta> {
        return this.getProposalWithMeta().getAccommodationNear(this);
    }

    getNearAccommodationForSubproduct(parent: ProposalEntryWithMeta): Option<ProposalEntryWithMeta> {
        return this.getProposalWithMeta().getAccommodationNear(parent);
    }

    getNearDestination(): Option<ProposalEntryWithMeta> {
        return this.getProposalWithMeta().getDestinationNear(this);
    }

    getNextSubproductAsEntry(pid: number): Option<ProposalEntryWithMeta> {
        const sortedEntries = this.getSortedSubproductsAsEntries();
        const idx = sortedEntries.findIndex(x => x.getProductId().contains(pid));
        if (idx === -1) {
            return None;
        } else {
            return Option.of(sortedEntries.get(idx + 1));
        }
    }

    getPrevSubproductAsEntry(pid: number): Option<ProposalEntryWithMeta> {
        const sortedEntries = this.getSortedSubproductsAsEntries();
        const idx = sortedEntries.findIndex(x => x.getProductId().contains(pid));
        if (idx === -1) {
            return None;
        } else {
            return Option.of(sortedEntries.get(idx - 1));
        }
    }

    getProduct(): Option<Product> {
        return this.getEntry()
            .getProduct();
    }

    private getProductContact(): Option<Contact> {
        return this.getProduct()
            .flatMap(x => x.getContact());
    }

    getProductContactCard(): ContactModel {
        const type = this.getProduct().flatMap(p => p.logo).isEmpty() ? 'Supplier' : 'Product';
        const option = this.getEntry().getOption();
        return new ContactModel(
            option.flatMap(po => po.name)
                .orElse(this.getProduct().flatMap(p => p.name))
                .orElse(this.getEntry().getCompany().flatMap(c => c.name)),
            this.getProduct().flatMap(p => p.logo).flatMap(x => x.getHref())
                .orElse(this.getEntry().getCompany().flatMap(c => c.logo)),
            false,
            type,
            'business',
            option.flatMap(po => po.location)
                .orElse(this.getProduct().flatMap(p => p.location))
                .orElse(this.getEntry().getCompany().flatMap(c => c.location))
                .getOrElse(new PhysicalLocation()),
            OptionUtils.applyOrReturnNonEmptyMultiple(
                (a, b) => a.merge(b),
                option.flatMap(po => po.contact),
                this.getProduct().flatMap(p => p.contact),
                this.getEntry().getCompany().flatMap(c => c.contact))
                .getOrElse(new Contact()),
            OptionUtils.applyOrReturnNonEmptyMultiple(
                (a, b) => a.merge(b),
                this.getProduct().flatMap(p => p.social),
                this.getProduct().flatMap(c => c.social))
                .getOrElse(new Social()));
    }

    getProductId(): Option<number> {
        return this.getProduct()
            .flatMap(x => x.getId());
    }

    private getProductLocation(): Option<GpsLocation> {
        return this.getProduct()
            .flatMap(x => x.getLocation());
    }

    getProductOptions(): List<ProductOption> {
        return this.getEntry()
            .getProduct()
            .map(x => x.getOptions())
            .getOrElse(List());
    }

    getProposalWithMeta(): ProposalWithMeta {
        return this.proposal
            .getOrElse(new ProposalWithMeta());
    }

    getSelectedOptionImages(): List<Image> {
        return this.getEntry()
            .getOption()
            .map(x => x.getImages())
            .getOrElse(List());
    }

    getSelfDriveEndMapMarkerWithMeta(): Option<MapMarkerWithMeta> {
        return this.getEntry()
            .getProduct()
            .flatMap(p => p.getDirections())
            .flatMap(d => d.getLastMapMarker())
            .map(x => x.withMeta(this, None));
    }

    getSelfDriveStartMapMarkerWithMeta(): Option<MapMarkerWithMeta> {
        return this.getEntry()
            .getProduct()
            .flatMap(p => p.getDirections())
            .flatMap(d => d.getFirstMapMarker())
            .map(x => x.withMeta(this, None));
    }

    getSnapshotDayRange(): Option<string> {
        return this.getMeta()
            .formatShortDateRange(
                this.getEntry().getStartDateTime(),
                this.getEntry().getEndDateTime(),
                '2D',
                this.getProposalWithMeta().getProposalLanguage(),
                'SnapshotCardDay',
                this.getEntryContext());
    }

    getSnapshotMonthRange(): Option<string> {
        return this.getMeta()
            .formatShortDateRange(
                this.getEntry().getStartDateTime(),
                this.getEntry().getEndDateTime(),
                '3M',
                this.getProposalWithMeta()
                    .getProposalLanguage(),
                'SnapshotCardMonth',
                this.getEntryContext(),
            );
    }

    getSortedFilteredSubproductsAsEntries(filter: EntryTypeFilter = 'All', textFilter: string = ''): List<ProposalEntryWithMeta> {
        return this.getSortedSubproductsAsEntries()
            .filter(x => x.getEntry().matchesTypeFilter(filter) && x.getEntry().matchesFuzzyTextFilter(textFilter));
    }

    // Sort by distance to start of product
    getSortedSubproductsAsEntries(): List<ProposalEntryWithMeta> {
        const startPointOfProduct = this.getLatLongLocation();

        if (startPointOfProduct.isEmpty()) {
            return this.getSubproductsAsEntries();
        }

        return this.getSubproductsAsEntries()
            .sort((a, b) => ComparisonUtils.optionNumberComparator.compare(
                a.getStraightLineDistance(startPointOfProduct.get()).map(x => x.getMeters()),
                b.getStraightLineDistance(startPointOfProduct.get()).map(x => x.getMeters())));
    }

    getStraightLineDistance(other: LatLongLocation): Option<Distance> {
        return this.getLatLongLocation().flatMap(x => x.getStraightLineDistance(other));
    }

    getSubproductAsEntry(id: number): Option<ProposalEntryWithMeta> {
        return Option.of(this.getEntry().getAllSubproducts().find(p => p.getId().contains(id)))
            .map(x => new ProposalEntryWithMeta(
                Some(ProposalEntry.buildFromProduct(x, this.getProposalWithMeta().getProposalLanguage())),
                Some(this.getProposalWithMeta()),
            ));
    }

    getSubproductMapMarkersWithMeta(): List<MapMarkerWithMeta> {
        return this.getSubproductsAsEntries()
            .flatMap(p => p.getMapMarkersWithMeta(Some(this)));
    }

    getSubproductsAsEntries(): List<ProposalEntryWithMeta> {
        return OptionUtils.flatMap2(this.proposal, this.entry, (p, e) => {
            return e.getProduct()
                .map(prod => {
                    return prod.getSubproducts()
                        .map(x => {
                            return new ProposalEntryWithMeta(
                                Some(ProposalEntry.buildFromProduct(x, p.getProposalLanguage())),
                                Some(p),
                            );
                        });
                });
        }).orElse(
            this.entry.map(e => e.getAllSubproducts().map(x => new ProposalEntryWithMeta(
                Some(ProposalEntry.buildFromProduct(x, Some('English'))),
                Some(new ProposalWithMeta(None, Some(this.getMeta())))))),
        ).getOrElse(List());
    }

    private getSupplierContact(): Option<Contact> {
        return this.getProduct()
            .flatMap(x => x.getSupplier())
            .flatMap(x => x.getContact());
    }

    private getSupplierLocation(): Option<PhysicalLocation> {
        return this.getProduct()
            .flatMap(x => x.getSupplier())
            .flatMap(x => x.getLocation());
    }

    getVideos(): List<Video> {
        return this.getEntry()
            .getProduct()
            .map(x => x.getVideos())
            .getOrElse(List());
    }

    hasExtraDetails(): boolean {
        return this.isProposalEntryNotesVisible()
            || this.isProposalEntryImportantInfoVisible()
            || this.isProposalEntryCancellationPolicyVisible()
            || this.isProposalEntryRentalTermsVisible();
    }

    isAccommodation(): boolean {
        return this.getEntry().isAccommodation();
    }

    isAccommodationFilterVisible(): boolean {
        return !this.getSortedFilteredSubproductsAsEntries('Accommodation').isEmpty();
    }

    isAirplane(): boolean {
        return this.getEntry().isAirplane();
    }

    isBoat(): boolean {
        return this.getEntry().isBoat();
    }

    isCampervan(): boolean {
        return this.getEntry().isCampervan();
    }

    isCustom(): boolean {
        return this.getEntry().isCustom();
    }

    isDayTour(): boolean {
        return this.getEntry().isDayTour();
    }

    isDestination(): boolean {
        return this.getEntry()
            .isDestination();
    }

    isDestinationFilterVisible(): boolean {
        return !this.getSortedFilteredSubproductsAsEntries('Destination').isEmpty();
    }

    isDistanceToAccommodationVisible(): boolean {
        return this.getDistanceToNearAccommodation()
            .nonEmpty();
    }

    isDistanceToParentProductVisible(parent: ProposalEntryWithMeta): boolean {
        return this.getDistanceToParentText(parent, true)
            .nonEmpty();
    }

    isDistanceToSubproductAccommodationVisible(parent: ProposalEntryWithMeta): boolean {
        return this.getDistanceToNearAccommodationForSubproductText(parent, true)
            .nonEmpty();
    }

    isDurationVisible(): boolean {
        return this.getEntry()
            .getNights()
            .nonEmpty();
    }

    isFirstDay(m: Moment): boolean {
        return this.getEntry().isFirstDay(m);
    }

    isFlight(): boolean {
        return this.getEntry()
            .isFlight();
    }

    isFlightFilterVisible(): boolean {
        return !this.getSortedFilteredSubproductsAsEntries('Flights').isEmpty();
    }

    isHelicopter(): boolean {
        return this.getEntry()
            .isHelicopter();
    }

    isHireCar(): boolean {
        return this.getEntry()
            .isHireCar();
    }

    isHireVehicle(): boolean {
        return this.isHireCar()
            || this.isCampervan();
    }

    isInsurance(): boolean {
        return this.getEntry().isInsurance();
    }

    isLastDay(m: Moment): boolean {
        return this.getEntry().isLastDay(m);
    }

    isLeisureEntry(): boolean {
        return this.getEntry().isLeisureEntry();
    }

    isMultidayTour(): boolean {
        return this.getEntry()
            .isMultidayTour();
    }

    isMultipleDays(): boolean {
        return this.getEntry().isMultipleDays();
    }

    isNumberOfPassengersVisible(): boolean {
        return this.getDisplayNumberOfPassengers()
            .nonEmpty();
    }

    isPrivateTransfer(): boolean {
        return this.getEntry()
            .isPrivateTransfer();
    }

    isProposalEntryAddressVisible(): boolean {
        return this.getDisplayProposalEntryFullSingleLineAddress()
            .nonEmpty();
    }

    isProposalEntryCancellationPolicyVisible(): boolean {
        // Needs field information added to it
        return this.getDisplayProposalEntryCancellationPolicy()
            .nonEmpty();
    }

    isProposalEntryCheckInOutVisible(): boolean {
        return this.getDisplayProposalEntryCheckInOut()
            .nonEmpty();
    }

    isProposalEntryCheckInVisible(): boolean {
        return this.getDisplayProposalEntryCheckIn()
            .nonEmpty();
    }

    isProposalEntryCheckOutVisible(): boolean {
        return this.getDisplayProposalEntryCheckOut()
            .nonEmpty();
    }

    isProposalEntryClassVisible(): boolean {
        return this.getDisplayProposalEntryClass()
            .nonEmpty();
    }

    isProposalEntryDayStringVisible(): boolean {
        return this.getDisplayProposalEntryDayString()
            .nonEmpty();
    }

    isProposalEntryDaysVisible(): boolean {
        return this.getDisplayProposalEntryDays()
            .nonEmpty();
    }

    isProposalEntryDescriptionVisible(): boolean {
        return this.getDisplayProposalEntryDescription()
            .nonEmpty();
    }

    isProposalEntryDirectionsVisible(): boolean {
        return this.getDisplayProposalEntryDirections()
            .nonEmpty();
    }

    isProposalEntryDropoffRemarkVisible(): boolean {
        return this.getDisplayProposalEntryDropoffRemark()
            .nonEmpty();
    }

    isProposalEntryDropoffTimeVisible(): boolean {
        return this.getDisplayProposalEntryDropoffTime()
            .nonEmpty();
    }

    isProposalEntryEndRemarkVisible(): boolean {
        return this.getDisplayProposalEntryEndRemark()
            .nonEmpty();
    }

    isProposalEntryEndTimeVisible(): boolean {
        return this.getDisplayProposalEntryEndTime()
            .nonEmpty();
    }

    isProposalEntryEndWaypointVisible(): boolean {
        return this.getDisplayProposalEntryEndWaypoint()
            .nonEmpty();
    }

    isProposalEntryExclusionsVisible(): boolean {
        return this.getDisplayProposalEntryExclusions()
            .nonEmpty();
    }

    isProposalEntryExtrasVisible(): boolean {
        return this.getDisplayProposalEntryExtras()
            .nonEmpty();
    }

    isProposalEntryGuideDetailsVisible(): boolean {
        return this.getDisplayProposalEntryGuideDetails()
            .nonEmpty();
    }

    isProposalEntryIdVisible(): boolean {
        return this.getDisplayProposalEntryId()
            .nonEmpty();
    }

    isProposalEntryImageVisible(): boolean {
        return !this.getDisplayProposalEntryImage()
            .isEmpty();
    }

    isProposalEntryImportantInfoVisible(): boolean {
        return this.getDisplayProposalEntryImportantInfo()
            .nonEmpty();
    }

    isProposalEntryInclusionsVisible(): boolean {
        return this.getDisplayProposalEntryInclusions()
            .nonEmpty();
    }

    isProposalEntryInternetInfoVisible(): boolean {
        return this.getDisplayProposalEntryInternetInfo()
            .nonEmpty();
    }

    isProposalEntryLocationVisible(): boolean {
        return this.getDisplayProposalEntryLocation()
            .nonEmpty();
    }

    isProposalEntryLongDescriptionVisible(): boolean {
        return this.getDisplayProposalEntryLongDescription()
            .nonEmpty();
    }

    isProposalEntryMapVisible(): boolean {
        return this.getDisplayProposalEntryMap()
            .exists(map => map.isUseful());
    }

    isProposalEntryMeetingPointVisible(): boolean {
        return this.getDisplayProposalEntryMeetingPoint()
            .nonEmpty();
    }

    isProposalEntryNightsOrDaysVisible(): boolean {
        return this.getDisplayProposalEntryNightsOrDays()
            .nonEmpty();
    }

    isProposalEntryNightStringVisible(): boolean {
        return this.getDisplayProposalEntryNightString()
            .nonEmpty();
    }

    isProposalEntryNightsVisible(): boolean {
        return this.getDisplayProposalEntryNights()
            .nonEmpty();
    }

    isProposalEntryNotesVisible(): boolean {
        return this.getDisplayProposalEntryNotes()
            .nonEmpty();
    }

    isProposalEntryOptionalTextVisible(): boolean {
        return this.getEntry()
            .isOptional();
    }

    isProposalEntryOptionAmenitiesVisible(): boolean {
        return this.getDisplayProposalEntryOptionAmenities()
            .nonEmpty();
    }

    isProposalEntrySafetyInformationVisible(): boolean {
        return this.getDisplayProposalEntrySafetyInformation()
            .nonEmpty();
    }

    isProposalEntrySafetyProceduresVisible(): boolean {
        return this.getDisplayProposalEntrySafetyProcedures()
            .nonEmpty();
    }

    isProposalEntryOptionExtraInfoVisible(): boolean {
        return this.getDisplayProposalEntryOptionExtraInfo()
            .nonEmpty();
    }

    isProposalEntryOptionPropertyInfoVisible(): boolean {
        return this.getDisplayProposalEntryOptionPropertyInfo()
            .nonEmpty();
    }

    isProposalEntryOptionVisible(): boolean {
        return this.getDisplayProposalEntryOption()
            .nonEmpty();
    }

    isProposalEntryPassengersVisible(): boolean {
        return this.getDisplayProposalEntryPassengers()
            .nonEmpty();
    }

    isProposalEntryPhoneVisible(): boolean {
        return this.getDisplayProposalEntryPhone()
            .nonEmpty();
    }

    isProposalEntryPickupRemarkVisible(): boolean {
        return this.getDisplayProposalEntryPickUpRemark()
            .nonEmpty();
    }

    isProposalEntryPickupTimeVisible(): boolean {
        return this.getDisplayProposalEntryPickupTime()
            .nonEmpty();
    }

    isProposalEntryProductLocationCityVisible(): boolean {
        return this.getDisplayProposalEntryProductLocationCity()
            .nonEmpty();
    }

    isProposalEntryProductLocationCountryVisible(): boolean {
        return this.getDisplayProposalEntryProductLocationCountry()
            .nonEmpty();
    }

    isProposalEntryProductLocationStateVisible(): boolean {
        return this.getDisplayProposalEntryProductLocationState()
            .nonEmpty();
    }

    isProposalEntryProductNameVisible(): boolean {
        return this.getDisplayProposalEntryProductName()
            .nonEmpty();
    }

    isProposalEntryProductOptionNameVisible(): boolean {
        return this.getDisplayProposalEntryProductOptionName()
            .nonEmpty();
    }

    isProposalEntryRatingVisible(): boolean {
        return this.getDisplayStarRating().nonEmpty() && this.getMeta().isFieldVisible('Rating', this.getEntryContext()) && this.isAccommodation();
    }

    isProposalEntryReferenceVisible(): boolean {
        return this.getDisplayProposalEntryReference()
            .nonEmpty();
    }

    isProposalEntryRemarksVisible(): boolean {
        return this.getDisplayProposalEntryRemarks()
            .nonEmpty();
    }

    isProposalEntryRentalTermsVisible(): boolean {
        return this.getDisplayProposalEntryRentalTerms()
            .nonEmpty();
    }

    isProposalEntryScheduleVisible(): boolean {
        return this.getDisplayProposalEntrySchedule()
            .nonEmpty();
    }

    isProposalEntryShortDescriptionVisible(): boolean {
        return this.getDisplayProposalEntryShortDescription()
            .nonEmpty();
    }

    isProposalEntryStartRemarkVisible(): boolean {
        return this.getDisplayProposalEntryStartRemark()
            .nonEmpty();
    }

    isProposalEntryStartTimeVisible(): boolean {
        return this.getDisplayProposalEntryStartTime()
            .nonEmpty();
    }

    isProposalEntryStartWaypointVisible(): boolean {
        return this.getDisplayProposalEntryStartWaypoint()
            .nonEmpty();
    }

    isProposalEntrySubproductsVisible(): boolean {
        return !this.getEntry().getAllSubproducts().isEmpty();
    }

    isProposalEntrySubtitleVisible(): boolean {
        return this.getDisplayProposalEntrySubtitle()
            .nonEmpty();
    }

    isProposalEntrySupplierAmenitiesVisible(): boolean {
        return this.getDisplayProposalEntrySupplierAmenities()
            .nonEmpty();
    }

    isProposalEntrySupplierExtraInfoVisible(): boolean {
        return this.getDisplayProposalEntrySupplierExtraInfo()
            .nonEmpty();
    }

    isProposalEntrySupplierPropertyInfoVisible(): boolean {
        return this.getDisplayProposalEntrySupplierPropertyInfo()
            .nonEmpty();
    }

    isProposalEntryTitleVisible(): boolean {
        return this.getDisplayProposalEntryTitle()
            .nonEmpty();
    }

    isProposalEntryTotalDistanceVisible(): boolean {
        return this.getDisplayProposalEntryTotalDistance()
            .nonEmpty();
    }

    isProposalEntryTotalTimeVisible(): boolean {
        return this.getDisplayProposalEntryTotalTime()
            .nonEmpty();
    }

    isProposalEntryTransitNotesVisible(): boolean {
        return this.getDisplayProposalEntryTransitNotes()
            .nonEmpty();
    }

    isProposalEntryTransportVisible(): boolean {
        return this.getDisplayProposalEntryTransport()
            .nonEmpty();
    }

    isProposalEntryTypeVisible(): boolean {
        return this.getDisplayProposalEntryType()
            .nonEmpty();
    }

    isProposalEntryVoucherNumberVisible(): boolean {
        return this.getDisplayProposalEntryVoucherNumber()
            .nonEmpty();
    }

    isProposalEntryWhatToBringVisible(): boolean {
        return this.getDisplayProposalEntryWhatToBring()
            .nonEmpty();
    }

    isQuote(): boolean {
        return this.getEntry().isQuote();
    }

    isRail(): boolean {
        return this.getEntry()
            .isRail();
    }

    isSelfDrive(): boolean {
        return this.getEntry()
            .isSelfDrive();
    }

    isSelfDriveFilterVisible(): boolean {
        return !this.getSortedFilteredSubproductsAsEntries('SelfDrive').isEmpty();
    }

    isSnapshotRangeVisible(): boolean {
        return this.getSnapshotMonthRange().nonEmpty()
            && this.getSnapshotDayRange().nonEmpty();
    }

    isSnapshotTextVisible(): boolean {
        return this.getDisplaySnapshotText()
            .nonEmpty();
    }

    isSnapshotTitleVisible(): boolean {
        return this.getDisplaySnapshotTitle()
            .nonEmpty();
    }

    isSpecialCustom(): boolean {
        return this.getEntry()
            .isSpecialCustom();
    }

    isTourFilterVisible(): boolean {
        return !this.getSortedFilteredSubproductsAsEntries('Tours').isEmpty();
    }

    isTransferToAccommodation(): boolean {
        return this.getEntry()
            .isTransferToAccommodation();
    }

    isTransferToAirport(): boolean {
        return this.getEntry()
            .isTransferToAirport();
    }

    isTransport(): boolean {
        return this.getEntry()
            .isTransport();
    }

    isUsefulMarkerOnMap(): boolean {
        return this.getLatLongLocation().nonEmpty()
            && (this.isAccommodation() || this.isDayTour() || this.isMultidayTour() || this.isDestination())
            && !(this.isSelfDrive() || this.isHireVehicle());
    }

    private shouldUseSmallTitle(): boolean {
        return this.getEntry()
                .isCustom()
            || this.getEntry()
                .isTransport()
            || this.getEntry()
                .isHireVehicle();
    }
}

export class ProposalEntryWithMetaJsonSerializer extends SimpleJsonSerializer<ProposalEntryWithMeta> {
    static instance: ProposalEntryWithMetaJsonSerializer = new ProposalEntryWithMetaJsonSerializer();

    protected fromJsonImpl(json: any): ProposalEntryWithMeta {
        return new ProposalEntryWithMeta(
            ProposalEntryJsonSerializer.instance.fromJson(json),
            ProposalWithMetaJsonSerializer.instance.fromJson(json),
        );
    }

    protected toJsonImpl(value: ProposalEntryWithMeta, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(entryKey, value.entry, ProposalEntryJsonSerializer.instance)
            .addOptionalSerializable(metaKey, value.proposal, ProposalWithMetaJsonSerializer.instance);
    }

}
