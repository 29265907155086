import { None, Option, Some } from 'funfix-core';
import * as _s from 'underscore.string';
import { ProposalEntry } from '../models';
import { Flight } from '../models/flight';
import { FlightInfo } from '../models/flight-info';
import { FlightSeat } from '../models/flight-seat';
import { FlightWaypoint } from '../models/flight-waypoint';

export function populateFlightInfo(e: ProposalEntry): ProposalEntry {
    if (e.transport.flatMap(t => t.type).contains('Flight')) {
        return e.withTransport(Some(Flight.fromTransport(getFlightInfoForEntry(e), e.transport.get())));
    } else {
        return e;
    }
}

function getFlightInfoForEntry(e: ProposalEntry): Option<FlightInfo> {
    return e.transport
        .flatMap(t => t.description)
        .map(d => getFlightInfoForString(d));
}

export function getFlightInfoForString(s: string): FlightInfo {
    return getFlightInfoForLines(_s.lines(s));
}

function getFlightInfoForLines(lines: ReadonlyArray<string>): FlightInfo {
    return lines
        .filter(l => l !== '')
        .map(l => l.trim())
        .reduce((prev, curr) => populateFlightInfoForLine(prev, curr), new FlightInfo());
}

function populateFlightInfoForLine(i: FlightInfo, line: string): FlightInfo {
    if (_s.startsWith(line, 'Departure:')) {
        i.departure = getFlightWaypoint(_s.lstrip(line, 'Departure:'));
        recoverMissing(i, i.departure, line);
    } else if (_s.startsWith(line, 'Arrival:')) {
        i.arrival = getFlightWaypoint(_s.lstrip(line, 'Arrival:'));
        recoverMissing(i, i.arrival, line);
    } else if (_s.startsWith(line, 'Airline:')) {
        i.airline = getTrimmedOptionalString(_s.lstrip(line, 'Airline:'));
        recoverMissing(i, i.airline, line);
    } else if (_s.startsWith(line, 'Duration:')) {
        i.duration = getTrimmedOptionalString(_s.lstrip(line, 'Duration:'));
        recoverMissing(i, i.duration, line);
    } else if (_s.startsWith(line, 'Operated By:')) {
        i.operatedBy = getTrimmedOptionalString(_s.lstrip(line, 'Operated By:'));
        recoverMissing(i, i.operatedBy, line);
    } else if (_s.startsWith(line, 'Airline Reservation Code:')) {
        i.reservationCode = getTrimmedOptionalString(_s.lstrip(line, 'Airline Reservation Code:'));
        recoverMissing(i, i.reservationCode, line);
    } else if (_s.startsWith(line, 'Seat:')) {
        const seat = getSeat(line);
        recoverMissing(i, seat, line);
        seat.forEach(s => i.seats = i.seats.push(s));
    } else {
        recoverMissing(i, None, line);
    }

    return i;
}

function recoverMissing<T>(i: FlightInfo, result: Option<T>, line: string): void {
    if (result.isEmpty()) {
        i.notes = i.notes.push(line);
    }
}

function getTrimmedOptionalString(input: string): Option<string> {
    return Option.of(input).map(s => s.trim());
}

function getFlightWaypoint(line: string): Option<FlightWaypoint> {
    const flightWaypoint = new FlightWaypoint();
    const r = line.split('Terminal:');
    if (r.length > 0) {
        flightWaypoint.time = getTrimmedOptionalString(r[0]);
    }
    if (r.length > 1) {
        flightWaypoint.terminal = getTrimmedOptionalString(r[1]);
    }
    return Some(flightWaypoint);
}

const passengerNameRegex = /Seat:.*?([A-Z\/\s]*)(?!\w)/;
const eticketRegex = /eTicket Receipt\(s\): (.*)/;

function getSeat(line: string): Option<FlightSeat> {
    return Some(new FlightSeat(
        getPassenger(line),
        Some(line),
        getEticket(line),
        Some(_s.contains(line, 'Check-In Required'))));
}

function getEticket(line: string): Option<string> {
    return Option.of(eticketRegex.exec(line))
        .filter(r => r.length !== 0)
        .flatMap(r => getTrimmedOptionalString(r[1]));
}

function getPassenger(line: string): Option<string> {
    const value = passengerNameRegex.exec(line);
    return Option.of(value)
        .filter(r => r.length !== 0)
        .flatMap(r => getTrimmedOptionalString(r[1]));
}
