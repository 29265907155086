// An Image with server/platform specfic information injected
import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    highKey,
    imageKey,
    JsonBuilder,
    localKey,
    lowKey,
    OptionUtils,
    parseString,
    remoteKey,
    SimpleJsonSerializer,
    thumb100Key,
    thumb50Key,
    thumb600Key,
} from '../core';
import {Url} from '../core/url';
import {Image, ImageJsonSerializer} from './image';

export type DownloadType = 'Minimal' | 'Compact' | 'Optimal';

export class DownloadTypeUtils {
    static getNumberOfImages(downloadType: DownloadType): number {
        switch (downloadType) {
            case 'Minimal':
                return 1;
            case 'Compact':
                return 5;
            case 'Optimal':
                return 10;
        }
    }
}

export class ContextualImage {
    constructor(
        readonly remoteUrl: string,
        readonly storageUrl: Option<string> = None) {
    }
}

export class ContextualImageJsonSerializer extends SimpleJsonSerializer<ContextualImage> {
    static instance: ContextualImageJsonSerializer = new ContextualImageJsonSerializer();

    protected fromJsonImpl(json: any): ContextualImage {
        return new ContextualImage(
            parseString(json[remoteKey]).getOrElse(''),
            parseString(json[localKey]),
        );
    }

    protected toJsonImpl(value: ContextualImage, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(remoteKey, Some(value.remoteUrl))
            .addOptional(localKey, value.storageUrl);
    }

}

export class ContextualImageSet {

    constructor(
        readonly image: Image,
        readonly highres: Option<ContextualImage>,
        readonly lowres: Option<ContextualImage> = None,
        readonly thumb600: Option<ContextualImage> = None,
        readonly thumb100: Option<ContextualImage> = None,
        readonly thumb50: Option<ContextualImage> = None) {
    }

    static buildFrom(
        image: Image,
        endpoint: string,
        hasLowRes: boolean,
        hasThumbnails: boolean,
        isRaw: boolean, // No uploaded folder
        origin: string,
        localDir: Option<string>,
    ): ContextualImageSet {
        return new ContextualImageSet(
            image,
            image.uri.map(u => this.getHighResImage(u, endpoint, origin, localDir, isRaw)),
            image.uri.filter(_ => hasLowRes).map(u => this.getLowResImage(u, endpoint, origin, localDir)),
            image.uri.filter(_ => hasThumbnails).map(u => this.getThumbnailImage(u, endpoint, '600', origin, localDir)),
            image.uri.filter(_ => hasThumbnails).map(u => this.getThumbnailImage(u, endpoint, '100', origin, localDir)),
            image.uri.filter(_ => hasThumbnails).map(u => this.getThumbnailImage(u, endpoint, '50', origin, localDir)),
        );
    }

    static buildFromStaticUri(image: string): ContextualImageSet {
        return new ContextualImageSet(
            new Image(Url.parse(image)),
            Some(new ContextualImage(image, Some(image))),
            None,
            None,
            None,
            None);
    }

    static getFullPath(u: Url, endpoint: string, origin: string, localDir: Option<string>): ContextualImage {
        const webUrl = u.prependPath(endpoint).withOrigin(origin).getHref();

        const localUrl = localDir.flatMap(dir => Url.parse(dir + '/' + endpoint + '/' + u.getHref())).map(x => x.getHref());

        return new ContextualImage(webUrl, localUrl);
    }

    static getHighResImage(
        uri: Url, endpoint: string, origin: string, localDir: Option<string>, isRaw: boolean): ContextualImage {
        if (isRaw) {
            return ContextualImageSet.getFullPath(uri, endpoint, origin, localDir);
        }
        return ContextualImageSet.getFullPath(uri.appendPath('Uploaded'), endpoint, origin, localDir);
    }

    static getLowResImage(uri: Url, endpoint: string, origin: string, localDir: Option<string>): ContextualImage {
        // HACK: This is really bad!
        if (endpoint === 'ProductImages') {
            return ContextualImageSet.getFullPath(
                uri.appendPath('Thumbnails').extendFile(`_thumblowres`),
                endpoint,
                origin,
                localDir);
        }
        return ContextualImageSet.getFullPath(
            uri.appendPath('Thumbnails').extendFile(`_thumb`),
            endpoint,
            origin,
            localDir);

    }

    static getThumbnailImage(
        uri: Url, endpoint: string, size: string, origin: string, localDir: Option<string>): ContextualImage {
        return ContextualImageSet.getFullPath(
            uri.appendPath('Thumbnails').extendFile(`_thumb${size}_${size}`),
            endpoint,
            origin,
            localDir);
    }

    getAllImages(): List<ContextualImage> {
        return OptionUtils.toList(this.highres, this.lowres, this.thumb600, this.thumb100, this.thumb50);
    }

    // We always fallback to the highres if it is the only image available
    // Note: reverse so order is lowest to highest
    getContextualImagesUnderMB(
        localFirst: boolean = true, capMibibytes: number = 5, count: number = 3, downloadType: DownloadType = 'Optimal'): List<ContextualImage> {
        if (downloadType === 'Compact' || (this.image.isLargerThan(capMibibytes).contains(true) && this.getAllImages().size > 1)) {
            return OptionUtils.toList(this.lowres, this.thumb600, this.thumb100, this.thumb50).take(count).reverse();
        } else {
            return this.getAllImages().take(count).reverse();
        }
    }

    getImagesUnderMB(localFirst: boolean = true, capMibibytes: number = 5, count: number = 3): List<string> {
        return this.getContextualImagesUnderMB(localFirst, capMibibytes, count)
            .map(i => localFirst ? i.storageUrl.getOrElse(i.remoteUrl) : i.remoteUrl);
    }

    isEmpty(): boolean {
        return this.getAllImages().isEmpty();
    }
}

export class ContextualImageSetJsonSerializer extends SimpleJsonSerializer<ContextualImageSet> {
    static instance: ContextualImageSetJsonSerializer = new ContextualImageSetJsonSerializer();

    protected fromJsonImpl(json: any): ContextualImageSet {
        return new ContextualImageSet(
            ImageJsonSerializer.instance.fromJson(json[imageKey]).getOrElse(new Image()),
            ContextualImageJsonSerializer.instance.fromJson(json[highKey]),
            ContextualImageJsonSerializer.instance.fromJson(json[lowKey]),
            ContextualImageJsonSerializer.instance.fromJson(json[thumb600Key]),
            ContextualImageJsonSerializer.instance.fromJson(json[thumb100Key]),
            ContextualImageJsonSerializer.instance.fromJson(json[thumb50Key]),
        );
    }

    protected toJsonImpl(value: ContextualImageSet, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(imageKey, Some(value.image), ImageJsonSerializer.instance)
            .addOptionalSerializable(highKey, value.highres, ContextualImageJsonSerializer.instance)
            .addOptionalSerializable(lowKey, value.lowres, ContextualImageJsonSerializer.instance)
            .addOptionalSerializable(thumb600Key, value.thumb600, ContextualImageJsonSerializer.instance)
            .addOptionalSerializable(thumb100Key, value.thumb100, ContextualImageJsonSerializer.instance)
            .addOptionalSerializable(thumb50Key, value.thumb50, ContextualImageJsonSerializer.instance);
    }

}
