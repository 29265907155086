import { None, Option } from 'funfix-core';
import { List } from 'immutable';
import {
    JsonBuilder,
    MapUtils,
    OptionUtils,
    parseBoolean,
    parseNumber,
    parseString,
    priceKey,
    SimpleJsonSerializer,
} from '../core';
import {
    commissionInclusiveKey,
    commissionPercentKey,
    costKey,
    currencyCostKey,
    currencySellKey,
    ratesKey,
} from '../core/json-keys';
import { FinancialsRate, FinancialsRateJsonSerialiser } from './financials-rate';

export class Financials {

    constructor(
        readonly currencyCost: Option<string> = None,
        readonly currencySell: Option<string> = None,
        readonly commissionInclusive: Option<boolean> = None,
        readonly commissionPercent: Option<number> = None,
        readonly rates: List<FinancialsRate> = List(),
        readonly cost: Option<number> = None,
        readonly sell: Option<number> = None,
    ) { }

    private currencyMap = MapUtils.buildMapFromListOptional(
        this.rates, r => Option.map2(r.fromCurrency, r.toCurrency, (RFC, RTC) => `${RFC}:${RTC}`));

    calculateConvertedSell(): Option<number> {
        return OptionUtils.flatMap3(
            this.calculatePreConversionSell(),
            this.currencyCost,
            this.currencySell, (pCS, cC, cS) => this.convertCurrency(pCS, cC, cS));
    }

    calculatePreConversionSell(): Option<number> {
        return Option.map2(this.cost, this.commissionPercent, (c, cp) => c / (1 - cp));
    }

    convertCurrency(amount: number, fromCurrency: string, toCurrency: string): Option<number> {
        return Option.of(this.currencyMap.get(`${fromCurrency}:${toCurrency}`))
            .flatMap(n => n.rate.map(m => amount * m));
    }

    getCost(): Option<number> {
        return this.cost;
    }

    getPrice(): Option<number> {
        return this.sell.orElse(this.calculateConvertedSell());
    }
}

export class FinancialsJsonSerializer extends SimpleJsonSerializer<Financials> {
    static instance: FinancialsJsonSerializer = new FinancialsJsonSerializer();

    fromJsonImpl(obj: any): Financials {
        return new Financials(
            parseString(obj[currencyCostKey]),
            parseString(obj[currencySellKey]),
            parseBoolean(obj[commissionInclusiveKey]),
            parseNumber(obj[commissionPercentKey]),
            FinancialsRateJsonSerialiser.instance.fromJsonArray(obj[ratesKey]),
            parseNumber(obj[costKey]),
            parseNumber(obj[priceKey]),
        );
    }

    protected toJsonImpl(financials: Financials, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(currencyCostKey, financials.currencyCost)
            .addOptional(currencySellKey, financials.currencySell)
            .addOptional(commissionInclusiveKey, financials.commissionInclusive)
            .addOptional(commissionPercentKey, financials.commissionPercent)
            .addIterableSerializable(ratesKey, financials.rates, FinancialsRateJsonSerialiser.instance)
            .addOptional(costKey, financials.cost)
            .addOptional(priceKey, financials.sell);
    }
}
