import {None, Option, Some} from 'funfix-core';
import {
    caseSensitiveKey,
    fieldKey,
    JsonBuilder,
    parseBoolean,
    parseString,
    SimpleJsonSerializer,
    StringSearchType,
    StringUtils,
    textKey,
    typeKey,
} from '../core';
import {SearchRadius} from './lat-long-location';
import {Product, ProductSearchField} from './product';

export type ProductCompanyStringSearchField =
    'Company:Name'
    | 'Company:City'
    | 'Company:Country'
    | 'Company:State'
    | 'Company:Type'
    | 'Company:Id';

export type ProductAndCompanySearchField = ProductSearchField | ProductCompanyStringSearchField;

export type ProductSearchType = StringSearchType | SearchRadius;

export class ProductSearch {
    constructor(
        readonly text: Option<string>,
        readonly type: ProductSearchType = 'Exact',
        readonly field: ProductAndCompanySearchField = 'Name',
        readonly caseSensitive: boolean = false) {
    }

    static parseCompanySearchField(s: string): Option<ProductAndCompanySearchField> {
        switch (s) {
            case 'Company:Name':
            case 'Company:City':
            case 'Company:Country':
            case 'Company:State':
            case 'Company:Type':
            case 'Company:Id':
                return Some(s as ProductCompanyStringSearchField);
            default:
                return None;
        }
    }

    static parseProductCompanyAndSearchField(s: string): Option<ProductAndCompanySearchField> {
        return Product.parseProductSearchField(s)
            .orElse(ProductSearch.parseCompanySearchField(s));
    }
}

export class ProductSearchJsonSerializer extends SimpleJsonSerializer<ProductSearch> {
    static instance: ProductSearchJsonSerializer = new ProductSearchJsonSerializer();

    fromJsonImpl(obj: any): ProductSearch {
        return new ProductSearch(
            parseString(obj[textKey]),
            parseString(obj[typeKey]).flatMap(x => StringUtils.parseStringSearchType(x)).getOrElse('Exact'),
            parseString(obj[fieldKey]).flatMap(x => ProductSearch.parseProductCompanyAndSearchField(x)).getOrElse('Name'),
            parseBoolean(obj[caseSensitiveKey]).getOrElse(false),
        );
    }

    protected toJsonImpl(search: ProductSearch, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(textKey, search.text)
            .add(typeKey, search.type)
            .add(fieldKey, search.field)
            .add(caseSensitiveKey, search.caseSensitive);
    }
}
