import {Some} from 'funfix-core';
import {
    JsonBuilder,
    nameKey,
    OptionUtils,
    regionKey,
    SimpleJsonSerializer,
    stateIdKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {DbState} from './db-state';

export class DbDestination implements Validatable {
    constructor(
        readonly name: string,
        readonly state: number = DbState.toBeDefinedStateId,
        readonly region: number = 50, // To be defined
    ) { }

    validate(): ValidationResult {

        return OptionUtils.toList(
            Some(ValidationUtils.validateVarchar('name', this.name, 50)),
            Some(ValidationUtils.validateInt('state', this.state.toString())),
            Some(ValidationUtils.validateInt('region', this.region.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbDestinationJsonSerializer extends SimpleJsonSerializer<DbDestination> {
    static instance: DbDestinationJsonSerializer = new DbDestinationJsonSerializer();

    fromJsonImpl(obj: any): DbDestination {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(destination: DbDestination, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(nameKey, destination.name)
            .add(stateIdKey, destination.state)
            .add(regionKey, destination.region);
    }
}
