import { None, Option } from 'funfix-core';
import {
    cityKey,
    countryKey,
    JsonBuilder,
    parseNumber,
    SimpleJsonSerializer,
    stateKey,
} from '../core';

// Note: These are expected to match DB ID fields
export class LocationIds {
    constructor(
        readonly cityId: Option<number> = None,
        readonly stateId: Option<number> = None,
        readonly countryId: Option<number> = None) {
    }

    getCityId(): Option<number> {
        return this.cityId;
    }

    getCountryId(): Option<number> {
        return this.countryId;
    }

    getStateId(): Option<number> {
        return this.stateId;
    }

    isEmpty(): boolean {
        return this.getCityId().isEmpty() && this.getStateId().isEmpty() && this.getCountryId().isEmpty();
    }
}

export class LocationIdsJsonSerializer extends SimpleJsonSerializer<LocationIds> {
    static instance: LocationIdsJsonSerializer = new LocationIdsJsonSerializer();
    protected fromJsonImpl(json: any): LocationIds {
        return new LocationIds(
            parseNumber(json[cityKey]),
            parseNumber(json[stateKey]),
            parseNumber(json[countryKey]));
    }

    protected toJsonImpl(value: LocationIds, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(cityKey, value.getCityId())
            .addOptional(stateKey, value.getStateId())
            .addOptional(countryKey, value.getCountryId());
    }

}
