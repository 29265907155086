import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    chineseKey,
    danishKey,
    dutchKey,
    frenchKey,
    germanKey,
    italianKey,
    JsonBuilder,
    koreanKey,
    OptionUtils,
    polishKey,
    SimpleJsonSerializer,
    spanishKey,
    swedishKey,
} from '../core';
import {Descriptions, DescriptionsJsonSerializer} from './descriptions';

export class DescriptionTranslations {
    constructor(
        public french: Option<Descriptions> = None,
        public spanish: Option<Descriptions> = None,
        public danish: Option<Descriptions> = None,
        public italian: Option<Descriptions> = None,
        public dutch: Option<Descriptions> = None,
        public swedish: Option<Descriptions> = None,
        public chinese: Option<Descriptions> = None,
        public german: Option<Descriptions> = None,
        public korean: Option<Descriptions> = None,
        public polish: Option<Descriptions> = None,
    ) {
    }

    getChinese(): Option<Descriptions> {
        return this.chinese;
    }

    getDanish(): Option<Descriptions> {
        return this.danish;
    }

    getDutch(): Option<Descriptions> {
        return this.dutch;
    }

    getFrench(): Option<Descriptions> {
        return this.french;
    }

    getGerman(): Option<Descriptions> {
        return this.german;
    }

    getItalian(): Option<Descriptions> {
        return this.danish;
    }

    getKorean(): Option<Descriptions> {
        return this.korean;
    }

    getPolish(): Option<Descriptions> {
        return this.polish;
    }

    getSpanish(): Option<Descriptions> {
        return this.spanish;
    }

    // Eg ['en', 'es']
    getSupportedLanguageCodes(): List<string> {
        return OptionUtils.toList(
            this.french.map(x => 'fr'),
            this.spanish.map(x => 'es'),
            this.danish.map(x => 'da'),
            this.italian.map(x => 'it'),
            this.dutch.map(x => 'nl'),
            this.swedish.map(x => 'sv'),
            this.chinese.map(x => 'zh'),
            this.german.map(x => 'de'),
            this.polish.map(x => 'pl'),
            this.korean.map(x => 'ko'),
        );
    }

    getSwedish(): Option<Descriptions> {
        return this.swedish;
    }
}

export class DescriptionTranslationsJsonSerializer extends SimpleJsonSerializer<DescriptionTranslations> {
    static instance: DescriptionTranslationsJsonSerializer = new DescriptionTranslationsJsonSerializer();

    fromJsonImpl(obj: any): DescriptionTranslations {
        return new DescriptionTranslations(
            DescriptionsJsonSerializer.instance.fromJson(obj[frenchKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[spanishKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[danishKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[italianKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[dutchKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[swedishKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[chineseKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[germanKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[koreanKey]),
            DescriptionsJsonSerializer.instance.fromJson(obj[polishKey]));
    }

    protected toJsonImpl(value: DescriptionTranslations, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(frenchKey, value.getFrench(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(spanishKey, value.getSpanish(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(danishKey, value.getDanish(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(italianKey, value.getItalian(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(dutchKey, value.getDutch(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(swedishKey, value.getSwedish(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(chineseKey, value.getChinese(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(germanKey, value.getGerman(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(koreanKey, value.getKorean(), DescriptionsJsonSerializer.instance)
            .addOptionalSerializable(polishKey, value.getPolish(), DescriptionsJsonSerializer.instance);
    }
}
