import {Either, Left, None, Option} from 'funfix-core';
import {Collection, List} from 'immutable';
import {OptionUtils} from '../';
import {EitherUtils} from './either-utils';
import {JsonBuilder} from './json-builder';
import {notImplemented} from './utils';

/**
 * Json Serializer
 *
 * A = fromJson type.
 * B = toJson type.
 *
 * This distinction allows supertypes to call fromJson and return the right type.
 */
export abstract class JsonSerializer<A, B extends A> {

    fromJson(json: any): Option<A> {
        return Option.of(json)
            .filter(v => typeof v === 'object')
            .filter(v => v !== {})
            .map(v => this.fromJsonImpl(v));
    }

    fromJsonArray(obj: object[] | undefined): List<A> {
        return OptionUtils.toList(Option.of(obj))
            .flatMap(o => OptionUtils.toList(...o.map(x => this.fromJson(x))));
    }

    protected abstract fromJsonImpl(json: any): A;

    fromJsonString(str: string): Option<A> {
        try {
            return Option.of(JSON.parse(str))
                .flatMap(v => this.fromJson(v));
        } catch (err) {
            console.warn(`Failed to decode json string ${str.substr(0, 40)}...`);
            console.warn(err);
            return None;

        }
    }

    fromJsonStringOrObjectToEither<E>(json: any, left: E): Either<E, A> {
        if (typeof json === 'string') {
            return this.fromJsonStringToEither(json, left);
        } else if (typeof json === 'object') {
            return this.fromJsonToEither(json, left);
        }
        return Left(left);
    }

    fromJsonStringToEither<E>(json: string, left: E): Either<E, A> {
        return EitherUtils.toEither(this.fromJsonString(json), left);
    }

    fromJsonToEither<E>(json: any, left: E): Either<E, A> {
        return EitherUtils.toEither(this.fromJson(json), left);
    }

    toJson(value: B): object {
        return this.toJsonImpl(value, new JsonBuilder()).build();
    }

    toJsonArray(collection: Collection<any, B>): ReadonlyArray<object> {
        return collection.map(v => this.toJson(v))
            .filter(v => JSON.stringify(v) !== '{}')
            .toArray();
    }

    toJsonArrayString(collection: Collection<any, B>): string {
        return JSON.stringify(this.toJsonArray(collection), null, 2);
    }

    /**
     * Having toJsonImpl allows for superclasses to add their own fields
     * by calling super.toJsonImpl in the subclass first.
     */
    protected abstract toJsonImpl(value: B, builder: JsonBuilder): JsonBuilder;

    toJsonString(value: B): string {
        return JSON.stringify(this.toJson(value), null, 2);
    }
}

/**
 * JSON Serializer whose input and output are the same type.
 */
export abstract class SimpleJsonSerializer<T> extends JsonSerializer<T, T> {
    sanitizeJson(json: any): Option<object> {
        return this.fromJson(json)
            .map(v => this.toJson(v));
    }
}

export class EmptyJsonSerializer<T> extends SimpleJsonSerializer<T> {
    constructor() {
        super();
    }

    static instance: EmptyJsonSerializer<never> = new EmptyJsonSerializer();

    fromJsonImpl(json: object): T {
        console.log('Empty Json Serializer!');
        return notImplemented();
    }

    protected toJsonImpl(value: T, builder: JsonBuilder): JsonBuilder {
        console.log('Empty Json Serializer!');
        return notImplemented();
    }
}
