import {Option} from 'funfix-core';
import {List} from 'immutable';
import {
    JsonBuilder,
    optFakeIdxKey,
    parentFakeIdxKey,
    productIdKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
} from '../core';
import {Product} from './product';

export class DbProductOptionSubproductMapping implements Validatable {
    constructor(
        readonly productId: Option<number>,
        readonly parentFakeIdx: number,
        readonly optionFakeIdx: number) {
    }

    static buildFromProduct(product: Product, parentFakeIdx: number): List<DbProductOptionSubproductMapping> {
        return product.getOptions()
            .flatMap((s, idx) => s.subproducts.map(x => new DbProductOptionSubproductMapping(x.getId(), parentFakeIdx, idx)));
    }

    static buildFromProducts(products: List<Product>): List<DbProductOptionSubproductMapping> {
        return products.flatMap((x, idx) => DbProductOptionSubproductMapping.buildFromProduct(x, idx));
    }

    validate(): ValidationResult {
        return ValidationResult.empty;
    }

}

export class DbProductOptionSubproductMappingJsonSerializer extends SimpleJsonSerializer<DbProductOptionSubproductMapping> {
    static instance: DbProductOptionSubproductMappingJsonSerializer = new DbProductOptionSubproductMappingJsonSerializer();

    fromJsonImpl(obj: any): DbProductOptionSubproductMapping {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(mapping: DbProductOptionSubproductMapping, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(productIdKey, mapping.productId)
            .add(optFakeIdxKey, mapping.optionFakeIdx)
            .add(parentFakeIdxKey, mapping.parentFakeIdx);
    }
}
