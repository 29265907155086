import {Either} from 'funfix-core';
import {List, Set} from 'immutable';
import {Image, ImageJsonSerializer} from '../models';
import {ApiBase} from './api-base';

export class DidgigoImageApi extends ApiBase {

    constructor(apiServer: string) {
        super(apiServer);
    }

    async saveAgentPhoto(image: string): Promise<Either<string, Image>> {
        return this.processPostApiRequestSerialized(`agent/photo/replace`, ImageJsonSerializer.instance, {image}, '');
    }

    async saveOptionImages(images: Set<string>): Promise<Either<string, List<Image>>> {
        return this.processPostApiRequestListSerialized(`product-option/images/add`, ImageJsonSerializer.instance, {images: images.toArray()}, '');
    }

    async saveProductImages(images: Set<string>): Promise<Either<string, List<Image>>> {
        return this.processPostApiRequestListSerialized(`product/images/add`, ImageJsonSerializer.instance, {images: images.toArray()}, '');
    }

    async saveProductLogo(image: string): Promise<Either<string, Image>> {
        return this.processPostApiRequestSerialized(`product/logo/replace`, ImageJsonSerializer.instance, {image}, '');
    }

    async saveProductMap(image: string): Promise<Either<string, Image>> {
        return this.processPostApiRequestSerialized(`product/map/replace`, ImageJsonSerializer.instance, {image}, '');
    }

    async saveProposalCompanyLogo(image: string): Promise<Either<string, Image>> {
        return this.processPostApiRequestSerialized(`proposal-company/logo/replace`, ImageJsonSerializer.instance, {image}, '');
    }

    async saveSupplierLogo(image: string): Promise<Either<string, Image>> {
        return this.processPostApiRequestSerialized(`supplier/logo/replace`, ImageJsonSerializer.instance, {image}, '');
    }

    async saveWelcomeImage(image: string): Promise<Either<string, Image>> {
        return this.processPostApiRequestSerialized(`welcome/replace`, ImageJsonSerializer.instance, {image}, '');
    }
}
