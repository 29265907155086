import { None, Option } from 'funfix-core';
import { Moment } from 'moment';
import {
    descriptionKey,
    JsonBuilder,
    locationKey,
    parseDate,
    parseString,
    timeKey,
} from '../core';
import { SimpleJsonSerializer } from '../core/json-serializer';
import { PhysicalLocation, PhysicalLocationJsonSerializer } from './physical-location';

export class Waypoint {
    constructor(
        readonly time: Option<Moment> = None,
        readonly location: Option<PhysicalLocation> = None,
        readonly description: Option<string> = None) { }

    getDescription(): Option<string> {
        return this.description;
    }

    getLocation(): Option<PhysicalLocation> {
        return this.location;
    }

    getTime(): Option<Moment> {
        return this.time;
    }

}

export class WaypointJsonSerializer extends SimpleJsonSerializer<Waypoint> {

    static instance: WaypointJsonSerializer = new WaypointJsonSerializer();

    fromJsonImpl(obj: any): Waypoint {
        return new Waypoint(
            parseDate(obj[timeKey]),
            PhysicalLocationJsonSerializer.instance.fromJson(obj[locationKey]),
            parseString(obj[descriptionKey]));
    }

    protected toJsonImpl(meetingPoint: Waypoint, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalDate(timeKey, meetingPoint.time)
            .addOptionalSerializable(locationKey, meetingPoint.location, PhysicalLocationJsonSerializer.instance)
            .addOptional(descriptionKey, meetingPoint.description);
    }
}
