import { None, Option } from 'funfix-core';
import {
    cityKey, coreImageIdKey,
    countryKey,
    JsonBuilder,
    parseNumber, parseString,
    SimpleJsonSerializer,
    stateKey, uriKey,
} from '../core';

// Note: These are expected to match DB ID fields
export class ImageToProcessMetadata {
    constructor(
        readonly coreImageId: Option<number> = None,
        readonly uri: Option<string> = None) {
    }
}

export class ImageToProcessMetadataJsonSerializer extends SimpleJsonSerializer<ImageToProcessMetadata> {
    static instance: ImageToProcessMetadataJsonSerializer = new ImageToProcessMetadataJsonSerializer();
    protected fromJsonImpl(json: any): ImageToProcessMetadata {
        return new ImageToProcessMetadata(
            parseNumber(json[coreImageIdKey]),
            parseString(json[uriKey]));
    }

    protected toJsonImpl(value: ImageToProcessMetadata, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(coreImageIdKey, value.coreImageId)
            .addOptional(uriKey, value.uri);
    }

}
