import {None, Option} from 'funfix-core';
import {descriptionKey, imageKey, JsonBuilder, parseString, SimpleJsonSerializer, titleKey} from '../core';
import {Image, ImageJsonSerializer} from './image';

/**
 * Welcome information, to be displayed as the initial page in a proposal
 */
export class Welcome {
    constructor(
        readonly title: Option<string> = None,
        readonly description: Option<string> = None,
        readonly image: Option<Image> = None) { }

    merge(other: Welcome): Welcome {
        return new Welcome(
            this.title.orElse(other.title),
            this.description.orElse(other.description),
            this.image.orElse(other.image));
    }
}

export class WelcomeJsonSerializer extends SimpleJsonSerializer<Welcome> {
    static instance: WelcomeJsonSerializer = new WelcomeJsonSerializer();

    fromJsonImpl(obj: any): Welcome {
        return new Welcome(
            parseString(obj[titleKey]),
            parseString(obj[descriptionKey]),
            ImageJsonSerializer.instance.fromJson(obj[imageKey]));
    }

    protected toJsonImpl(value: Welcome, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(titleKey, value.title)
            .addOptional(descriptionKey, value.description)
            .addOptionalSerializable(imageKey, value.image, ImageJsonSerializer.instance);
    }
}
