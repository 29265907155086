import { None, Option, Some } from 'funfix-core';
import { List } from 'immutable';
import * as _s from 'underscore.string';
import {StringUtils} from './string-utils';

export class Url {

    private constructor(readonly url: string) {
    }

    static buildFromParts(
        protocol: Option<string>,
        path: Option<string>,
        query: Option<string>,
        fragment: Option<string>,
    ): Option<Url> {
        let res = '';
        protocol.forEach(x => res += (x + '://'));
        path.forEach(x => res += x);
        query.forEach(x => res += ('?' + x));
        fragment.forEach(x => res += ('#' + x));
        return Option.of(res)
            .filter(x => x.trim() !== '')
            .map(x => new Url(x));
    }

    // TODO: Check validity
    static isValidUrl(s: string): boolean {
        return true;
    }

    static parse(s: string): Option<Url> {
        if (Url.isValidUrl(s)) {
            return Option.of(s).map(x => new Url(x));
        } else {
            return None;
        }
    }

    appendPath(path: string): Url {
        // could be just filename, or just domain, cant tell... eg foo.jpg or didgigo.com
        // Assume filename
        if (this.hasDomain() && this.getFileExtension().nonEmpty()) {
            return this.withPath(path + '/' + this.getFileName());
        }

        if (this.isFileNameOnly()) {
            return Url.parse(path + '/' + this.getFileName()).get();
        }

        return this.withPath(this.getPathWithoutFileName() + '/' + path + '/' + this.getFileName());
    }

    extendFile(extension: string): Url {
        return this.withFileNameKeepExtension(this.getFileNameWithoutExtension() + extension);
    }

    private getDomain(): string {
        if (this.hasDomain()) {
            return this.getPath().substring(0, this.getDomainIdx() + 1);
        }
        return this.getPath();
    }

    private getDomainIdx(): number {
        return this.getPath().indexOf('/');
    }

    getFileExtension(): Option<string> {
        return this.getSuffix();
    }

    getFileName(): string {
        const idx = this.getFileNameIdx();
        return this.getPath().substring(idx + 1);
    }

    private getFileNameIdx(): number {
        return this.getPath().lastIndexOf('/');
    }

    getFileNameWithoutExtension(): string {
        if (this.isFile()) {
            return this.getFileName().substring(0, this.getSuffixIdx());
        } else {
            return this.getFileName();
        }
    }

    getFragment(): Option<string> {
        const fragIdx = this.getFragmentIndex();
        const urlLength = this.url.length;

        // Check that the url does not end in a hash too.
        if (fragIdx > -1 && urlLength !== fragIdx + 1) {
            return Option.of(this.url.substring(fragIdx + 1));
        } else {
            return None;
        }
    }

    private getFragmentIndex(): number {
        return this.url.indexOf('#');
    }

    getHref(): string {
        return this.url;
    }

    getHrefWithoutFile(): string {
        let res = '';
        this.getProtocol().forEach(x => res += (x + '://'));
        res += this.getPathWithoutFileName();
        return res;
    }

    getOrigin(): Option<string> {
        return this.getProtocol().map(p => p + '://' + this.getDomain())
            .orElse(Some(this.getDomain()));
    }

    getPath(): string {
        if (this.hasProtocol()) {
            return this.getUrlStringWithoutFragmentOrQuery().substring(this.getProtocolSepIdx() + 3);
        } else {
            return this.getUrlStringWithoutFragmentOrQuery();
        }
    }

    getPathWithoutDomain(): string {
        if (this.hasDomain()) {
            return this.getPath().substring(this.getDomainIdx() + 1);
        }
        return this.getPath();
    }

    getPathWithoutFileName(): string {
        if (this.hasFileName()) {
            return this.getPath().substring(0, this.getFileNameIdx());
        }
        return this.getPath();
    }

    getProtocol(): Option<string> {
        const pIdx = this.getProtocolSepIdx();
        const urlWithNoFrag = this.getUrlStringWithoutFragmentOrQuery();

        if (pIdx > -1 && urlWithNoFrag.length !== pIdx + 1) {
            return Option.of(urlWithNoFrag.substring(0, pIdx));
        } else {
            return None;
        }
    }

    private getProtocolSepIdx(): number {
        return this.url.indexOf('://');
    }

    getQuery(): Option<string> {
        const qIdx = this.getQueryIndex();
        const urlWithNoFrag = this.getUrlStringWithoutFragment();

        // Check that the url does not end in a hash too.
        if (qIdx > -1 && urlWithNoFrag.length !== qIdx + 1) {
            return Option.of(urlWithNoFrag.substring(qIdx + 1));
        } else {
            return None;
        }
    }

    private getQueryIndex(): number {
        return this.url.indexOf('?');
    }

    getSegments(): List<string> {
        return List(this.getPathWithoutFileName().split('/'));
    }

    getSuffix(): Option<string> {
        const idx = this.getSuffixIdx();
        if (idx > -1) {
            return Some(this.getFileName().substring(idx + 1));
        }
        return None;
    }

    private getSuffixIdx(): number {
        return this.getFileName().lastIndexOf('.');
    }

    private getUrlStringWithoutFragment(): string {
        if (this.hasFragment()) {
            return this.url.substring(0, this.getFragmentIndex());
        } else {
            return this.url;
        }
    }

    private getUrlStringWithoutFragmentOrQuery(): string {
        if (this.hasQuery()) {
            return this.getUrlStringWithoutFragment().substring(0, this.getQueryIndex());
        } else {
            return this.getUrlStringWithoutFragment();
        }
    }

    private hasDomain(): boolean {
        return this.getDomainIdx() > -1 &&
            this.getPath().substring(0, this.getDomainIdx() + 1).includes('.');
    }

    hasFileName(): boolean {
        return this.getFileNameIdx() > -1;
    }

    hasFragment(): boolean {
        return this.getFragmentIndex() > -1;
    }

    hasProtocol(): boolean {
        return this.getProtocolSepIdx() > -1;
    }

    hasQuery(): boolean {
        return this.getQueryIndex() > -1;
    }

    hasSuffix(): boolean {
        return this.getSuffixIdx() > -1;
    }

    isAbsolute(): boolean {
        return this.hasProtocol();
    }

    isDirectory(): boolean {
        return !this.isFile();
    }

    isFile(): boolean {
        return this.hasSuffix();
    }

    isFileNameOnly(): boolean {
        return this.getFileName() === this.getHref();
    }

    isRelative(): boolean {
        return !this.isAbsolute();
    }

    // After domain, before rest of path
    prependPath(path: string): Url {
        if (this.isFileNameOnly()) {
            return Url.parse(path + '/' + this.getFileName()).get();
        }

        if (!this.hasDomain()) {
            return this.withPath(path + '/' + this.getPathWithoutDomain());
        }

        return this.withPath(this.getDomain() + '/' + path + '/' + this.getPathWithoutDomain());
    }

    /**
     * Example:
     *
     * other www.google.com
     * this www.google.com/foo.jpg
     * result /foo.jpg
     */
    relativeTo(other: Url): Url {
        return Url.parse(StringUtils.stripPrefix(this.getHref(), other.getHref())).get();
    }

    withDomain(domain: string): Url {
        if (domain.endsWith('/')) {
            return this.withPath(domain + this.getPathWithoutDomain());
        }
        return this.withPath(domain + '/' + this.getPathWithoutDomain());
    }

    withFileExtension(ext: string): Url {
        return this.withFileName(this.getFileNameWithoutExtension() + '.' + ext);
    }

    withFileName(fileName: string): Url {
        return this.withPath(this.getPathWithoutFileName() + '/' + fileName);
    }

    withFileNameKeepExtension(fname: string): Url {
        if (this.hasSuffix()) {
            return this.withFileName(fname + '.' + this.getSuffix().get());
        }
        return this.withFileName(fname);
    }

    withOrigin(origin: string): Url {
        const url = new Url(origin);
        if (url.hasProtocol()) {
            return this.withProtocol(url.getProtocol().get()).withDomain(url.getDomain());
        }
        return this.withDomain(origin);
    }

    withPath(path: string): Url {
        return Url.buildFromParts(
            this.getProtocol(),
            Some(path),
            this.getQuery(),
            this.getFragment())
            .getOrElse(new Url(this.getPath()));
    }

    withProtocol(protocol: string): Url {
        return Url.buildFromParts(
            Some(protocol),
            Some(this.getPath()),
            this.getQuery(),
            this.getFragment())
            .getOrElse(new Url(this.getPath()));
    }

}
