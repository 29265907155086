import { None, Option } from 'funfix-core';
import { parseNumber } from '../core';
import { JsonBuilder } from '../core/json-builder';
import { maxKey, minKey } from '../core/json-keys';
import { SimpleJsonSerializer } from '../core/json-serializer';

export class Budget {
    constructor(
        readonly max: Option<number> = None,
        readonly min: Option<number> = None) { }
}

export class BudgetJsonSerializer extends SimpleJsonSerializer<Budget> {
    static instance: BudgetJsonSerializer = new BudgetJsonSerializer();

    fromJsonImpl(obj: any): Budget {
        return new Budget(
            parseNumber(obj[maxKey]),
            parseNumber(obj[minKey]));
    }

    protected toJsonImpl(budget: Budget, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(maxKey, budget.max)
            .addOptional(minKey, budget.min);
    }
}
