import {List} from 'immutable';
import {CollectionUtils} from '../core';

export type Rating = 'Black' | 'Bronze' | 'Silver' | 'Gold';

export class RatingUtils {

    static average(ratings: List<Rating>): Rating {
        if (ratings.isEmpty()) {
            return 'Gold';
        }

        return RatingUtils.convertFromNumber(CollectionUtils.average(ratings.map(r => RatingUtils.convertToNumber(r))));
    }

    static convertFromNumber(r: number): Rating {
        switch (Math.floor(r)) {
            case 0:
                return 'Black';
            case 1:
                return 'Bronze';
            case 2:
                return 'Silver';
            case 3:
                return 'Gold';
            default:
                return 'Black';
        }
    }

    static convertToNumber(r: Rating): number {
        switch (r) {
            case 'Black':
                return 0;
            case 'Bronze':
                return 1;
            case 'Silver':
                return 2;
            case 'Gold':
                return 3;
        }
    }

    static isAtLeastThen(current: Rating, other: Rating): boolean {
        const c = RatingUtils.convertToNumber(current);
        const o = RatingUtils.convertToNumber(other);
        return c === o;
    }

    static isGreaterThen(current: Rating, other: Rating): boolean {
        const c = RatingUtils.convertToNumber(current);
        const o = RatingUtils.convertToNumber(other);
        return c > o;
    }

    static min(ratings: List<Rating>): Rating {
        if (ratings.contains('Black')) {
            return 'Black';
        } else if (ratings.contains('Bronze')) {
            return 'Bronze';
        } else if (ratings.contains('Silver')) {
            return 'Silver';
        } else if (ratings.contains('Gold')) {
            return 'Gold';
        }

        return 'Black';
    }

}
