import {None, Option} from 'funfix-core';
import {
    aboutKey,
    entryOrderKey,
    exclusionsKey,
    idKey,
    inclusionsKey,
    JsonBuilder,
    parseBoolean,
    parseNumber,
    parseString,
    priceDescriptionKey,
    priceHeaderKey,
    showAgentIntroKey,
    showFlightDetailsKey,
    showNotesSummaryKey,
    SimpleJsonSerializer,
    termsKey,
    welcomeKey,
} from '../core';
import {ProposalLike} from './proposal';
import {Welcome, WelcomeJsonSerializer} from './welcome';

export class ProposalTemplateDefaults extends ProposalLike {

    constructor(
        readonly id: Option<number> = None,
        readonly welcome: Option<Welcome> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly priceHeader: Option<string> = None,
        readonly priceDescription: Option<string> = None,
        readonly terms: Option<string> = None,
        readonly about: Option<string> = None,
        readonly showAgentIntro: Option<boolean> = None,
        readonly showFlightDetails: Option<boolean> = None,
        readonly showNotesSummary: Option<boolean> = None,
        readonly entryOrder: Option<string> = None,
    ) {
        super();
    }

    getAbout(): Option<string> {
        return this.about;
    }

    getEntryOrder(): Option<string> {
        return this.entryOrder;
    }

    getExclusions(): Option<string> {
        return this.exclusions;
    }

    getInclusions(): Option<string> {
        return this.inclusions;
    }

    getPriceDescription(): Option<string> {
        return this.priceDescription;
    }

    getPriceHeader(): Option<string> {
        return this.priceHeader;
    }

    getShowAgentIntro(): Option<boolean> {
        return this.showAgentIntro;
    }

    getShowFlightDetails(): Option<boolean> {
        return this.showFlightDetails;
    }

    getShowNotesSummary(): Option<boolean> {
        return this.showNotesSummary;
    }

    getTerms(): Option<string> {
        return this.terms;
    }

    getWelcome(): Option<Welcome> {
        return this.welcome;
    }

}

export class ProposalTemplateDefaultsJsonSerializer extends SimpleJsonSerializer<ProposalTemplateDefaults> {
    static instance: ProposalTemplateDefaultsJsonSerializer = new ProposalTemplateDefaultsJsonSerializer();

    fromJsonImpl(obj: any): ProposalTemplateDefaults {
        return new ProposalTemplateDefaults(
            parseNumber(obj[idKey]),
            WelcomeJsonSerializer.instance.fromJson(obj[welcomeKey]),
            parseString(obj[inclusionsKey]),
            parseString(obj[exclusionsKey]),
            parseString(obj[priceHeaderKey]),
            parseString(obj[priceDescriptionKey]),
            parseString(obj[termsKey]),
            parseString(obj[aboutKey]),
            parseBoolean(obj[showAgentIntroKey]),
            parseBoolean(obj[showFlightDetailsKey]),
            parseBoolean(obj[showNotesSummaryKey]),
            parseString(obj[entryOrderKey]),
        );
    }

    toJsonImpl(value: ProposalTemplateDefaults, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(welcomeKey, value.welcome, WelcomeJsonSerializer.instance)
            .addOptional(idKey, value.id)
            .addOptional(inclusionsKey, value.inclusions)
            .addOptional(exclusionsKey, value.exclusions)
            .addOptional(priceHeaderKey, value.priceHeader)
            .addOptional(priceDescriptionKey, value.priceDescription)
            .addOptional(termsKey, value.terms)
            .addOptional(aboutKey, value.about)
            .addOptional(showAgentIntroKey, value.showAgentIntro)
            .addOptional(showFlightDetailsKey, value.showFlightDetails)
            .addOptional(showNotesSummaryKey, value.showNotesSummary)
            .addOptional(entryOrderKey, value.entryOrder);
    }

}
