import {Either, None, Option, Some} from 'funfix-core';
import {
    aboutKey,
    agentIdKey,
    companyIdKey,
    currencyIdKey,
    EitherUtils,
    entrySortingKey,
    estimatedBudgetMaxKey,
    estimatedBudgetMinKey,
    exclusionsKey,
    finishDateTimeKey,
    inclusionsKey,
    JsonBuilder,
    languageIdKey,
    nextActionDateKey,
    nextActionIdKey,
    notesKey,
    numberAdultsKey,
    numberKidsKey,
    OptionUtils,
    personIdKey,
    presentAsKey,
    priceDescriptionKey,
    priceHeaderKey,
    proposalDescriptionKey,
    proposalIdKey,
    proposalTitleKey,
    proposalTypeIdKey,
    referenceKey,
    SimpleJsonSerializer,
    startAddressKey,
    startDateTimeKey,
    statusIdKey,
    templateIdKey,
    termsKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
    welcomeDescriptionKey,
    welcomeImageKey,
    welcomeTitleKey,
} from '../core';
import {EnumConstantMap} from './enum-constant';
import {Proposal, ProposalLike} from './proposal';

export class DbProposal implements Validatable {
    constructor(
        readonly proposalId: Option<number> = None,
        readonly companyId: Option<number> = None,
        readonly templateId: Option<number> = None,
        readonly agentId: Option<number> = None,
        readonly personId: number,
        readonly languageId: Option<number> = None,
        readonly currencyId: Option<number>,
        readonly proposalTypeId: number = 3, // 3: Default
        readonly statusId: Option<number> = None,
        readonly nextActonId: Option<number> = None,
        readonly presentAsProposalCompanyId: Option<number> = None,
        readonly nextActionDate: Option<string> = None,
        readonly proposalTitle: Option<string> = None,
        readonly proposalDescription: Option<string> = None,
        readonly startDatetime: Option<string> = None,
        readonly finishDatetime: Option<string> = None,
        readonly startAddress: Option<string> = None,
        readonly estimatedBudgetMin: Option<number> = None,
        readonly estimatedBudgetMax: Option<number> = None,
        readonly numberKids: Option<number> = None,
        readonly numberAdults: Option<number> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly notes: Option<string> = None,
        readonly welcomeTitle: Option<string> = None,
        readonly welcomeDescription: Option<string> = None,
        readonly welcomeImage: Option<string> = None,
        readonly about: Option<string> = None,
        readonly reference: Option<string> = None,
        readonly entrySorting: Option<string> = Some(Proposal.defaultEntrySorting),
        readonly priceHeader: Option<string> = None,
        readonly priceDescription: Option<string> = None,
        readonly termsAndConditions: Option<string> = None,
    ) {
    }

    static fromProposal(
        proposal: ProposalLike,
        currencyMap: EnumConstantMap,
        languageMap: EnumConstantMap,
        proposalTypeMap: EnumConstantMap,
    ): Either<string, DbProposal> {
        const companyId = proposal.getCompany()
            .flatMap(company => company.getId());
        const agentId = proposal.getAgent()
            .flatMap(agent => agent.id);
        const statusId = proposal.getStatus()
            .flatMap(x => DbProposal.toStatusId(x));
        const proposalTypeId = proposalTypeMap.getIdByOptName(proposal.getProposalType());

        return Either.map3(
            EitherUtils.toEither(companyId, 'FATAL ERROR: Missing company id when creating proposal'),
            EitherUtils.toEither(agentId, 'FATAL ERROR: Missing agent id when creating proposal'),
            EitherUtils.toEither(statusId, 'FATAL ERROR: Missing status id when creating proposal'),
            () =>
                new DbProposal(
                    proposal.getId(),
                    companyId,
                    proposal.getTemplateId(),
                    agentId,
                    0, // This needs to be assigned an actual personId, 0 is just a placeholder
                    languageMap.getIdByOptName(proposal.getLanguage()),
                    currencyMap.getIdByOptName(proposal.getCurrency()),
                    proposalTypeId.getOrElse(3),
                    statusId,
                    None, // Next action id
                    proposal.getPresentAsCompany()
                        .flatMap(company => company.getId()),
                    None, // Next action date
                    proposal.getTitle(),
                    proposal.getDescription(),
                    proposal.getStartDateTime()
                        .map(start => start.toISOString()), // Start datetime
                    proposal.getEndDateTime()
                        .map(end => end.toISOString()), // End datetime
                    proposal.getStartWaypoint().flatMap(start => start.location
                        .flatMap(l => l.address)),
                    proposal.getBudget()
                        .flatMap(budget => budget.min),
                    proposal.getBudget()
                        .flatMap(budget => budget.max),
                    proposal.getTravellers()
                        .flatMap(travellers => travellers.numberOfKids),
                    proposal.getTravellers()
                        .flatMap(travellers => travellers.numberOfAdults),
                    proposal.getInclusions(),
                    proposal.getExclusions(),
                    proposal.getNotes(),
                    proposal.getWelcome()
                        .flatMap(welcome => welcome.title),
                    proposal.getWelcome()
                        .flatMap(welcome => welcome.description),
                    proposal.getWelcome()
                        .flatMap(welcome => welcome
                            .image
                            .filter(x => !x.isFullUrl())
                            .flatMap(image => image.getHref())),
                    proposal.getAbout(),
                    proposal.getReference(),
                    proposal.getSorting(),
                    proposal.getPriceHeader(),
                    proposal.getPriceDescription(),
                    proposal.getTerms(),
                ),
        );
    }

    static fromProposalForUpdate(
        proposal: ProposalLike,
        currencyMap: EnumConstantMap,
        languageMap: EnumConstantMap,
        proposalTypeMap: EnumConstantMap,
    ): Either<string, DbProposal> {
        const companyId = proposal.getCompany()
            .flatMap(company => company.getId());
        const agentId = proposal.getAgent()
            .flatMap(agent => agent.getId());
        const statusId = proposal.getStatus()
            .flatMap(x => DbProposal.toStatusId(x));
        const proposalTypeId = proposalTypeMap.getIdByOptName(proposal.getProposalType());

        return EitherUtils.toEither(
            Option.of(
                new DbProposal(
                    proposal.getId(),
                    companyId,
                    proposal.getTemplateId(),
                    agentId,
                    0, // This needs to be assigned an actual personId, 0 is just a placeholder
                    languageMap.getIdByOptName(proposal.getLanguage()),
                    currencyMap.getIdByOptName(proposal.getCurrency()),
                    proposalTypeId.getOrElse(3),
                    statusId,
                    None, // Next action id
                    proposal.getPresentAsCompany()
                        .flatMap(company => company.getId()),
                    None, // Next action date
                    proposal.getTitle(),
                    proposal.getDescription(),
                    proposal.getStartDateTime()
                        .map(start => start.toISOString()), // Start datetime
                    proposal.getEndDateTime()
                        .map(end => end.toISOString()), // End datetime
                    proposal.getStartWaypoint()
                        .flatMap(start => start.location)
                        .flatMap(l => l.address),
                    proposal.getBudget()
                        .flatMap(budget => budget.min),
                    proposal.getBudget()
                        .flatMap(budget => budget.max),
                    proposal.getTravellers()
                        .flatMap(travellers => travellers.numberOfKids),
                    proposal.getTravellers()
                        .flatMap(travellers => travellers.numberOfAdults),
                    proposal.getInclusions(),
                    proposal.getExclusions(),
                    proposal.getNotes(),
                    proposal.getWelcome()
                        .flatMap(welcome => welcome.title),
                    proposal.getWelcome()
                        .flatMap(welcome => welcome.description),
                    proposal.getWelcome()
                        .flatMap(welcome => welcome.image)
                        .filter(x => !x.isFullUrl())
                        .flatMap(image => image.getHref()),
                    proposal.getAbout(),
                    proposal.getReference(),
                    proposal.getSorting(),
                    proposal.getPriceHeader(),
                    proposal.getPriceDescription(),
                    proposal.getTerms(),
                ),
            ),
            'Error updating proposal',
        );
    }

    static toStatusId(status: string): Option<number> {
        switch (status) {
            case 'Quote':
                return Some(3);
            case 'Confirmed':
                return Some(37);
            case 'Travelling':
                return Some(42);
            case 'Cancelled':
                return Some(121);
            default:
                return None;
        }
    }

    getTemplateLogo(templateId: number): string {
        switch (templateId) {
            case 355:
                return 'DemoClassicLogo.jpg';
            case 358:
                return 'DemoCurvesLogo.jpg';
            case 356:
                return 'DemoGeometricLogo.jpg';
            case 354:
                return 'DemoModernLogo.jpg';
            case 357:
                return 'DemoRetroLogo.jpg';
            case 369:
                return 'DemoLandscapeLogo.jpg';
            case 413:
                return 'DemoRusticLogo.jpg';
            default:
                return 'DemoClassicLogo.jpg';
        }
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.proposalId.map(n => ValidationUtils.validateInt('proposalId', n.toString())),
            Some(ValidationUtils.validateInt('companyId', this.companyId.toString())),
            this.templateId.map(n => ValidationUtils.validateInt('proposalCompanyTemplateId', n.toString())),
            Some(ValidationUtils.validateInt('agentId', this.agentId.toString())),
            Some(ValidationUtils.validateInt('personId', this.personId.toString())),
            this.languageId.map(n => ValidationUtils.validateInt('languageId', n.toString())),
            this.currencyId.map(n => ValidationUtils.validateInt('currencyId', n.toString())),
            Some(ValidationUtils.validateInt('proposalTypeId', this.proposalTypeId.toString())),
            Some(ValidationUtils.validateInt('statusId', this.statusId.toString())),
            this.nextActonId.map(n => ValidationUtils.validateInt('nextActonId', n.toString())),
            this.presentAsProposalCompanyId.map(n => ValidationUtils.validateInt('presentAsProposalCompanyId', n.toString())),
            this.nextActionDate.map(n => ValidationUtils.validateChar('nextActionDate', n)),
            this.proposalTitle.map(n => ValidationUtils.validateVarchar('proposalTitle', n, 400)),
            this.proposalDescription.map(n => ValidationUtils.validateVarchar('proposalDescription', n, Number.MAX_VALUE)),
            this.startDatetime.map(n => ValidationUtils.validateChar('startDatetime', n)),
            this.finishDatetime.map(n => ValidationUtils.validateChar('finishDatetime', n)),
            this.startAddress.map(n => ValidationUtils.validateVarchar('startAddress', n, 4000)),
            this.estimatedBudgetMin.map(n => ValidationUtils.validateInt('estimatedBudgetMin', n.toString())),
            this.estimatedBudgetMax.map(n => ValidationUtils.validateInt('estimatedBudgetMax', n.toString())),
            this.numberKids.map(n => ValidationUtils.validateInt('numberKids', n.toString())),
            this.numberAdults.map(n => ValidationUtils.validateInt('numberAdults', n.toString())),
            this.inclusions.map(n => ValidationUtils.validateVarchar('inclusions', n, Number.MAX_VALUE)),
            this.exclusions.map(n => ValidationUtils.validateVarchar('exclusions', n, Number.MAX_VALUE)),
            this.notes.map(n => ValidationUtils.validateVarchar('notes', n, Number.MAX_VALUE)),
            this.welcomeTitle.map(n => ValidationUtils.validateVarchar('welcomeTitle', n, 4000)),
            this.welcomeDescription.map(n => ValidationUtils.validateVarchar('welcomeDescription', n, Number.MAX_VALUE)),
            this.welcomeImage.map(n => ValidationUtils.validateVarchar('welcomeImage', n, Number.MAX_VALUE)),
            this.about.map(n => ValidationUtils.validateVarchar('about', n, Number.MAX_VALUE)),
            this.reference.map(n => ValidationUtils.validateVarchar('reference', n, 8000)),
            this.entrySorting.map(n => ValidationUtils.validateVarchar('entrySorting', n, 100)),
            this.priceHeader.map(n => ValidationUtils.validateVarchar('priceHeader', n, 150)),
            this.priceDescription.map(n => ValidationUtils.validateVarchar('priceDescription', n, 200)),
            this.termsAndConditions.map(n => ValidationUtils.validateVarchar('termsAndConditions', n, Number.MAX_VALUE)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBProposalJsonSerializer extends SimpleJsonSerializer<DbProposal> {
    static instance: DBProposalJsonSerializer = new DBProposalJsonSerializer();

    fromJsonImpl(obj: any): DbProposal {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(proposal: DbProposal, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(proposalIdKey, proposal.proposalId)
            .addOptional(companyIdKey, proposal.companyId)
            .addOptional(templateIdKey, proposal.templateId)
            .addOptional(agentIdKey, proposal.agentId)
            .add(personIdKey, proposal.personId)
            .addOptional(languageIdKey, proposal.languageId)
            .addOptional(currencyIdKey, proposal.currencyId)
            .addOptional(statusIdKey, proposal.statusId)
            .add(proposalTypeIdKey, proposal.proposalTypeId)
            .addOptional(nextActionIdKey, proposal.nextActonId)
            .addOptional(presentAsKey, proposal.presentAsProposalCompanyId)
            .addOptional(nextActionDateKey, proposal.nextActionDate)
            .addOptional(proposalTitleKey, proposal.proposalTitle)
            .addOptional(proposalDescriptionKey, proposal.proposalDescription)
            .addOptional(startDateTimeKey, proposal.startDatetime)
            .addOptional(finishDateTimeKey, proposal.finishDatetime)
            .addOptional(startAddressKey, proposal.startAddress)
            .addOptional(estimatedBudgetMinKey, proposal.estimatedBudgetMin)
            .addOptional(estimatedBudgetMaxKey, proposal.estimatedBudgetMax)
            .addOptional(numberKidsKey, proposal.numberKids)
            .addOptional(numberAdultsKey, proposal.numberAdults)
            .addOptional(inclusionsKey, proposal.inclusions)
            .addOptional(exclusionsKey, proposal.exclusions)
            .addOptional(notesKey, proposal.notes)
            .addOptional(welcomeTitleKey, proposal.welcomeTitle)
            .addOptional(welcomeDescriptionKey, proposal.welcomeDescription)
            .addOptional(welcomeImageKey, proposal.welcomeImage)
            .addOptional(aboutKey, proposal.about)
            .addOptional(referenceKey, proposal.reference)
            .addOptional(priceHeaderKey, proposal.priceHeader)
            .addOptional(priceDescriptionKey, proposal.priceDescription)
            .addOptional(termsKey, proposal.termsAndConditions)
            .addOptional(entrySortingKey, proposal.entrySorting);
    }
}
