import {None, Option} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {
    accessorsKey,
    agentKey,
    companyKey,
    createdTimeKey,
    customNotesKey,
    idKey,
    inclusionsKey,
    JsonBuilder,
    modifiedTimeKey,
    parseDate,
    parseListSerializable,
    parseNumber,
    parseSet,
    parseString,
    pdfKey,
    presentAsKey,
    referenceKey,
    SimpleJsonSerializer,
    startKey,
    statusKey,
    titleKey,
} from '../core';
import {CompanyLike} from './company';
import {CompanySummary, CompanySummaryJsonSerializer} from './company-summary';
import {Note, NoteJsonSerializer} from './note';
import {Pdf, PdfJsonSerializer} from './pdf';
import {Person, PersonJsonSerializer} from './person';
import {ProposalLike} from './proposal';
import {Waypoint, WaypointJsonSerializer} from './waypoint';

export class ProposalSummary extends ProposalLike {

    constructor(
        readonly id: Option<number> = None,
        readonly title: Option<string> = None,
        readonly reference: Option<string> = None,
        readonly company: Option<CompanyLike> = None,
        readonly presentAsCompany: Option<CompanyLike> = None,
        readonly start: Option<Waypoint> = None,
        readonly created: Option<Moment> = None,
        readonly modified: Option<Moment> = None,
        readonly agent: Option<Person> = None,
        readonly pdf: Option<Pdf> = None,
        readonly status: Option<string> = None,
        readonly accessors: Set<number> = Set(),
        readonly customNotes: List<Note> = List(),
    ) {
        super();
    }

    static getSummary(proposal: ProposalLike): Option<ProposalSummary> {
        return Option.of(new ProposalSummary(
            proposal.getId(),
            proposal.getTitle(),
            proposal.getReference(),
            proposal.getCompany().map(x => CompanySummary.getSummary(x)),
            proposal.getPresentAsCompany().map(x => CompanySummary.getSummary(x)),
            proposal.getStartWaypoint(),
            proposal.getCreated(),
            proposal.getModified(),
            proposal.getAgent().flatMap(x => x.getSummary()),
            proposal.getPdf().flatMap(x => x.getSummary()),
            proposal.getStatus(),
            proposal.getAccessors(),
            proposal.getCustomNotes().filter(x => x.classifications.contains('API_STATUS')),
        ));
    }

    getAccessors(): Set<number> {
        return this.accessors;
    }

    getAgent(): Option<Person> {
        return this.agent;
    }

    getCompany(): Option<CompanyLike> {
        return this.company;
    }

    getCreated(): Option<Moment> {
        return this.created;
    }

    getCustomNotes(): List<Note> {
        return this.customNotes;
    }

    getId(): Option<number> {
        return this.id;
    }

    getModified(): Option<Moment> {
        return this.modified;
    }

    getPdf(): Option<Pdf> {
        return this.pdf;
    }

    getPresentAsCompany(): Option<CompanyLike> {
        return this.presentAsCompany;
    }

    getReference(): Option<string> {
        return this.reference;
    }

    getStartWaypoint(): Option<Waypoint> {
        return this.start;
    }

    getStatus(): Option<string> {
        return this.status;
    }

    getTitle(): Option<string> {
        return this.title;
    }

    merge(other: ProposalSummary): ProposalSummary {
        return new ProposalSummary(
            this.getId().orElse(other.getId()),
            this.getTitle().orElse(other.getTitle()),
            this.getReference().orElse(other.getReference()),
            this.getCompany().orElse(other.getCompany()),
            this.getPresentAsCompany().orElse(other.getPresentAsCompany()),
            this.getStartWaypoint().orElse(other.getStartWaypoint()),
            this.getCreated().orElse(other.getCreated()),
            this.getModified().orElse(other.getModified()),
            this.getAgent().orElse(other.getAgent()),
            this.getPdf().orElse(other.getPdf()),
            this.getStatus().orElse(other.getStatus()),
        );
    }
}

export class ProposalSummaryJsonSerializer extends SimpleJsonSerializer<ProposalSummary> {
    static instance: ProposalSummaryJsonSerializer = new ProposalSummaryJsonSerializer();

    fromJsonImpl(obj: any): ProposalSummary {
        return new ProposalSummary(
            parseNumber(obj[idKey]),
            parseString(obj[titleKey]),
            parseString(obj[referenceKey]),
            CompanySummaryJsonSerializer.instance.fromJson(obj[companyKey]),
            CompanySummaryJsonSerializer.instance.fromJson(obj[presentAsKey]),
            WaypointJsonSerializer.instance.fromJson(obj[startKey]),
            parseDate(obj[createdTimeKey]),
            parseDate(obj[modifiedTimeKey]),
            PersonJsonSerializer.instance.fromJson(obj[agentKey]),
            PdfJsonSerializer.instance.fromJson(obj[pdfKey]),
            parseString(obj[statusKey]),
            parseSet(obj[accessorsKey], parseNumber),
            parseListSerializable(obj[customNotesKey], NoteJsonSerializer.instance));
    }

    toJsonImpl(proposal: ProposalSummary, builder: JsonBuilder): any {
        return builder
            .addOptional(idKey, proposal.getId())
            .addOptional(titleKey, proposal.getTitle())
            .addOptional(referenceKey, proposal.getReference())
            .addOptional(inclusionsKey, proposal.getInclusions())
            .addOptional(statusKey, proposal.getStatus())
            .addOptionalSerializable(companyKey, proposal.getCompany(), CompanySummaryJsonSerializer.instance)
            .addOptionalSerializable(presentAsKey, proposal.getPresentAsCompany(), CompanySummaryJsonSerializer.instance)
            .addOptionalSerializable(startKey, proposal.getStartWaypoint(), WaypointJsonSerializer.instance)
            .addOptionalDate(createdTimeKey, proposal.getCreated())
            .addOptionalDate(modifiedTimeKey, proposal.getModified())
            .addOptionalSerializable(agentKey, proposal.getAgent(), PersonJsonSerializer.instance)
            .addIterableSerializable(customNotesKey, proposal.getCustomNotes(), NoteJsonSerializer.instance)
            .addIterable(accessorsKey, proposal.accessors);
    }
}
