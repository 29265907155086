import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {
    ComparisonUtils,
    distanceKey,
    durationKey,
    endKey,
    endLatitudeKey,
    endLongitudeKey,
    imageKey,
    instructionsKey,
    JsonBuilder,
    latitudeKey,
    legsKey,
    longitudeKey,
    maneuverKey,
    markersKey,
    OptionUtils,
    orderKey,
    parseListSerializable,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
    startKey,
    startLatitudeKey,
    startLongitudeKey,
    stepsKey,
    travelModeKey,
    zoomKey,
} from '../core';
import {Distance} from './distance';
import {Duration} from './duration';
import {GMap} from './gmap';
import {LatLongLocation, LatLongRoute} from './lat-long-location';
import {MapMarker, MapMarkerJsonSerializer} from './map-marker';

export class DirectionStep {
    constructor(
        readonly order: Option<number> = None,
        readonly startLatitude: Option<number> = None,
        readonly startLongitude: Option<number> = None,
        readonly endLatitude: Option<number> = None,
        readonly endLongitude: Option<number> = None,
        readonly instructions: Option<string> = None,
        readonly maneuver: Option<string> = None,
        readonly distance: Option<Distance> = None,
        readonly duration: Option<Duration> = None,
    ) { }
}

export class DirectionStepJsonSerializer extends SimpleJsonSerializer<DirectionStep> {
    static instance: DirectionStepJsonSerializer = new DirectionStepJsonSerializer();

    fromJsonImpl(obj: any): DirectionStep {
        return new DirectionStep(
            parseNumber(obj[orderKey]),
            parseNumber(obj[startLatitudeKey]),
            parseNumber(obj[startLongitudeKey]),
            parseNumber(obj[endLatitudeKey]),
            parseNumber(obj[endLongitudeKey]),
            parseString(obj[instructionsKey]),
            parseString(obj[maneuverKey]),
            parseNumber(obj[distanceKey]).map(n => new Distance(n)),
            parseNumber(obj[durationKey]).map(n => new Duration(n)));
    }

    protected toJsonImpl(value: DirectionStep, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(orderKey, value.order)
            .addOptional(startLatitudeKey, value.startLatitude)
            .addOptional(startLongitudeKey, value.startLongitude)
            .addOptional(endLatitudeKey, value.endLatitude)
            .addOptional(endLongitudeKey, value.endLongitude)
            .addOptional(instructionsKey, value.instructions)
            .addOptional(maneuverKey, value.maneuver)
            .addOptional(distanceKey, value.distance.map(d => d.meters))
            .addOptional(durationKey, value.duration.map(d => d.seconds));
    }
}

export class DirectionLeg {
    constructor(
        readonly start: Option<MapMarker> = None,
        readonly end: Option<MapMarker> = None,
        readonly steps: List<DirectionStep> = List()) {
    }

    getEndLatLongLocation(): Option<LatLongLocation> {
        return this.end;
    }

    getEndName(): Option<string> {
        return this.end.flatMap(m => m.title).orElse(this.end.flatMap(m => m.address));
    }

    getEndPoints(): Option<{ latitude: number; longitude: number }> {
        return this.end.flatMap(x => x.getPoints());
    }

    getEndPointsAlternate(): Option<{ lat: number; lng: number }> {
        return this.end.flatMap(x => x.getPointsAlternate());
    }

    getNavigationPoint(): Option<ReadonlyArray<number>> {
        const lat = this.end.flatMap(e => e.latitude);
        const long = this.end.flatMap(e => e.longitude);
        return Option.map2(lat, long, (a, b) => [a, b]);
    }

    getSortedSteps(): List<DirectionStep> {
        const comparator = ComparisonUtils.optionNumberComparator;
        return this.steps.sort((a, b) => comparator.compare(a.order, b.order));
    }

    getStartLatLongLocation(): Option<LatLongLocation> {
        return this.start;
    }

    getStartName(): Option<string> {
        return this.start.flatMap(m => m.title).orElse(this.start.flatMap(m => m.address));
    }

    getStartPoints(): Option<{ latitude: number; longitude: number }> {
        return this.start.flatMap(x => x.getPoints());
    }

    getStartPointsAlternate(): Option<{ lat: number; lng: number }> {
        return this.start.flatMap(x => x.getPointsAlternate());
    }

    getTotalDistance(): Distance {
        return this.steps
            .reduce((a: Distance, b: DirectionStep) =>
                a.add(b.distance.getOrElse(Distance.empty)), Distance.empty);

    }

    getTotalDuration(): Duration {
        return this.steps.reduce((a: Duration, b: DirectionStep) =>
            a.add(b.duration.getOrElse(Duration.empty)), Duration.empty);
    }
}

export class DirectionLegJsonSerializer extends SimpleJsonSerializer<DirectionLeg> {
    static instance: DirectionLegJsonSerializer = new DirectionLegJsonSerializer();

    fromJsonImpl(obj: any): DirectionLeg {
        return new DirectionLeg(
            MapMarkerJsonSerializer.instance.fromJson(obj[startKey]),
            MapMarkerJsonSerializer.instance.fromJson(obj[endKey]),
            parseListSerializable(obj[stepsKey], DirectionStepJsonSerializer.instance),
        );
    }

    protected toJsonImpl(value: DirectionLeg, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(startKey, value.start, MapMarkerJsonSerializer.instance)
            .addOptionalSerializable(endKey, value.end, MapMarkerJsonSerializer.instance)
            .addIterableSerializable(stepsKey, value.steps, DirectionStepJsonSerializer.instance);
    }
}

export class Directions extends GMap {
    constructor(
        latitude: Option<number> = None,
        longitude: Option<number> = None,
        zoom: Option<number> = None,
        image: Option<string> = None,
        markers: List<MapMarker> = List(),
        readonly travelMode: Option<string> = None,
        readonly legs: List<DirectionLeg> = List()) {
        super(latitude, longitude, zoom, image, markers);
    }

    endsNear(to: LatLongLocation, maxDistance: Distance): boolean {
        return this.getLastLeg()
            .flatMap(x => x.getEndLatLongLocation())
            .exists(x => x.isNear(to, maxDistance).contains(true));
    }

    getEndLatitude(): Option<number> {
        return this.getEndLatLongLocation().flatMap(x => x.getLatitude());
    }

    getEndLatLongLocation(): Option<LatLongLocation> {
        return this.getLastLeg().flatMap(x => x.getEndLatLongLocation());
    }

    getEndLongitude(): Option<number> {
        return this.getEndLatLongLocation().flatMap(x => x.getLongitude());
    }

    getFirstLeg(): Option<DirectionLeg> {
        return Option.of(this.getSortedLegs().first());
    }

    getFirstMapMarker(): Option<MapMarker> {
        return Option.of(this.getSortedMarkers().first());
    }

    getFirstMapMarkerPointsAlternate(): Option<{ lat: number; lng: number }> {
        return this.getFirstMapMarker().flatMap(x => x.getPointsAlternate());
    }

    getLastLeg(): Option<DirectionLeg> {
        return Option.of(this.getSortedLegs().last());
    }

    getLastMapMarker(): Option<MapMarker> {
        return Option.of(this.getSortedMarkers().last());
    }

    getLastMapMarkerPointsAlternate(): Option<{ lat: number; lng: number }> {
        return this.getLastMapMarker().flatMap(x => x.getPointsAlternate());
    }

    getLatLongRoute(): LatLongRoute {
        return new LatLongRoute(this.getFirstMapMarker(), this.getLastMapMarker());
    }

    getMarkers(): List<MapMarker> {
        return this.marker;
    }

    getSortedLegs(): List<DirectionLeg> {
        const comparator = ComparisonUtils.optionNumberComparator;
        return this.legs.sort((a, b) => comparator.compare(a.start.flatMap(m => m.order), b.end.flatMap(m => m.order)));
    }

    getSortedMarkers(): List<MapMarker> {
        const comparator = ComparisonUtils.optionNumberComparator;
        return this.marker.sort((a, b) => comparator.compare(a.order, b.order));
    }

    getStartLatitude(): Option<number> {
        return this.getStartLatLongLocation().flatMap(x => x.getLatitude());
    }

    getStartLatLongLocation(): Option<LatLongLocation> {
        return this.getFirstLeg().flatMap(x => x.getStartLatLongLocation());
    }

    getStartLongitude(): Option<number> {
        return this.getStartLatLongLocation().flatMap(x => x.getLongitude());
    }

    getTotalDistance(): Distance {
        return this.legs.reduce((a: Distance, b: DirectionLeg) =>
            a.add(b.getTotalDistance()), Distance.empty);
    }

    getTotalDuration(): Duration {
        return this.legs.reduce((a: Duration, b: DirectionLeg) =>
            a.add(b.getTotalDuration()), Duration.empty);
    }

    getWaypoints(): List<{ location: { lat: number; lng: number } }> {
        return OptionUtils.flattenList(this.getSortedMarkers().shift().pop().map(x => x.getPointsAlternate())).map(x => ({location: x}));
    }

    isEmpty(): boolean {
        return this.legs.isEmpty();
    }

    startsNear(from: LatLongLocation, maxDistance: Distance): boolean {
        return this.getFirstLeg()
            .flatMap(x => x.getStartLatLongLocation())
            .exists(x => x.isNear(from, maxDistance).contains(true));
    }
}

export class DirectionsJsonSerializer extends SimpleJsonSerializer<Directions> {
    static instance: DirectionsJsonSerializer = new DirectionsJsonSerializer();

    fromJsonImpl(obj: any): Directions {
        return new Directions(
            parseNumber(obj[latitudeKey]),
            parseNumber(obj[longitudeKey]),
            parseNumber(obj[zoomKey]),
            parseString(obj[imageKey]),
            parseListSerializable(obj[markersKey], MapMarkerJsonSerializer.instance),
            parseString(obj[travelModeKey]),
            parseListSerializable(obj[legsKey], DirectionLegJsonSerializer.instance),
        );
    }

    protected toJsonImpl(value: Directions, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(latitudeKey, value.latitude)
            .addOptional(longitudeKey, value.longitude)
            .addOptional(zoomKey, value.zoom)
            .addOptional(imageKey, value.image)
            .addIterableSerializable(markersKey, value.marker, MapMarkerJsonSerializer.instance)
            .addOptional(travelModeKey, value.travelMode)
            .addIterableSerializable(legsKey, value.legs, DirectionLegJsonSerializer.instance);
    }
}
