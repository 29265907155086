import {None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {
    apiKey,
    fakeIdxKey,
    fallbackXmlKey,
    JsonBuilder,
    optcodeKey,
    OptionUtils,
    productIdKey,
    productNameKey,
    proposalCompanyIdKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Mapping, MappingApi} from './mapping';
import {Product} from './product';

export class DbProductMapping implements Validatable {

    constructor(
        readonly product: Product,
        readonly code: string,
        readonly company: number,
        readonly api: MappingApi = 'TPL',
        readonly fallbackXml: Option<string>,
        readonly fakeIdx: Option<number>) {
    }

    static buildFromProduct(product: Product, fakeIdx: Option<number>): List<DbProductMapping> {
        return product.mapping
            .filter(x => x.optcode.nonEmpty())
            .filter(x => x.ownerId.nonEmpty())
            .map(code => new DbProductMapping(product, code.optcode.get(), code.ownerId.get(), code.api, code.fallbackXml, fakeIdx))
            .concat(DbProductMapping.buildFromProducts(product.subproducts, fakeIdx));
    }

    static buildFromProductForTourplanUpdate(productId: number, code: string, companyId: number): DbProductMapping {
        return new DbProductMapping(
            new Product(Some(productId)),
            code,
            companyId,
            'TPL',
            None,
            None,
        );
    }

    static buildFromProducts(
        products: List<Product>,
        parentFakeIdx: Option<number> = None,
    ): List<DbProductMapping> {
        return products.flatMap((x, idx) => DbProductMapping.buildFromProduct(
            x,
            OptionUtils.applyOrReturnNonEmpty(parentFakeIdx, Some(idx), (a, b) => a * 1000 + b)));
    }

    buildAsMapping(type: 'TPL' | 'AOT' = 'TPL'): Mapping {
        return new Mapping(
            Some(this.code),
            Some(this.company),
            this.product.getId(),
            None,
            this.fallbackXml,
            type,
            Set.of(this.company));
    }

    isMappingToExistingProduct(): boolean {
        return this.product.id.nonEmpty();
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateInt('company', this.company.toString())),
            Some(ValidationUtils.validateChar('api', this.api)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbProductMappingJsonSerializer extends SimpleJsonSerializer<DbProductMapping> {
    static instance: DbProductMappingJsonSerializer = new DbProductMappingJsonSerializer();

    fromJsonImpl(obj: any): DbProductMapping {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(mapping: DbProductMapping, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(optcodeKey, mapping.code)
            .add(apiKey, mapping.api)
            .addOptional(productIdKey, mapping.product.id)
            .add(proposalCompanyIdKey, mapping.company)
            .addOptional(productNameKey, mapping.product.name)
            .addOptional(fallbackXmlKey, mapping.fallbackXml)
            .addOptional(fakeIdxKey, mapping.fakeIdx);
    }
}
