import {Either} from 'funfix-core';
import {List, Map} from 'immutable';
import {parseNumber, parseNumberToEither, parseString, parseStringMap, SimpleJsonSerializer} from '../core';
import {
  Api,
  ApiAgency,
  ApiAgencyJsonSerializer,
  ApiConnection,
  ApiConnectionJsonSerializer,
  ApiJsonSerializer,
  ApiTemplate,
  ApiTemplateJsonSerializer,
  Company,
  CompanyJsonSerializer,
  Person,
  PersonJsonSerializer,
  Product,
  ProductJsonSerializer,
  ProductOption,
  ProductOptionJsonSerializer,
  Proposal,
  ProposalEntry,
  ProposalEntryJsonSerializer,
  ProposalJsonSerializer,
  ServiceCodeAnalysis,
  ServiceCodeAnalysisJsonSerializer,
  User,
} from '../models';
import {ApiBase} from './api-base';

export class DidgigoIngestionApi extends ApiBase {

    constructor(apiServer: string) {
        super(apiServer);
    }

    private getBodyParams(template: ApiTemplate, connection: ApiConnection, agency: ApiAgency, user: User): object {
        return {
            template: ApiTemplateJsonSerializer.instance.toJson(template),
            connection: ApiConnectionJsonSerializer.instance.toJson(connection),
            agency: ApiAgencyJsonSerializer.instance.toJson(agency),
            user: PersonJsonSerializer.instance.toJson(user.person.getOrElse(new Person())),
        };
    }

    getBooking(
        ref: string,
        api: Api,
        template: ApiTemplate,
        conn: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Proposal>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-booking/${ref}`,
          template,
          conn,
          agency,
          user,
          {},
          ProposalJsonSerializer.instance,
        );
    }

    getBookingForData(
        data: string,
        api: Api,
        template: ApiTemplate,
        conn: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Proposal>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-booking-for-data`,
          template,
          conn,
          agency,
          user,
          {data}
          , ProposalJsonSerializer.instance,
        );
    }

    getBookingMessageDataAsString(
        reference: string,
        message: string,
        format: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, string>> {
        return this.runIngestionRequestString(
            `tourplan/get-booking-message/${reference}/${message}/${format}`, template, connection, agency, user);
    }

    getBookingMessageLabels(
        ref: string,
        template: ApiTemplate,
        api: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, List<string>>> {
        return this.runIngestionRequestStringList(
            `tourplan/get-avaliable-messages-for-booking/${ref}`, template, api, agency, user, {});
    }

    getBookingMessageXml(
        reference: string,
        message: string,
        format: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, string>> {
        return this.runIngestionRequestString(
            `tourplan/get-booking-message/xml/${reference}/${message}/${format}`, template, connection, agency, user);
    }

    getBookingRFXml(
        api: Api,
        ref: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, any>> {
        return this.runIngestionRequestString(
            `ccrs/rf/xml/${ref}`, template, connection, agency, user);
    }

    getBookingXml(
        api: Api,
        ref: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, any>> {
        return this.runIngestionRequestString(
            `${api.name.getOrElse('').toLowerCase()}/get-booking/xml/${ref}`, template, connection, agency, user);
    }

    getBookingXmlForData(
        api: Api,
        data: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, any>> {
        return this.runIngestionRequestString(
            `${api.name.getOrElse('').toLowerCase()}/get-booking-for-data/xml`, template, connection, agency, user, {data});
    }

    getDpProductApiReferenceXml(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, any>> {
        return this.runIngestionRequestString(
            `ccrs/dp/xml/${reference}`, template, connection, agency, user);
    }

    getHeaders(): object {
        return {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        };
    }

    getProductApiReferenceAsProduct(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Product>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-product-api-reference-as-product/${reference}`,
          template,
          connection,
          agency,
          user,
          {},
          ProductJsonSerializer.instance,
        );
    }

    getProductApiReferenceAsProductOption(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, ProductOption>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-product-api-reference-as-product-option/${reference}`,
          template,
          connection,
          agency,
          user,
          {},
          ProductOptionJsonSerializer.instance,
        );
    }

    getProductApiReferenceAsProposalEntry(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, ProposalEntry>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-product-api-reference-as-proposal-entry/${reference}`,
          template,
          connection,
          agency,
          user,
          {},
          ProposalEntryJsonSerializer.instance,
        );
    }

    getProductApiReferenceXml(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, any>> {
        return this.runIngestionRequestString(
            `${api.name.getOrElse('').toLowerCase()}/get-product-api-reference/xml/${reference}`,
          template,
          connection,
          agency,
          user,
          );
    }

    getSupplierApiReferenceAsCompany(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Company>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-supplier-api-reference-as-supplier/${reference}`,
          template,
          connection,
          agency,
          user,
          {},
          CompanyJsonSerializer.instance,
          );
    }

    getSupplierApiReferenceAsProduct(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Product>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/get-supplier-api-reference-as-product/${reference}`,
          template,
          connection,
          agency,
          user,
          {},
          ProductJsonSerializer.instance,
          );
    }

    getSupplierApiReferenceXml(
        api: Api,
        reference: string,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, string>> {
        return this.runIngestionRequestString(
            `${api.name.getOrElse('').toLowerCase()}/get-supplier-api-reference/xml/${reference}`,
          template,
          connection,
          agency,
          user,
          );
    }

    getSystemSettingsXml(
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, string>> {
        return this.runIngestionRequestString(
            `tourplan/get-system-settings/xml`, template, connection, agency, user);
    }

    importBooking(
        ref: string,
        api: Api,
        template: ApiTemplate,
        conn: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Proposal>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/import-booking/${ref}`,
          template,
          conn,
          agency,
          user,
          {},
          ProposalJsonSerializer.instance,
          );
    }

    importBookingMessage(
        ref: string,
        api: Api,
        template: ApiTemplate,
        conn: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Proposal>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/import-booking-message/${ref}`,
          template,
          conn,
          agency,
          user,
          {},
          ProposalJsonSerializer.instance,
          );
    }

    importManualBooking(
        value: string,
        api: Api,
        template: ApiTemplate,
        conn: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Proposal>> {
        return this.runIngestionRequestSerialized(
            `${api.name.getOrElse('').toLowerCase()}/import-manual-booking/`,
          template,
          conn,
          agency,
          user,
          {data: value},
          ProposalJsonSerializer.instance,
          );
    }

    importMissingProductReferences(
        api: Api,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, List<number>>> {
        return this.runIngestionRequestNumberList(
          `${api.name.getOrElse('').toLowerCase()}/import-missing-product-references`,
          template,
          connection,
          agency,
          user,
          );
    }

    // TODO: Actually return a proper response from import product
    importProduct(
        ref: string,
        api: Api,
        template: ApiTemplate,
        conn: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, number>> {
        return this.runIngestionRequestParsable(
            `${api.name.getOrElse('').toLowerCase()}/import-product/${ref}`,
            template,
            conn,
            obj => parseNumberToEither(obj['id'], 'Missing id in import product response'),
            agency,
            user,
            {});
    }

    listBookingsForClient(
        api: Api,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User,
        recent: boolean): Promise<Either<string, List<Proposal>>> {
        return this.runIngestionRequestListSerialized(
            `${api.name.getOrElse('').toLowerCase()}/list-bookings?recent=${recent}`,
          template,
          connection,
          agency,
          user,
          {},
          ProposalJsonSerializer.instance,
        );
    }

    listLocationsForClient(
        template: ApiTemplate,
        api: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, Map<string, string>>> {
        return this.runIngestionRequestStringMap('tourplan/get-locations', template, api, agency, user);
    }

    listMissingProductReferences(
        api: Api,
        template: ApiTemplate,
        connection: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, List<string>>> {
        return this.runIngestionRequestStringList(`${api.name.getOrElse('').toLowerCase()}/list-missing-product-references`, template, connection, agency, user);
    }

    listServicesForClient(
        template: ApiTemplate,
        api: ApiConnection,
        agency: ApiAgency,
        user: User): Promise<Either<string, List<ServiceCodeAnalysis>>> {
        return this.runIngestionRequestListSerialized(
            'tourplan/get-services',
          template,
          api,
          agency,
          user,
          {},
          ServiceCodeAnalysisJsonSerializer.instance,
          );
    }

    parseAwardWalletResponseAsProposal(
      api: Api,
      template: ApiTemplate,
      connection: ApiConnection,
      agency: ApiAgency,
      user: User,
      data: object,
    ): Promise<Either<string, Proposal>> {
      return this.runIngestionRequestSerialized(
        `${api.name.getOrElse('').toLowerCase()}/callback`,
        template,
        connection,
        agency,
        user,
        data,
        ProposalJsonSerializer.instance,
      );
    }

    runIngestionRequestListSerialized<T>(
        path: string,
        template: ApiTemplate,
        api: ApiConnection,
        client: ApiAgency,
        user: User, body: object = {},
        serializer: SimpleJsonSerializer<T>): Promise<Either<string, List<T>>> {
        return this.processPostApiRequestListSerialized(
          path,
          serializer,
          {...body, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          );
    }

    runIngestionRequestNumberList(
        path: string,
        template: ApiTemplate,
        api: ApiConnection,
        client: ApiAgency,
        user: User, body: object = {}): Promise<Either<string, List<number>>> {
        return this.processPostApiRequestListOptParsable(
          path,
          parseNumber,
          {...body, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          );
    }

    runIngestionRequestParsable<T>(
        path: string,
        template: ApiTemplate,
        api: ApiConnection,
        parser: (a: any) => Either<string, T>,
        client: ApiAgency,
        user: User,
        other: object = {}): Promise<Either<string, T>> {
        return this.processPostApiRequestParsable(
          path,
          parser,
          {...other, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          );
    }

    runIngestionRequestSerialized<T>(
        path: string,
        template: ApiTemplate,
        api: ApiConnection,
        client: ApiAgency,
        user: User,
        body: object = {},
        serializer: SimpleJsonSerializer<T>): Promise<Either<string, T>> {
        return this.processPostApiRequestSerialized(
          path,
          serializer,
          {...body, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          );

    }

    runIngestionRequestString(
        path: string,
        template: ApiTemplate,
        api: ApiConnection,
        client: ApiAgency,
        user: User,
        other: object = {}): Promise<Either<string, string>> {
        return this.processPostApiRequestString(
          path,
          {...other, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          );
    }

    runIngestionRequestStringList(
        path: string,
        template: ApiTemplate,
        api: ApiConnection,
        client: ApiAgency,
        user: User, body: object = {}): Promise<Either<string, List<string>>> {
        return this.processPostApiRequestListOptParsable(
          path,
          parseString,
          {...body, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          );
    }

    runIngestionRequestStringMap(
        path: string, template: ApiTemplate,
        api: ApiConnection,
        client: ApiAgency,
        user: User,
        body: object = {}): Promise<Either<string, Map<string, string>>> {
        return this.processPostApiRequest(
          path,
          {...body, ...this.getBodyParams(template, api, client, user)},
          user.getEmail().getOrElse(''),
          )
            .then(e => e.map(x => parseStringMap(x)));
    }
}
