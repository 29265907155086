import {None, Option, Some} from 'funfix-core';
import {
    activeKey,
    currencyIdKey,
    currencyTypeKey,
    JsonBuilder,
    OptionUtils,
    proposalFinancialsCompanyIdKey,
    proposalFinancialsCurrencyIdKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';

export class DBFinancialsCurrencies implements Validatable {

    constructor(
        readonly proposalFinancialsCurrenciesId: Option<number> = None,
        readonly currencyId: number,
        readonly proposalFinancialsCompanyId: number,
        readonly currencyType: string,
        readonly active: string,
    ) { }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.proposalFinancialsCurrenciesId.map(n => ValidationUtils.validateInt('proposalFinancialsCurrenciesId', n.toString())),
            Some(ValidationUtils.validateInt('currencyId', this.currencyId.toString())),
            Some(ValidationUtils.validateInt('proposalFinancialsCompanyId', this.proposalFinancialsCompanyId.toString())),
            Some(ValidationUtils.validateVarchar('currencyType', this.currencyType, 200)),
            Some(ValidationUtils.validateChar('active', this.active)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DBFinancialsCurrenciesJsonSerializer extends SimpleJsonSerializer<DBFinancialsCurrencies> {
    static instance: DBFinancialsCurrenciesJsonSerializer = new DBFinancialsCurrenciesJsonSerializer();

    fromJsonImpl(obj: any): DBFinancialsCurrencies {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(financialsCurrencies: DBFinancialsCurrencies, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(proposalFinancialsCurrencyIdKey, financialsCurrencies.proposalFinancialsCurrenciesId)
            .add(currencyIdKey, financialsCurrencies.currencyId)
            .add(proposalFinancialsCompanyIdKey, financialsCurrencies.proposalFinancialsCompanyId)
            .add(currencyTypeKey, financialsCurrencies.currencyType)
            .add(activeKey, financialsCurrencies.active);
    }

}
