import {None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {accessorsKey, companyKey, JsonBuilder, parseListSerializable, personKey, roleKey, SimpleJsonSerializer, usingAsKey} from '../core';
import {Company, CompanyJsonSerializer} from './company';
import {Person, PersonJsonSerializer} from './person';
import {Product} from './product';

export type UserRole = 'System_Manager' | 'Company_Admin' | 'Proposal_Agent' | 'Guest';

export class User {
    constructor(
        readonly person: Option<Person> = None,
        readonly accessibleCompanies: List<Company> = List(),
        readonly usingAs: Option<Company> = None,
        readonly company: Option<Company> = None,
        readonly role: Option<UserRole> = None,
    ) {
    }

    static fromPerson(person: Person): User {
        return new User(Some(person));
    }

    getAccessibleCompanies(): List<Company> {
        return this.accessibleCompanies;
    }

    getCompany(): Option<Company> {
        return this.company;
    }

    getEmail(): Option<string> {
        return this.getPerson()
            .flatMap(x => x.getEmail());
    }

    getLoggedInAgentId(): Option<number> {
        return this.getPerson()
            .flatMap(x => x.id);
    }

    getLoggedInAgentName(): Option<string> {
        return this.getPerson()
            .flatMap(x => x.getFullNameWithSalutation());
    }

    getLoggedInCompany(): Option<Company> {
        return this.getCompany();
    }

    getLoggedInCompanyId(): Option<number> {
        return this.getPerson()
            .flatMap(x => x.getCompanyId());
    }

    getPerson(): Option<Person> {
        return this.person;
    }

    getRole(): Option<UserRole> {
        return this.role;
    }

    getSystemManager(): Option<UserRole> {
        return Some('System_Manager');
    }

    getUsingAsCompany(): Option<Company> {
        return this.usingAs;
    }

    getUsingAsCompanyId(): Option<number> {
        return this.getUsingAsCompany()
            .flatMap(x => x.getId());
    }

    hasRoleLevel(minimumRole: UserRole): boolean {
        switch (minimumRole) {
            case 'System_Manager':
                return this.isOneOfRoles('System_Manager');
            case 'Company_Admin':
                return this.isOneOfRoles('Company_Admin', 'System_Manager');
            case 'Proposal_Agent':
                return this.isOneOfRoles('Proposal_Agent', 'Company_Admin', 'System_Manager');
        }
        return false;
    }

    isAgent(): boolean {
        return this.hasRoleLevel('Proposal_Agent');
    }

    isCompanyAdmin(): boolean {
        return this.hasRoleLevel('Company_Admin');
    }

    isOneOfRoles(...s: UserRole[]): boolean {
        return this.role
            .exists(x => Set(s).contains(x));
    }

    isProductEditable(p: Product): boolean {
        if (this.isSystemManager()) {
            return true;
        } else if (p.getId().exists(x => p.isProposalCompanyOwner(x))) {
            return true;
        }
        return false;
    }

    isReady(): boolean {
        return this.getPerson().nonEmpty()
            && this.getCompany().nonEmpty()
            && this.getUsingAsCompany().nonEmpty();
    }

    isSystemManager(): boolean {
        return this.hasRoleLevel('System_Manager');
    }

    useAsCompany(company: Company): User {
        return new User(
            this.getPerson(),
            this.getAccessibleCompanies(),
            Some(company),
            this.getCompany(),
            this.getRole(),
        );
    }

    withAccessibleCompanies(companies: List<Company>): User {
        return new User(
            this.getPerson(),
            companies,
            this.getUsingAsCompany(),
            this.getCompany(),
            this.getRole(),
        );
    }

    withCompaniesForLogin(company: Company, accessibleCompanies: List<Company>): User {
        return new User(
            this.getPerson(),
            accessibleCompanies.map(x => x.withoutDefaults()),
            this.getUsingAsCompany(),
            Some(company),
            this.getRole(),
        );
    }

    withCompany(company: Company): User {
        return new User(
            this.getPerson(),
            this.getAccessibleCompanies(),
            this.getUsingAsCompany(),
            Some(company),
            this.getRole(),
        );
    }

    withPerson(person: Person): User {
        return new User(
            Some(person),
            List(),
            None,
            this.getCompany(),
            this.getRole(),
        );
    }

    withUsingAs(company: Company): User {
        return new User(
            this.getPerson(),
            this.getAccessibleCompanies(),
            Some(company),
            this.getCompany(),
            this.getRole(),
        );
    }
}

export class UserJsonSerializer extends SimpleJsonSerializer<User> {
    static instance: UserJsonSerializer = new UserJsonSerializer();

    protected fromJsonImpl(json: any): User {
        return new User(
            PersonJsonSerializer.instance.fromJson(json[personKey]),
            parseListSerializable(json[accessorsKey], CompanyJsonSerializer.instance),
            CompanyJsonSerializer.instance.fromJson(json[usingAsKey]),
            CompanyJsonSerializer.instance.fromJson(json[companyKey]),
            Option.of(json[roleKey]),
        );
    }

    protected toJsonImpl(value: User, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(personKey, value.getPerson(), PersonJsonSerializer.instance)
            .addIterableSerializable(accessorsKey, value.getAccessibleCompanies(), CompanyJsonSerializer.instance)
            .addOptionalSerializable(usingAsKey, value.getUsingAsCompany(), CompanyJsonSerializer.instance)
            .addOptionalSerializable(companyKey, value.getCompany(), CompanyJsonSerializer.instance)
            .addOptional(roleKey, value.getRole());
    }
}
