import {None, Option} from 'funfix-core';
import {List, Set} from 'immutable';
import {
    connectionsKey,
    idKey,
    JsonBuilder,
    nameKey,
    parseListSerializable,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
    templatesKey,
} from '../core';
import {ApiConnection, ApiConnectionJsonSerializer} from './api-connection';
import {ApiTemplate, ApiTemplateJsonSerializer} from './api-template';
import {AutoImportSettings} from './auto-import-settings';

export class Api {
    constructor(
        readonly id: Option<number> = None,
        readonly name: Option<string> = None,
        readonly connections: List<ApiConnection> = List(),
        readonly templates: List<ApiTemplate> = List(),
    ) {
    }

    /**
     * Will return none if there is no useful information.
     */
    filterAccessibleBy(owner: number, accessible: Set<number>): Option<Api> {
        const connections = this.getConnections()
            .filter(x => x.isAccessibleBy(accessible.add(owner)))
            .map(x => x.filterAccessibleCredentials(owner));

        const templates = this.templates.filter(x => x.isAccessibleBy(accessible));

        return Option.of(new Api(
            this.id,
            this.name,
            connections,
            templates))
            .filter(api => api.isUseful());
    }

    getConnections(): List<ApiConnection> {
        return this.connections;
    }

    getId(): Option<number> {
        return this.id;
    }

    getName(): Option<string> {
        return this.name;
    }

    getProductApiRequestSettings(n: number): List<AutoImportSettings> {
        return this.getConnections().flatMap(c => c.getProductApiRequestSettingsForInterval(n, c));
    }

    getProposalApiRequestSettings(n: number): List<AutoImportSettings> {
        return this.getConnections().flatMap(c => c.getProposalApiRequestSettingsForInterval(n));
    }

    getTemplates(): List<ApiTemplate> {
        return this.templates;
    }

    isCcrs(): boolean {
        return this.name.contains('CCRS');
    }

    isProductImportOnly(): boolean {
        return this.name.exists(x => Set.of('Ice Portal', 'Ice Portal Test').contains(x));
    }

    isTourplan(): boolean {
        return this.name.contains('Tourplan');
    }

    isUseful(): boolean {
        return !(this.connections.isEmpty() && this.templates.isEmpty());
    }

    withConnections(conns: List<ApiConnection>): Api {
        return new Api(
            this.id,
            this.name,
            conns,
            this.templates);
    }
}

export class ApiJsonSerializer extends SimpleJsonSerializer<Api> {
    static instance = new ApiJsonSerializer();

    protected fromJsonImpl(obj: any): Api {
        return new Api(
            parseNumber(obj[idKey]),
            parseString(obj[nameKey]),
            parseListSerializable(obj[connectionsKey], ApiConnectionJsonSerializer.instance),
            parseListSerializable(obj[templatesKey], ApiTemplateJsonSerializer.instance),
        );
    }

    protected toJsonImpl(value: Api, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, value.id)
            .addOptional(nameKey, value.name)
            .addIterableSerializable(connectionsKey, value.connections, ApiConnectionJsonSerializer.instance)
            .addIterableSerializable(templatesKey, value.templates, ApiTemplateJsonSerializer.instance);
    }
}
