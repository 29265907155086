import {None, Option} from 'funfix-core';
import {
    agentCodeKey,
    agentIdKey,
    companyIdKey,
    JsonBuilder,
    OptionUtils,
    passwordKey,
    presentAsKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {ApiAgency} from './api-agency';

export class DbApiAgency implements Validatable {

    constructor(
        readonly agentCode: Option<string> = None,
        readonly companyId: Option<number> = None,
        readonly presentAs: Option<number> = None,
        readonly password: Option<string> = None,
        readonly agentId: Option<number> = None,
    ) {
    }

    static fromApiAgency(agency: ApiAgency): DbApiAgency {
        return new DbApiAgency(
            agency.getUser(),
            agency.getCompanyId(),
            agency.getPresentAsCompanyId(),
            agency.getPassword(),
            agency.getAgent().flatMap(x => x.getId()),
        );
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.agentCode.map(x => ValidationUtils.validateVarchar('agentCode', x, 500)),
            this.companyId.map(x => ValidationUtils.validateInt('companyId', this.companyId.toString())),
            this.presentAs.map(x => ValidationUtils.validateInt('presentAs', this.presentAs.toString())),
            this.password.map(x => ValidationUtils.validateVarchar('password', x, 100)),
            this.agentId.map(x => ValidationUtils.validateInt('agentId', x.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbApiAgencyJsonSerializer extends SimpleJsonSerializer<DbApiAgency> {
    static instance: DbApiAgencyJsonSerializer = new DbApiAgencyJsonSerializer();

    fromJsonImpl(json: any): DbApiAgency {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(value: DbApiAgency, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(agentCodeKey, value.agentCode)
            .addOptional(companyIdKey, value.companyId)
            .addOptional(presentAsKey, value.presentAs)
            .addOptional(passwordKey, value.password)
            .addOptional(agentIdKey, value.agentId);
    }

}
