import { Moment } from 'moment';
import { now } from './date-utils';

export function logTime(key: string, startTime: Moment): void {
    logTimePreCalculated(key, preciseTimeDiff(startTime));
}

/* tslint:disable */
export function logTimePreCalculated(key: string, time: number): void {
    console.debug('Performance Log: ' + key + ' : ' + time.toString() + 's');
}
/* tslint:enable */

export function preciseTimeDiff(startTime: Moment): number {
    const time = now();
    return time.diff(startTime, 'seconds', true);
}
