import { None, Option } from 'funfix-core';
import { Set } from 'immutable';
import {
    companyKey,
    idKey,
    JsonBuilder,
    parseNumber,
    parseString,
    shortCodeKey,
    SimpleJsonSerializer,
} from '../core';
import { Company, CompanyJsonSerializer } from './company';

export class ApiTemplate {
    constructor(
        readonly id: Option<number> = None,
        readonly shortCode: Option<string> = None,
        readonly company: Option<Company> = None,
    ) {
    }

    getCompany(): Option<Company> {
        return this.company;
    }

    getId(): Option<number> {
        return this.id;
    }

    getShortCode(): Option<string> {
        return this.shortCode;
    }

    isAccessibleBy(companies: Set<number>): boolean {
        return this.company
            .flatMap(x => x.id)
            .exists(x => companies.contains(x));
    }
}

export class ApiTemplateJsonSerializer extends SimpleJsonSerializer<ApiTemplate> {
    static instance = new ApiTemplateJsonSerializer();

    protected fromJsonImpl(json: any): ApiTemplate {
        return new ApiTemplate(
            parseNumber(json[idKey]),
            parseString(json[shortCodeKey]),
            CompanyJsonSerializer.instance.fromJson(json[companyKey]),
        );
    }

    protected toJsonImpl(value: ApiTemplate, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, value.id)
            .addOptional(shortCodeKey, value.shortCode)
            .addOptionalSerializable(companyKey, value.company, CompanyJsonSerializer.instance);
    }
}
