import { None, Option } from 'funfix-core';
import { parseString } from '../core';
import { JsonBuilder } from '../core/json-builder';
import { descriptionKey, titleKey } from '../core/json-keys';
import { SimpleJsonSerializer } from '../core/json-serializer';

export class Custom {
    constructor(
        readonly title: Option<string> = None,
        readonly description: Option<string> = None) { }

    getDescription(): Option<string> {
        return this.description;
        }

    getTitle(): Option<string> {
        return this.title;
        }

    isEmpty(): boolean {
        return this.getTitle().isEmpty() && this.getDescription().isEmpty();
    }
}

export class CustomJsonSerializer extends SimpleJsonSerializer<Custom> {
    static instance: CustomJsonSerializer = new CustomJsonSerializer();

    fromJsonImpl(obj: any): Custom {
        return new Custom(
            parseString(obj[titleKey]),
            parseString(obj[descriptionKey]),
        );
    }

    protected toJsonImpl(custom: Custom, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(titleKey, custom.title)
            .addOptional(descriptionKey, custom.description);
    }
}
