import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    addressKey,
    cityIdKey,
    countryIdKey,
    imagesKey,
    JsonBuilder,
    nameKey,
    optFakeIdxKey,
    OptionUtils,
    postcodeKey,
    prodFakeIdxKey,
    productIdKey,
    productNameKey,
    SimpleJsonSerializer,
    stateIdKey,
    typeKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
    websiteKey,
} from '../core';
import {Image, ImageJsonSerializer} from './image';
import {LocationIds} from './location-ids';
import {PhysicalLocation} from './physical-location';
import {Product} from './product';
import {ProductOption} from './product-option';

export class DbProductOption implements Validatable {

    constructor(
        readonly product: Option<Product>,
        readonly underlying: Option<ProductOption>,
        readonly name: Option<string> = None,
        readonly address: Option<string> = None,
        readonly cityId: Option<number> = None,
        readonly stateId: Option<number> = None,
        readonly countryId: Option<number> = None,
        readonly postcode: Option<string> = None,
        readonly website: Option<string> = None,
        readonly optionType: Option<string> = None,
        readonly prodFakeIdx: Option<number> = None,
        readonly optFakeIdx: Option<number> = None,
        readonly images: List<Image> = List(),
    ) {
    }

    static buildFromProduct(
        product: Product,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        prodFakeIdx: Option<number>,
    ): List<DbProductOption> {
        return product.options
            .map((x, idx) =>
                DbProductOption.buildFromProductOption(
                    x,
                    product,
                    locationIdFinder,
                    prodFakeIdx,
                    Some(idx),
                ),
            ).concat(DbProductOption.buildFromProducts(product.subproducts, locationIdFinder, prodFakeIdx));
    }

    static buildFromProductOption(
        option: ProductOption,
        product: Product,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        prodFakeIdx: Option<number>,
        optFakeIdx: Option<number>,
    ): DbProductOption {
        const location = option.location.map(x => locationIdFinder(x)).getOrElse(new LocationIds());
        return new DbProductOption(
            Some(product),
            Some(option),
            option.name,
            option.location.flatMap(l => l.address),
            location.cityId,
            location.stateId,
            location.countryId,
            option.location.flatMap(l => l.postcode),
            option.contact.flatMap(l => l.website),
            option.getOptionType(),
            prodFakeIdx,
            optFakeIdx,
            option.getImages(),
        );
    }

    static buildFromProducts(
        products: List<Product>,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        parentFakeIdx: Option<number> = None,
    ): List<DbProductOption> {
        return products.flatMap((x, idx) => DbProductOption.buildFromProduct(
            x,
            locationIdFinder,
            OptionUtils.applyOrReturnNonEmpty(parentFakeIdx, Some(idx), (a, b) => a * 1000 + b)));
    }

    getProductOptionTypeId(type: string): Option<number> {
        switch (type) {
            case 'Room':
                return Some(1);
            case 'Core Product':
                return Some(2);
            case 'Food and Beverage':
                return Some(4);
            case 'Fitness Room/Gym':
                return Some(5);
            case 'Day Spa':
                return Some(8);
            case 'Activities and Other':
                return Some(10);
            case 'Exclusive':
                return Some(12);
            case 'Day':
                return Some(17);
            default:
                throw new Error('Unable to map option type to id');
        }
        return None;
    }

    getSummary(): string {
        return OptionUtils.toList<any>(
            this.name,
            this.address,
            this.cityId,
            this.stateId,
            this.countryId,
            this.postcode,
            this.website)
            .reduce((a, b) => `${a} - ${b}`, '');
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.name.map(n => ValidationUtils.validateTitleNvarchar('name', n, 100)),
            this.address.map(n => ValidationUtils.validateNvarchar('address', n, 100)),
            this.cityId.map(n => ValidationUtils.validateInt('cityId', n.toString())),
            this.stateId.map(n => ValidationUtils.validateInt('stateId', n.toString())),
            this.countryId.map(n => ValidationUtils.validateInt('countryId', n.toString())),
            this.postcode.map(n => ValidationUtils.validateNvarchar('postcode', n, 20)),
            this.website.map(n => ValidationUtils.validateUrl('website', n, 255)),
            this.optionType.map(n => ValidationUtils.validateNvarchar('optionType', n, 50)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbProductOptionJsonSerializer extends SimpleJsonSerializer<DbProductOption> {
    static instance: DbProductOptionJsonSerializer = new DbProductOptionJsonSerializer();

    fromJsonImpl(obj: any): DbProductOption {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(option: DbProductOption, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(nameKey, option.name)
            .addOptional(addressKey, option.address)
            .addOptional(cityIdKey, option.cityId)
            .addOptional(stateIdKey, option.stateId)
            .addOptional(countryIdKey, option.countryId)
            .addOptional(postcodeKey, option.postcode)
            .addOptional(websiteKey, option.website)
            .addOptional(productIdKey, option.product.flatMap(x => x.id))
            .addOptional(productNameKey, option.product.flatMap(x => x.name))
            .addOptional(typeKey, option.optionType)
            .addOptional(prodFakeIdxKey, option.prodFakeIdx)
            .addOptional(optFakeIdxKey, option.optFakeIdx)
            .addIterableSerializable(imagesKey, option.images, ImageJsonSerializer.instance);
    }
}
