import {None, Option} from 'funfix-core';
import {List} from 'immutable';
import {apiKeyKey, companyKey, JsonBuilder, nameKey, parseString, permissionsKey, SimpleJsonSerializer} from '../core';
import {Company, CompanyJsonSerializer} from './company';
import {EnumConstantJsonSerializer, EnumConstantMap} from './enum-constant';

export class ApiUser {
    constructor(
        readonly apiKey: Option<string> = None,
        readonly company: Option<Company> = None,
        readonly permissions: EnumConstantMap = new EnumConstantMap(List()),
        readonly name: Option<string> = None,
    ) {
    }

    getApiKey(): Option<string> {
        return this.apiKey;
    }

    getCompany(): Option<Company> {
        return this.company;
    }

    getCompanyId(): Option<number> {
        return this.getCompany()
            .flatMap(x => x.getId());
    }

    getName(): Option<string> {
        return this.name;
    }

    getPermissions(): EnumConstantMap {
        return this.permissions;
    }

    hasAllPermissions(...n: number[]): boolean {
        return n.every(x => this.hasPermission(x));
    }

    hasOneOfPermissions(...n: number[]): boolean {
        return n.some(x => this.hasPermission(x));
    }

    hasPermission(n: number): boolean {
        return this.getPermissions()
            .getById(n).nonEmpty();
    }
}

export class ApiUserJsonSerializer extends SimpleJsonSerializer<ApiUser> {
    static instance: ApiUserJsonSerializer = new ApiUserJsonSerializer();

    fromJsonImpl(obj: any): ApiUser {
        return new ApiUser(
            parseString(obj[apiKeyKey]),
            CompanyJsonSerializer.instance.fromJson(obj[companyKey]),
            EnumConstantMap.parseFromArray(obj[permissionsKey]),
            parseString(obj[nameKey]),
        );
    }

    protected toJsonImpl(user: ApiUser, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(apiKeyKey, user.getApiKey())
            .addOptionalSerializable(companyKey, user.getCompany(), CompanyJsonSerializer.instance)
            .addIterableSerializable(permissionsKey, user.getPermissions().getEnums(), EnumConstantJsonSerializer.instance)
            .addOptional(nameKey, user.getName());
    }
}
