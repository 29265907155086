import {None, Option} from 'funfix-core';
import {emailKey, JsonBuilder, JsonSerializer, parseString, phoneKey, secondaryPhoneKey, websiteKey} from '../core';
import {Mergable} from './mergable';
import {PhysicalLocation} from './physical-location';
import {Rating} from './rating';
import {Social} from './social';

export class ContactModel {
    constructor(
        readonly title: Option<string>,
        readonly image: Option<string>,
        readonly circleImage: boolean,
        readonly type: 'Agent' | 'Company' | 'Supplier' | 'Product',
        readonly icon: string,
        readonly location: PhysicalLocation,
        readonly contact: Contact,
        readonly social: Social) {
    }

    isUseless(): boolean {
        return this.social.isEmpty()
            && this.contact.isEmpty();
    }
}

export class Contact implements Mergable<Contact> {
    constructor(
        readonly email: Option<string> = None,
        readonly phone: Option<string> = None,
        readonly secondaryPhone: Option<string> = None,
        readonly website: Option<string> = None) {
    }

    calculateUpdates(contact: Contact): Contact {
        return new Contact(
            contact.email.filter(_ => this.email.isEmpty()),
            contact.phone.filter(_ => this.phone.isEmpty()),
            contact.secondaryPhone.filter(_ => this.secondaryPhone.isEmpty()),
            contact.website.filter(_ => this.website.isEmpty()),
        );
    }

    getCompanyContactRating(): Rating {
        if (this.website.nonEmpty() && this.phone.nonEmpty()) {
            return 'Gold';
        } else if (this.phone.nonEmpty()) {
            return 'Silver';
        }

        return 'Black';
    }

    getEmail(): Option<string> {
        return this.email;
    }

    getPhone(): Option<string> {
        return this.phone;
    }

    getSecondPhone(): Option<string> {
        return this.secondaryPhone;
    }

    getWebsite(): Option<string> {
        return this.website;
    }

    isEmpty(): boolean {
        return this.email.isEmpty()
            && this.phone.isEmpty()
            && this.secondaryPhone.isEmpty()
            && this.website.isEmpty();
    }

    merge(other: Contact): Contact {
        return new Contact(
            this.email.orElse(other.email),
            this.phone.orElse(other.phone),
            this.secondaryPhone.orElse(other.secondaryPhone),
            this.website.orElse(other.website),
        );
    }

    mergeOptional(other: Option<Contact>): Contact {
        return other
            .map(o => this.merge(o))
            .getOrElse(this);
    }
}

export class ContactJsonSerializer<T extends Contact> extends JsonSerializer<Contact, T> {
    static instance: ContactJsonSerializer<Contact> = new ContactJsonSerializer();

    fromJsonImpl(obj: any): Contact {
        return new Contact(
            parseString(obj[emailKey]),
            parseString(obj[phoneKey]),
            parseString(obj[secondaryPhoneKey]),
            parseString(obj[websiteKey]));
    }

    protected toJsonImpl(contact: T, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(emailKey, contact.email)
            .addOptional(phoneKey, contact.phone)
            .addOptional(secondaryPhoneKey, contact.secondaryPhone)
            .addOptional(websiteKey, contact.website);
    }
}
