import {Either, None, Option} from 'funfix-core';
import {Collection, List, Set} from 'immutable';
import {Moment} from 'moment';
import {DOMParser, XMLSerializer} from 'xmldom';
import * as xpath from 'xpath';
import {EitherUtils} from './either-utils';
import {parseBoolean, parseDate, parseDateWithFormat, parseNumber, parseString} from './object-utils';
import {OptionUtils} from './option-utils';

export interface NamespaceMap {
    [name: string]: string;
}

export class XmlUtils {

    static emptyDiv: Document = XmlUtils.parse('<div></div>').get();

    static getBooleanAttribute(attributeName: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<boolean> {
        return XmlUtils.parseBooleanChild('./@' + attributeName, node, namespaces);
    }

    static getBooleanAttributeForPath(nodePath: string, attributeName: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<boolean> {
        return XmlUtils.parseBooleanChild(nodePath + '/@' + attributeName, node, namespaces);
    }

    static getChildNode(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<Node> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }

        try {
            if (namespaces !== {}) {
                return Option.of(xpath.useNamespaces(namespaces)(path, node)[0])
                    .map(x => x as any);
            }

            return Option.of(xpath.select1(path, node))
                .map(x => x as any);
        } catch (e) {
            console.error('Error in xpath: ' + path);
            console.error(e);
            return None;
        }
    }

    static getChildNodeEither(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Either<string, Node> {
        return EitherUtils.toEither(XmlUtils.getChildNode(path, node, namespaces), `Could not get child node for path ${path}`);
    }

    static getChildNodes(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): List<Node> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }

        try {
            if (namespaces !== {}) {
                return List(xpath.useNamespaces(namespaces)(path, node))
                    .map<Node>(x => x as any);
            }

            return List(xpath.select(path, node))
                .map<Node>(x => x as any);
        } catch (e) {
            console.error('Error in xpath: ' + path);
            console.error(e);
            return List();
        }
    }

    static getChildNodesParsable<T>(
        path: string, node: Node | undefined, f: (s: Node) => Option<T>, namespaces: NamespaceMap = {}): List<T> {
        return OptionUtils.flattenList(XmlUtils.getChildNodes(path, node, namespaces).map(x => f(x)));
    }

    // tl;dr all children of this path.
    static getChildNodesWildcard(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): List<Node> {
        return XmlUtils.getChildNodes(path + '/*', node, namespaces);
    }

    static getMomentAttribute(
        attributeName: string, node: Node | undefined, keepLocal: boolean = false, namespaces: NamespaceMap = {}): Option<Moment> {
        return XmlUtils.parseMomentChild('./@' + attributeName, node, keepLocal, namespaces);
    }

    static getNumberAttribute(attributeName: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<number> {
        return XmlUtils.parseNumberChild('./@' + attributeName, node, namespaces);
    }

    static getNumberAttributeForPath(
        nodePath: string, attributeName: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<number> {
        return XmlUtils.parseNumberChild(nodePath + '/@' + attributeName, node, namespaces);
    }

    static getStringAttribute(attributeName: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<string> {
        return XmlUtils.parseStringChild('./@' + attributeName, node, namespaces);
    }

    static getStringAttributeForPath(nodePath: string, attributeName: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<string> {
        return XmlUtils.parseStringChild(nodePath + '/@' + attributeName, node, namespaces);
    }

    static getTextContent(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<string> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }

        try {
            if (namespaces !== {}) {
                const select = xpath.useNamespaces(namespaces);
                return Option.of(select(path, node)[0])
                    .flatMap((x: any) => Option.of(x.textContent));
            }
            const value = xpath.select1(path, node);
            return Option.of(value)
                .flatMap((x: any) => Option.of(x.textContent));
        } catch (e) {
            console.error('Error in xpath: ' + path);
            console.error('xpath error', e);
            return None;
        }
    }

    static getTextList(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): List<string> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        if (namespaces !== {}) {
            const select = xpath.useNamespaces(namespaces);
            return OptionUtils.flattenList(List(select(path, node)).map((x: any) => Option.of(x.textContent)));
        }

        return OptionUtils.flattenList(List(xpath.select(path, node)).map((x: any) => Option.of(x.textContent)));
    }

    static parse(s: string): Option<Document> {
        if (s.trim() === '') {
            return None;
        }
        const parser = new DOMParser();
        try {
            return Option.of(parser.parseFromString(s, 'text/xml'));
        } catch (e) {
            return None;
        }
    }

    static parseBooleanChild(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<boolean> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return XmlUtils.getTextContent(path, node, namespaces).flatMap(t => parseBoolean(t));
    }

    static parseEither(s: string): Either<string, Document> {
        return EitherUtils.toEither(XmlUtils.parse(s), 'Failed to parse document');
    }

    static parseMomentChild(path: string, node: Node | undefined, keepLocal = false, namespaces: NamespaceMap = {}): Option<Moment> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return XmlUtils.getTextContent(path, node, namespaces).flatMap(t => parseDate(t, keepLocal));
    }

    static parseMomentChildWithFormat(path: string, node: Node | undefined, format: string, namespaces: NamespaceMap = {}): Option<Moment> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return XmlUtils.getTextContent(path, node, namespaces).flatMap(x => parseDateWithFormat(x, false, format));
    }

    static parseNumberChild(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<number> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return XmlUtils.getTextContent(path, node, namespaces).flatMap(t => parseNumber(t));
    }

    static parseNumberChildren(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): List<number> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return OptionUtils.flattenList(XmlUtils.getTextList(path, node, namespaces).map(t => parseNumber(t)));
    }

    static parseStringChild(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Option<string> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return XmlUtils.getTextContent(path, node, namespaces).flatMap(t => parseString(t));
    }

    static parseStringChildEither(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): Either<string, string> {
        return EitherUtils.toEither(XmlUtils.parseStringChild(path, node, namespaces), `Failed to parse string at ${path}`);
    }

    static parseStringChildren(path: string, node: Node | undefined, namespaces: NamespaceMap = {}): List<string> {
        if (!path.startsWith('./')) {
            throw new Error('Must be relative path');
        }
        return OptionUtils.flattenList(XmlUtils.getTextList(path, node, namespaces).map(t => parseString(t)));
    }

    static toString(doc: Node): string {
        try {
            return new XMLSerializer().serializeToString(doc);
        } catch (err) {
            return '';
        }
    }

    static toStringFromCollection<T>(nodes: Collection<T, Node>): Collection<T, string> {
        try {
            return nodes.map(x => new XMLSerializer().serializeToString(x));
        } catch (err) {
            return Set();
        }
    }
}
