import {List} from 'immutable';
import {JsonBuilder, parentFakeIdxKey, productIdKey, SimpleJsonSerializer, Validatable, ValidationResult} from '../core';
import {Product} from './product';

export class DbSubproductMapping implements Validatable {
    static buildFromProduct(product: Product, parentFakeIdx: number): List<DbSubproductMapping> {
        return product.getSubproducts()
            .map(s => new DbSubproductMapping(s, parentFakeIdx));
    }

    static buildFromProducts(products: List<Product>): List<DbSubproductMapping> {
        return products.flatMap((x, idx) => DbSubproductMapping.buildFromProduct(x, idx));
    }

    constructor(
        readonly product: Product,
        readonly parentFakeIdx: number) {
    }

    validate(): ValidationResult {
        return ValidationResult.empty;
    }

}

export class DbSubproductMappingJsonSerializer extends SimpleJsonSerializer<DbSubproductMapping> {
    static instance: DbSubproductMappingJsonSerializer = new DbSubproductMappingJsonSerializer();

    fromJsonImpl(obj: any): DbSubproductMapping {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(mapping: DbSubproductMapping, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(productIdKey, mapping.product.id)
            .add(parentFakeIdxKey, mapping.parentFakeIdx);
    }
}
