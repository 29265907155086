import {Option} from 'funfix-core';
import {Set} from 'immutable';
import {Observable} from 'rxjs';
import {Crud, Entry} from './crud';

export class DelegatingCrud<K, V> extends Crud<K, V> {

    constructor(readonly delegate: Crud<K, V>) {
        super();
    }

    delete(k: K): Promise<Option<V>> {
        return this.delegate.delete(k);
    }

    deleteAll(): Promise<void> {
        return this.delegate.deleteAll();
    }

    entries(): Promise<Set<Entry<K, V>>> {
        return this.delegate.entries();
    }

    getLast(k: K): Promise<Option<V>> {
        return this.delegate.getLast(k);
    }

    hasKey(k: K): Promise<boolean> {
        return this.delegate.hasKey(k);
    }

    keys(): Promise<Set<K>> {
        return this.delegate.keys();
    }

    observe(k: K): Observable<Option<V>> {
        return this.delegate.observe(k);
    }

    set(k: K, v: V): Promise<Option<V>> {
        return this.delegate.set(k, v);
    }

    values(): Promise<Set<V>> {
        return this.delegate.values();
    }
}
