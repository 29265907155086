import {Either, None, Option, Some} from 'funfix-core';
import {Map} from 'immutable';
import {EitherUtils} from './either-utils';

export type Language =
    'English'
    | 'German'
    | 'French'
    | 'Dutch'
    | 'Swedish'
    | 'Danish'
    | 'Italian'
    | 'Spanish'
    | 'Korean'
    | 'Polish'
    | 'Chinese';

// Supported languages in the api/eproposal and app
export class Languages {
    static languages: Map<string, number> = Map({
        'English': 7,
        'English-Base': 7,
        'French': 8,
        'German': 9,
        'Dutch': 10,
        'Swedish': 11,
        'Danish': 12,
        'Italian': 16,
        'Spanish': 17,
        'Chinese': 26,
        'Korean': 40,
        'Polish': 41,
    });

    static getLanguageId(language: Language): Option<number> {
        return Option.of(Languages.languages.get(language));
    }

    static getLanguageIdEither(language: Language): Either<string, number> {
        return EitherUtils.toEither(Languages.getLanguageId(language), `Language ${language} is missing language id`);
    }

    static isSupportedLanguage(language: string): boolean {
        return this.parseLanguage(language)
            .flatMap(l => Languages.getLanguageId(l))
            .nonEmpty();
    }

    static parseLanguage(string: string): Option<Language> {
        switch (string) {
            case 'English':
            case 'English-Base':
                return Some('English');
            case 'German':
                return Some('German');
            case 'French':
                return Some('French');
            case 'Dutch':
                return Some('Dutch');
            case 'Swedish':
                return Some('Swedish');
            case 'Danish':
                return Some('Danish');
            case 'Italian':
                return Some('Italian');
            case 'Spanish':
                return Some('Spanish');
            case 'Chinese':
                return Some('Chinese');
            case 'Korean':
                return Some('Korean');
            case 'Polish':
                return Some('Polish');
            default:
                return None;
        }
    }

    static parseLanguageEither(string: string): Either<string, Language> {
        return EitherUtils.toEither(Languages.parseLanguage(string), `${string} is not a supported language`);
    }
}
