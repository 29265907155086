import {Some} from 'funfix-core';
import {List} from 'immutable';
import {
    confidenceKey,
    hashKey,
    JsonBuilder,
    keywordKey,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Image} from './image';

export class DbImageKeyword implements Validatable {

    constructor(
        readonly sha256Hash: string,
        readonly keyword: string,
        readonly confidence: number,
    ) { }

    static fromImage(image: Image): List<DbImageKeyword> {
        return image.hash
            .map(h => image.keywordMap.entrySeq()
                .map(([k, v]) => new DbImageKeyword(h, k, v))
                .toList())
            .getOrElse(List());
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateInt('sha256Hash', this.sha256Hash)),
            Some(ValidationUtils.validateVarchar('keyword', this.keyword, 100)),
            Some(ValidationUtils.validateInt('confidence', this.confidence.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbImageKeywordJsonSerializer extends SimpleJsonSerializer<DbImageKeyword> {
    static instance: DbImageKeywordJsonSerializer = new DbImageKeywordJsonSerializer();

    fromJsonImpl(obj: any): DbImageKeyword {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(image: DbImageKeyword, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(hashKey, image.sha256Hash)
            .add(keywordKey, image.keyword)
            .add(confidenceKey, image.confidence);
    }

}
