import {Either, None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {ChainableComparator, ValueComparator} from 'ts-comparators';
import * as _s from 'underscore.string';
import {
    aboutKey,
    accessorsKey,
    agentKey,
    budgetKey,
    CollectionUtils,
    companyKey,
    ComparisonUtils,
    computeEndTime,
    computeStartTime,
    createdTimeKey,
    currencyKey,
    descriptionKey,
    EitherUtils,
    endKey,
    entriesKey,
    exclusionsKey,
    extraNotesKey,
    financialsKey,
    helpfulHintsKey,
    idKey,
    importantContactsKey,
    inclusionsKey,
    isMomentBetween,
    isTodayBetween,
    JsonBuilder,
    languageKey,
    mapKey,
    modifiedTimeKey,
    moduleKey,
    notesKey,
    OptionUtils,
    parseDate,
    parseListSerializable,
    parseNumber,
    parseSet,
    parseString,
    pdfKey,
    peopleKey,
    presentAsKey,
    priceDescriptionKey,
    priceHeaderKey,
    proposalTypeKey,
    referenceKey,
    SimpleJsonSerializer,
    sortingKey,
    startKey,
    statusKey,
    templateIdKey,
    termsKey,
    titleKey,
    travellersKey,
    welcomeKey,
} from '../core';
import {Budget, BudgetJsonSerializer} from './budget';
import {CompanyJsonSerializer, CompanyLike} from './company';
import {DownloadType} from './contextual-image';
import {Financials, FinancialsJsonSerializer} from './financials';
import {GMap, GoogleMapJsonSerializer} from './gmap';
import {Image} from './image';
import {Module, ModuleJsonSerializer} from './modules';
import {Note, NoteJsonSerializer} from './note';
import {Pdf, PdfJsonSerializer} from './pdf';
import {AgentDetails, Person, PersonJsonSerializer} from './person';
import {Product} from './product';
import {ProposalEntry, ProposalEntryJsonSerializer, ProposalEntryLike} from './proposal-entry';
import {Rating, RatingUtils} from './rating';
import {Timeframed} from './timeframed';
import {Travellers, TravellersJsonSerializer} from './travellers';
import {Waypoint, WaypointJsonSerializer} from './waypoint';
import {Welcome, WelcomeJsonSerializer} from './welcome';

export abstract class ProposalLike extends Timeframed {

    buildProposal(): Option<Proposal> {
        return Option.of(
            new Proposal(
                this.getId(),
                this.getTitle(),
                this.getDescription(),
                this.getAbout(),
                this.getTemplateId(),
                this.getReference(),
                this.getInclusions(),
                this.getExclusions(),
                this.getPriceHeader(),
                this.getPriceDescription(),
                this.getTerms(),
                this.getNotes(),
                this.getSorting(),
                this.getWelcome(),
                this.getTravellers(),
                this.getCompany(),
                this.getPresentAsCompany(),
                this.getStartWaypoint(),
                this.getEndDateTime(),
                this.getAgent(),
                this.getBudget(),
                this.getMap(),
                this.getPdf(),
                this.getStatus(),
                this.getPeople(),
                this.getEntries(),
                this.getLanguage(),
                this.getCurrency(),
                this.getCustomNotes(),
                this.getFinancials(),
                this.getCompaniesWithAccess(),
                this.getModule(),
                this.getProposalType(),
                this.getCreated(),
                this.getModified(),
            ),
        );
    }

    buildProposalEither(): Either<string, Proposal> {
        return EitherUtils.toEither(this.buildProposal(), 'Failed to build proposal');
    }

    calculateLeisureDays(): List<ProposalEntry> {
        const days = this.getDatesDuring().filter(x => this.isCalculatedAsLeisureDay(x));
        return days.map(x => ProposalEntry.quickBuildCustomEntry(
            Some('Day at Leisure'),
            Some('Day at Leisure'),
            Some('Day at Leisure'),
            Some(x),
        ));
    }

    calculateTransitDays(): List<ProposalEntry> {
        const days = this.getDatesDuring().filter(x => this.isCalculatedAsTransitDay(x));
        return days.map(x => ProposalEntry.quickBuildCustomEntry(
            Some('Day in Transit'),
            Some('Day in Transit'),
            Some('Day in Transit'),
            Some(x),
        ));
    }

    containsEntry(id: string): boolean {
        return this.getEntries().find(i => i.id.map(v => v.toString()).contains(id)) !== undefined;
    }

    createdDuring(start: Moment, end: Moment): boolean {
        return this.getCreated().exists(s => isMomentBetween(Some(start), Some(end), s));
    }

    private estimateThumbSize(size: number): number {
        return size * 0.15;
    }

    getAbout(): Option<string> {
        return None;
    }

    getAccessors(): Set<number> {
        return Set();
    }

    getAccommodationPairs(): List<readonly [ProposalEntry, ProposalEntry]> {
        return this.getSortedEntries()
            .filter(x => x.isAccommodation() && this.getNextAccommodation(x).nonEmpty())
            .map(x => [x, this.getNextAccommodation(x).get()] as const);
    }

    getAccommodations(): List<ProposalEntry> {
        return this.getSortedEntries().filter(x => x.isAccommodation());
    }

    // GetPeople returns all pax, we need non-primary
    getAdditionalPeople(): List<Person> {
        return this.getPeople().shift();
    }

    getAgent(): Option<Person> {
        return None;
    }

    getAgentDetails(): AgentDetails {
        return new AgentDetails(this.getAgent(), this.getCompany());
    }

    getAgentFullName(): Option<string> {
        return this.getAgent().flatMap(a => a.getFullName());
    }

    // Days before, during or after depending on state
    getAnalyticDays(): Option<number> {
        if (this.isCurrentlyBefore()) {
            return this.getDaysBeforeStart();
        } else if (this.isCurrentlyAfter()) {
            return this.getDaysSinceEnd();
        } else if (this.isCurrentlyTravelling()) {
            return this.getDaysSinceStart();
        }
        return None;
    }

    getApiAgencyId(): Option<number> {
        return this.getFirstNote('API_AGENCY')
            .flatMap(x => parseNumber(x));
    }

    getApiStatus(): Option<string> {
        return this.getFirstNote('API_STATUS');
    }

    getApiTemplateId(): Option<number> {
        return this.getFirstNote('API_TEMPLATE')
            .flatMap(x => parseNumber(x));
    }

    getBudget(): Option<Budget> {
        return None;
    }

    getCompaniesWithAccess(): Set<number> {
        return this.getAccessors()
          .union(OptionUtils.toSet(this.getCompany().flatMap(x => x.getId())));
    }

    getCompany(): Option<CompanyLike> {
        return None;
    }

    getCompanyId(): Option<number> {
        return this.getCompany().flatMap(c => c.getId());
    }

    getCompanyIdEither(): Either<string, number> {
        return EitherUtils.toEither(this.getCompany().flatMap(c => c.getId()), 'Unable to get company id');
    }

    getCompanyName(): Option<string> {
        return this.getCompany()
          .flatMap(c => c.getName());
    }

    getConsult(): Option<string> {
        return this.getFirstNote('CONSULT');
    }

    getCreated(): Option<Moment> {
        return None;
    }

    getCurrency(): Option<string> {
        return None;
    }

    // Returns the current state based on current time.
    // For example, whether its before or after the trip
    getCurrentState(): 'before' | 'after' | 'in-progress' | 'unknown' {
        if (this.isCurrentlyBefore()) {
            return 'before';
        } else if (this.isCurrentlyAfter()) {
            return 'after';
        } else if (this.isCurrentlyTravelling()) {
            return 'in-progress';
        }
        return 'unknown';
    }

    getCustomNotes(): List<Note> {
        return List();
    }

    getDayTours(): List<ProposalEntry> {
        return this.getEntries()
          .filter(x => x.isDayTour());
    }

    getDescription(): Option<string> {
        return None;
    }

    getDestinations(): List<ProposalEntry> {
        return this.getEntries()
          .filter(x => x.isDestination());
    }

    getDisplayCompany(): Option<CompanyLike> {
        return this.getPresentAsCompany()
          .orElse(this.getCompany());
    }

    getDisplayCompanyLogo(): Option<Image> {
        return this.getDisplayCompany()
          .flatMap(x => x.getLogo());
    }

    getDisplayCompanyName(): Option<string> {
        return this.getDisplayCompany()
          .flatMap(x => x.getName());
    }

    getEndDateTime(): Option<Moment> {
        return computeEndTime(this.getEntries())
            .orElse(this.getStartDateTime());
    }

    getEntries(): List<ProposalEntry> {
        return List();
    }

    getEntryByProductId(i: number): Option<ProposalEntry> {
        return Option.of(this.getEntries().find(x => x.getProductId().contains(i)));
    }

    getEntryIds(): List<number> {
        return OptionUtils.flattenList(this.getEntries().map(e => e.id));
    }

    getEntrySorter(): ChainableComparator<ProposalEntry> {
        if (this.getSorting().contains('Date')) {
            return ProposalEntryLike.entryMomentComparator;
        }
        if (this.getSorting().contains('Sequence')) {
            return ProposalEntryLike.entrySequenceComparator;
        }
        return ProposalEntryLike.entryDayComparator
            .then(ProposalEntryLike.entrySequenceComparator)
            .then(ProposalEntryLike.entryMomentComparator);
    }

    getEstimatedDownloadSize(dlType: DownloadType): Option<number> {
        const allImages = this.getImagesToDownload(dlType);
        if (allImages.isEmpty()) {
            return None;
        } else {
            return Option.of(allImages.map(i => i.getMebiBytes()).reduce((a, b) => ((b.exists(mb => mb > 5) || (dlType !== 'Optimal' && b.nonEmpty())) ? this.estimateThumbSize(b.get()) : b.getOrElse(0)) + a, 0),
            );
        }
    }

    getExclusions(): Option<string> {
        return None;
    }

    getFinancials(): Option<Financials> {
        return None;
    }

    getFirstNote(k: string): Option<string> {
        return Option.of(this.getCustomNotes().find(n => n.classifications.contains(k))).flatMap(n => n.note);
    }

    getFlights(): List<ProposalEntry> {
        return this.getEntries()
            .filter(x => x.isFlight());
    }

    getHelpfulHints(): Option<string> {
        return None;
    }

    getId(): Option<number> {
        return None;
    }

    getImagesToDownload(downloadType: DownloadType): List<Image> {
        return this.getEntries()
            .flatMap(e => e.getImagesToDownload(downloadType));
    }

    getImportantContacts(): Option<string> {
        return None;
    }

    getInclusions(): Option<string> {
        return None;
    }

    getLanguage(): Option<string> {
        return Some('English');
    }

    getMap(): Option<GMap> {
        return None;
    }

    getModified(): Option<Moment> {
        return None;
    }

    getModule(): Option<Module> {
        return None;
    }

    getMultidayTours(): List<ProposalEntry> {
        return this.getEntries()
          .filter(x => x.isMultidayTour());
    }

    getNextAccommodation(e: ProposalEntry): Option<ProposalEntry> {
        const accommodations = this.getAccommodations();
        const idx = accommodations.indexOf(e);
        if (idx === -1) {
            return None;
        }
        return Option.of(accommodations.get(idx + 1));
    }

    getNotes(): Option<string> {
        return None;
    }

    getOwner(): Option<CompanyLike> {
        return this.getCompany();
    }

    getPdf(): Option<Pdf> {
        return None;
    }

    getPeople(): List<Person> {
        return List();
    }

    getPresentAsCompany(): Option<CompanyLike> {
        return this.getCompany();
    }

    getPriceDescription(): Option<string> {
        return None;
    }

    getPriceHeader(): Option<string> {
        return None;
    }

    getPrimaryPerson(): Option<Person> {
        return Option.of(this.getPeople().first());
    }

    getProposalDisplayTitle(isStaff: boolean): string {
        if (isStaff) {
            const lastName = Option.of(this.getPeople().first()).flatMap(p => p.lastName);
            return Option.map2(lastName, this.getReference(), (ln, r) => _s.titleize(ln) + ' - ' + r)
                .orElse(this.getReference())
                .getOrElse('Untitled Proposal');
        }
        return this.getWelcome()
            .flatMap(w => w.title)
            .getOrElse('Your Trip');
    }

    getProposalType(): Option<string> {
        return None;
    }

    getQuickstartId(): Option<string> {
        return this.getId()
          .map(i => btoa(`PR${i}`));
    }

    getReference(): Option<string> {
        return None;
    }

    getSelfDrives(): List<ProposalEntry> {
        return this.getEntries()
            .filter(x => x.isSelfDrive());
    }

    getSortedEntries(): List<ProposalEntry> {
        return this.getEntries()
            .sort((a, b) => this.getEntrySorter().compare(a, b));
    }

    getSorting(): Option<string> {
        return Some(Proposal.defaultEntrySorting);
    }

    getStartDateTime(): Option<Moment> {
        return computeStartTime(this.getEntries()).orElse(this.getStartWaypoint().flatMap(s => s.time));
    }

    getStartWaypoint(): Option<Waypoint> {
        return None;
    }

    getStatus(): Option<string> {
        return None;
    }

    getTemplateId(): Option<number> {
        return None;
    }

    getTerms(): Option<string> {
        return None;
    }

    getTitle(): Option<string> {
        return None;
    }

    getTravellers(): Option<Travellers> {
        return None;
    }

    getWelcome(): Option<Welcome> {
        return None;
    }

    getWelcomeDescription(): Option<string> {
        return this.getWelcome()
            .flatMap(x => x.description);
    }

    getWelcomeImage(): Option<Image> {
        return this.getWelcome()
            .flatMap(x => x.image);
    }

    getWelcomeTitle(): Option<string> {
        return this.getWelcome()
            .flatMap(x => x.title);
    }

    hasAccess(companyId: number): boolean {
        return this.isOwner(companyId)
            || this.getCompaniesWithAccess().contains(companyId);
    }

    // Has any free text about the proposal excluding quote related fields and welcome.
    hasCustomInfo(): boolean {
        return !OptionUtils.toList(
            this.getAbout(),
            this.getDescription(),
            this.getInclusions(),
            this.getExclusions(),
            this.getNotes(),
        ).isEmpty();
    }

    isCalculatedAsLeisureDay(m: Moment): boolean {
        return CollectionUtils.none(this.getEntries(), e => e.isNonLeisureActivityOnDay(m));
    }

    isCalculatedAsTransitDay(m: Moment): boolean {
        const entriesDuringToday = this.getEntries()
          .filter(e => e.isDuring(m));
        if (entriesDuringToday.isEmpty()) {
            return false;
        }
        return entriesDuringToday.every(e => e.isTransport());
    }

    isCurrentlyTravelling(): boolean {
        return (this.getStatus().contains('Travelling') || this.getStatus().contains('Confirmed'))
            && isTodayBetween(this.getStartWaypoint().flatMap(s => s.time), this.getEndDateTime());
    }

    isDisplayingAsChildCompany(): boolean {
        return Option.map2(
            this.getOwner().flatMap(x => x.getId()),
            this.getDisplayCompany().flatMap(x => x.getId()),
            (a, b) => a !== b)
            .contains(true);
    }

    isDisplayingAsOwner(): boolean {
        return !this.isDisplayingAsChildCompany();
    }

    isOnBehalfOf(): boolean {
        return this.getDescription()
            .exists(s => _s.contains(s.toLowerCase(), 'on behalf'));
    }

    isOwner(companyId: number): boolean {
        return this.getCompany()
            .flatMap(x => x.getId()).contains(companyId);
    }

    isPrimaryPerson(person: Person): boolean {
        return person.id
          .fold(() => false, i => this.isPrimaryPersonById(i));
    }

    // Note: this relies on the primary person being the first in the list, we may want to tweak this later
    isPrimaryPersonById(id: number): boolean {
        return Option.of(this.getPeople().first()).exists(p => p.id.contains(id));
    }

    isQuote(): boolean {
        return this.getStatus()
            .contains('Quote');
    }

    isReference(ref: string): boolean {
        return this.getReference()
          .contains(ref);
    }

    modifiedDuring(start: Moment, end: Moment): boolean {
        return this.getModified()
            .exists(s => isMomentBetween(Some(start), Some(end), s));
    }

    replaceEntry(id: number, entry: ProposalEntry): List<ProposalEntry> {
        if (!this.getEntries().some(k => k.id.contains(id))) {
            return this.getEntries().push(entry);
        }
        return this.getEntries().map(e => {
            if (e.id.contains(id)) {
                return entry;
            } else {
                return e;
            }
        });
    }
}

export class Proposal extends ProposalLike {

    constructor(
        readonly id: Option<number> = None,
        readonly title: Option<string> = None,
        readonly description: Option<string> = None,
        readonly about: Option<string> = None,
        readonly templateId: Option<number> = None,
        readonly reference: Option<string> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly priceHeader: Option<string> = None,
        readonly priceDescription: Option<string> = None,
        readonly terms: Option<string> = None,
        readonly notes: Option<string> = None,
        readonly sorting: Option<string> = Some(Proposal.defaultEntrySorting),
        readonly welcome: Option<Welcome> = None,
        readonly travellers: Option<Travellers> = None,
        readonly company: Option<CompanyLike> = None,
        readonly presentAsCompany: Option<CompanyLike> = None,
        readonly start: Option<Waypoint> = None,
        readonly end: Option<Moment> = None,
        readonly agent: Option<Person> = None,
        readonly budget: Option<Budget> = None,
        readonly map: Option<GMap> = None,
        readonly pdf: Option<Pdf> = None,
        readonly status: Option<string> = None,
        readonly people: List<Person> = List(),
        readonly entries: List<ProposalEntry> = List(),
        readonly language: Option<string> = None,
        readonly currency: Option<string> = None,
        readonly customNotes: List<Note> = List(),
        readonly financials: Option<Financials> = None,
        readonly accessors: Set<number> = Set(),
        readonly module: Option<Module> = None,
        readonly proposalType: Option<string> = None,
        readonly created: Option<Moment> = None,
        readonly modified: Option<Moment> = None,
        readonly helpfulHints: Option<string> = None,
        readonly importantContacts: Option<string> = None,
    ) {
        super();
    }

    static defaultEntrySorting = 'DaySequence';

    static proposalStartComparator =
        new ValueComparator<ProposalLike, Option<Moment>>(e => e.getStartDateTime(), ComparisonUtils.optionDayComparator);

    generateLeisureDays(): Proposal {
        return this.withEntries(this.entries.concat(this.calculateLeisureDays()));
    }

    generateTransitDays(): Proposal {
        return this.withEntries(this.entries.concat(this.calculateTransitDays()));
    }

    getAbout(): Option<string> {
        return this.about;
    }

    getAccessors(): Set<number> {
        return this.accessors;
    }

    getAgent(): Option<Person> {
        return this.agent;
    }

    getBudget(): Option<Budget> {
        return this.budget;
    }

    getCompany(): Option<CompanyLike> {
        return this.company;
    }

    getCreated(): Option<Moment> {
        return this.created;
    }

    getCurrency(): Option<string> {
        return this.currency;
    }

    getCustomNotes(): List<Note> {
        return this.customNotes;
    }

    getDescription(): Option<string> {
        return this.description;
    }

    getEndDateTime(): Option<Moment> {
        return this.end;
    }

    getEntries(): List<ProposalEntry> {
        return this.entries;
    }

    getExclusions(): Option<string> {
        return this.exclusions;
    }

    getFinancials(): Option<Financials> {
        return this.financials;
    }

    getHelpfulHints(): Option<string> {
        return this.helpfulHints;
    }

    getId(): Option<number> {
        return this.id;
    }

    getImportantContacts(): Option<string> {
        return this.importantContacts;
    }

    getInclusions(): Option<string> {
        return this.inclusions;
    }

    getLanguage(): Option<string> {
        return this.language;
    }

    getMap(): Option<GMap> {
        return this.map;
    }

    getModified(): Option<Moment> {
        return this.modified;
    }

    getModule(): Option<Module> {
        return this.module;
    }

    getNotes(): Option<string> {
        return this.notes;
    }

    getOverallScore(): Rating {
        return RatingUtils.average(this.getProducts().map(x => x.getOverallScore()));
    }

    getPdf(): Option<Pdf> {
        return this.pdf;
    }

    getPeople(): List<Person> {
        return this.people;
    }

    getPresentAsCompany(): Option<CompanyLike> {
        return this.presentAsCompany;
    }

    getPriceDescription(): Option<string> {
        return this.priceDescription;
    }

    getPriceHeader(): Option<string> {
        return this.priceHeader;
    }

    getProducts(): List<Product> {
        return OptionUtils.flattenList(this.getEntries().map(x => x.getProduct()));
    }

    getProposalType(): Option<string> {
        return this.proposalType;
    }

    getReference(): Option<string> {
        return this.reference;
    }

    getSorting(): Option<string> {
        return this.sorting;
    }

    getStartWaypoint(): Option<Waypoint> {
        return this.start;
    }

    getStatus(): Option<string> {
        return this.status;
    }

    getTemplateId(): Option<number> {
        return this.templateId;
    }

    getTerms(): Option<string> {
        return this.terms;
    }

    getTitle(): Option<string> {
        return this.title;
    }

    getTravellers(): Option<Travellers> {
        return this.travellers;
    }

    getWelcome(): Option<Welcome> {
        return this.welcome;
    }

    hideDuplicateApiReferencesFromPdf(): Proposal {
        const newEntries = this.entries.map((e, idx) => {
            if (e.getProductApiReference().isEmpty()) {
                return e;
            }
            const notFirstEntry = this.entries.findIndex(x => x.getProductApiReference().exists(ref => e.getProductApiReference().contains(ref))) !== idx;
            if (notFirstEntry) {
                return e.addClassifications('Hidden in PDF');
            }
            return e;
        });
        return this.withEntries(newEntries);
    }

    merge(other: Proposal): Proposal {
        return new Proposal(
            this.getId().orElse(other.getId()),
            this.getTitle().orElse(other.getTitle()),
            this.getDescription().orElse(other.getDescription()),
            this.getAbout().orElse(other.getAbout()),
            this.getTemplateId().orElse(other.getTemplateId()),
            this.getReference().orElse(other.getReference()),
            this.getInclusions().orElse(other.getInclusions()),
            this.getExclusions().orElse(other.getExclusions()),
            this.getPriceHeader().orElse(other.getPriceHeader()),
            this.getPriceDescription().orElse(other.getPriceDescription()),
            this.getTerms().orElse(other.getTerms()),
            this.getNotes().orElse(other.getNotes()),
            this.getSorting().orElse(other.getSorting()),
            OptionUtils.applyOrReturnNonEmpty(this.getWelcome(), other.getWelcome(), (a, b) => a.merge(b)),
            this.getTravellers().orElse(other.getTravellers()),
            this.getCompany().orElse(other.getCompany()),
            this.getPresentAsCompany().orElse(other.getPresentAsCompany()),
            this.getStartWaypoint().orElse(other.getStartWaypoint()),
            this.getEndDateTime().orElse(other.getEndDateTime()),
            this.getAgent().orElse(other.getAgent()),
            this.getBudget().orElse(other.getBudget()),
            this.getMap().orElse(other.getMap()),
            this.getPdf().orElse(other.getPdf()),
            this.getStatus().orElse(other.getStatus()),
            this.getPeople().concat(other.getPeople()), // TODO: consider merging alike?
            this.getEntries().concat(other.getEntries()), // TODO: consider merging alike?
            this.getLanguage().orElse(other.getLanguage()),
            this.getCurrency().orElse(other.getCurrency()),
            this.getCustomNotes().concat(other.getCustomNotes()),  // TODO: consider merging alike?
            this.getFinancials().orElse(other.getFinancials()),
            this.getAccessors().concat(other.getAccessors()),  // TODO: consider merging alike?
            this.getModule().orElse(other.getModule()),
            this.getProposalType().orElse(other.getProposalType()),
            this.getCreated().orElse(other.getCreated()),
            this.getModified().orElse(other.getModified()),
            this.getHelpfulHints().orElse(other.getHelpfulHints()),
            this.getImportantContacts().orElse(other.getImportantContacts()),
        );
    }

    withEntries(entries: List<ProposalEntry>): Proposal {
        return new Proposal(
            this.getId(),
            this.getTitle(),
            this.getDescription(),
            this.getAbout(),
            this.getTemplateId(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            this.getPriceHeader(),
            this.getPriceDescription(),
            this.getTerms(),
            this.getNotes(),
            this.getSorting(),
            this.getWelcome(),
            this.getTravellers(),
            this.getCompany(),
            this.getPresentAsCompany(),
            this.getStartWaypoint(),
            this.getEndDateTime(),
            this.getAgent(),
            this.getBudget(),
            this.getMap(),
            this.getPdf(),
            this.getStatus(),
            this.getPeople(),
            entries,
            this.getLanguage(),
            this.getCurrency(),
            this.getCustomNotes(),
            this.getFinancials(),
            this.getAccessors(),
            this.getModule(),
            this.getProposalType(),
            this.getCreated(),
            this.getModified(),
            this.getHelpfulHints(),
            this.getImportantContacts(),
        );
    }

    withId(id: Option<number>): Proposal {
        return new Proposal(
            id,
            this.getTitle(),
            this.getDescription(),
            this.getAbout(),
            this.getTemplateId(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            this.getPriceHeader(),
            this.getPriceDescription(),
            this.getTerms(),
            this.getNotes(),
            this.getSorting(),
            this.getWelcome(),
            this.getTravellers(),
            this.getCompany(),
            this.getPresentAsCompany(),
            this.getStartWaypoint(),
            this.getEndDateTime(),
            this.getAgent(),
            this.getBudget(),
            this.getMap(),
            this.getPdf(),
            this.getStatus(),
            this.getPeople(),
            this.getEntries(),
            this.getLanguage(),
            this.getCurrency(),
            this.getCustomNotes(),
            this.getFinancials(),
            this.getAccessors(),
            this.getModule(),
            this.getProposalType(),
            this.getCreated(),
            this.getModified(),
            this.getHelpfulHints(),
            this.getImportantContacts(),
        );
    }

    withPdf(pdf: Option<Pdf>): Proposal {
        return new Proposal(
            this.getId(),
            this.getTitle(),
            this.getDescription(),
            this.getAbout(),
            this.getTemplateId(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            this.getPriceHeader(),
            this.getPriceDescription(),
            this.getTerms(),
            this.getNotes(),
            this.getSorting(),
            this.getWelcome(),
            this.getTravellers(),
            this.getCompany(),
            this.getPresentAsCompany(),
            this.getStartWaypoint(),
            this.getModified(),
            this.getAgent(),
            this.getBudget(),
            this.getMap(),
            pdf,
            this.getStatus(),
            this.getPeople(),
            this.getEntries(),
            this.getLanguage(),
            this.getCurrency(),
            this.getCustomNotes(),
            this.getFinancials(),
            this.getAccessors(),
            this.getModule(),
            this.getProposalType(),
            this.getCreated(),
            this.getModified(),
            this.getHelpfulHints(),
            this.getImportantContacts(),
        );
    }

    withType(type: string): Proposal {
        return new Proposal(
            this.getId(),
            this.getTitle(),
            this.getDescription(),
            this.getAbout(),
            this.getTemplateId(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            this.getPriceHeader(),
            this.getPriceDescription(),
            this.getTerms(),
            this.getNotes(),
            this.getSorting(),
            this.getWelcome(),
            this.getTravellers(),
            this.getCompany(),
            this.getPresentAsCompany(),
            this.getStartWaypoint(),
            this.getEndDateTime(),
            this.getAgent(),
            this.getBudget(),
            this.getMap(),
            this.getPdf(),
            this.getStatus(),
            this.getPeople(),
            this.getEntries(),
            this.getLanguage(),
            this.getCurrency(),
            this.getCustomNotes(),
            this.getFinancials(),
            this.getAccessors(),
            this.getModule(),
            Some(type),
            this.getCreated(),
            this.getModified(),
            this.getHelpfulHints(),
            this.getImportantContacts(),
        );
    }
}

export class ProposalJsonSerializer extends SimpleJsonSerializer<Proposal> {
    static instance: ProposalJsonSerializer = new ProposalJsonSerializer();

    fromJsonImpl(obj: any): Proposal {
        return new Proposal(
            parseNumber(obj[idKey]),
            parseString(obj[titleKey]),
            parseString(obj[descriptionKey]),
            parseString(obj[aboutKey]),
            parseNumber(obj[templateIdKey]),
            parseString(obj[referenceKey]),
            parseString(obj[inclusionsKey]),
            parseString(obj[exclusionsKey]),
            parseString(obj[priceHeaderKey]),
            parseString(obj[priceDescriptionKey]),
            parseString(obj[termsKey]),
            parseString(obj[notesKey]),
            parseString(obj[sortingKey]),
            WelcomeJsonSerializer.instance.fromJson(obj[welcomeKey]),
            TravellersJsonSerializer.instance.fromJson(obj[travellersKey]),
            CompanyJsonSerializer.instance.fromJson(obj[companyKey]),
            CompanyJsonSerializer.instance.fromJson(obj[presentAsKey]),
            WaypointJsonSerializer.instance.fromJson(obj[startKey]),
            parseDate(obj[endKey]),
            PersonJsonSerializer.instance.fromJson(obj[agentKey]),
            BudgetJsonSerializer.instance.fromJson(obj[budgetKey]),
            GoogleMapJsonSerializer.instance.fromJson(obj[mapKey]),
            PdfJsonSerializer.instance.fromJson(obj[pdfKey]),
            parseString(obj[statusKey]),
            parseListSerializable(obj[peopleKey], PersonJsonSerializer.instance),
            parseListSerializable(obj[entriesKey], ProposalEntryJsonSerializer.instance),
            parseString(obj[languageKey]),
            parseString(obj[currencyKey]),
            parseListSerializable(obj[extraNotesKey], NoteJsonSerializer.instance),
            FinancialsJsonSerializer.instance.fromJson(obj[financialsKey]),
            parseSet(obj[accessorsKey], parseNumber),
            ModuleJsonSerializer.instance.fromJson(obj[moduleKey]),
            parseString(obj[proposalTypeKey]),
            parseDate(obj[createdTimeKey]),
            parseDate(obj[modifiedTimeKey]),
            parseString(obj[helpfulHintsKey]),
            parseString(obj[importantContactsKey]),
        );
    }

    toJsonImpl(proposal: Proposal, builder: JsonBuilder): any {
        return builder
            .addOptional(idKey, proposal.getId())
            .addOptional(titleKey, proposal.getTitle())
            .addOptional(descriptionKey, proposal.getDescription())
            .addOptional(aboutKey, proposal.getAbout())
            .addOptional(templateIdKey, proposal.getTemplateId())
            .addOptional(referenceKey, proposal.getReference())
            .addOptional(inclusionsKey, proposal.getInclusions())
            .addOptional(exclusionsKey, proposal.getExclusions())
            .addOptional(priceHeaderKey, proposal.getPriceHeader())
            .addOptional(priceDescriptionKey, proposal.getPriceDescription())
            .addOptional(termsKey, proposal.getTerms())
            .addOptional(notesKey, proposal.getNotes())
            .addOptional(sortingKey, proposal.getSorting())
            .addOptional(statusKey, proposal.getStatus())
            .addOptionalSerializable(welcomeKey, proposal.getWelcome(), WelcomeJsonSerializer.instance)
            .addOptionalSerializable(travellersKey, proposal.getTravellers(), TravellersJsonSerializer.instance)
            .addOptionalSerializable(companyKey, proposal.getCompany(), CompanyJsonSerializer.instance)
            .addOptionalSerializable(presentAsKey, proposal.getPresentAsCompany(), CompanyJsonSerializer.instance)
            .addOptionalSerializable(startKey, proposal.getStartWaypoint(), WaypointJsonSerializer.instance)
            .addOptionalDate(endKey, proposal.getEndDateTime())
            .addOptionalDate(createdTimeKey, proposal.getCreated())
            .addOptionalDate(modifiedTimeKey, proposal.getModified())
            .addOptionalSerializable(agentKey, proposal.getAgent(), PersonJsonSerializer.instance)
            .addOptionalSerializable(budgetKey, proposal.getBudget(), BudgetJsonSerializer.instance)
            .addOptionalSerializable(mapKey, proposal.getMap(), GoogleMapJsonSerializer.instance)
            .addOptionalSerializable(pdfKey, proposal.getPdf(), PdfJsonSerializer.instance)
            .addIterableSerializable(peopleKey, proposal.getPeople(), PersonJsonSerializer.instance)
            .addIterableSerializable(entriesKey, proposal.getEntries(), ProposalEntryJsonSerializer.instance)
            .addOptional(languageKey, proposal.getLanguage())
            .addOptional(currencyKey, proposal.getCurrency())
            .addIterableSerializable(extraNotesKey, proposal.getCustomNotes(), NoteJsonSerializer.instance)
            .addOptionalSerializable(financialsKey, proposal.getFinancials(), FinancialsJsonSerializer.instance)
            .addIterable(accessorsKey, proposal.getAccessors())
            .addOptionalSerializable(moduleKey, proposal.getModule(), ModuleJsonSerializer.instance)
            .addOptional(proposalTypeKey, proposal.getProposalType())
            .addOptional(helpfulHintsKey, proposal.getHelpfulHints())
            .addOptional(importantContactsKey, proposal.getImportantContacts());
    }
}
