import { SimpleJsonSerializer } from '../json-serializer';
import { Crud } from './crud';
import { DelegatingCrud } from './delegating-crud';
import { PrefixedCrud } from './prefixed-crud';
import { SerializationCrud } from './serialization-crud';

/**
 * Note: Only use with flat storage options like K,V stores
 * in which the key also contains the prefix.
 */
export class PrefixedSerializationFlatStorageCrud<V> extends DelegatingCrud<string, V> {

    constructor(
        readonly prefix: string,
        readonly serializer: SimpleJsonSerializer<V>,
        readonly underlying: Crud<string, string>) {
        super(new SerializationCrud(serializer, new PrefixedCrud(prefix, underlying)));
    }
}
