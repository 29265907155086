import {Some} from 'funfix-core';
import {
    countryIdKey,
    JsonBuilder,
    nameKey,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {DbCountry} from './db-country';

export class DbState implements Validatable {
    static toBeDefinedStateId = 67;

    constructor(
        readonly name: string,
        readonly country: number = DbCountry.toBeDefinedCountryId,
    ) { }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateNvarchar('name', this.name, 4000)),
            Some(ValidationUtils.validateInt('country', this.country.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbStateJsonSerializer extends SimpleJsonSerializer<DbState> {
    static instance: DbStateJsonSerializer = new DbStateJsonSerializer();

    fromJsonImpl(obj: any): DbState {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(destination: DbState, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(nameKey, destination.name)
            .add(countryIdKey, destination.country);
    }
}
