import {Either, Left, None, Option} from 'funfix-core';
import {Image} from '../models';
import {EitherUtils} from './either-utils';
import {Url} from './url';

export type ImageQuality = 'raw' | 'low' | 'thumb';

export class MediaService {

    static getAgentImage(url: Option<Url>, quality: ImageQuality): Either<string, Url> {
        switch (quality) {
            case 'raw':
                return EitherUtils.toEither(url.map(u => this.getHighResImage(u, 'ProposalAgentImages', false)), 'Image not available');
            case 'low':
                return EitherUtils.toEither(url.map(u => this.getLowResImage(u, 'ProposalAgentImages')), 'Image not available');
            default:
                return Left(`Quality not available: ${quality}`);
        }
    }

    static getFullPath(u: Url, endpoint: string): Url {
        return u.prependPath(endpoint);
    }

    static getHighResImage(uri: Url, endpoint: string, isInUploaded: boolean): Url {
        if (isInUploaded) {
            return MediaService.getFullPath(uri, endpoint);
        }
        return MediaService.getFullPath(uri.appendPath(`Uploaded`), endpoint);
    }

    static getLowResImage(uri: Url, endpoint: string): Url {
        if (endpoint === 'ParticipantImages') {
            return MediaService.getFullPath(
                uri.appendPath('Thumbnails'),
                endpoint);
        }
        // HACK: This is really bad!
        if (endpoint === 'ProductImages') {
            return MediaService.getFullPath(
                uri.appendPath('Thumbnails').extendFile(`_thumblowres`),
                endpoint);
        }
        return MediaService.getFullPath(
            uri.appendPath('Thumbnails').extendFile(`_thumb`),
            endpoint);
    }

    static getParticipantContantImage(url: Option<Url>, quality: ImageQuality): Either<string, Url> {
        switch (quality) {
            case 'raw':
                return EitherUtils.toEither(url.map(u => this.getHighResImage(u, 'ParticipantImages', false)), 'Image not available');
            case 'low':
                return EitherUtils.toEither(url.map(u => this.getLowResImage(u, 'ParticipantImages')), 'Image not available');
            default:
                return Left(`Quality not available: ${quality}`);
        }
    }

    static getProductImage(image: Image, quality: ImageQuality): Url {
        switch (quality) {
            case 'raw':
                return this.getHighResImage(image.uri.get(), 'ProductImages', false);
            case 'low':
                return this.getLowResImage(image.uri.get(), 'ProductImages');
            case 'thumb': // Note: Thumb 100 and 50 are not avaliable at present
                return this.getThumbnailImage(image.uri.get(), 'ProductImages', '600');
        }
    }

    static getProposalCompanyLogo(url: Option<Url>, quality: ImageQuality): Either<string, Url> {
        switch (quality) {
            case 'raw':
                return EitherUtils.toEither(url.map(u => this.getHighResImage(url.get(), 'ProposalCompanyImages/Logos', false)), 'Image not available');
            case 'low':
                return EitherUtils.toEither(url.map(u => this.getLowResImage(url.get(), 'ProposalCompanyImages/Logos')), 'Image not available');
            default:
                return Left(`Quality not available: ${quality}`);
        }
    }

    static getProposalWelcomeImage(url: Option<Url>, quality: ImageQuality): Either<string, Url> {
        switch (quality) {
            case 'raw':
                return EitherUtils.toEither(url.map(u => this.getHighResImage(url.get(), 'ProposalImages/WelcomeImages', true)), 'Image not available');
            case 'thumb':
                return EitherUtils.toEither(url.map(u => this.getLowResImage(url.get(), 'ProposalCompanyImages/Thumbnail')), 'Image not available');
            default:
                return Left(`Quality not available: ${quality}`);
        }
    }

    static getSupplierLogo(url: Option<Url>, quality: ImageQuality): Either<string, Url> {
        switch (quality) {
            case 'raw':
                return EitherUtils.toEither(url.map(u => this.getHighResImage(url.get(), 'CompanyLogos', false)), 'Image not available');
            case 'low':
                return EitherUtils.toEither(url.map(u => this.getLowResImage(url.get(), 'CompanyLogos')), 'Image not available');
            default:
                return Left(`Quality not available: ${quality}`);
        }
    }

    static getThumbnailImage(
        uri: Url,
        endpoint: string,
        size: string,
    ): Url {
        return MediaService.getFullPath(
            uri.appendPath('Thumbnails').extendFile(`_thumb${size}_${size}`),
            endpoint);
    }

    static getWelcomeImage(img: Image, quality: ImageQuality): Either<string, Url> {
        switch (quality) {
            case 'raw':
                return EitherUtils.toEither(
                    img.uri.map(u => this.getHighResImage(u, 'ProposalImages/WelcomeImages', true)),
                    'Image not available',
                );
            case 'low':
                return EitherUtils.toEither(
                    img.uri.map(u => this.getLowResImage(u, 'ProposalImages/WelcomeImages')),
                    'Image not available',
                );
            default:
                return Left(`Quality not available: ${quality}`);
        }
    }

    static parseQuality(s: string): Option<ImageQuality> {
        switch (s) {
            case 'raw':
            case 'low':
            case 'thumb':
                return Option.of<ImageQuality>(s);
            default:
                return None;
        }
    }

    static parseQualityEither(s: string): Either<string, ImageQuality> {
        return EitherUtils.toEither(MediaService.parseQuality(s), 'Invalid Image Quality');
    }
}
