import { None, Option } from 'funfix-core';
import { parseString } from '../core';
import { JsonBuilder } from '../core/json-builder';
import {
    facebookKey,
    googleKey,
    instagramKey,
    pintrestKey,
    tripAdvisorKey,
    twitterKey,
} from '../core/json-keys';
import { SimpleJsonSerializer } from '../core/json-serializer';
import { Mergable } from './mergable';

export class Social implements Mergable<Social> {

    constructor(
        readonly facebook: Option<string> = None,
        readonly instagram: Option<string> = None,
        readonly twitter: Option<string> = None,
        readonly google: Option<string> = None,
        readonly pinterest: Option<string> = None,
        readonly tripAdvisor: Option<string> = None) {
    }

    calculateUpdates(contact: Social): Social {
        return new Social(
            contact.facebook.filter(_ => this.facebook.isEmpty()),
            contact.instagram.filter(_ => this.instagram.isEmpty()),
            contact.twitter.filter(_ => this.twitter.isEmpty()),
            contact.google.filter(_ => this.google.isEmpty()),
            contact.pinterest.filter(_ => this.pinterest.isEmpty()),
            contact.tripAdvisor.filter(_ => this.tripAdvisor.isEmpty()),
        );
    }

    isEmpty(): boolean {
        return this.facebook.isEmpty()
            && this.google.isEmpty()
            && this.pinterest.isEmpty()
            && this.twitter.isEmpty()
            && this.instagram.isEmpty()
            && this.tripAdvisor.isEmpty();
    }

    merge(other: Social): Social {
        return new Social(
            this.facebook.orElse(other.facebook),
            this.instagram.orElse(other.instagram),
            this.twitter.orElse(other.twitter),
            this.google.orElse(other.google),
            this.pinterest.orElse(other.pinterest),
            this.tripAdvisor.orElse(other.tripAdvisor),
        );
    }

    mergeOptional(other: Option<Social>): Social {
        return other
            .map(o => this.merge(o))
            .getOrElse(this);
    }
}

export class SocialJsonSerializer extends SimpleJsonSerializer<Social> {
    static instance: SocialJsonSerializer = new SocialJsonSerializer();

    fromJsonImpl(obj: any): Social {
        return new Social(
            parseString(obj[facebookKey]),
            parseString(obj[instagramKey]),
            parseString(obj[twitterKey]),
            parseString(obj[googleKey]),
            parseString(obj[pintrestKey]),
            parseString(obj[tripAdvisorKey]));
    }

    protected toJsonImpl(social: Social, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(facebookKey, social.facebook)
            .addOptional(instagramKey, social.instagram)
            .addOptional(twitterKey, social.twitter)
            .addOptional(googleKey, social.google)
            .addOptional(pintrestKey, social.pinterest)
            .addOptional(tripAdvisorKey, social.tripAdvisor);
    }
}
