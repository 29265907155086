import {None, Option, Some} from 'funfix-core';
import {JsonBuilder, parseString, SimpleJsonSerializer, statusKey} from '../core';

export class HealthInformation {
    constructor(readonly status: Option<string> = None) {
    }

    // Colors taking from https://www.color-hex.com/color-palette/35021
    getColor(): Option<string> {
        return this.status.flatMap(x => {
            switch (x) {
                case 'Red':
                    return Some('#ed1c2f');
                case 'Amber':
                    return Some('#ffa400');
                case 'Green':
                    return Some('#009649');
                default:
                    return None;
            }
        });
    }

    getStatusText(): Option<string> {
        return this.status.flatMap(x => {
            switch (x) {
                case 'Red':
                    return Some('Stop, do not progress and await action');
                case 'Amber':
                    return Some('More Information Required');
                case 'Green':
                    return Some('Continue your trip');
                default:
                    return None;
            }
        });
    }
}

export class HealthStatusJsonSerializer extends SimpleJsonSerializer<HealthInformation> {
    static instance: HealthStatusJsonSerializer = new HealthStatusJsonSerializer();

    fromJsonImpl(obj: any): HealthInformation {
        return new HealthInformation(parseString(obj[statusKey]));
    }

    protected toJsonImpl(budget: HealthInformation, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(statusKey, budget.status);
    }
}
