import {None, Option, Some} from 'funfix-core';
import {Map} from 'immutable';
import {Moment} from 'moment';
import {
    configurationKey,
    DateUtils,
    getDays,
    getNights,
    isMidnight,
    isToday,
    isTomorrow,
    JsonBuilder,
    Language,
    OptionUtils,
    parseBoolean,
    parseNumber,
    parseString,
    parseStringMap,
    SimpleJsonSerializer,
    translationsKey,
} from '../core';
import {ColourPalette} from './colour-palette';

export class Metadata {
    constructor(
        readonly translations: Map<string, string> = Map(),
        readonly configuration: Map<string, unknown> = Map()) {
    }

    formatDate(moment: Moment, fieldname: string, defaultFormat: string, language: Option<string>, context: Option<string>, useToday: boolean = false): string {
        const culture = language.map(x => this.getCultureForLanguage(x)).getOrElse('en');
        let format = this.getFormatForField(fieldname, defaultFormat, context);
        format = this.getFormatRemovingTimeIfMidnight(Some(moment), format);
        const mFormat = this.getFormatReplacingDateWithTodayTomorrow(moment, format, defaultFormat, useToday);
        moment.locale(culture);
        return moment.format(mFormat);
    }

    formatLongDateRange(
        start: Option<Moment>,
        end: Option<Moment>,
        defaultFormat: string,
        language: Option<Language>,
        fieldName: string,
        context: Option<string> = None,
    ): Option<string> {
        const separator = this.getSeparatorForField(fieldName, '-', context);
        const culture = language.map(x => this.getCultureForLanguage(x)).getOrElse('en');
        let format = this.getFormatForField(fieldName, defaultFormat, context);
        start.map(x => {
            if (isMidnight(x)) {
                switch (format) {
                    case 'DSDT':
                        format = 'DSD';
                        break;
                    case 'DSDT24':
                        format = 'DSD';
                        break;
                    case '2D3M4YZ24T':
                        format = '2D3M4Y';
                        break;
                    case '2D3M4YZT':
                        format = '2D3M4Y';
                        break;
                }
            }
        });
        const mFormat = DateUtils.dateFormats.get(format);
        const startFormat = start.map(x => x.locale(culture).format(mFormat));
        const endFormat = end.map(x => x.locale(culture).format(mFormat));
        return OptionUtils.applyOrReturnNonEmpty(startFormat, endFormat, (a, b) => `${a} ${separator} ${b}`);
    }

    // If something to do with formatting is broken, look here
    // Had to do a quick release and uncommented a line from ages ago that I'm not too sure of why was commented out
    formatShortDateRange(
        start: Option<Moment>,
        end: Option<Moment>,
        defaultFormat: string,
        language: Option<Language>,
        fieldName: string,
        context: Option<string>,
    ): Option<string> {
        const seperator = this.getSeparatorForField(fieldName, '-', context);
        const culture = language.map(x => this.getCultureForLanguage(x)).getOrElse('en');
        const format = this.getFormatForField(fieldName, defaultFormat, context);
        const mFormat = DateUtils.dateFormats.get(format, '');
        const startFormat = this.getFormatRemovingTimeIfMidnight(start, format);

        const mStartFormat = this.getStartMFormat(start, end, startFormat);

        const endFormatOpt =
            end
                .filter(x => mFormat.trim() !== '')
                .map(x => x.locale(culture).format(mFormat));

        const startFormatOpt =
            start
                .filter(x => mStartFormat.trim() !== '')
                .map(x => x.locale(culture).format(mStartFormat));

        return OptionUtils.applyOrReturnNonEmpty(
            startFormatOpt,
            endFormatOpt,
            (a, b) => `${a}${seperator}${b}`);
    }

    getBooleanParameterForField(context: Option<string>, fieldName: string, fallback: boolean, param: string): boolean {
        return this.getParsableParamForField(context, fieldName, param, fallback, parseBoolean);
    }

    getColourPalette(fallback: ColourPalette): ColourPalette {
        return new ColourPalette(
            this.getPrimaryColour(fallback.primary),
            this.getSecondaryColour(fallback.secondary),
            this.getTertiaryColour(fallback.tertiary),
            this.getTextColour(fallback.text),
        );
    }

    getCultureForLanguage(language: string): string {
        switch (language) {
            case 'French':
                return 'fr';
            case 'German':
                return 'de';
            case 'Danish':
                return 'da';
            case 'Spanish':
                return 'es';
            case 'Chinese':
                return 'zh';
            case 'Italian':
                return 'it';
            case 'Swedish':
                return 'sv';
            case 'Dutch':
                return 'nl';
            case 'English':
            default:
                return 'en';
        }
    }

    getDayString(start: Option<Moment>, end: Option<Moment>, showSingle: boolean = true): Option<string> {
        return getDays(start, end)
            .filter(n => (showSingle || n !== 1) && n !== 0)
            .map(n => n === 1 ? `1 ${this.getTranslatedLabelForField('Day', 'Day', None)}` : n.toString() + ' ' + this.getTranslatedLabelForField('Days', 'Days', None));
    }

    // See DateUtils for formats
    // EntryPickupTime(Accommodation):Format = DTD
    getFormatForField(fieldName: string, fallback: string, context: Option<string> = None): string {
        return this.getStringParameterForField(context, fieldName, fallback, 'Format');
    }

    getFormatRemovingTimeIfMidnight(start: Option<Moment>, format: string): string {
        return start.map(x => {
            if (isMidnight(x)) {
                switch (format) {
                    case 'DSDT':
                        return 'DSD';
                    case 'DSDT24':
                        return 'DSD';
                    case '2D3M4YZ24T':
                        return '2D3M4Y';
                    case '2D3M4YZT':
                        return '2D3M4Y';
                }
            }
            return format;
        }).getOrElse(format);
    }

    private getFormatReplacingDateWithTodayTomorrow(moment: Moment, format: string, defaultFormat: string, useToday: boolean = false): string {
        const todayString = this.getTranslatedLabelForField('Today', 'Today');
        const tomorrowString = this.getTranslatedLabelForField('Tomorrow', 'Tomorrow');

        if (useToday && (isToday(moment) || isTomorrow(moment))) {
            const text = isToday(moment) ? todayString : tomorrowString;
            switch (format) {
                case 'DSDT':
                case '2D3M4YZT':
                    return `[${text}]` + ' hh:mm A';
                case 'DSDT24':
                case '2D3M4YZ24T':
                    return `[${text}]` + ' HH:mm';
                case 'DSD':
                case 'DLD':
                    return `[${text}]`;
                default:
                    return DateUtils.dateFormats.get(format, defaultFormat);
            }
        }

        return DateUtils.dateFormats.get(format, defaultFormat);
    }

    // EntryPickupTime(Transport):Label = "Departure"
    private getLabelForField(fieldName: string, fallback: string, context: Option<string> = None): string {
        return this.getStringParameterForField(context, fieldName, fallback, 'Label');
    }

    getNightString(start: Option<Moment>, end: Option<Moment>, showSingle: boolean = true): Option<string> {
        return getNights(start, end)
            .filter(n => (showSingle || n !== 1) && n !== 0)
            .map(n => n === 1 ? `1 ${this.getTranslatedLabelForField('Night', 'Night', None)}` : n.toString() + ' ' + this.getTranslatedLabelForField('Nights', 'Nights', None));
    }

    getNumberParameter(field: string, fallback: number): number {
        return this.getParsableParameter(field, parseNumber).getOrElse(fallback);
    }

    getNumberParameterForField(context: Option<string>, fieldName: string, fallback: number, param: string): number {
        return this.getParsableParamForField(context, fieldName, param, fallback, parseNumber);
    }

    getParsableParameter<T>(fieldName: string, parser: (a: any) => Option<T>): Option<T> {
        return Option.of(this.configuration.get(fieldName))
            .flatMap(x => parser(x));
    }

    private getParsableParamForField<T>(
        context: Option<string>,
        fieldName: string,
        param: string,
        fallback: T,
        parser: (a: any) => Option<T>): T {
        const contextText = context.isEmpty() ? '' : `(${context.get()})`;
        const contextual = this.getParsableParameter(fieldName + contextText + ':' + param, parser);
        const noContext = this.getParsableParameter(fieldName + ':' + param, parser);
        return contextual
            .orElse(noContext)
            .getOrElse(fallback);
    }

    // Colour:Primary = "#00000"
    getPrimaryColour(fallback: string): string {
        return this.getStringParameter('Colour:Primary', fallback);
    }

    // Colour:Secondary = "#00000"
    getSecondaryColour(fallback: string): string {
        return this.getStringParameter('Colour:Secondary', fallback);

    }

    // EntryPickupTime(Transport):Separator = "-"
    getSeparatorForField(fieldName: string, fallback: string, context: Option<string> = None): string {
        return this.getStringParameterForField(context, fieldName, fallback, 'Separator');
    }

    getShortDateRangeStartFormatSameDay(start: Moment, end: Moment, format: string): string {
        switch (format) {
            case 'DSDT24':
            case '2D3M4YZ24T':
                return 'HH:mm';
            case 'DSDT':
            case '2D3M4YZT':
                return 'hh:mm A';
            case 'DSD':
            case '2D3M4Y':
            case 'DLD':
            case 'DDMMM':
            case '4Y':
            case '3M':
            case '3D':
            case '2D':
            case '2DO':
            case 'DAY':
                return '';
            default:
                return Option.of(DateUtils.dateFormats.get(format)).getOrElse('');
        }
    }

    getShortDateRangeStartFormatSameMonth(start: Moment, end: Moment, format: string): string {
        switch (format) {
            case 'DSDT':
                return 'ddd, D hh:mm A';
            case 'DSDT24':
                return 'ddd, D HH:mm';
            case 'DSD':
                return 'ddd, D';
            case '2D3M4YZ24T':
                return 'D HH:mm';
            case '2D3M4YZT':
                return 'D hh:mm A';
            case '2D3M4Y':
                return 'D';
            case 'DLD':
                return 'dddd, D';
            case 'DDMMM':
                return 'D';
            case '4Y':
                return '';
            case '3M':
                return '';
            default:
                return Option.of(DateUtils.dateFormats.get(format)).getOrElse('');
        }
    }

    getShortDateRangeStartFormatSameYear(start: Moment, end: Moment, format: string): string {
        switch (format) {
            case 'DSDT':
                return 'ddd, D MMM hh:mm A';
            case 'DSDT24':
                return 'ddd, D MMM HH:mm';
            case 'DSD':
                return 'ddd, D MMM';
            case '2D3M4YZ24T':
                return 'D MMM HH:mm';
            case '2D3M4YZT':
                return 'D MMM hh:mm A';
            case '2D3M4Y':
                return 'D MMM';
            case 'DLD':
                return 'dddd, MMMM D';
            case 'DDMMM':
                return 'D MMM';
            case '4Y':
                return '';
            default:
                return Option.of(DateUtils.dateFormats.get(format)).getOrElse('');
        }
    }

    private getStartMFormat(start: Option<Moment>, end: Option<Moment>, format: string): string {
        if (OptionUtils.exists2(start, end, (s, e) => s.isSame(e, 'day'))) {
            return this.getShortDateRangeStartFormatSameDay(start.get(), end.get(), format);
        } else if (OptionUtils.exists2(start, end, (s, e) => s.isSame(e, 'month'))) {
            return this.getShortDateRangeStartFormatSameMonth(start.get(), end.get(), format);
        } else if (OptionUtils.exists2(start, end, (s, e) => s.isSame(e, 'year'))) {
            return this.getShortDateRangeStartFormatSameYear(start.get(), end.get(), format);
        }
        return DateUtils.dateFormats.get(format, '');
    }

    getStringParameter(field: string, fallback: string): string {
        return this.getParsableParameter(field, parseString).getOrElse(fallback);
    }

    getStringParameterForField(context: Option<string>, fieldName: string, fallback: string, param: string): string {
        return this.getParsableParamForField(context, fieldName, param, fallback, parseString);
    }

    // Colour:Primary = "#00000"
    getTertiaryColour(fallback: string): string {
        return this.getStringParameter('Colour:Tertiary', fallback);
    }

    getTextColour(fallback: string): string {
        return this.getStringParameter('Colour:Text', fallback);
    }

    getTranslatedLabelForField(fieldName: string, fallback: string, context: Option<string> = None): string {
        return this.translate(this.getLabelForField(fieldName, fallback, context), fallback);
    }

    getTranslatedStringParameter(field: string, fallback: string): string {
        return this.translate(this.getStringParameter(field, fallback), fallback);
    }

    getTranslatedTitleForPage(pageName: string, fallback: Option<string> = None): string {
        return this.translate(this.getLabelForField(pageName + ':Page', fallback.getOrElse(pageName)), pageName);
    }

    // EntryPickupTime(Accommodation):Visible = false
    isFieldVisible(fieldName: string, context: Option<string> = None): boolean {
        return this.getBooleanParameterForField(context, fieldName, true, 'Visible');
    }

    // EntryPickupTime(Accommodation):Icon:Visible = false
    isIconVisible(fieldName: string, context: Option<string> = None): boolean {
        return this.getBooleanParameterForField(context, fieldName, true, 'Icon:Visible');
    }

    // EntryPickupTime(Accommodation):Label:Visible = false
    isLabelVisible(fieldName: string, context: Option<string> = None): boolean {
        return this.getBooleanParameterForField(context, fieldName, true, 'Label:Visible');
    }

    // PricingPage:Visible = false
    isPageVisible(pageName: string): boolean {
        return this.getBooleanParameterForField(None, pageName, true, 'Page:Visible');
    }

    translate(word: string, fallback: string): string {
        return this.translations.get(word, fallback);
    }
}

export class MetadataJsonSerializer extends SimpleJsonSerializer<Metadata> {
    static instance: MetadataJsonSerializer = new MetadataJsonSerializer();

    fromJsonImpl(obj: any): Metadata {
        return new Metadata(
            parseStringMap(obj[translationsKey]),
            Map(obj[configurationKey]));
    }

    protected toJsonImpl(meta: Metadata, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addMap(translationsKey, meta.translations, s => s)
            .addObject(configurationKey, meta.configuration.toJSON());
    }
}
