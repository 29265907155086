import {Either, None, Option, Some} from 'funfix-core';
import {List, Map} from 'immutable';
import {Moment} from 'moment';
import {Comparator, PropertyComparator} from 'ts-comparators';
import {
    analysisKey,
    bytesKey,
    captureDateKey,
    ComparisonUtils,
    copyrightKey,
    dpiKey,
    EitherUtils,
    hashKey,
    heightKey,
    heroKey,
    JsonBuilder,
    keywordMapKey,
    keywordsKey,
    latitudeKey,
    longitudeKey,
    notesKey,
    OptionUtils,
    orderKey,
    parseBoolean,
    parseDate,
    parseList,
    parseMap,
    parseNumber,
    parseString,
    parseURL,
    SimpleJsonSerializer,
    titleKey,
    uriKey,
    Url,
    widthKey,
} from '../core';
import {ImageWebAnalysis, ImageWebAnalysisJsonSerializer} from './image-web-analysis';
import {Media} from './media';
import {Rating} from './rating';

const heroComparator = new PropertyComparator<Image, 'hero'>('hero', ComparisonUtils.optionBooleanComparator).reverse();
const orderComparator = new PropertyComparator<Image, 'order'>('order', ComparisonUtils.optionNumberComparator);
export const imageComparator: Comparator<Image> = heroComparator.then(orderComparator);

// Its important to note that Image can be recursive, although it "should" only go 2 deep
export class Image extends Media {

    constructor(
        uri: Option<Url> = None,
        hero: Option<boolean> = None,
        order: Option<number> = None,
        notes: Option<string> = None,
        readonly title: Option<string> = None,
        readonly keywords: List<string> = List(), // Standard keywords
        readonly keywordMap: Map<string, number> = Map(), // Score base keywords
        readonly bytes: Option<number> = None,
        readonly dpi: Option<number> = None,
        readonly width: Option<number> = None,
        readonly height: Option<number> = None,
        readonly hash: Option<string> = None,
        readonly analysis: Option<ImageWebAnalysis> = None,
        readonly copyright: Option<string> = None,
        readonly latitude: Option<number> = None,
        readonly longitude: Option<number> = None,
        readonly captureDate: Option<Moment> = None) {
        super(uri, hero, order, notes);
    }

    /**
     * The size in pixels, that the longest side of a low res photo can be.
     * This is effectively A4 size at 72DPI
     */
    static lowResLongestLengthMinimum = 812;

    static fromString(uri: string, notes: Option<string> = None): Image {
        const uriOption = parseURL(uri);
        return new Image(
            uriOption,
            Some(true), // Hero
            None, // Order
            notes,
            uriOption
                .flatMap(u => Some(u.getFileName()))
                .map(u => decodeURI(u)));
    }

    // Note: Please
    cullUselessWebImages(): Image {
        return this.withAnalysis(this.analysis.map(x => x.removeImagesLessThen(this.bytes)));
    }

    getAnalysis(): Option<ImageWebAnalysis> {
        return this.analysis;
    }

    getBytes(): Option<number> {
        return this.bytes;
    }

    getCaptureDate(): Option<Moment> {
        return this.captureDate;
    }

    getCopyright(): Option<string> {
        return this.copyright;
    }

    getDpi(): Option<number> {
        return this.dpi;
    }

    /**
     *
     * Eg. foo.jpg with addition 'lowres'
     *
     * foo-lowres.jpg
     */
    getExtendedUri(s: string): Option<Url> {
        return this.uri.map(x => x.extendFile(s));
    }

    getHash(): Option<string> {
        return this.hash;
    }

    getHashEither(): Either<string, string> {
        return EitherUtils.toEither(this.getHash(), 'Missing hash for image');
    }

    getHeight(): Option<number> {
        return this.height;
    }

    getHeightAtRes(n: number): Option<number> {
        if (!this.isScaledLowRes()) {
            return this.height;
        } else if (this.isLandscape()) {
            return Some(n);
        }
        return Option.map2(
            this.getSmallestSide(),
            this.getLowResScaleRatio(),
            (len, ratio) => len * ratio);
    }

    getHref(): Option<string> {
        return this.uri.map(u => u.getHref());
    }

    getKeywordMap(): Map<string, number> {
        return this.keywordMap;
    }

    getKeyWords(): List<string> {
        return this.keywords;
    }

    getKibiBytes(): Option<number> {
        return this.bytes.map(b => b / 1024);
    }

    getKiloBytes(): Option<number> {
        return this.bytes.map(b => b / 1000);
    }

    getLatitude(): Option<number> {
        return this.latitude;
    }

    getLongestSide(): Option<number> {
        return Option.map2(this.width, this.height, (a, b) => Math.max(a, b));
    }

    getLongitude(): Option<number> {
        return this.longitude;
    }

    getLowResHeight(): Option<number> {
        return this.getHeightAtRes(Image.lowResLongestLengthMinimum);
    }

    // Number between 0 and 1 describing percentage low res is scaled by,
    // 1 being image has not been reduced in size
    // eg. 0.3 = low res 30% of original size
    getLowResScaleRatio(): Option<number> {
        return this.getScaleRatio(Image.lowResLongestLengthMinimum);
    }

    getLowResWidth(): Option<number> {
        return this.getWidthAtRes(Image.lowResLongestLengthMinimum);
    }

    getMebiBytes(): Option<number> {
        return this.getKibiBytes().map(b => b / 1024);
    }

    getMegaBytes(): Option<number> {
        return this.getKiloBytes().map(b => b / 1000);
    }

    getRating(): Rating {
        if (this.isLargerThan(5)) {
            return 'Gold';
        } else if (this.isLargerThan(3)) {
            return 'Silver';
        } else if (this.isLargerThan(0.5)) {
            return 'Bronze';
        }
        return 'Black';
    }

    getScaleRatio(n: number): Option<number> {
        if (!this.hasDimensions()) {
            return None;
        }

        if (this.isScaledLowRes()) {
            if (this.isPortrait()) {
                return Some(n / this.height.get());
            } else if (this.isLandscape()) {
                return Some(n / this.width.get());
            }
        }

        return Some(1);
    }

    getSmallestSide(): Option<number> {
        return Option.map2(this.width, this.height, (a, b) => Math.min(a, b));
    }

    getTitle(): Option<string> {
        return this.title;
    }

    getWidth(): Option<number> {
        return this.width;
    }

    getWidthAtRes(n: number): Option<number> {
        if (!this.isScaledLowRes()) {
            return this.width;
        } else if (this.isPortrait()) {
            return Some(n);
        }
        return Option.map2(
            this.getSmallestSide(),
            this.getLowResScaleRatio(),
            (len, ratio) => len * ratio);
    }

    hasDimensions(): boolean {
        return this.width.nonEmpty() && this.height.nonEmpty();
    }

    isDuplicate(image: Image): boolean {
        return Option.map2(image.hash, this.hash, (a, b) => a === b).contains(true);
    }

    isFullUrl(): boolean {
        return this.uri.exists(x => x.hasProtocol());
    }

    isHeroImage(): boolean {
        return this.hero.contains(true);
    }

    isLandscape(): boolean {
        return Option.map2(this.width, this.height, (w, h) => w > h).contains(true);
    }

    isLargerThan(mebibytes: number): Option<boolean> {
        return this.getMebiBytes()
            .map(mb => mb > mebibytes);
    }

    isPortrait(): boolean {
        return !this.isLandscape();
    }

    isScaledAtRes(n: number): boolean {
        return OptionUtils.toList(this.width, this.height)
            .every(x => x >= n);
    }

    // Whether we actually can scale the image
    // Note: Only returns true when we actually have width/height metadata
    isScaledLowRes(): boolean {
        return this.isScaledAtRes(Image.lowResLongestLengthMinimum);
    }

    withAnalysis(analysis: Option<ImageWebAnalysis>): Image {
        return new Image(
            this.uri,
            this.hero,
            this.order,
            this.notes,
            this.title,
            this.keywords,
            this.keywordMap,
            this.bytes,
            this.dpi,
            this.width,
            this.height,
            this.hash,
            analysis,
            this.copyright,
            this.latitude,
            this.longitude,
            this.captureDate,
        );
    }

    withFileNameAsHash(): Image {
        return new Image(
            this.hash.flatMap(x => Url.parse(x + '.jpg')),
            this.hero,
            this.order,
            this.notes,
            this.title,
            this.keywords,
            this.keywordMap,
            this.bytes,
            this.dpi,
            this.width,
            this.height,
            this.hash,
            this.analysis,
            this.copyright,
            this.latitude,
            this.longitude,
            this.captureDate,
        );
    }

    withKeywords(keywordMap: Map<string, number>): Image {
        return new Image(
            this.uri,
            this.hero,
            this.order,
            this.notes,
            this.title,
            this.keywords,
            keywordMap,
            this.bytes,
            this.dpi,
            this.width,
            this.height,
            this.hash,
            this.analysis,
            this.copyright,
            this.latitude,
            this.longitude,
            this.captureDate,
        );
    }

    withMetadata(
        bytes: Option<number> = None,
        dpi: Option<number> = None,
        width: Option<number> = None,
        height: Option<number> = None,
        hash: Option<string> = None,
        copyright: Option<string> = None,
        latitude: Option<number> = None,
        longitude: Option<number> = None,
        captureDate: Option<Moment> = None): Image {
        return new Image(
            this.uri,
            this.hero,
            this.order,
            this.notes,
            this.title,
            this.keywords,
            this.keywordMap,
            bytes,
            dpi,
            width,
            height,
            hash,
            this.analysis,
            copyright,
            latitude,
            longitude,
            captureDate,
        );
    }
}

export class ImageJsonSerializer<T extends Media> extends SimpleJsonSerializer<Image> {
    static instance: ImageJsonSerializer<Media> = new ImageJsonSerializer();

    fromJsonImpl(obj: any): Image {
        return new Image(
            parseURL(obj[uriKey]),
            parseBoolean(obj[heroKey]),
            parseNumber(obj[orderKey]),
            parseString(obj[notesKey]),
            parseString(obj[titleKey]),
            parseList(obj[keywordsKey], parseString),
            parseMap(obj[keywordMapKey], parseNumber),
            parseNumber(obj[bytesKey]),
            parseNumber(obj[dpiKey]),
            parseNumber(obj[widthKey]),
            parseNumber(obj[heightKey]),
            parseString(obj[hashKey]),
            ImageWebAnalysisJsonSerializer.instance.fromJson(obj[analysisKey]),
            parseString(obj[copyrightKey]),
            parseNumber(obj[latitudeKey]),
            parseNumber(obj[longitudeKey]),
            parseDate(obj[captureDateKey]),
        );
    }

    protected toJsonImpl(value: Image, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalURI(uriKey, value.uri)
            .addOptional(heroKey, value.hero)
            .addOptional(orderKey, value.order)
            .addOptional(notesKey, value.notes)
            .addOptional(titleKey, value.title)
            .addIterable(keywordsKey, value.keywords)
            .addMap(keywordMapKey, value.keywordMap, x => x)
            .addOptional(bytesKey, value.bytes)
            .addOptional(dpiKey, value.dpi)
            .addOptional(widthKey, value.width)
            .addOptional(heightKey, value.height)
            .addOptional(hashKey, value.hash)
            .addOptionalSerializable(analysisKey, value.analysis, ImageWebAnalysisJsonSerializer.instance)
            .addOptional(copyrightKey, value.copyright)
            .addOptional(latitudeKey, value.latitude)
            .addOptional(longitudeKey, value.longitude)
            .addOptionalDate(captureDateKey, value.captureDate);
    }
}
