export const readAllSupplierProductsKey = 1;
export const readAllProductsKey = 2;
export const updateAllProductsKey = 3;
export const updateAllSupplierProductsKey = 4;
export const updateOwnProductsKey = 5;
export const readOwnProductsKey = 6;
export const readOwnProposalsKey = 7;
export const createOwnProposalsKey = 8;
export const createSupplierProductsKey = 9;
export const createOwnProductsKey = 10;
export const administrationKey = 11;
export const generateAnyPdfKey = 12;
export const generateOwnPdfKey = 13;
export const generateSupplierPdfKey = 14;
export const readAllProposalsKey = 15;
export const createAllProposalsKey = 16;
export const readCompanyKey = 17;
export const createSupplierKey = 18;
export const createLocationsKey = 19;
export const createAllProductsKey = 20;
export const readAccessibleApiDetailsKey = 21;
export const readAllApiDetailsKey = 22;
export const createProposalCompanyKey = 23;
export const productivitySuiteKey = 24;
