import {None, Option} from 'funfix-core';
import {
    addressKey,
    colourKey, ComparisonUtils,
    idKey,
    JsonBuilder,
    latitudeKey,
    longitudeKey,
    OptionUtils,
    orderKey,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
    titleKey,
} from '../core';
import {Image} from './image';
import {LatLongLocation} from './lat-long-location';
import {ProposalEntryWithMeta} from './proposal-entry-with-meta';
import {Comparator, PropertyComparator} from 'ts-comparators';

export class MapMarker extends LatLongLocation {
    constructor(
        readonly latitude: Option<number> = None,
        readonly longitude: Option<number> = None,
        readonly order: Option<number> = None,
        readonly id: Option<number> = None,
        readonly title: Option<string> = None,
        readonly address: Option<string> = None,
        readonly colour: Option<string> = None) {
        super(latitude, longitude);
    }

    isEmpty(): boolean {
        return this.latitude.isEmpty() || this.longitude.isEmpty();
    }

    withMeta(entry: ProposalEntryWithMeta, parent: Option<ProposalEntryWithMeta>): MapMarkerWithMeta {
        return new MapMarkerWithMeta(
            this.latitude,
            this.longitude,
            this.order,
            this.id,
            this.title,
            this.address,
            this.colour,
            entry,
            parent,
        );
    }
}

export class MapMarkerWithMeta extends MapMarker {
    constructor(
        latitude: Option<number> = None,
        longitude: Option<number> = None,
        order: Option<number> = None,
        id: Option<number> = None,
        title: Option<string> = None,
        address: Option<string> = None,
        colour: Option<string> = None,
        readonly entry: ProposalEntryWithMeta,
        readonly parent: Option<ProposalEntryWithMeta>) {
        super(latitude, longitude, order, id, title, address, colour);
    }

    static compareTo(point: LatLongLocation, a: MapMarkerWithMeta, b: MapMarkerWithMeta): number {
        return ComparisonUtils.optionNumberComparator.compare(
            a.getStraightLineDistance(point).map(x => x.getMeters()),
            b.getStraightLineDistance(point).map(x => x.getMeters()));
    }

    public equals(obj: MapMarkerWithMeta): boolean {
        return OptionUtils.exists2(obj.latitude, this.latitude, (a, b) => a === b)
            && OptionUtils.exists2(obj.longitude, this.longitude, (a, b) => a === b);
    }

    getZoom(): number {
        return this.entry.getProduct().flatMap(x => x.getDefaultZoom()).getOrElse(13)
    }

    getIcon(): string {
        return this.entry.getDisplayProposalEntryIcon();
    }
}

export class MapMarkerJsonSerializer extends SimpleJsonSerializer<MapMarker> {
    static instance: MapMarkerJsonSerializer = new MapMarkerJsonSerializer();

    fromJsonImpl(obj: any): MapMarker {
        return new MapMarker(
            parseNumber(obj[latitudeKey]),
            parseNumber(obj[longitudeKey]),
            parseNumber(obj[orderKey]),
            parseNumber(obj[idKey]),
            parseString(obj[titleKey]),
            parseString(obj[addressKey]),
            parseString(obj[colourKey]));
    }

    protected toJsonImpl(value: MapMarker, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(latitudeKey, value.latitude)
            .addOptional(longitudeKey, value.longitude)
            .addOptional(orderKey, value.order)
            .addOptional(titleKey, value.title)
            .addOptional(idKey, value.id)
            .addOptional(addressKey, value.address)
            .addOptional(colourKey, value.colour);
    }
}
