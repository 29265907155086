import { None, Option } from 'funfix-core';
import { List } from 'immutable';
import {
    JsonBuilder,
    parseList,
    parseNumber,
    parseString,
    secondsKey,
    SimpleJsonSerializer,
    statusKey,
    templateKey,
} from '../core';
import { ApiTemplate, ApiTemplateJsonSerializer } from './api-template';

export class AutoImportProposals {

    constructor(
        readonly seconds: Option<number> = None,
        readonly bookingStatus: List<string> = List(),
        readonly template: Option<ApiTemplate> = None,
    ) {
    }

    getBookingStatus(): List<string> {
        return this.bookingStatus;
    }

    getSeconds(): Option<number> {
        return this.seconds;
    }

    getTemplate(): Option<ApiTemplate> {
        return this.template;
    }

}

export class ProposalAutoImportSettingsJsonSerializer extends SimpleJsonSerializer<AutoImportProposals> {
    static instance: ProposalAutoImportSettingsJsonSerializer = new ProposalAutoImportSettingsJsonSerializer();

    protected fromJsonImpl(obj: any): AutoImportProposals {
        return new AutoImportProposals(
            parseNumber(obj[secondsKey]),
            parseList(obj[statusKey], parseString),
            ApiTemplateJsonSerializer.instance.fromJson(obj[templateKey]),
        );
    }

    protected toJsonImpl(value: AutoImportProposals, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(secondsKey, value.seconds)
            .addIterable(statusKey, value.bookingStatus)
            .addOptionalSerializable(templateKey, value.template, ApiTemplateJsonSerializer.instance);
    }

}
