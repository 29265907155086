import {Rating} from './rating';

export class ProductScore {

  constructor(
    readonly imageRating: Rating,
    readonly imageCountRating: Rating,
    readonly locationRating: Rating,
    readonly optionRating: Rating,
    readonly optionCount: Rating,
    readonly subproductRating: Rating,
    readonly subproductCount: Rating,
    readonly videoRating: Rating,
    readonly contactRating: Rating) {
  }

  getContactRating(): Rating {
    return this.contactRating;
  }

  getImageCountRating(): Rating {
    return this.imageCountRating;
  }

  getImageRating(): Rating {
    return this.imageRating;
  }

  getLocationRating(): Rating {
    return this.locationRating;
  }

  getOptionCount(): Rating {
    return this.optionCount;
  }

  getOptionRating(): Rating {
    return this.optionRating;
  }

  getSubProductCount(): Rating {
    return this.subproductCount;
  }

  getSubProductRating(): Rating {
    return this.subproductRating;
  }

  getVideoRating(): Rating {
    return this.videoRating;
  }

}
