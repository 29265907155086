import {Either, Left, Option, Right} from 'funfix-core';
import {List, Map, Set} from 'immutable';

import fetch from 'node-fetch';
import {
  parseListEither,
  parseListSerializableEither,
  parseMapSerializable,
  parseString,
  parseStringToEither,
  SimpleJsonSerializer,
  XmlUtils,
} from '../core';

export class ApiBase {

  constructor(
    readonly apiServer: string,
  ) {
  }

  getHeaders(): object {
    return {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    };
  }

  async processApiRequest(
    path: string,
    method: 'GET' | 'POST',
    body: object = {},
    modified_by: string = '',
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, any>> {
    const config: any = {
      method,
      mode: 'cors',
      headers,
    };

    if (method === 'POST') {
      if (stringify) {
        config['body'] = JSON.stringify({...body, modified_by});
      } else {
        config['body'] = {...body, modified_by};
      }
    }

    const fullUrl = encodeURI(`${this.apiServer}/${path}`);

    try {
      const res = await fetch(fullUrl, config);

      if (Set.of(404, 500, 503).contains(res.status)) {
        return Left('Error Code: ' + res.status + ' - ' + res.statusText + ' - ' + fullUrl);
      }

      if (Option.of(res.headers.get('Content-Type')).exists(x => x.includes('xml'))) {
        const xml: Either<string, any> = await res.text()
          .then(x => Right(x))
          .catch(err => {
            return Left(parseString(err.message).getOrElse('Failed to parse error message'));
          });
        return xml.flatMap(x => this.processErrors(x));
      } else {
        const json: Either<string, any> = await res.json()
          .then(x => Right(x))
          .catch(err => {
            return Left(parseString(err.message).getOrElse('Failed to parse error message'));
          });
        return json.flatMap(x => this.processErrors(x));
      }
    } catch (e) {
      console.error(`${fullUrl}\n ${e}`);
      return Left('Error processing api request');
    }
  }

  private processErrors(json: any): Either<string, any> {
    const didgigoMessage = parseString(json.error);

    if (didgigoMessage.nonEmpty()) {
      return Left(didgigoMessage.value);
    }
    return Right(json);
  }

  async processGetApiRequest(path: string, headers: object = this.getHeaders()): Promise<Either<string, any>> {
    return this.processApiRequest(path, 'GET', {}, '', headers);
  }

  async processGetApiRequestDocument<T>(path: string): Promise<Either<string, Document>> {
    const json = await this.processGetApiRequest(path);
    return json.flatMap(x => XmlUtils.parseEither(x));
  }

  async processGetApiRequestListOptParsable<T>(path: string, f: (x: unknown) => Option<T>): Promise<Either<string, List<T>>> {
    const json = await this.processGetApiRequest(path);
    return json.flatMap(x => parseListEither(x, f));
  }

  async processGetApiRequestListSerialized<T>(path: string, serializer: SimpleJsonSerializer<T>): Promise<Either<string, List<T>>> {
    const json = await this.processGetApiRequest(path);
    return json.flatMap(x => parseListSerializableEither(x, serializer));
  }

  async processGetApiRequestMapSerialized<T>(path: string, serializer: SimpleJsonSerializer<T>): Promise<Either<string, Map<string, T>>> {
    const json = await this.processGetApiRequest(path);
    return json.map(x => parseMapSerializable(x, serializer));
  }

  async processGetApiRequestSerialized<T>(path: string, serializer: SimpleJsonSerializer<T>): Promise<Either<string, T>> {
    const json = await this.processGetApiRequest(path);
    return json.flatMap(x => serializer.fromJsonToEither(x, 'Failed to deserialize'));
  }

  async processPostApiRequest(
    path: string,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, any>> {
    return this.processApiRequest(path, 'POST', body, modifiedBy, headers, stringify);
  }

  async processPostApiRequestListOptParsable<T>(
    path: string,
    f: (x: unknown) => Option<T>,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, List<T>>> {
    const json = await this.processPostApiRequest(path, body, modifiedBy, headers, stringify);
    return json.flatMap(x => parseListEither(x, f));
  }

  async processPostApiRequestListSerialized<T>(
    path: string,
    serializer: SimpleJsonSerializer<T>,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, List<T>>> {
    const json = await this.processPostApiRequest(path, body, modifiedBy, headers, stringify);
    return json.flatMap(x => parseListSerializableEither(x, serializer));
  }

  async processPostApiRequestMapSerialized<T>(
    path: string,
    serializer: SimpleJsonSerializer<T>,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, Map<string, T>>> {
    const json = await this.processPostApiRequest(path, body, modifiedBy, headers, stringify);
    return json.map(x => parseMapSerializable(x, serializer));
  }

  async processPostApiRequestParsable<T>(
    path: string,
    f: (x: any) => Either<string, T>,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, T>> {
    const json = await this.processPostApiRequest(path, body, modifiedBy, headers, stringify);
    return json.flatMap(x => f(x));
  }

  async processPostApiRequestSerialized<T>(
    path: string,
    serializer: SimpleJsonSerializer<T>,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders()): Promise<Either<string, T>> {
    const json = await this.processPostApiRequest(path, body, modifiedBy, headers);
    return json.flatMap(x => serializer.fromJsonToEither(x, 'Failed to deserialize'));
  }

  async processPostApiRequestString(
    path: string,
    body: object,
    modifiedBy: string,
    headers: object = this.getHeaders(),
    stringify: boolean = true,
  ): Promise<Either<string, string>> {
    const json = await this.processPostApiRequest(path, body, modifiedBy, headers, stringify);
    return json.flatMap(x => parseStringToEither(x, `Failed to parse string for path ${path}`));
  }
}
