import { Option } from 'funfix-core';
import { List } from 'immutable';
import {
    fullMatchesKey, JsonBuilder, parseList, parseListSerializable,
    parseString, partialMatchesKey,
    SimpleJsonSerializer,
    websitesKey,
} from '../core';
import { Image, ImageJsonSerializer } from './image';

export class ImageWebAnalysis {
    constructor(
        readonly fullMatches: List<Image>,
        readonly partialMatches: List<Image>,
        readonly websites: List<string>) { }

    getAllImages(): List<Image> {
        return this.fullMatches.concat(this.partialMatches);
    }

    removeImagesLessThen(bytes: Option<number>): ImageWebAnalysis {
        if (bytes.isEmpty()) {
            return this;
        }

        const byteCount = bytes.get();

        return new ImageWebAnalysis(
            this.fullMatches.filter(x => x.bytes.exists(b => b > byteCount)),
            this.partialMatches.filter(x => x.bytes.exists(b => b > byteCount)),
            this.websites,
        );
    }
}

export class ImageWebAnalysisJsonSerializer extends SimpleJsonSerializer<ImageWebAnalysis> {
    static instance: ImageWebAnalysisJsonSerializer = new ImageWebAnalysisJsonSerializer();

    fromJsonImpl(obj: any): ImageWebAnalysis {
        return new ImageWebAnalysis(
            parseListSerializable(obj[fullMatchesKey], ImageJsonSerializer.instance),
            parseListSerializable(obj[partialMatchesKey], ImageJsonSerializer.instance),
            parseList(obj[websitesKey], parseString));
    }

    protected toJsonImpl(analysis: ImageWebAnalysis, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addIterableSerializable(fullMatchesKey, analysis.fullMatches, ImageJsonSerializer.instance)
            .addIterableSerializable(partialMatchesKey, analysis.partialMatches, ImageJsonSerializer.instance)
            .addIterable(websitesKey, analysis.websites);
    }
}
