import {None, Option, Some} from 'funfix-core';
import {Set} from 'immutable';
import {ServiceCodeAnalysis} from './service-code-analysis';
import {SupplierCode} from './supplier-code';

export class OptCode {
    constructor(readonly optcode: string) { }

    canIngest(): boolean {
        return this.isProduct() || this.isProductOption();
    }

    getAccommodationServiceCodes(): Set<string> {
        return Set();
    }

    // DO NOT OVERRIDE
    getAllAccommodationServiceCodes(): Set<string> {
        return this.getAccommodationServiceCodes()
            .union(this.getCruiseAccommodationServiceCodes())
            .union(this.getBedAndBreakfastServiceCodes());
    }

    // DO NOT OVERRIDE
    getAllCustomServiceCodes(): Set<string> {
        return Set();
    }

    // DO NOT OVERRIDE
    getAllDayTourOrAttractionServiceCodes(): Set<string> {
        return this.getHireCarServiceCodes()
            .union(this.getGenericFlightAsDayTourServiceCodes())
            .union(this.getDayTourOrAttractionServiceCodes())
            .union(this.getTrainAsDayTourServiceCodes())
            .union(this.getWeddingServiceCodes())
            .union(this.getDivingServiceCodes())
            .union(this.getCruiseDayTourServiceCodes())
            .union(this.getCampervanServiceCodes());
    }

    getAllMultidayServiceCodes(): Set<string> {
        return this.getPackageServiceCodes()
            .union(this.getMultidayServiceCodes());
    }

    // DO NOT OVERRIDE
    getAllServiceCodes(): Set<string> {
        return this.getAllCustomServiceCodes()
            .union(
                this.getAllAccommodationServiceCodes(),
                this.getAllTransportServiceCode(),
                this.getAllDayTourOrAttractionServiceCodes(),
                this.getAllMultidayServiceCodes());
    }

    // DO NOT OVERRIDE
    getAllTransportServiceCode(): Set<string> {
        return this.getGenericFlightAsTransportServiceCodes()
            .union(this.getSharedTransferServiceCodes())
            .union(this.getFerryAsTransportServiceCodes())
            .union(this.getHelicopterAsTransportServiceCodes())
            .union(this.getTrainAsTransportServiceCodes())
            .union(this.getCharterFlightAsTransportServiceCodes())
            .union(this.getDomesticFlightAsTransportServiceCodes())
            .union(this.getGenericFlightAsTransportServiceCodes())
            .union(this.getInternationalFlightAsTransportServiceCodes())
            .union(this.getSelfDriveTransportCodes())
            .union(this.getPrivateTransferServiceCodes());
    }

    getBedAndBreakfastServiceCodes(): Set<string> {
        return Set();
    }

    getCampervanServiceCodes(): Set<string> {
        return Set();
    }

    getCharterFlightAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getCoachServiceCodes(): Set<string> {
        return Set.of();
    }

    getCruiseAccommodationServiceCodes(): Set<string> {
        return Set();
    }

    getCruiseDayTourServiceCodes(): Set<string> {
        return Set();
    }

    getDayTourOrAttractionServiceCodes(): Set<string> {
        return Set();
    }

    getDestinationServiceCodes(): Set<string> {
        return Set();
    }

    getDivingServiceCodes(): Set<string> {
        return Set();
    }

    getDomesticFlightAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getEntryType(): Option<string> {
        if (this.isDayTourOrAttraction()) {
            return Option.of('Day Tour / Attraction');
        }

        if (this.isMultiday()) {
            return Option.of('Multiday');
        }

        if (this.isAccommodation()) {
            return Option.of('Accommodation');
        }

        if (this.isDestination()) {
            return Option.of('Destination');
        }

        if (this.isTransport()) {
            return Option.of('Transport');
        }

        if (this.isCustom()) {
            return Option.of('Custom');
        }

        if (this.isMealAndFood()) {
            return Option.of('Meal / Food');
        }

        return None;
    }

    getFerryAsDayTourServiceCodes(): Set<string> {
        return Set.of();
    }

    getFerryAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getGenericFlightAsDayTourServiceCodes(): Set<string> {
        return Set.of();
    }

    getGenericFlightAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getHelicopterAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getHireCarServiceCodes(): Set<string> {
        return Set();
    }

    getInternationalFlightAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getLocationCode(): string {
        return this.optcode.substr(0, 3);
    }

    getMultidayServiceCodes(): Set<string> {
        return Set();
    }

    getOptCodeString(): string {
        return this.optcode;
    }

    getOptCodeStringWithoutOption(): string {
        return this.optcode.substr(0, 11);
    }

    getOptCodeStringWithoutOptionAndCustomServiceCode(code: string): string {
        return `${this.getLocationCode()}${code}${this.getSupplierCodeString()}`;
    }

    getPackageServiceCodes(): Set<string> {
        return Set();
    }

    getPrivateTransferServiceCodes(): Set<string> {
        return Set.of();
    }

    getProductType(): Option<string> {
        if (this.isDayTourOrAttraction()) {
            return Option.of('Day Tour / Attraction');
        }

        if (this.isMultiday()) {
            return Option.of('Multiday');
        }

        if (this.isAccommodation()) {
            return Option.of('Accommodation');
        }

        return None;
    }

    getSelfDriveDestinationCodes(): Set<string> {
        return Set.of();
    }

    getSelfDriveTransportCodes(): Set<string> {
        return Set.of();
    }

    getServiceCode(): string {
        return this.optcode.substr(3, 2);
    }

    getServiceCodeAnalysis(): ServiceCodeAnalysis {
        return new ServiceCodeAnalysis(
            Some(this.getServiceCode()),
            Some(''),
            this.getProductType(),
            this.getEntryType(),
            this.getTransportType());
    }

    getSharedTransferServiceCodes(): Set<string> {
        return Set.of();
    }

    getSupplierCode(): SupplierCode {
        return new SupplierCode(this.getSupplierCodeString());
    }

    getSupplierCodeString(): string {
        return this.optcode.substr(5, 6);
    }

    getTrainAsDayTourServiceCodes(): Set<string> {
        return Set.of();
    }

    getTrainAsTransportServiceCodes(): Set<string> {
        return Set.of();
    }

    getTransportType(): Option<string> {
        if (this.isInternationalFlight()) {
            return Option.of('Flight - International');
        }

        if (this.isDomesticFlight()) {
            return Option.of('Flight - Domestic');
        }

        if (this.isCharterFlight()) {
            return Option.of('Charter Flight');
        }

        if (this.isGenericFlight()) {
            return Option.of('Flight');
        }

        if (this.isSharedTransfer()) {
            return Option.of('Shared Transfer');
        }

        if (this.isPrivateTransfer()) {
            return Option.of('Private Transfer');
        }

        if (this.isFerry()) {
            return Option.of('Boat / Ferry');
        }

        if (this.isRailTransport()) {
            return Option.of('Train');
        }

        if (this.isHelicopter()) {
            return Option.of('Helicopter');
        }

        if (this.isSelfDriveTransport()) {
            return Option.of('Self Drive');
        }

        return None;
    }

    getWeddingServiceCodes(): Set<string> {
        return Set();
    }

    isAccommodation(): boolean {
        return this.getAllAccommodationServiceCodes().contains(this.getServiceCode());
    }

    isActivity(): boolean {
        return false;
    }

    isAttraction(): boolean {
        return false;
    }

    isBedAndBreakfast(): boolean {
        return this.getBedAndBreakfastServiceCodes().contains(this.getServiceCode());
    }

    isCampervan(): boolean {
        return this.getCampervanServiceCodes().contains(this.getServiceCode());
    }

    isCharterFlight(): boolean {
        return this.getCharterFlightAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isCruise(): boolean {
        return this.getCruiseDayTourServiceCodes().contains(this.getServiceCode());
    }

    isCustom(): boolean {
        return this.getAllCustomServiceCodes().contains(this.getServiceCode());
    }

    isDayTourOrAttraction(): boolean {
        return this.getAllDayTourOrAttractionServiceCodes().contains(this.getServiceCode());
    }

    isDestination(): boolean {
        return this.getDestinationServiceCodes().contains(this.getServiceCode());
    }

    isDiving(): boolean {
        return this.getDivingServiceCodes().contains(this.getServiceCode());
    }

    isDomesticFlight(): boolean {
        return this.getDomesticFlightAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isFerry(): boolean {
        return this.getFerryAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isGenericFlight(): boolean {
        return this.getGenericFlightAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isHelicopter(): boolean {
        return this.getHelicopterAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isHidden(): boolean {
        return false;
    }

    isHireCar(): boolean {
        return this.getHireCarServiceCodes().contains(this.getServiceCode());
    }

    isInternationalFlight(): boolean {
        return this.getInternationalFlightAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isLeisure(): boolean {
        return false;
    }

    isMealAndFood(): boolean {
        return false;
    }

    isMeetAndGreet(): boolean {
        return false;
    }

    isMultiday(): boolean {
        return this.getAllMultidayServiceCodes().contains(this.getServiceCode()) || this.isPackage();
    }

    isPackage(): boolean {
        return this.getPackageServiceCodes().contains(this.getServiceCode());
    }

    isPackageItem(): boolean {
        return false;
    }

    isPrivateTransfer(): boolean {
        return this.getPrivateTransferServiceCodes().contains(this.getServiceCode());
    }

    isProduct(): boolean {
        return !(this.getAllServiceCodes().isEmpty()) && !(this.isTransport() || this.isCustom() || this.isProductOption());
    }

    isProductOption(): boolean {
        return this.isAccommodation();
    }

    isPromotion(): boolean {
        return false;
    }

    isProposalCompanyOwned(): boolean {
        return this.getSupplierCode().isProposalCompanyOwned();
    }

    isRailTransport(): boolean {
        return this.getTrainAsTransportServiceCodes().contains(this.getServiceCode());
    }

    isRestaurant(): boolean {
        return false;
    }

    isSelfDriveDestination(): boolean {
        return this.getSelfDriveDestinationCodes().contains(this.getServiceCode());
    }

    isSelfDriveTransport(): boolean {
        return this.getSelfDriveTransportCodes().contains(this.getServiceCode());
    }

    isSharedTransfer(): boolean {
        return this.getSharedTransferServiceCodes().contains(this.getServiceCode());
    }

    isSupplierOwned(): boolean {
        return this.getSupplierCode().isSupplierOwned();
    }

    isTransport(): boolean {
        return this.getAllTransportServiceCode().contains(this.getServiceCode());
    }

    isWedding(): boolean {
        return this.getWeddingServiceCodes().contains(this.getServiceCode());
    }
}
