import {None, Option} from 'funfix-core';
import {JsonBuilder, metaKey, productKey, SimpleJsonSerializer} from '../core';
import {Metadata, MetadataJsonSerializer} from './metadata';
import {Product, ProductJsonSerializer} from './product';

export class ProductWithMeta {

    constructor(
        readonly product: Option<Product> = None,
        readonly meta: Option<Metadata> = None,
    ) {
    }

    getMeta(): Option<Metadata> {
        return this.meta;
    }

    getProduct(): Option<Product> {
        return this.product;
    }

}

export class ProductWithMetaJsonSerializer extends SimpleJsonSerializer<ProductWithMeta> {
    static instance: ProductWithMetaJsonSerializer = new ProductWithMetaJsonSerializer();

    protected fromJsonImpl(json: any): ProductWithMeta {
        return new ProductWithMeta(
            ProductJsonSerializer.instance.fromJson(json[productKey]),
            MetadataJsonSerializer.instance.fromJson(json[metaKey]),
        );
    }

    protected toJsonImpl(value: ProductWithMeta, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(productKey, value.getProduct(), ProductJsonSerializer.instance)
            .addOptionalSerializable(metaKey, value.getMeta(), MetadataJsonSerializer.instance);
    }

}
