import {None, Option} from 'funfix-core';
import {
    addressKey,
    cityIdKey,
    countryIdKey,
    JsonBuilder,
    logoKey,
    nameKey,
    OptionUtils,
    phoneKey,
    postcodeKey,
    SimpleJsonSerializer,
    stateIdKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
    websiteKey,
} from '../core';
import {Company} from './company';
import {LocationIds} from './location-ids';
import {PhysicalLocation} from './physical-location';

export class DbProposalCompany implements Validatable {

    constructor(
        readonly name: Option<string>,
        readonly address: Option<string> = None,
        readonly cityId: Option<number> = None,
        readonly stateId: Option<number> = None,
        readonly countryId: Option<number> = None,
        readonly postcode: Option<string> = None,
        readonly logo: Option<string> = None,
        readonly phone: Option<string> = None,
        readonly website: Option<string> = None,
    ) {
    }

    static buildFromCompany(
        company: Company,
        locationIdFinder: (l: PhysicalLocation) => LocationIds): DbProposalCompany {
        const location = company.location.map(x => locationIdFinder(x)).getOrElse(new LocationIds());
        return new DbProposalCompany(
            company.name,
            company.location.flatMap(l => l.address),
            location.cityId,
            location.stateId,
            location.countryId,
            company.location.flatMap(l => l.postcode),
            company.logo,
            company.contact.flatMap(l => l.phone),
            company.contact.flatMap(l => l.website),
        );
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.name.map(n => ValidationUtils.validateTitleVarchar('name', n, 400)),
            this.address.map(n => ValidationUtils.validateVarchar('address', n, 400)),
            this.cityId.map(n => ValidationUtils.validateInt('cityId', n.toString())),
            this.stateId.map(n => ValidationUtils.validateInt('stateId', n.toString())),
            this.countryId.map(n => ValidationUtils.validateInt('countryId', n.toString())),
            this.postcode.map(n => ValidationUtils.validateVarchar('postcode', n, 400)),
            this.logo.map(n => ValidationUtils.validateVarchar('logo', n, 400)),
            this.phone.map(n => ValidationUtils.validatePhone('phone', n, 400)),
            this.website.map(n => ValidationUtils.validateUrl('website', n, 400)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbProposalCompanyJsonSerializer extends SimpleJsonSerializer<DbProposalCompany> {
    static instance: DbProposalCompanyJsonSerializer = new DbProposalCompanyJsonSerializer();

    fromJsonImpl(obj: any): DbProposalCompany {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(proposalCompany: DbProposalCompany, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(nameKey, proposalCompany.name)
            .addOptional(addressKey, proposalCompany.address)
            .addOptional(cityIdKey, proposalCompany.cityId)
            .addOptional(stateIdKey, proposalCompany.stateId)
            .addOptional(countryIdKey, proposalCompany.countryId)
            .addOptional(postcodeKey, proposalCompany.postcode)
            .addOptional(logoKey, proposalCompany.logo)
            .addOptional(phoneKey, proposalCompany.phone)
            .addOptional(websiteKey, proposalCompany.website);
    }

}
