import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    JsonBuilder,
    OptionUtils,
    personIdKey,
    proposalAdditionalPeopleIdKey,
    proposalIdKey,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Person} from './person';

export class DbAdditionalPeople implements Validatable {
    constructor(
        readonly proposalAdditionalPeopleId: Option<number> = None,
        readonly proposalId: Option<number>, // WARNING, NON NULL FIELD MUST BE HANDLED BY DB
        readonly personId: number,
    ) {
    }

    static fromPeople(people: List<Person>): List<DbAdditionalPeople> {
        return OptionUtils.flattenList(people.map(x => DbAdditionalPeople.fromPerson(x)));
    }

    static fromPerson(person: Person): Option<DbAdditionalPeople> {
        return person.id.map(id => new DbAdditionalPeople(None, None, id));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.proposalAdditionalPeopleId.map(n => ValidationUtils.validateInt('proposalAdditionalPeopleId', n.toString())),
            Some(ValidationUtils.validateInt('proposalId', this.proposalId.toString())),
            Some(ValidationUtils.validateInt('personId', this.personId.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBAdditionalPeopleJsonSerializer extends SimpleJsonSerializer<DbAdditionalPeople> {
    static instance: DBAdditionalPeopleJsonSerializer = new DBAdditionalPeopleJsonSerializer();

    fromJsonImpl(obj: any): DbAdditionalPeople {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(people: DbAdditionalPeople, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(proposalAdditionalPeopleIdKey, people.proposalAdditionalPeopleId)
            .addOptional(proposalIdKey, people.proposalId)
            .add(personIdKey, people.personId);
    }

}
