import {Either, Left, None, Option, Right, Some} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {
    cancellationPolicyKey,
    confirmationReferenceKey,
    confirmedKey,
    costKey,
    customIdKey,
    dropoffRemarkKey,
    dropoffTimeKey,
    EitherUtils,
    endDateTimeKey,
    entryClassKey,
    entryDescriptionKey,
    entryReferenceKey,
    entryStatusIdKey,
    entryTypeIdKey,
    exclusionsKey,
    fakeIdxKey,
    inclusionsKey,
    itineraryKey,
    JsonBuilder,
    meetingPointKey,
    notesKey,
    OptionUtils,
    pickupRemarkKey,
    pickupTimeKey,
    priceKey,
    productOptionIdKey,
    proposalIdKey,
    sequenceKey,
    shortDescriptionKey,
    SimpleJsonSerializer,
    startDateTimeKey,
    transportIdKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {EnumConstantMap} from './enum-constant';
import {ProposalEntry} from './proposal-entry';

export class DBProposalEntry implements Validatable {
    constructor(
        readonly fakeIdx: Option<number> = None,
        readonly proposalId: Option<number>, // IMPORTANT NON NULL FIELD POPULATED BY DB
        readonly entryTypeId: number,
        readonly transportId: Option<number> = None,
        readonly productOptionId: Option<number> = None,
        readonly customId: Option<number> = None,
        readonly entryStatusId: number,
        readonly sequence: Option<number> = None,
        readonly startDateTime: Option<Moment> = None,
        readonly endDateTime: Option<Moment> = None,
        readonly pickupTime: Option<Moment> = None,
        readonly dropoffTime: Option<Moment> = None,
        readonly pickupRemark: Option<string> = None,
        readonly dropoffRemark: Option<string> = None,
        readonly entryReference: Option<string> = None,
        readonly cost: Option<number> = None,
        readonly price: Option<number> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly confirmed: boolean = false,
        readonly confirmationReference: Option<string> = None,
        readonly cancellationPolicy: Option<string> = None,
        readonly notes: Option<string> = None,
        readonly entryClass: Option<string> = None,
        readonly entryDescription: Option<string> = None,
        readonly itinerary: Option<string> = None,
        readonly meetingPoint: Option<string> = None,
        readonly shortDescription: Option<string> = None,
    ) {
    }

    static fromEntries(
        entries: List<ProposalEntry>,
        entryClassificationMap: EnumConstantMap,
        prodOptfinder: (prodId: number) => Either<string, number>): Either<string, List<DBProposalEntry>> {
        return EitherUtils.sequenceList(entries.map((e, idx) => DBProposalEntry.fromEntry(e, entryClassificationMap, prodOptfinder, idx)));
    }

    static fromEntry(
        e: ProposalEntry,
        entryClassificationMap: EnumConstantMap,
        prodOptfinder: (prodId: number) => Either<string, number>,
        idx: number): Either<string, DBProposalEntry> {

        let prodOptId: Option<number> = None;
        if (e.getProductId().nonEmpty()) {
            const res = prodOptfinder(e.getProductId().get());

            if (res.isLeft()) {
                return Left(res.value);
            }

            prodOptId = Some(res.get());
        }

        return Right(new DBProposalEntry(
            Some(idx),
            None,
            DBProposalEntry.toEntryTypeId(e.getType().getOrElse('')),
            None,
            prodOptId,
            None,
            DBProposalEntry.toEntryStatusId(e.getStatus().getOrElse('')),
            e.getSequence(),
            e.getStartDateTime(),
            e.getEndDateTime(),
            e.getPickupTime(),
            e.getDropoffTime(),
            e.getPickupRemark(),
            e.getDropoffRemark(),
            None, // TODO: This needs to be added to proposal entry
            e.getFinancials().flatMap(x => x.getCost()),
            e.getFinancials().flatMap(x => x.getPrice()),
            e.getInclusions(),
            e.getExclusions(),
            e.isConfirmed(),
            e.getReference(),
            e.getCancellationPolicy(),
            e.getNotes(),
            e.getClass(),
            e.getTitle(), // Entry Title
            e.getLongDescription(), // Entry Description
            e.getMeetingPoint(),
            e.getShortDescription()));
    }

    // TODO: Use Cache
    static toEntryStatusId(entryStatus: string): number {
        switch (entryStatus) {
            case 'New Entry':
                return 17;
            case 'Requested':
                return 38;
            case 'Quote':
                return 39;
            case 'Confirmed':
                return 40;
            case 'Optional':
                return 41;
            case 'Cancelled':
                return 42;
            case 'Unavailable':
                return 43;
            case 'Agent Holding':
                return 44;
            case 'Provisionally Confirmed':
                return 45;
            case 'Waitlisted':
                return 46;
            default:
                return 17;
        }
    }

    // TODO: Use Cache
    static toEntryTypeId(entryType: string): number {
        switch (entryType) {
            case 'Destination':
                return 1;
            case 'Multiday':
                return 2;
            case 'Day Tour / Attraction':
                return 3;
            case 'Accommodation':
                return 4;
            case 'Transport':
                return 5;
            case 'Custom':
                return 6;
            case 'Meal / Food':
                return 7;
            default:
                throw new Error(`${entryType} is not a valid entry type`);
        }
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.fakeIdx.map(n => ValidationUtils.validateInt('fakeIdx', n.toString())),
            Some(ValidationUtils.validateInt('proposalId', this.proposalId.toString())),
            Some(ValidationUtils.validateInt('entryTypeId', this.entryTypeId.toString())),
            this.transportId.map(n => ValidationUtils.validateInt('transportId', n.toString())),
            this.productOptionId.map(n => ValidationUtils.validateInt('productOptionId', n.toString())),
            this.customId.map(n => ValidationUtils.validateInt('customId', n.toString())),
            Some(ValidationUtils.validateInt('entryStatusId', this.entryStatusId.toString())),
            this.sequence.map(n => ValidationUtils.validateInt('sequence', n.toString())),
            this.entryReference.map(n => ValidationUtils.validateVarchar('entryReference', n, 400)),
            this.inclusions.map(n => ValidationUtils.validateVarchar('inclusions', n, Number.MAX_VALUE)),
            this.exclusions.map(n => ValidationUtils.validateVarchar('exclusions', n, Number.MAX_VALUE)),
            this.confirmationReference.map(n => ValidationUtils.validateVarchar('confirmationReference', n, Number.MAX_VALUE)),
            this.cancellationPolicy.map(n => ValidationUtils.validateVarchar('cancellationPolicy', n, Number.MAX_VALUE)),
            this.notes.map(n => ValidationUtils.validateVarchar('notes', n, Number.MAX_VALUE)),
            this.entryClass.map(n => ValidationUtils.validateVarchar('entryClass', n, Number.MAX_VALUE)),
            this.entryDescription.map(n => ValidationUtils.validateVarchar('entryDescription', n, Number.MAX_VALUE)),
            this.itinerary.map(n => ValidationUtils.validateVarchar('itinerary', n, Number.MAX_VALUE)),
            this.meetingPoint.map(n => ValidationUtils.validateVarchar('meetingPoint', n, Number.MAX_VALUE)),
            this.shortDescription.map(n => ValidationUtils.validateVarchar('short_description', n, 4000)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBProposalEntryJsonSerializer extends SimpleJsonSerializer<DBProposalEntry> {
    static instance: DBProposalEntryJsonSerializer = new DBProposalEntryJsonSerializer();

    fromJsonImpl(obj: any): DBProposalEntry {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(proposalEntry: DBProposalEntry, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(fakeIdxKey, proposalEntry.fakeIdx)
            .addOptional(proposalIdKey, proposalEntry.proposalId)
            .add(entryTypeIdKey, proposalEntry.entryTypeId)
            .addOptional(transportIdKey, proposalEntry.transportId)
            .addOptional(productOptionIdKey, proposalEntry.productOptionId)
            .addOptional(customIdKey, proposalEntry.customId)
            .add(entryStatusIdKey, proposalEntry.entryStatusId)
            .addOptional(sequenceKey, proposalEntry.sequence)
            .addOptionalDate(startDateTimeKey, proposalEntry.startDateTime)
            .addOptionalDate(endDateTimeKey, proposalEntry.endDateTime)
            .addOptionalDate(pickupTimeKey, proposalEntry.pickupTime)
            .addOptionalDate(dropoffTimeKey, proposalEntry.dropoffTime)
            .addOptional(pickupRemarkKey, proposalEntry.pickupRemark)
            .addOptional(dropoffRemarkKey, proposalEntry.dropoffRemark)
            .addOptional(entryReferenceKey, proposalEntry.entryReference)
            .addOptional(costKey, proposalEntry.cost)
            .addOptional(priceKey, proposalEntry.price)
            .addOptional(inclusionsKey, proposalEntry.inclusions)
            .addOptional(exclusionsKey, proposalEntry.exclusions)
            .add(confirmedKey, proposalEntry.confirmed ? 'Y' : 'N')
            .addOptional(confirmationReferenceKey, proposalEntry.confirmationReference)
            .addOptional(cancellationPolicyKey, proposalEntry.cancellationPolicy)
            .addOptional(notesKey, proposalEntry.notes)
            .addOptional(entryClassKey, proposalEntry.entryClass)
            .addOptional(entryDescriptionKey, proposalEntry.entryDescription)
            .addOptional(itineraryKey, proposalEntry.itinerary)
            .addOptional(meetingPointKey, proposalEntry.meetingPoint)
            .addOptional(shortDescriptionKey, proposalEntry.shortDescription);
    }

}
