import { Option } from 'funfix-core';
import { Moment } from 'moment';
import {
    deletedKey,
    fromKey,
    JsonBuilder, messageKey,
    parseBoolean, parseDate,
    parseString,
    SimpleJsonSerializer, timeKey,
} from '../core';

export class ChatMessage {
    constructor(
        readonly from: Option<string>,
        readonly time: Option<Moment>,
        readonly message: Option<string>,
        readonly deleted: Option<boolean>) {
    }

    isDeleted(): boolean {
        return this.deleted.getOrElse(false);
    }

    wasReceivedAfter(t: Moment): Option<boolean> {
        return this.time.map(tme => tme.isAfter(t, 'ms'));
    }
}

export class ChatMessageSerializer extends SimpleJsonSerializer<ChatMessage> {
    static instance: ChatMessageSerializer = new ChatMessageSerializer();

    protected fromJsonImpl(json: any): ChatMessage {
        return new ChatMessage(
            parseString(json[fromKey]),
            parseDate(json[timeKey]),
            parseString(json[messageKey]),
            parseBoolean(json[deletedKey]));
    }

    protected toJsonImpl(value: ChatMessage, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(fromKey, value.from)
            .addOptionalDate(timeKey, value.time)
            .addOptional(messageKey, value.message)
            .addOptional(deletedKey, value.deleted);
    }
}
