import {Either, None, Option, Some} from 'funfix-core';
import {Set} from 'immutable';
import {
    accessorsKey,
    apiKey,
    EitherUtils,
    fallbackXmlKey,
    JsonBuilder,
    optcodeKey,
    optionIdKey,
    ownerKey,
    parseNumber,
    parseSet,
    parseString,
    productIdKey,
    SimpleJsonSerializer,
    StringSearchType,
    StringUtils,
} from '../core';

export type MappingApi = 'TPL' | 'AOT';

export class Mapping {
    constructor(
        readonly optcode: Option<string> = None,
        readonly ownerId: Option<number> = None,
        readonly productId: Option<number> = None,
        readonly optionId: Option<number> = None,
        readonly fallbackXml: Option<string> = None,
        readonly api: MappingApi = 'TPL',
        readonly accessors: Set<number> = Set(),
    ) {
    }

    static parseApi(s: string): Option<MappingApi> {
        switch (s) {
            case 'TPL':
                return Option.of<MappingApi>('TPL');
            case 'AOT':
                return Option.of<MappingApi>('AOT');
            default:
                return None;
        }
    }

    static parseApiEither(s: string): Either<string, MappingApi> {
        return EitherUtils.toEither(Mapping.parseApi(s), `Mapping ${s} not found`);
    }

    static withProductIdForMappingUpdate(id: number, ref: string, cid: number, api: 'TPL' | 'AOT'): Mapping {
        return new Mapping(
            Some(ref),
            Some(cid),
            Some(id),
            None,
            None,
            api,
            Set(),
        );
    }

    canUse(companyId: number): boolean {
        return this.accessors.contains(companyId);
    }

    getOptCodeString(): Option<string> {
        return this.optcode;
    }

    isOwner(companyId: number): boolean {
        return this.ownerId.contains(companyId);
    }

    matchesSearch(caseSensitive: boolean, comparison: string, type: StringSearchType): boolean {
        return this.optcode.exists(x => StringUtils.stringSearchMatch(caseSensitive, x, comparison, type));
    }

    withProductId(id: number): Mapping {
        return new Mapping(
            this.optcode,
            this.ownerId,
            Some(id),
            this.optionId,
            this.fallbackXml,
            this.api,
            this.accessors,
        );
    }

}

export class MappingJsonSerializer extends SimpleJsonSerializer<Mapping> {
    static instance: MappingJsonSerializer = new MappingJsonSerializer();

    fromJsonImpl(obj: any): Mapping {
        return new Mapping(
            parseString(obj[optcodeKey]),
            parseNumber(obj[ownerKey]),
            parseNumber(obj[productIdKey]),
            parseNumber(obj[optionIdKey]),
            parseString(obj[fallbackXmlKey]),
            Mapping.parseApi(obj[apiKey]).getOrElse('TPL'),
            parseSet(obj[accessorsKey], parseNumber),
        );
    }

    protected toJsonImpl(mapping: Mapping, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(optcodeKey, mapping.optcode)
            .addOptional(ownerKey, mapping.ownerId)
            .addOptional(productIdKey, mapping.productId)
            .addOptional(optionIdKey, mapping.optionId)
            .addOptional(fallbackXmlKey, mapping.fallbackXml)
            .add(apiKey, mapping.api)
            .addIterable(accessorsKey, mapping.accessors);
    }
}
