import {None, Option} from 'funfix-core';
import {
    distanceFromAirportKey,
    distanceFromCityCenterKey,
    gettingThereKey,
    JsonBuilder,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
} from '../core';
import {Distance} from './distance';

export class LocationContentCollection {
    constructor(
        readonly gettingThere: Option<string> = None,
        readonly distanceFromCityCenter: Option<Distance> = None,
        readonly distanceFromAirport: Option<Distance> = None,
    ) { }
}

export class LocationContentCollectionJsonSerializer extends SimpleJsonSerializer<LocationContentCollection> {

    static instance: LocationContentCollectionJsonSerializer = new LocationContentCollectionJsonSerializer();

    protected fromJsonImpl(json: any): LocationContentCollection {
        return new LocationContentCollection(
            parseString(json[gettingThereKey]),
            parseNumber(json[distanceFromCityCenterKey]).map(x => new Distance(x)),
            parseNumber(json[distanceFromAirportKey]).map(x => new Distance(x)));
    }

    protected toJsonImpl(value: LocationContentCollection, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(gettingThereKey, value.gettingThere)
            .addOptional(distanceFromCityCenterKey, value.distanceFromCityCenter.map(x => x.meters))
            .addOptional(distanceFromAirportKey, value.distanceFromAirport.map(x => x.meters));
    }
}
