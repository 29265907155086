import {Option, Some} from 'funfix-core';
import {
    bytesKey,
    coreImageIdKey,
    dpiKey,
    hashKey,
    heightKey,
    JsonBuilder,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
    widthKey,
} from '../core';
import {Image} from './image';

export class DbCoreImageMetadata implements Validatable {

    constructor(
        readonly coreImageId: number,
        readonly sha256Hash: string,
        readonly bytes: number,
        readonly width: number,
        readonly height: number,
        readonly dpi: number,
    ) { }

    static fromImage(coreImageId: number, image: Image): Option<DbCoreImageMetadata> {
        return Option.map5(
            image.hash,
            image.bytes,
            image.width,
            image.height,
            image.dpi,
            (a: string, b: number, c: number, d: number, e: number) =>
                new DbCoreImageMetadata(coreImageId, a, b, c, d, e));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateInt('coreImageId', this.coreImageId.toString())),
            Some(ValidationUtils.validateVarchar('sha256Hash', this.sha256Hash, 64)),
            Some(ValidationUtils.validateInt('bytes', this.bytes.toString())),
            Some(ValidationUtils.validateInt('width', this.width.toString())),
            Some(ValidationUtils.validateInt('height', this.height.toString())),
            Some(ValidationUtils.validateInt('dpi', this.dpi.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbCoreImageMetadataJsonSerializer extends SimpleJsonSerializer<DbCoreImageMetadata> {
    static instance: DbCoreImageMetadataJsonSerializer = new DbCoreImageMetadataJsonSerializer();

    fromJsonImpl(obj: any): DbCoreImageMetadata {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(image: DbCoreImageMetadata, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(coreImageIdKey, image.coreImageId)
            .add(hashKey, image.sha256Hash)
            .add(bytesKey, image.bytes)
            .add(widthKey, image.width)
            .add(heightKey, image.height)
            .add(dpiKey, image.dpi);
    }

}
