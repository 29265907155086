import {List} from 'immutable';
import {imageDataKey, JsonBuilder, keywordsKey, SimpleJsonSerializer, urlsKey} from '../core';
import {
    DbImageData,
    DbImageDataJsonSerializer,
    DbImageKeyword,
    DbImageKeywordJsonSerializer,
    DbImageUrl,
    DbImageUrlJsonSerializer,
    Image,
} from './';

export class DbImageResultSet {
    constructor(
        readonly imageData: List<DbImageData> = List(),
        readonly urls: List<DbImageUrl> = List(),
        readonly keywords: List<DbImageKeyword> = List(),
    ) { }

    static fromImage(image: Image): DbImageResultSet {
        const mainImage = DbImageData.fromImage(image, true);
        return new DbImageResultSet(
            mainImage,
            DbImageUrl.fromImage(image),
            DbImageKeyword.fromImage(image),
        );
    }

    static fromImages(images: List<Image>): DbImageResultSet {
        return images.reduce((acc, im) =>
                acc.merge(DbImageResultSet.fromImage(im)),
            new DbImageResultSet());
    }

    merge(other: DbImageResultSet): DbImageResultSet {
        return new DbImageResultSet(
            this.imageData.concat(other.imageData),
            this.urls.concat(other.urls),
            this.keywords.concat(other.keywords),
        );
    }
}

export class DbImageResultSetJsonSerializer extends SimpleJsonSerializer<DbImageResultSet> {
    static instance: DbImageResultSetJsonSerializer = new DbImageResultSetJsonSerializer();

    fromJsonImpl(obj: any): DbImageResultSet {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(image: DbImageResultSet, builder: JsonBuilder): JsonBuilder {
        return builder
            .addIterableSerializable(imageDataKey, image.imageData, DbImageDataJsonSerializer.instance)
            .addIterableSerializable(urlsKey, image.urls, DbImageUrlJsonSerializer.instance)
            .addIterableSerializable(keywordsKey, image.keywords, DbImageKeywordJsonSerializer.instance);
    }
}
