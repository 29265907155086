import { Option } from 'funfix-core';
import {
    descriptionKey,
    JsonBuilder,
    parseBoolean,
    parseString,
    passengerKey,
    receiptKey, requiredCheckInKey,
} from '../core';
import { SimpleJsonSerializer } from '../core/json-serializer';

export class FlightSeat {
    constructor(
        public passenger: Option<string>,
        public description: Option<string>,
        public reciept: Option<string>,
        public requiredCheckIn: Option<boolean>) { }
}

export class FlightSeatJsonSerializer extends SimpleJsonSerializer<FlightSeat> {
    static instance: FlightSeatJsonSerializer = new FlightSeatJsonSerializer();

    fromJsonImpl(obj: any): FlightSeat {
        return new FlightSeat(
            parseString(passengerKey),
            parseString(descriptionKey),
            parseString(receiptKey),
            parseBoolean(requiredCheckInKey),
        );
    }

    protected toJsonImpl(value: FlightSeat, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(passengerKey, value.passenger)
            .addOptional(descriptionKey, value.description)
            .addOptional(receiptKey, value.reciept)
            .addOptional(requiredCheckInKey, value.requiredCheckIn);
    }
}
