import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    addressKey,
    cityIdKey,
    companyIdKey,
    countryIdKey,
    didgigoUserNameKey,
    emailKey,
    faxKey,
    firstNameKey,
    JsonBuilder,
    lastNameKey,
    notesKey,
    OptionUtils,
    personIdKey,
    phoneKey,
    postcodeKey,
    salutationKey,
    secondaryPhoneKey,
    SimpleJsonSerializer,
    stateIdKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Person} from './person';

export class DBPeople implements Validatable {

    constructor(
        readonly personId: Option<number> = None,
        readonly companyId: number,
        readonly didgigoUserName: Option<string> = None,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string,
        readonly address: Option<string> = None,
        readonly cityId: Option<number> = None,
        readonly stateId: Option<number> = None,
        readonly countryId: Option<number> = None,
        readonly postCode: Option<string> = None,
        readonly phone1: Option<string> = None,
        readonly phone2: Option<string> = None,
        readonly fax: Option<string> = None,
        readonly salutation: Option<string> = None,
        readonly notes: Option<string> = None,
    ) { }

    static fromPeople(companyId: number, people: List<Person>): List<DBPeople> {
        return people.map(x => DBPeople.fromPerson(companyId, x));
    }

    static fromPerson(companyId: number, person: Person): DBPeople {
        return new DBPeople(
            None,
            companyId,
            None,
            person.firstName.getOrElse('PAX'),
            person.lastName.getOrElse('UNKNOWN'),
            person.contact.flatMap(x => x.email).getOrElse(''),
        );
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.personId.map(n => ValidationUtils.validateInt('personId', n.toString())),
            Some(ValidationUtils.validateInt('companyId', this.companyId.toString())),
            this.didgigoUserName.map(n => ValidationUtils.validateVarchar('didgigoUserName', n, 400)),
            Some(ValidationUtils.validateVarchar('firstName', this.firstName, 400)),
            Some(ValidationUtils.validateVarchar('lastName', this.lastName, 400)),
            Some(ValidationUtils.validateVarchar('email', this.email, 400)),
            this.address.map(n => ValidationUtils.validateVarchar('address', n, 400)),
            this.cityId.map(n => ValidationUtils.validateInt('cityId', n.toString())),
            this.stateId.map(n => ValidationUtils.validateInt('stateId', n.toString())),
            this.countryId.map(n => ValidationUtils.validateInt('countryId', n.toString())),
            this.postCode.map(n => ValidationUtils.validateVarchar('postCode', n, 400)),
            this.phone1.map(n => ValidationUtils.validateVarchar('phone1', n, 400)),
            this.phone2.map(n => ValidationUtils.validateVarchar('phone2', n, 400)),
            this.fax.map(n => ValidationUtils.validateVarchar('fax', n, 400)),
            this.salutation.map(n => ValidationUtils.validateNvarchar('salutation', n, 100)),
            this.notes.map(n => ValidationUtils.validateVarchar('notes', n, 4000)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBPeopleJsonSerializer extends SimpleJsonSerializer<DBPeople> {
    static instance: DBPeopleJsonSerializer = new DBPeopleJsonSerializer();

    fromJsonImpl(obj: any): DBPeople {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(people: DBPeople, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(personIdKey, people.personId)
            .add(companyIdKey, people.companyId)
            .addOptional(didgigoUserNameKey, people.didgigoUserName)
            .add(firstNameKey, people.firstName)
            .add(lastNameKey, people.lastName)
            .add(emailKey, people.email)
            .addOptional(addressKey, people.address)
            .addOptional(cityIdKey, people.cityId)
            .addOptional(stateIdKey, people.stateId)
            .addOptional(countryIdKey, people.countryId)
            .addOptional(postcodeKey, people.postCode)
            .addOptional(phoneKey, people.phone1)
            .addOptional(secondaryPhoneKey, people.phone2)
            .addOptional(faxKey, people.fax)
            .addOptional(salutationKey, people.salutation)
            .addOptional(notesKey, people.notes);
    }

}
