import {Option} from 'funfix-core';
import {Set} from 'immutable';
import {Observable} from 'rxjs';
import * as _s from 'underscore.string';
import {Crud, Entry} from './crud';
import {DelegatingCrud} from './delegating-crud';

/**
 * String Key crud that prefixes all keys with a given prefix
 */
export class PrefixedCrud<V> extends DelegatingCrud<string, V> {

    constructor(
        readonly prefix: string,
        readonly underlying: Crud<string, V>) {
        super(underlying);
    }

    private addPrefix(k: string): string {
        return `${this.prefix}:${k}`;
    }

    delete(k: string): Promise<Option<V>> {
        return super.delete(this.addPrefix(k));
    }

    /**
     *  Yuck... Special casing due to Storage storing as a flat map.
     *
     *  Due to the nature of storage layers that are prefixed,
     *  values will return all prefixed entries, however we only want the ones with the prefix.
     */
    async entries(): Promise<Set<Entry<string, V>>> {
        const current = await super.entries();
        return current
            .filter(e => _s.startsWith(e.k, this.prefix))
            .map(e => new Entry(_s.ltrim(e.k, this.prefix), e.v));
    }

    getLast(k: string): Promise<Option<V>> {
        return super.getLast(this.addPrefix(k));
    }

    hasKey(k: string): Promise<boolean> {
        return super.hasKey(this.addPrefix(k));
    }

    async keys(): Promise<Set<string>> {
        const keys = await super.keys();
        return keys
            .filter(k => _s.startsWith(k, `${this.prefix}:`))
            .map(k => this.removePrefix(k));
    }

    observe(k: string): Observable<Option<V>> {
        return super.observe(this.addPrefix(k));
    }

    private removePrefix(k: string): string {
        return _s.ltrim(k, `${this.prefix}:`).trim();
    }

    set(k: string, v: V): Promise<Option<V>> {
        return super.set(this.addPrefix(k), v);
    }

    async values(): Promise<Set<V>> {
        const entries = await this.entries();
        return entries.map(e => e.v);
    }
}
