import {None, Option} from 'funfix-core';
import {
    heroKey,
    JsonBuilder,
    JsonSerializer,
    notesKey,
    orderKey,
    parseBoolean,
    parseNumber,
    parseString,
    parseURL,
    uriKey,
    Url,
} from '../core';

export class Media {
    constructor(
        readonly uri: Option<Url> = None,
        readonly hero: Option<boolean> = None,
        readonly order: Option<number> = None,
        readonly notes: Option<string> = None) {
    }

    getHero(): Option<boolean> {
        return this.hero;
    }

    getNotes(): Option<string> {
        return this.notes;
    }

    getOrder(): Option<number> {
        return this.order;
    }

    getUri(): Option<Url> {
        return this.uri;
    }

}

export class MediaJsonSerializer<T extends Media> extends JsonSerializer<Media, T> {
    static instance: MediaJsonSerializer<Media> = new MediaJsonSerializer();

    fromJsonImpl(obj: any): Media {
        return new Media(
            parseURL(obj[uriKey]),
            parseBoolean(obj[heroKey]),
            parseNumber(obj[orderKey]),
            parseString(obj[notesKey]));
    }

    protected toJsonImpl(value: T, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalURI(uriKey, value.uri)
            .addOptional(heroKey, value.hero)
            .addOptional(orderKey, value.order)
            .addOptional(notesKey, value.notes);
    }
}
