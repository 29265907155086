import { None, Option } from 'funfix-core';
import {
    descriptionKey,
    dropoffKey, flightInfoKey,
    JsonBuilder,
    parseString,
    pickupKey,
    titleKey,
    typeKey,
} from '../core';
import { SimpleJsonSerializer } from '../core/json-serializer';
import { FlightInfo, FlightInfoJsonSerializer } from './flight-info';
import { Transport } from './transport';
import { Waypoint, WaypointJsonSerializer } from './waypoint';

export class Flight extends Transport {
    constructor(
        type: Option<string> = None,
        title: Option<string> = None,
        description: Option<string> = None,
        dropoff: Option<Waypoint> = None,
        pickup: Option<Waypoint> = None,
        readonly flightDetails: Option<FlightInfo>) {
        super(type, title, description, dropoff, pickup);
    }

    static fromTransport(info: Option<FlightInfo>, transport: Transport): Flight {
        return new Flight(
            transport.type,
            transport.title,
            transport.description,
            transport.dropoff,
            transport.pickup,
            info);
    }
}

export class FlightJsonSerializer extends SimpleJsonSerializer<Flight> {
    static instance: FlightJsonSerializer = new FlightJsonSerializer();

    fromJsonImpl(obj: any): Flight {
        return new Flight(
            parseString(obj[typeKey]),
            parseString(obj[titleKey]),
            parseString(obj[descriptionKey]),
            WaypointJsonSerializer.instance.fromJson(obj[dropoffKey]),
            WaypointJsonSerializer.instance.fromJson(obj[pickupKey]),
            FlightInfoJsonSerializer.instance.fromJson(obj[flightInfoKey]));
    }

    toJsonImpl(value: Flight, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(typeKey, value.type)
            .addOptional(titleKey, value.title)
            .addOptional(descriptionKey, value.description)
            .addOptionalSerializable(dropoffKey, value.dropoff, WaypointJsonSerializer.instance)
            .addOptionalSerializable(pickupKey, value.pickup, WaypointJsonSerializer.instance)
            .addOptionalSerializable(flightInfoKey, value.flightDetails, FlightInfoJsonSerializer.instance);

    }
}
