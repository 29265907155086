import {None, Option} from 'funfix-core';
import {Set} from 'immutable';
import {Observable, of} from 'rxjs';
import {Crud, Entry} from './crud';

export class NoCache<K, V> extends Crud<K, V> {

    async delete(k: K): Promise<Option<V>> {
        return None;
    }

    async deleteAll(): Promise<void> {
    }

    async entries(): Promise<Set<Entry<K, V>>> {
        return Set();
    }

    async keys(): Promise<Set<K>> {
        return Set();
    }

    observe(k: K): Observable<Option<V>> {
        return of(None);
    }

    async set(k: K, v: V): Promise<Option<V>> {
        return None;
    }
}
