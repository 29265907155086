import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {classificationsKey, JsonBuilder, noteKey, parseList, parseString, SimpleJsonSerializer} from '../core';

export class Note {
    constructor(
        readonly note: Option<string> = None,
        readonly classifications: List<string> = List(),
    ) {
    }

    static buildFromClassification(classification: string, note: string): Note {
        return new Note(Some(note), List.of(classification));
    }
}

export class NoteJsonSerializer extends SimpleJsonSerializer<Note> {
    static instance: NoteJsonSerializer = new NoteJsonSerializer();

    fromJsonImpl(obj: any): Note {
        return new Note(
            parseString(obj[noteKey]),
            parseList(obj[classificationsKey], parseString));
    }

    protected toJsonImpl(budget: Note, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(noteKey, budget.note)
            .addIterable(classificationsKey, budget.classifications);
    }
}
