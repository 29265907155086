import {Option} from 'funfix-core';
import {List, Map} from 'immutable';
import {OptionUtils} from './option-utils';

export class MapUtils {

    static buildGroupedMapFromListOptional<K, V>(input: List<V>, f: (v: V) => Option<K>): Map<K, List<V>> {
        return input
            .filter(v => f(v).nonEmpty())
            .groupBy(v => f(v).get())
            .map(x => x.toList())
            .toMap();
    }

    static buildMapFromExtractors<A, K, V>(input: List<A>, fk: (a: any) => K, fv: (a: any) => V): Map<K, V> {
        return input
            .map(o => [fk(o), fv(o)] as [K, V])
            .reduce((acc, [k, v]) => acc.set(k, v), Map());
    }

    static buildMapFromList<K, V>(input: List<V>, f: (v: V) => K): Map<K, V> {
        return input
            .map<[K, V]>(s => [f(s), s])
            .reduce((acc, [key, value]) => acc.set(key, value), Map());
    }

    static buildMapFromListOptional<K, V>(input: List<V>, f: (v: V) => Option<K>): Map<K, V> {
        return input
            .filter(v => f(v).nonEmpty())
            .map<[K, V]>(v => [f(v).get(), v])
            .reduce((acc, [k, v]) => acc.set(k, v), Map());
    }

    static buildMapFromListValue<K, V>(input: List<K>, f: (k: K) => V): Map<K, V> {
        return input
            .map<[K, V]>(s => [s, f(s)])
            .reduce((acc, [key, value]) => acc.set(key, value), Map());
    }

    static buildMapFromListValueOptional<K, V>(input: List<K>, f: (k: K) => Option<V>): Map<K, V> {
        return input
            .filter(k => f(k).nonEmpty())
            .map<[K, V]>(k => [k, f(k).get()])
            .reduce((acc, [k, v]) => acc.set(k, v), Map());
    }

    static buildMapFromOptionalExtractors<A, K, V>(input: List<A>, fk: (a: any) => Option<K>, fv: (a: any) => Option<V>): Map<K, V> {
        return OptionUtils.flattenList(input
            .map(o => Option.map2(fk(o), fv(o), (k, v) => [k, v] as [K, V])))
            .reduce((acc, [k, v]) => acc.set(k, v), Map());
    }

    static buildMapFromPairs<A, B>(input: List<readonly [A, B]>): Map<A, B> {
        return this.buildMapFromExtractors(input, ([a, _]) => a, ([_, b]) => b);
    }

    static collect<K, V, V2>(input: Map<K, V>, f: (v: V) => Option<V2>): Map<K, V2> {
        return input
            .filter(k => f(k).nonEmpty())
            .map(v => f(v).get());
    }

    static extractValueFromOption<K, V>(map: Map<K, V>, key: Option<K>): Option<V> {
        return key.flatMap(k => Option.of(map.get(k)));
    }

    static extractValuesFromOption<K, V>(map: Map<K, V>, keys: List<K>): List<V> {
        return OptionUtils.flattenList(keys.map(k => Option.of(map.get(k))));
    }

    static swap<K, V>(map: Map<K, V>): Map<V, K> {
        return map.mapEntries(([k, v]) => [v, k]);
    }
}
