import { None, Option } from 'funfix-core';
import {
    descriptionsKey,
    idKey,
    JsonBuilder,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
    typeKey,
} from '../core';

export class ProductOptionType {

    constructor(
        readonly productOptionTypeId: Option<number> = None,
        readonly description: Option<string> = None,
        readonly type: Option<string> = None,
    ) { }

    getDescription(): Option<string> {
        return this.description;
    }

    getProductOptionTypeId(): Option<number> {
        return this.productOptionTypeId;
    }

    getType(): Option<string> {
        return this.type;
    }

}

export class ProductOptionTypeJsonSerializer extends SimpleJsonSerializer<ProductOptionType> {
    static instance: ProductOptionTypeJsonSerializer = new ProductOptionTypeJsonSerializer();

    fromJsonImpl(obj: any): ProductOptionType {
        return new ProductOptionType(
            parseNumber(obj[idKey]),
            parseString(obj[descriptionsKey]),
            parseString(obj[typeKey]),
        );
    }

    toJsonImpl(optionType: ProductOptionType, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, optionType.productOptionTypeId)
            .addOptional(descriptionsKey, optionType.description)
            .addOptional(typeKey, optionType.type);
    }

}
