
export class SupplierCode {
    constructor(readonly supcode: string) { }

    getLocationCode(): string {
        return this.supcode.substr(3, 3);
    }

    isProposalCompanyOwned(): boolean {
        return false;
    }

    isSupplierOwned(): boolean {
        return !this.isProposalCompanyOwned();
    }
}
