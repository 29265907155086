import { None, Option } from 'funfix-core';
import { JsonBuilder, locationKey, SimpleJsonSerializer } from '../core';
import { LocationContentCollection, LocationContentCollectionJsonSerializer } from './location-content-collection';

export class ProductContentCollection {
    constructor(readonly location: Option<LocationContentCollection> = None) {
    }
}

export class ProductContentCollectionJsonSerializer extends SimpleJsonSerializer<ProductContentCollection> {
    static instance: ProductContentCollectionJsonSerializer = new ProductContentCollectionJsonSerializer();

    protected fromJsonImpl(json: any): ProductContentCollection {
        return new ProductContentCollection(
            LocationContentCollectionJsonSerializer.instance.fromJson(json[locationKey]));
    }

    protected toJsonImpl(value: ProductContentCollection, builder: JsonBuilder): JsonBuilder {
        return builder.addOptionalSerializable(locationKey, value.location, LocationContentCollectionJsonSerializer.instance);
    }

}
