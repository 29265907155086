import {None, Option} from 'funfix-core';
import {List, Set} from 'immutable';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {OptionUtils} from '../option-utils';
import {PromiseUtils} from '../promise-utils';

// TODO: Bulk update/set
export abstract class Crud<K, V> {

    /**
     * Merger defaults to just replacing
     */
    constructor(readonly merger: (old: V, nue: V) => V = (o, n) => n) {
    }

    /**
     * Apply a function to a given set of values in the map, saving afterwards
     */
    async bulkTransform(ks: List<K>, f: (v: V) => V): Promise<List<V>> {
        const result = await PromiseUtils.allList(ks.map(k => this.transform(k, f)));
        return OptionUtils.flattenList(result);
    }

    /**
     * Delete given keys from the store
     *
     * @return the old value.
     *
     * If the value did not exist in the store, then None will be returned
     */
    abstract delete(k: K): Promise<Option<V>>;

    /**
     * Delete all from the cache
     *
     * @return an observable to that will complete on finish
     */
    abstract deleteAll(): Promise<void>;

    abstract entries(): Promise<Set<Entry<K, V>>>;

    /**
     * If the result is empty, attempt to fix the issue by running the updater.
     */
    private async fallbackOptToObservable<T>(o: Option<T>, updater: () => Promise<Option<T>>): Promise<Option<T>> {
        if (o.nonEmpty()) {
            return o;
        } else {
            return updater();
        }
    }

    async getLast(k: K): Promise<Option<V>> {
        const hasKey = await this.hasKey(k);
        if (hasKey) {
            return this.observe(k)
                .pipe(take(1))
                .toPromise();
        }
        return None;
    }

    /**
     * Gets the value or else sets it to the given value.
     *
     * Useful in circumstances when you want
     * to use the data in the cache if it exists
     */
    async getOrElseSet(k: K, v: V): Promise<Option<V>> {
        const last = await this.getLast(k);
        return this.fallbackOptToObservable(last, () => this.set(k, v));
    }

    /**
     * Default method is inefficient, its recommended to override this method
     */
    async hasKey(k: K): Promise<boolean> {
        const keys = await this.keys();
        return keys.contains(k);
    }

    /**
     * Get all keys.
     *
     * Warning: No speed guarantees are given
     */
    abstract keys(): Promise<Set<K>>;

    /**
     * Merge if both present, otherwise fallback to new value
     */
    private merge(opt: Option<V>, v: V): V {
        return opt
            .map(e => this.merger(e, v))
            .getOrElse(v);
    }

    abstract observe(k: K): Observable<Option<V>>;

    observeNonEmpty(k: K): Observable<V> {
        return this.observe(k)
            .pipe(filter(op => op.nonEmpty()))
            .pipe(map(x => x.get()));
    }

    /**
     * Will use the given merger to update the given record.
     *
     * Unlike getOrElseUpdate this will always call the supplier function
     */
    async replace(k: K, update: (key: K) => Promise<V>): Promise<Option<V>> {
        const data = await update(k);
        return this.set(k, data);
    }

    /**
     * Sets a value in the cache.
     * @return the value after it has been set.
     */
    abstract set(k: K, v: V): Promise<Option<V>>;

    /**
     * Apply a function to a given value in the map, saving afterwards
     */
    async transform(k: K, f: (v: V) => V): Promise<Option<V>> {
        const current = await this.getLast(k);
        return current.map(v => this.set(k, f(v)))
            .getOrElse(None);
    }

    /**
     * Will use the given merger to update the given record.
     *
     * Unlike getOrElseUpdate this will always call the supplier function
     *
     * @return the value after the update.
     */
    async update(k: K, v: (key: K) => Promise<V>): Promise<Option<V>> {
        const old = await this.getLast(k);
        const nue = await v(k);
        return this.set(k, this.merge(old, nue));
    }

    async updateFromExisting(k: K, v: V): Promise<Option<V>> {
        const old = await this.getLast(k);
        return this.set(k, this.merge(old, v));
    }

    /**
     * Get all values.
     *
     * Warning: No speed guarantees are given
     */
    async values(): Promise<Set<V>> {
        const entries = await this.entries();
        return entries.map(e => e.v);
    }
}

export class Entry<K, V> {
    constructor(
        readonly k: K,
        readonly v: V) {
    }
}
