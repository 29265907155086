import {None, Option} from 'funfix-core';
import {exclusionsKey, inclusionsKey, JsonBuilder, longKey, meetingPointKey, parseString, shortKey, titleKey} from '../core';
import {SimpleJsonSerializer} from '../core/json-serializer';

export class Descriptions {
    constructor(
        public short: Option<string> = None,
        public long: Option<string> = None,
        public title: Option<string> = None,
        public inclusions: Option<string> = None,
        public exclusions: Option<string> = None,
        public meetingPoint: Option<string> = None,
    ) {
    }

    calculateUpdates(desc: Descriptions): Descriptions {
        return new Descriptions(
            desc.getShort().filter(_ => this.getShort().isEmpty()),
            desc.getLong().filter(_ => this.getLong().isEmpty()),
            desc.getTitle().filter(_ => this.getTitle().isEmpty()),
            desc.getInclusions().filter(_ => this.getInclusions().isEmpty()),
            desc.getExclusions().filter(_ => this.getExclusions().isEmpty()),
            desc.getMeetingPoint().filter(_ => this.getMeetingPoint().isEmpty()),
        );
    }

    getExclusions(): Option<string> {
        return this.exclusions;
    }

    getInclusions(): Option<string> {
        return this.inclusions;
    }

    getLong(): Option<string> {
        return this.long;
    }

    getMeetingPoint(): Option<string> {
        return this.meetingPoint;
    }

    getShort(): Option<string> {
        return this.short;
    }

    getTitle(): Option<string> {
        return this.title;
    }

    isEmpty(): boolean {
        return this.getShort().isEmpty()
            && this.getLong().nonEmpty()
            && this.getTitle().nonEmpty()
            && this.getInclusions().nonEmpty()
            && this.getExclusions().nonEmpty()
            && this.getMeetingPoint().nonEmpty();
    }
}

export class DescriptionsJsonSerializer extends SimpleJsonSerializer<Descriptions> {
    static instance: DescriptionsJsonSerializer = new DescriptionsJsonSerializer();

    fromJsonImpl(obj: any): Descriptions {
        return new Descriptions(
            parseString(obj[shortKey]),
            parseString(obj[longKey]),
            parseString(obj[titleKey]),
            parseString(obj[inclusionsKey]),
            parseString(obj[exclusionsKey]),
            parseString(obj[meetingPointKey]),
        );
    }

    protected toJsonImpl(value: Descriptions, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(shortKey, value.getShort())
            .addOptional(longKey, value.getLong())
            .addOptional(titleKey, value.getTitle())
            .addOptional(inclusionsKey, value.getInclusions())
            .addOptional(exclusionsKey, value.getExclusions())
            .addOptional(meetingPointKey, value.getMeetingPoint());
    }
}
