import { None, Option } from 'funfix-core';
import { descriptionKey, idKey, JsonBuilder, nameKey, parseNumber, parseString, SimpleJsonSerializer } from '../core';

export class Module {

    constructor(
        readonly id: Option<number> = None,
        readonly name: Option<string> = None,
        readonly description: Option<string> = None,
    ) { }

    getDescription(): Option<string> {
        return this.description;
    }

    getId(): Option<number> {
        return this.id;
    }

    getName(): Option<string> {
        return this.name;
    }
}

export class ModuleJsonSerializer extends SimpleJsonSerializer<Module> {
    static instance: ModuleJsonSerializer = new ModuleJsonSerializer();

    fromJsonImpl(obj: any): Module {
        return new Module(
            parseNumber(obj[idKey]),
            parseString(obj[nameKey]),
            parseString(obj[descriptionKey]),
        );
    }

    protected toJsonImpl(mod: Module, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, mod.id)
            .addOptional(nameKey, mod.name)
            .addOptional(descriptionKey, mod.description);
    }
}
