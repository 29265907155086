// Identity is a unique identifier across both Didgigo and Firebase
import {None, Option} from 'funfix-core';
import {List, Map} from 'immutable';
import {Moment} from 'moment';
import * as _s from 'underscore.string';
import {
    adminPrefix,
    agentPrefix,
    companyKey,
    guestPrefix,
    identityKey,
    imageKey,
    JsonBuilder,
    lastActiveKey,
    logoKey,
    now,
    parseDate,
    parseList,
    parseMap,
    parseString,
    personKey,
    SimpleJsonSerializer,
    supplierPrefix,
    travellerCustomPrefix,
    travellerDidgigoPrefix,
    uuidKey,
} from '../core';
import {CompanyJsonSerializer, CompanyLike, FilteredCompanyJsonSerializer} from './company';
import {ContextualImageSet, ContextualImageSetJsonSerializer} from './contextual-image';
import {FilteredPersonJsonSerializer, Person} from './person';

// This is the "User" class for firebase, stores didgigo people.
export class IdentifiablePerson {
    constructor(
        readonly identity: Option<string>,
        readonly uuid: List<string>,
        readonly last_active: Map<string, Moment>,
        readonly image: Option<ContextualImageSet>,
        readonly logo: Option<ContextualImageSet>,
        readonly company: Option<CompanyLike>,
        readonly person: Option<Person>) {
    }

    canChat(): boolean {
        return this.isTraveller() || this.isAgent();
    }

    getFullName(): Option<string> {
        return this.person.flatMap(p => p.getFullName());
    }

    getFullNameAndType(): string {
        return this.getFullName().getOrElse('Unknown') + ' - ' + this.getPersonType();
    }

    getLastActiveTime(): Option<Moment> {
        if (this.last_active.size === 0) {
            return None;
        }

        return Option.of(List<Moment>(this.last_active.values())
            .reduce((res, m2) => Option.of(res).nonEmpty() && Option.of(res).get() > m2 ? res : m2));
    }

    getPersonId(): Option<number> {
        return this.person.flatMap(x => x.id);
    }

    getPersonType(): string {
        if (this.isAgent()) {
            return 'Agent';
        } else if (this.isTraveller()) {
            return 'Traveller';
        } else if (this.isSupplier()) {
            return 'Supplier';
        } else if (this.isAdmin()) {
            return 'Admin';
        }

        return 'Guest';
    }

    hasApp(): boolean {
        return Option.of(this.last_active.get('tripigo')).nonEmpty();
    }

    isAdmin(): boolean {
        return this.identity.exists(i => _s.startsWith(i, adminPrefix));
    }

    isAgent(): boolean {
        return this.identity.exists(i => _s.startsWith(i, agentPrefix));
    }

    isCustomNamedTraveller(): boolean {
        return this.identity.exists(i => _s.startsWith(i, travellerCustomPrefix));
    }

    isDidgigoTraveller(): boolean {
        return this.identity.exists(i => _s.startsWith(i, travellerDidgigoPrefix));
    }

    isGuest(): boolean {
        return this.identity.exists(i => _s.startsWith(i, guestPrefix));
    }

    isStaff(): boolean {
        return !(this.isTraveller() || this.isGuest());
    }

    isSupplier(): boolean {
        return this.identity.exists(i => _s.startsWith(i, supplierPrefix));
    }

    isTraveller(): boolean {
        return this.isCustomNamedTraveller() || this.isDidgigoTraveller();
    }

    wasActiveToday(): boolean {
        return this.getLastActiveTime().exists(m => m.isSame(now(), 'day'));
    }
}

export class IdentifiablePersonJsonSerializer extends SimpleJsonSerializer<IdentifiablePerson> {
    static instance: IdentifiablePersonJsonSerializer = new IdentifiablePersonJsonSerializer();

    protected fromJsonImpl(json: any): IdentifiablePerson {
        return new IdentifiablePerson(
            parseString(json[identityKey]),
            parseList(json[uuidKey], parseString),
            parseMap(json[lastActiveKey], parseDate),
            ContextualImageSetJsonSerializer.instance.fromJson(json[imageKey]),
            ContextualImageSetJsonSerializer.instance.fromJson(json[logoKey]),
            CompanyJsonSerializer.instance.fromJson(json[companyKey]),
            FilteredPersonJsonSerializer.instance.fromJson(json[personKey]));
    }

    protected toJsonImpl(value: IdentifiablePerson, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(identityKey, value.identity)
            .addIterable(uuidKey, value.uuid)
            .addMap(lastActiveKey, value.last_active, d => d.toISOString())
            .addOptionalSerializable(imageKey, value.image, ContextualImageSetJsonSerializer.instance)
            .addOptionalSerializable(logoKey, value.image, ContextualImageSetJsonSerializer.instance)
            .addOptionalSerializable(companyKey, value.company, FilteredCompanyJsonSerializer.instance)
            .addOptionalSerializable(personKey, value.person, FilteredPersonJsonSerializer.instance);
    }
}
