import {Either, None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {Moment} from 'moment';
import {ValueComparator} from 'ts-comparators';
import * as _s from 'underscore.string';
import {
    cancellationPolicyKey,
    classificationsKey,
    classKey,
    companyKey,
    ComparisonUtils,
    confirmedKey,
    customKey,
    descriptionKey,
    dropoffKey,
    EitherUtils,
    endKey,
    exclusionsKey,
    extraNotesKey,
    financialsKey,
    idKey,
    imageKey,
    inclusionsKey,
    JsonBuilder,
    Language,
    meetingPointKey,
    notesKey,
    OptionUtils,
    parseBoolean,
    parseList,
    parseListSerializable,
    parseNumber,
    parseString,
    pickupKey,
    productKey,
    productOptionKey,
    referenceKey,
    sequenceKey,
    shortDescriptionKey,
    SimpleJsonSerializer,
    startKey,
    statusKey,
    StringSearchType,
    StringUtils,
    titleKey,
    transportKey,
    typeKey,
} from '../core';
import {Company, CompanyJsonSerializer} from './company';
import {DownloadType, DownloadTypeUtils} from './contextual-image';
import {Custom, CustomJsonSerializer} from './custom';
import {Descriptions} from './descriptions';
import {Financials, FinancialsJsonSerializer} from './financials';
import {Flight, FlightJsonSerializer} from './flight';
import {HealthInformation} from './health-information';
import {Image, imageComparator, ImageJsonSerializer} from './image';
import {ImageForGallery} from './image-for-gallery';
import {LatLongLocation} from './lat-long-location';
import {Note, NoteJsonSerializer} from './note';
import {Product, ProductJsonSerializer} from './product';
import {ProductOption, ProductOptionJsonSerializer} from './product-option';
import {Rating} from './rating';
import {Timeframed} from './timeframed';
import {Transport, TransportJsonSerializer} from './transport';
import {Video} from './video';
import {VideoForGallery} from './video-for-gallery';
import {Waypoint, WaypointJsonSerializer} from './waypoint';

const boatClassifications = List.of('Yacht', 'Cruise', 'Shuttle Boat');
const flightClassifications = Set.of('Flight', 'Flight - Domestic', 'Flight - International', 'Charter Flight');
export type EntryTypeFilter = 'All' | 'Tours' | 'Flights' | 'Accommodation' | 'Transport' | 'Destination' | 'SelfDrive';
export type ProposalEntryStringSearchField =
    'Title'
    | 'SubTitle'
    | 'Reference'
    | 'Type'
    | 'TransportType'
    | 'City'
    | 'Country';

export class ProposalEntryLike extends Timeframed {

    static entryDayComparator =
        new ValueComparator<ProposalEntryLike, Option<Moment>>(e => e.getStartDateTime(), ComparisonUtils.optionDayComparator);

    static entryMomentComparator =
        new ValueComparator<ProposalEntryLike, Option<Moment>>(e => e.getStartDateTime(), ComparisonUtils.optionMomentComparator);

    static entrySequenceComparator =
        new ValueComparator<ProposalEntryLike, Option<number>>(e => e.getSequence(), ComparisonUtils.optionNumberComparator);

    buildProposalEntry(): Option<ProposalEntry> {
        return Option.of(
            new ProposalEntry(
                this.getId(),
                this.getSequence(),
                this.getTitle(),
                this.getType(),
                this.getImage(),
                this.getShortDescription(),
                this.getLongDescription(),
                this.getCompany(),
                this.getStartWaypoint(),
                this.getEndWaypoint(),
                this.getPickup(),
                this.getDropoff(),
                this.getMeetingPoint(),
                this.getReference(),
                this.getInclusions(),
                this.getExclusions(),
                this.getTransport(),
                this.getCustom(),
                this.getConfirmed(),
                this.getStatus(),
                this.getProduct().map(p => p.withMappings(List())), // Remove mapping data
                this.getOption(),
                this.getClass(),
                this.getCancellationPolicy(),
                this.getNotes(),
                this.getClassifications(),
                this.getExtraNotes(),
                this.getFinancials(),
            ),
        );
    }

    buildProposalEntryEither(): Either<string, ProposalEntry> {
        return EitherUtils.toEither(this.buildProposalEntry(), 'Failed to build Proposal Entry');
    }

    // Includes subproducts
    getAllProducts(): List<Product> {
        const prod = OptionUtils.toList(this.getProduct());
        return prod.flatMap(x => x.subproducts).concat(prod);
    }

    getAllSubproducts(): List<Product> {
        const prod = OptionUtils.toList(this.getProduct());
        return prod.flatMap(x => x.subproducts);
    }

    getBestImage(): Option<Image> {
        return Option.of(this.getSortedProductImages().first());
    }

    getBestImages(count: number): List<Image> {
        const sortedProdImages = this.getSortedProductImages();
        const sortedOptionImages = this.getSortedOptionImages();

        return sortedProdImages
            .zipAll(sortedOptionImages)
            .flatMap<Image>(x => x)
            .filter(x => x)
            .take(count);
    }

    getCancellationPolicy(): Option<string> {
        return None;
    }

    getClass(): Option<string> {
        return None;
    }

    getClassifications(): List<string> {
        return List();
    }

    getCompany(): Option<Company> {
        return None;
    }

    getConfirmed(): Option<boolean> {
        return None;
    }

    getCustom(): Option<Custom> {
        return None;
    }

    getDropoff(): Option<Waypoint> {
        return None;
    }

    getDropoffRemark(): Option<string> {
        return this.getDropoff()
            .flatMap(x => x.description);
    }

    getDropoffTime(): Option<Moment> {
        return this.getDropoff()
            .flatMap(x => x.time);
    }

    getEndDateTime(): Option<Moment> {
        return this.getEndWaypoint()
            .flatMap(w => w.time);
    }

    getEndWaypoint(): Option<Waypoint> {
        return None;
    }

    getExclusions(): Option<string> {
        return None;
    }

    getExtraNotes(): List<Note> {
        return List();
    }

    getFinancials(): Option<Financials> {
        return None;
    }

    getFirstNote(k: string): Option<string> {
        return Option.of(this.getExtraNotes().find(n => n.classifications.contains(k)))
            .flatMap(n => n.note);
    }

    // TODO: Make this an actual data model, rather then relying on status
    getHealthInformation(): Option<HealthInformation> {
        return this.getStatus()
            .filter(x => Set.of('Amber', 'Red', 'Green').contains(x))
            .map(s => new HealthInformation(Some(s)));
    }

    /**
     * If it is identified as a transport it will default to car,
     * otherwise if custom default to compass, otherwise default to camera.
     */
    getIcon(): string {
        if (this.isSelfDrive()) {
            return 'self-drive';
        } else if (this.isCampervan()) {
            return 'campervan';
        } else if (this.isHireVehicle()) {
            return 'car';
        } else if (this.isHelicopter()) {
            return 'helicopter';
        } else if (this.isFlight()) {
            return 'flight';
        } else if (this.isBoat()) {
            return 'boat';
        } else if (this.isRail()) {
            return 'rail';
        } else if (this.isSharedTransfer()) {
            return 'bus';
        } else if (this.isPrivateTransfer() || this.isTransferToAirport() || this.isTransferToAccommodation()) {
            return 'limo';
        } else if (this.isWeddingCeremony()) {
            return 'wedding';
        } else if (this.isDiving()) {
            return 'diving';
        } else if (this.isTransport()) {
            return 'car';
        } else if (this.isAccommodation()) {
            return 'accommodation';
        } else if (this.isDayTour()) {
            return 'day-tour';
        } else if (this.isMultidayTour()) {
            return 'multiday-tour';
        } else if (this.isInsurance()) {
            return 'notes';
        } else if (this.isDayInTransit()) {
            return 'in-transit';
        } else if (this.isLeisureEntry()) {
            return 'leisure';
        } else if (this.isCustom()) {
            return 'notes';
        }
        return 'destination';
    }

    getId(): Option<number> {
        return None;
    }

    getImage(): Option<Image> {
        return None;
    }

    getImagesToDownload(downloadType: DownloadType): List<Image> {
        const entryImages = this.getBestImages(DownloadTypeUtils.getNumberOfImages(downloadType));
        const subproductImages = this.getAllSubproducts()
            .flatMap(e => e.getSortedImages(DownloadTypeUtils.getNumberOfImages(downloadType)));
        return entryImages.concat(subproductImages);
    }

    getImportError(): Option<string> {
        return this.getFirstNote('IMPORT_ERROR');
    }

    getInclusions(): Option<string> {
        return None;
    }

    getLatLongLocation(): Option<LatLongLocation> {
        return this.getProduct()
            .map(x => x.getLatLongLocation());
    }

    getLongDescription(): Option<string> {
        return None;
    }

    getMeetingPoint(): Option<string> {
        return None;
    }

    getNotes(): Option<string> {
        return None;
    }

    getNumberOfPassengers(): Option<string> {
        return this.getFirstNote('No_Passengers');
    }

    getOption(): Option<ProductOption> {
        return None;
    }

    getOptionShortDescription(): Option<string> {
        return this.getOption()
            .flatMap(x => x.getDescriptions())
            .flatMap(x => x.getShort());
    }

    getOverallScore(): Option<Rating> {
        return this.getProduct()
            .map(x => x.getOverallScore());
    }

    getPickup(): Option<Waypoint> {
        return None;
    }

    getPickupRemark(): Option<string> {
        return this.getPickup()
            .flatMap(x => x.getDescription());
    }

    getPickupTime(): Option<Moment> {
        return this.getPickup()
            .flatMap(x => x.getTime());
    }

    getProduct(): Option<Product> {
        return None;
    }

    getProductApiReference(): Option<string> {
        return this.getFirstNote('API_REFERENCE');
    }

    getProductId(): Option<number> {
        return None;
    }

    getReference(): Option<string> {
        return None;
    }

    getSearchString(field: ProposalEntryStringSearchField): Option<string> {
        switch (field) {
            case 'City':
                return this.getProduct()
                    .flatMap(x => x.getCity());
            case 'Country':
                return this.getProduct()
                    .flatMap(x => x.getCountry());
            case 'SubTitle':
                return this.getSubTitle();
            case 'Reference':
                return this.getReference();
            case 'TransportType':
                return this.getTransport()
                    .flatMap(x => x.getType());
            case 'Type':
                return this.getType();
            case 'Title':
                return this.getTitle();
        }
    }

    getSequence(): Option<number> {
        return None;
    }

    getShortDescription(): Option<string> {
        return None;
    }

    getSortedOptionImages(): List<Image> {
        const optionImages: List<Image> = this.getOption()
            .flatMap(i => i.mediaLibrary)
            .map(m => m.images)
            .getOrElse(List());

        return optionImages.sort((a, b) => imageComparator.compare(a, b));
    }

    getSortedProductImages(): List<Image> {
        const prodImages: List<Image> = this.getProduct()
            .flatMap(i => i.media)
            .map(m => m.images)
            .getOrElse(List());

        return prodImages.sort((a, b) => imageComparator.compare(a, b));
    }

    getStartDateTime(): Option<Moment> {
        return this.getStartWaypoint()
            .flatMap(w => w.getTime());
    }

    getStartWaypoint(): Option<Waypoint> {
        return None;
    }

    getStatus(): Option<string> {
        return None;
    }

    getSubDescription(): Option<string> {
        return this.getOption()
            .flatMap(o => o.getDescriptions())
            .flatMap(d => d.getLong())
            .orElse(this.getOptionShortDescription());
    }

    getSubTitle(): Option<string> {
        return this.getOption()
            .flatMap(p => p.getOptionName());
    }

    getTitle(): Option<string> {
        return None;
    }

    getTransport(): Option<Transport> {
        return None;
    }

    getType(): Option<string> {
        return None;
    }

    isAccommodation(): boolean {
        return this.getType()
            .contains('Accommodation');
    }

    isAirplane(): boolean {
        return (
            this.isFlightAsDayTour()
            || this.isGenericFlightAsTransport()
            || this.isCharterFlightAsTransport()
            || this.isInternationalFlightAsTransport()
            || this.isDomesticFlightAsTransport()
        );
    }

    isBoat(): boolean {
        return (
            this.getClassifications().find(v => boatClassifications.contains(v)) !== undefined ||
            this.getTransport()
                .flatMap(t => t.getType())
                .contains('Boat')
        );
    }

    isCampervan(): boolean {
        return this.getClassifications()
            .contains('Campervan');
    }

    isCancelled(): boolean {
        return this.getStatus()
            .contains('Cancelled');
    }

    isCharterFlightAsTransport(): boolean {
        return this.getTransport()
            .flatMap(t => t.type)
            .contains('Charter Flight');
    }

    isCoach(): boolean {
        return this.getTransport()
            .flatMap(t => t.getType())
            .contains('Coach');
    }

    isConfirmed(): boolean {
        return this.getStatus()
            .contains('Confirmed');
    }

    isCustom(): boolean {
        return this.getType()
            .contains('Custom');
    }

    isDayInTransit(): boolean {
        return this.isCustom()
            && this.getTitle()
                .exists(t => _s.contains(t.toLowerCase(), 'transit'));
    }

    isDayTour(): boolean {
        return this.getType()
            .contains('Day Tour / Attraction');
    }

    isDestination(): boolean {
        return this.getType()
            .contains('Destination');
    }

    isDiving(): boolean {
        return this.getClassifications()
            .find(v => v === 'Diving') !== undefined;
    }

    isDomesticFlightAsTransport(): boolean {
        return this.getTransport()
            .flatMap(t => t.type)
            .contains('Flight - Domestic');
    }

    isFlight(): boolean {
        return this.isHelicopter()
            || this.isAirplane();
    }

    isFlightAsDayTour(): boolean {
        return this.getClassifications()
            .find(v => flightClassifications.contains(v)) !== undefined;
    }

    isGeneralDestination(): boolean {
        return this.getType()
                .contains('Destination')
            && !this.isSelfDrive();
    }

    isGenericFlightAsTransport(): boolean {
        return this.getTransport()
            .flatMap(t => t.type)
            .contains('Flight');
    }

    isHelicopter(): boolean {
        return (
            this.getClassifications().find(v => v === 'Helicopter') !== undefined ||
            this.getTransport()
                .flatMap(t => t.getType())
                .contains('Helicopter')
        );
    }

    isHireCar(): boolean {
        return !this.isDestination()
            && this.getClassifications()
                .contains('Hire Car/Self Drive');
    }

    isHireVehicle(): boolean {
        return this.isHireCar()
            || this.isCampervan();
    }

    isInsurance(): boolean {
        return this.isCustom()
            && this.getTitle()
                .exists(t => _s.contains(t.toLowerCase(), 'insurance'));
    }

    isInternationalFlightAsTransport(): boolean {
        return this.getTransport()
            .flatMap(t => t.type)
            .contains('Flight - International');
    }

    isLeisureEntry(): boolean {
        return this.isCustom()
            && this.getTitle()
                .exists(t => _s.contains(t.toLowerCase(), 'leisure'));
    }

    isMultidayTour(): boolean {
        return this.getType()
            .contains('Multiday');
    }

    isNonLeisureActivityOnDay(m: Moment): boolean {
        if (!this.isDuring(m)) {
            return false;
        }

        if (!this.isAccommodation() && !this.isHireCar()) {
            return true;
        }
        return this.isFirstOrLastDay(m);
    }

    isOptional(): boolean {
        return this.getStatus()
            .contains('Optional');
    }

    isOvernight(): boolean {
        return this.isAccommodation()
            || this.isMultidayTour();
    }

    isPrivateTransfer(): boolean {
        return this.getTransport()
            .flatMap(t => t.getType())
            .contains('Private Transfer');
    }

    isPublicTransfer(): boolean {
        return this.isSharedTransfer()
            || this.isCoach()
            || this.isShuttleTransfer();
    }

    isQuote(): boolean {
        return this.getStatus()
            .contains('Quote');
    }

    isRail(): boolean {
        return (
            this.getClassifications().contains('Rail/Train') ||
            this.getTransport()
                .flatMap(t => t.getType())
                .contains('Rail')
        );
    }

    isSelfDrive(): boolean {
        return this.isDestination()
            && this.getClassifications()
                .contains('Hire Car/Self Drive');
    }

    isSharedTransfer(): boolean {
        return this.getTransport()
            .flatMap(t => t.getType())
            .contains('Shared Transfer');
    }

    isShuttleTransfer(): boolean {
        return this.getTransport()
            .flatMap(t => t.getType())
            .contains('Shuttle Transfer');
    }

    isSpecialCustom(): boolean {
        return this.isDayInTransit()
            || this.isLeisureEntry()
            || this.isDayInTransit();
    }

    isTour(): boolean {
        return (
            (this.isDayTour() || this.isMultidayTour()) &&
            !this.isTransport() &&
            !this.isSelfDrive() &&
            !this.isHireVehicle()
        );
    }

    isTransfer(): boolean {
        return (
            this.isPrivateTransfer() ||
            this.isPublicTransfer() ||
            this.isTransferToAirport() ||
            this.isTransferToAccommodation()
        );
    }

    isTransferToAccommodation(): boolean {
        return this.getTransport()
            .flatMap(t => t.getType())
            .contains('Private Transfer to Accommodation');
    }

    isTransferToAirport(): boolean {
        return this.getTransport()
            .flatMap(t => t.getType())
            .contains('Private Transfer to Airport');
    }

    isTransport(): boolean {
        return this.getType()
                .contains('Transport')
            || this.isRail()
            || this.isFlight();
    }

    isUnavaliable(): boolean {
        return this.getStatus()
            .contains('Unavailable');
    }

    isWeddingCeremony(): boolean {
        return this.getClassifications().find(v => v === 'Wedding') !== undefined;
    }

    matchesFuzzyTextFilter(s: string): boolean {
        if (s.trim().length === 0) {
            return true;
        }
        return this.matchesSearch('Title', false, s, 'Contains')
            || this.matchesSearch('SubTitle', false, s, 'Contains')
            || this.matchesSearch('Reference', false, s, 'Contains')
            || this.matchesSearch('TransportType', false, s, 'Contains')
            || this.matchesSearch('Type', false, s, 'Contains')
            || this.matchesSearch('City', false, s, 'Contains')
            || this.matchesSearch('Country', false, s, 'Contains');
    }

    matchesSearch(field: ProposalEntryStringSearchField, caseSensitive: boolean, comparison: string, type: StringSearchType): boolean {
        return this.getSearchString(field)
            .exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type));
    }

}

export class ProposalEntry extends ProposalEntryLike {

    constructor(
        readonly id: Option<number> = None,
        readonly sequence: Option<number> = None,
        readonly title: Option<string> = None,
        readonly type: Option<string> = None,
        readonly image: Option<Image> = None,
        readonly shortDescription: Option<string> = None,
        readonly longDescription: Option<string> = None,
        readonly company: Option<Company> = None,
        readonly start: Option<Waypoint> = None,
        readonly end: Option<Waypoint> = None,
        readonly pickup: Option<Waypoint> = None,
        readonly dropoff: Option<Waypoint> = None,
        readonly meetingPoint: Option<string> = None,
        readonly reference: Option<string> = None,
        readonly inclusions: Option<string> = None,
        readonly exclusions: Option<string> = None,
        readonly transport: Option<Transport> = None,
        readonly custom: Option<Custom> = None,
        readonly confirmed: Option<boolean> = None,
        readonly status: Option<string> = None,
        readonly product: Option<Product> = None,
        readonly option: Option<ProductOption> = None,
        readonly clazz: Option<string> = None,
        readonly cancellationPolicy: Option<string> = None,
        readonly notes: Option<string> = None,
        readonly classifications: List<string> = List(),
        readonly extraNotes: List<Note> = List(),
        readonly financials: Option<Financials> = None,
    ) {
        super();
    }

    static buildFromProduct(p: Product, language: Option<Language>): ProposalEntry {
        return new ProposalEntry(
            None,
            None,
            p.getProductName(),
            p.getProductType(),
            Option.of(p.getImages().first()),
            p.getTranslatedShortDescription(language.getOrElse('English')),
            p.getTranslatedLongDescription(language.getOrElse('English')),
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            p.inclusions,
            p.exclusions,
            None,
            None,
            Some(true),
            Some('Confirmed'),
            Some(p));
    }

    static quickBuildCustomEntry(
        title: Option<string>,
        shortDescription: Option<string>,
        longDescription: Option<string>,
        startTime: Option<Moment>,
    ): ProposalEntry {
        return new ProposalEntry(
            None,
            None, // Sequence
            title,
            Some('Custom'),
            None,
            shortDescription,
            longDescription,
            None,
            Some(new Waypoint(startTime)),
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            Some(new Custom(title, longDescription)),
            Some(true),
            Some('Confirmed'),
        );
    }

    // Adds an entry before this one
    // TODO: Languages
    static quickBuildDestinationBefore(
        e: ProposalEntry,
        p: Product,
    ): ProposalEntry {
        return new ProposalEntry(
            None,
            e.sequence.map(x => x - 1),
            p.getProductName(),
            Some('Destination'),
            None,
            p.getShortDescription(),
            p.getLongDescription(),
            None,
            Some(new Waypoint(e.getStartDay())),
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            Some(true),
            Some('Confirmed'),
            Some(p));
    }

    // Adds an entry before this one
    // TODO: Languages
    static quickBuildSelfDriveBefore(
        e: ProposalEntry,
        p: Product): ProposalEntry {
        return new ProposalEntry(
            None,
            e.sequence.map(x => x - 2),
            p.getProductName(),
            Some('Destination'),
            None,
            p.getShortDescription(),
            p.getLongDescription(),
            None,
            Some(new Waypoint(e.getStartDay())),
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            None,
            Some(true),
            Some('Confirmed'),
            Some(p),
            None,
            None,
            None,
            None,
            List.of('Hire Car/Self Drive'),
        );
    }

    addClassifications(...classifications: string[]): ProposalEntry {
        return new ProposalEntry(
            this.getId(),
            this.getSequence(),
            this.getTitle(),
            this.getType(),
            this.getImage(),
            this.getShortDescription(),
            this.getLongDescription(),
            this.getCompany(),
            this.getStartWaypoint(),
            this.getEndWaypoint(),
            this.getPickup(),
            this.getDropoff(),
            this.getMeetingPoint(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            this.getTransport(),
            this.getCustom(),
            this.getConfirmed(),
            this.getStatus(),
            this.getProduct(),
            this.getOption(),
            this.getClass(),
            this.getCancellationPolicy(),
            this.getNotes(),
            this.getClassifications().concat(List(classifications)),
            this.getExtraNotes(),
            this.getFinancials(),
        );
    }

    doesImportRequireProductId(): boolean {
        return !(this.isCustom() || this.isTransport());
    }

    getCancellationPolicy(): Option<string> {
        return this.cancellationPolicy;
    }

    getClass(): Option<string> {
        return this.clazz;
    }

    getClassifications(): List<string> {
        return this.classifications;
    }

    getCompany(): Option<Company> {
        return this.company;
    }

    getConfirmed(): Option<boolean> {
        return this.confirmed;
    }

    getCustom(): Option<Custom> {
        return this.custom;
    }

    getDropoff(): Option<Waypoint> {
        return this.dropoff;
    }

    getEndWaypoint(): Option<Waypoint> {
        return this.end;
    }

    getExclusions(): Option<string> {
        return this.exclusions;
    }

    getExtraNotes(): List<Note> {
        return this.extraNotes;
    }

    getFinancials(): Option<Financials> {
        return this.financials;
    }

    getId(): Option<number> {
        return this.id;
    }

    getImage(): Option<Image> {
        return this.image;
    }

    getImagesForGallery(count: number): List<ImageForGallery> {
        return OptionUtils.toList(this.getProduct())
            .flatMap(x => x.getImages())
            .take(count)
            .map(x => new ImageForGallery(
                this.getId(),
                this.getProductId(),
                Some(x),
                this.getTitle(),
                this.getShortDescription()
                    .orElse(this.getProduct().flatMap(p => p.getShortDescription()))));
    }

    getInclusions(): Option<string> {
        return this.inclusions;
    }

    getLongDescription(): Option<string> {
        return this.longDescription;
    }

    getMeetingPoint(): Option<string> {
        return this.meetingPoint;
    }

    getNotes(): Option<string> {
        return this.notes;
    }

    getOption(): Option<ProductOption> {
        return this.option;
    }

    getPickup(): Option<Waypoint> {
        return this.pickup;
    }

    getProduct(): Option<Product> {
        return this.product;
    }

    getProductId(): Option<number> {
        return this.product
            .flatMap(x => x.getId());
    }

    getReference(): Option<string> {
        return this.reference;
    }

    getSequence(): Option<number> {
        return this.sequence;
    }

    getShortDescription(): Option<string> {
        return this.shortDescription;
    }

    getStartWaypoint(): Option<Waypoint> {
        return this.start;
    }

    getStatus(): Option<string> {
        return this.status;
    }

    getTitle(): Option<string> {
        return this.title.orElse(this.getCustom().flatMap(c => c.getTitle()))
            .orElse(this.getTransport().flatMap(t => t.getTitle()))
            .orElse(this.getProduct().flatMap(p => p.getProductName()))
            .orElse(this.getCustom().flatMap(c => c.getDescription()));
    }

    getTransport(): Option<Transport> {
        return this.transport;
    }

    getType(): Option<string> {
        return this.type;
    }

    getVideosForGallery(count: number): List<VideoForGallery> {
        return this.getProduct()
            .map(x => x.getVideos().take(count))
            .getOrElse(List<Video>())
            .map(x => new VideoForGallery(
                this.getId(),
                this.getProductId(),
                Some(x),
                this.getTitle(),
                this.getShortDescription().orElse(this.getProduct().flatMap(p => p.getShortDescription()))
                ),
            );
    }

    hasProductId(): boolean {
        return this.getProductId()
            .nonEmpty();
    }

    matchesTypeFilter(filter: EntryTypeFilter): boolean {
        switch (filter) {
            case 'SelfDrive':
                return this.isSelfDrive();
            case 'Destination':
                return this.isGeneralDestination();
            case 'All':
                return true;
            case 'Flights':
                return this.isFlight();
            case 'Tours':
                return this.isTour();
            case 'Accommodation':
                return this.isAccommodation();
            case 'Transport':
                return this.isTransport()
                    && !this.isFlight();
            default:
                return false;
        }
    }

    matchesTypeFilterSet(s: Set<EntryTypeFilter>): boolean {
        return s.some(x => this.matchesTypeFilter(x));
    }

    withDescriptions(descriptions: Option<Descriptions>): ProposalEntry {
        return new ProposalEntry(
            this.getId(),
            this.getSequence(),
            descriptions.flatMap(d => d.getTitle()).orElse(this.getTitle()),
            this.getType(),
            this.getImage(),
            descriptions.flatMap(d => d.getShort()),
            descriptions.flatMap(d => d.getLong()),
            this.getCompany(),
            this.getStartWaypoint(),
            this.getEndWaypoint(),
            this.getPickup(),
            this.getDropoff(),
            this.getMeetingPoint(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            this.getTransport(),
            this.getCustom(),
            this.getConfirmed(),
            this.getStatus(),
            this.getProduct(),
            this.getOption(),
            this.getClass(),
            this.getCancellationPolicy(),
            this.getNotes(),
            this.getClassifications(),
            this.getExtraNotes(),
            this.getFinancials(),
        );
    }

    withTransport(transport: Option<Transport>): ProposalEntry {
        return new ProposalEntry(
            this.getId(),
            this.getSequence(),
            this.getTitle(),
            this.getType(),
            this.getImage(),
            this.getShortDescription(),
            this.getLongDescription(),
            this.getCompany(),
            this.getStartWaypoint(),
            this.getEndWaypoint(),
            this.getPickup(),
            this.getDropoff(),
            this.getMeetingPoint(),
            this.getReference(),
            this.getInclusions(),
            this.getExclusions(),
            transport,
            this.getCustom(),
            this.getConfirmed(),
            this.getStatus(),
            this.getProduct(),
            this.getOption(),
            this.getClass(),
            this.getCancellationPolicy(),
            this.getNotes(),
            this.getClassifications(),
            this.getExtraNotes(),
            this.getFinancials(),
        );
    }
}

export class ProposalEntryJsonSerializer extends SimpleJsonSerializer<ProposalEntry> {
    static instance: ProposalEntryJsonSerializer = new ProposalEntryJsonSerializer();

    static getTransportExportSerializer(value: ProposalEntry): SimpleJsonSerializer<Transport> {
        if (value.transport.exists(t => t instanceof Flight)) {
            return FlightJsonSerializer.instance;
        }
        return TransportJsonSerializer.instance;
    }

    static getTransportImportSerializer(obj: any): SimpleJsonSerializer<Transport> {
        if (parseString(obj[transportKey + '.' + typeKey]).contains('Flight')) {
            return FlightJsonSerializer.instance;
        }
        return TransportJsonSerializer.instance;
    }

    fromJsonImpl(obj: any): ProposalEntry {
        return new ProposalEntry(
            parseNumber(obj[idKey]),
            parseNumber(obj[sequenceKey]),
            parseString(obj[titleKey]),
            parseString(obj[typeKey]),
            ImageJsonSerializer.instance.fromJson(obj[imageKey]),
            parseString(obj[shortDescriptionKey]),
            parseString(obj[descriptionKey]),
            CompanyJsonSerializer.instance.fromJson(obj[companyKey]),
            WaypointJsonSerializer.instance.fromJson(obj[startKey]),
            WaypointJsonSerializer.instance.fromJson(obj[endKey]),
            WaypointJsonSerializer.instance.fromJson(obj[pickupKey]),
            WaypointJsonSerializer.instance.fromJson(obj[dropoffKey]),
            parseString(obj[meetingPointKey]),
            parseString(obj[referenceKey]),
            parseString(obj[inclusionsKey]),
            parseString(obj[exclusionsKey]),
            ProposalEntryJsonSerializer.getTransportImportSerializer(obj).fromJson(obj[transportKey]),
            CustomJsonSerializer.instance.fromJson(obj[customKey]),
            parseBoolean(obj[confirmedKey]),
            parseString(obj[statusKey]),
            ProductJsonSerializer.instance.fromJson(obj[productKey]),
            ProductOptionJsonSerializer.instance.fromJson(obj[productOptionKey]),
            parseString(obj[classKey]),
            parseString(obj[cancellationPolicyKey]),
            parseString(obj[notesKey]),
            parseList(obj[classificationsKey], parseString),
            parseListSerializable(obj[extraNotesKey], NoteJsonSerializer.instance),
            FinancialsJsonSerializer.instance.fromJson(obj[financialsKey]),
        );
    }

    protected toJsonImpl(value: ProposalEntry, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, value.id)
            .addOptional(sequenceKey, value.sequence)
            .addOptional(titleKey, value.title)
            .addOptional(typeKey, value.type)
            .addOptionalSerializable(imageKey, value.image, ImageJsonSerializer.instance)
            .addOptional(shortDescriptionKey, value.shortDescription)
            .addOptional(descriptionKey, value.longDescription)
            .addOptionalSerializable(companyKey, value.company, CompanyJsonSerializer.instance)
            .addOptionalSerializable(startKey, value.start, WaypointJsonSerializer.instance)
            .addOptionalSerializable(endKey, value.end, WaypointJsonSerializer.instance)
            .addOptionalSerializable(pickupKey, value.pickup, WaypointJsonSerializer.instance)
            .addOptionalSerializable(dropoffKey, value.dropoff, WaypointJsonSerializer.instance)
            .addOptional(meetingPointKey, value.meetingPoint)
            .addOptional(referenceKey, value.reference)
            .addOptional(inclusionsKey, value.inclusions)
            .addOptional(exclusionsKey, value.exclusions)
            .addOptionalSerializable(transportKey, value.transport, ProposalEntryJsonSerializer.getTransportExportSerializer(value))
            .addOptionalSerializable(customKey, value.custom, CustomJsonSerializer.instance)
            .addOptional(confirmedKey, value.confirmed)
            .addOptional(statusKey, value.status)
            .addOptionalSerializable(productKey, value.product, ProductJsonSerializer.instance)
            .addOptionalSerializable(productOptionKey, value.option, ProductOptionJsonSerializer.instance)
            .addOptional(classKey, value.clazz)
            .addOptional(cancellationPolicyKey, value.cancellationPolicy)
            .addOptional(notesKey, value.notes)
            .addIterable(classificationsKey, value.classifications)
            .addIterableSerializable(extraNotesKey, value.extraNotes, NoteJsonSerializer.instance)
            .addOptionalSerializable(financialsKey, value.financials, FinancialsJsonSerializer.instance);
    }
}
