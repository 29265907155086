import { None, Option } from 'funfix-core';
import { List } from 'immutable';
import {
    airlineKey,
    arrivalKey,
    departureKey,
    durationKey, equipmentKey,
    JsonBuilder, mealsKey, notesKey, operatedByKey,
    parseList, parseListSerializable, parseNumber,
    parseString, pnrKey, reservationCodeKey, seatsKey, stopsKey,
} from '../core';
import { SimpleJsonSerializer } from '../core/json-serializer';
import { FlightSeat, FlightSeatJsonSerializer } from './flight-seat';
import { FlightWaypoint, FlightWaypointJsonSerializer } from './flight-waypoint';

export class FlightInfo {
    constructor(
        public departure: Option<FlightWaypoint> = None,
        public arrival: Option<FlightWaypoint> = None,
        public duration: Option<string> = None,
        public airline: Option<string> = None,
        public equipment: Option<string> = None,
        public pnr: Option<string> = None,
        public numberOfStops: Option<number> = None,
        public operatedBy: Option<string> = None,
        public meals: List<string> = List(),
        public seats: List<FlightSeat> = List(),
        public reservationCode: Option<string> = None,
        public notes: List<string> = List()) { }
}

export class FlightInfoJsonSerializer extends SimpleJsonSerializer<FlightInfo> {
    static instance: FlightInfoJsonSerializer = new FlightInfoJsonSerializer();

    fromJsonImpl(obj: any): FlightInfo {
        return new FlightInfo(
            FlightWaypointJsonSerializer.instance.fromJson(obj[departureKey]),
            FlightWaypointJsonSerializer.instance.fromJson(obj[arrivalKey]),
            parseString(durationKey),
            parseString(airlineKey),
            parseString(equipmentKey),
            parseString(pnrKey),
            parseNumber(stopsKey),
            parseString(operatedByKey),
            parseList(mealsKey, parseString),
            parseListSerializable(seatsKey, FlightSeatJsonSerializer.instance),
            parseString(reservationCodeKey),
            parseList(notesKey, parseString),
        );
    }

    protected toJsonImpl(value: FlightInfo, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalSerializable(departureKey, value.departure, FlightWaypointJsonSerializer.instance)
            .addOptionalSerializable(arrivalKey, value.arrival, FlightWaypointJsonSerializer.instance)
            .addOptional(durationKey, value.duration)
            .addOptional(airlineKey, value.airline)
            .addOptional(equipmentKey, value.equipment)
            .addOptional(pnrKey, value.pnr)
            .addOptional(stopsKey, value.numberOfStops)
            .addOptional(operatedByKey, value.operatedBy)
            .addIterable(mealsKey, value.meals)
            .addIterableSerializable(seatsKey, value.seats, FlightSeatJsonSerializer.instance)
            .addOptional(reservationCodeKey, value.reservationCode)
            .addIterable(notesKey, value.notes);
    }
}
