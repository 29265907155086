import {Either, Option} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {Moment} from 'moment';
import {CollectionUtils, ComparisonUtils, EitherUtils, MapUtils, OptionUtils, readAllProposalsKey, StringUtils} from '../core';
import {ApiUser} from './api-user';
import {Proposal, ProposalJsonSerializer, ProposalLike} from './proposal';
import {ProposalSummary, ProposalSummaryJsonSerializer} from './proposal-summary';

export class ProposalCache {
    constructor(
        readonly proposals: List<ProposalLike>,
        readonly byCompany: Map<number, List<ProposalLike>> =
            MapUtils.buildGroupedMapFromListOptional(proposals, e => e.getCompany().flatMap(x => x.getId())),
        readonly byId: Map<number, ProposalLike> = MapUtils.buildMapFromListOptional(proposals, e => e.getId()),
        readonly byRef: Map<string, List<ProposalLike>> = MapUtils.buildGroupedMapFromListOptional(proposals, e => e.getReference())) {
    }

    getAccessibleProposals(user: ApiUser): ProposalCache {
        if (user.hasPermission(readAllProposalsKey)) {
            return this;
        }
        const userCompanyId = user.getCompanyId();
        if (userCompanyId.isEmpty()) {
            return new ProposalCache(List());
        }
        return this.getByCompany(userCompanyId.get());
    }

    getByAgent(n: number): ProposalCache {
        return new ProposalCache(this.proposals.filter(e => e.getAgent().flatMap(x => x.id).contains(n)));
    }

    getByCaseInsensitiveName(s: string): ProposalCache {
        return new ProposalCache(this.proposals.filter(p => p.getTitle().exists(t => StringUtils.equalsIgnoringCase(t, s))));
    }

    getByCompanies(n: Set<number>): ProposalCache {
        return new ProposalCache(this.proposals.filter(x => !x.getCompaniesWithAccess().intersect(n).isEmpty()));
    }

    getByCompany(n: number): ProposalCache {
        return new ProposalCache(
            this.proposals
                .filter(x => x.getCompaniesWithAccess().contains(n)));
    }

    getById(n: number): Option<ProposalLike> {
        return Option.of(this.byId.get(n));
    }

    getByIdEither(n: number): Either<string, ProposalLike> {
        return EitherUtils.toEither(
            Option.of(
                this.byId.get(n),
            ),
            'Proposal does not exist',
        );
    }

    getByIds(ids: Set<number>): ProposalCache {
        return new ProposalCache(OptionUtils.flattenSet(ids.map(x => this.getById(x))).toList());
    }

    getByPresentAs(n: number): ProposalCache {
        return new ProposalCache(this.proposals.filter(e => e.getPresentAsCompany().flatMap(x => x.getId()).contains(n)));
    }

    getByReference(s: string): List<ProposalLike> {
        return this.byRef.get(s, List());
    }

    getByRefs(refs: Set<string>): ProposalCache {
        return new ProposalCache(
            this.proposals
                .filter(x => x.getReference().exists(q => refs.contains(q))),
        );
    }

    getByStatus(s: string): ProposalCache {
        return new ProposalCache(this.proposals.filter(x => x.getStatus().contains(s)));
    }

    getByTitle(s: string): ProposalCache {
        return new ProposalCache(this.proposals.filter(x => x.getTitle().contains(s)));
    }

    getByTitleContains(s: string): ProposalCache {
        return new ProposalCache(this.proposals.filter(p => p.getTitle().exists(t => t.includes(s))));
    }

    getFirstWithReference(ref: string): Option<ProposalLike> {
        return Option.of(this.getByReference(ref)
            .sort((a, b) => ComparisonUtils.optionMomentComparator.reverse().compare(a.getCreated(), b.getCreated()))
            .first());
    }

    getLowercaseTitles(): Set<string> {
        return CollectionUtils.collect(this.proposals, p => p.getTitle().map(x => x.toLowerCase())).toSet();
    }

    getMissingRefs(s: Set<string>): Set<string> {
        return s.subtract(this.getRefs());
    }

    getMissingTitles(s: Set<string>): Set<string> {
        return s.subtract(this.getTitles());
    }

    getMissingTitlesCaseInsensitive(s: Set<string>): Set<string> {
        return s
            .map(x => x.toLowerCase())
            .subtract(this.getTitles());
    }

    getProposals(): List<ProposalLike> {
        return this.proposals;
    }

    getRefs(): Set<string> {
        return this.byRef.keySeq().toSet();
    }

    getTitles(): Set<string> {
        return CollectionUtils.collect(this.proposals, p => p.getTitle()).toSet();
    }

    getTravellingAfter(moment: Moment): ProposalCache {
        return new ProposalCache(this.proposals.filter(p => p.startsAfter(moment)));
    }

    hasAccess(proposalId: number, apiUser: ApiUser): boolean {
        const companyId = apiUser.getCompanyId();
        if (companyId.isEmpty()) {
            return false;
        }
        return Option.of(this.byId.get(proposalId))
            .exists(x => x.hasAccess(companyId.get()));
    }

    /**
     * Checks if the proposal is accessible either via ownership or relationships
     */
    isAccessible(proposalId: number, apiUser: ApiUser): boolean {
        return apiUser.getCompanyId()
            .map(cid => this.isOwner(proposalId, cid) || this.hasAccess(proposalId, apiUser))
            .getOrElse(false);
    }

    isOwner(proposalId: number, companyId: number): boolean {
        return Option.of(this.byId.get(proposalId))
            .exists(x => x.isOwner(companyId));
    }

    merge(other: ProposalCache): ProposalCache {
        return new ProposalCache(
            this.proposals.concat(other.proposals),
            this.byCompany.concat(other.byCompany),
            this.byId.concat(other.byId),
            this.byRef.concat(other.byRef),
        );
    }

    take(limit: number): ProposalCache {
        return new ProposalCache(this.proposals.take(limit));
    }

    toJsonArray(): ReadonlyArray<object> {
        return ProposalJsonSerializer.instance.toJsonArray(this.proposals.map(x => x.buildProposal().getOrElse(new Proposal())));
    }

    toJsonSummary(): readonly object[] {
        const summaries = OptionUtils.flattenList(this.proposals.map(x => ProposalSummary.getSummary(x)));
        return ProposalSummaryJsonSerializer.instance.toJsonArray(summaries);
    }

    update(propsToUpdate: List<Proposal>): ProposalCache {
        if (propsToUpdate.isEmpty()) {
            return this;
        }
        const toUpdateMap = MapUtils.buildMapFromListOptional(propsToUpdate, e => e.id);
        return new ProposalCache(toUpdateMap.concat(this.byId).valueSeq().toList());
    }
}
