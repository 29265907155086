import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    JsonBuilder,
    optFakeIdxKey,
    optionIdKey,
    optionNameKey,
    OptionUtils,
    prodFakeIdxKey,
    productIdKey,
    productNameKey,
    productOptionIdKey,
    SimpleJsonSerializer,
    uriKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Product} from './product';
import {ProductOption} from './product-option';

export class DbImageToProcess implements Validatable {

    constructor(
        readonly option: Option<ProductOption>,
        readonly product: Product,
        readonly url: string,
        readonly title: Option<string>,
        readonly type: 'Core Image' | 'Product Logo' | 'Supplier Logo' | 'Agent Photo' = 'Core Image',
        readonly productOptionId: Option<number> = None,
        readonly prodFakeIdx: Option<number> = None,
        readonly optFakeIdx: Option<number> = None,
    ) { }

    static buildFromProduct(product: Product): List<DbImageToProcess> {
        return product.getImages()
            .map(i => new DbImageToProcess(None, product, i.getHref().get(), i.title));
    }
    static buildFromProductOption(
        product: Product,
        option: ProductOption,
        prodFakeIdx: Option<number>,
        optFakeIdx: Option<number>): List<DbImageToProcess> {
        return option.getImages()
            .filter(x => x.isFullUrl())
            .map(i => new DbImageToProcess(Some(option), product, i.getHref().get(), i.title));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateNvarchar('url', this.url, 1000)),
            this.title.map(n => ValidationUtils.validateVarchar('title', n, 100)),
            Some(ValidationUtils.validateNvarchar('type', this.type, 20)),
            this.productOptionId.map(n => ValidationUtils.validateInt('productOptionId', n.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbImageToProcessJsonSerializer extends SimpleJsonSerializer<DbImageToProcess> {
    static instance: DbImageToProcessJsonSerializer = new DbImageToProcessJsonSerializer();

    fromJsonImpl(obj: any): DbImageToProcess {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(description: DbImageToProcess, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(uriKey, description.url)
            .addOptional(productIdKey, description.product.id)
            .addOptional(productOptionIdKey, description.productOptionId)
            .addOptional(productNameKey, description.product.name)
            .addOptional(optionIdKey, description.option.nonEmpty() ? description.option.flatMap(x => x.id) : Some(2))
            .addOptional(optionNameKey, description.option.flatMap(x => x.name))
            .addOptional(prodFakeIdxKey, description.prodFakeIdx)
            .addOptional(optFakeIdxKey, description.optFakeIdx);

    }
}
