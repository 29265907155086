import {List} from 'immutable';

// Note: A successfull result can still have warnings
export class ValidationResult {

    constructor(
        readonly errors: List<string> = List(),
        readonly warnings: List<string> = List(),
        readonly info: List<string> = List(),
    ) { }

    static empty: ValidationResult = new ValidationResult();

    static error(s: string): ValidationResult {
        return new ValidationResult(List.of(s));
    }

    static errors(s: List<string>): ValidationResult {
        return new ValidationResult(s);
    }

    static info(s: string): ValidationResult {
        return new ValidationResult(List(), List(), List.of(s));
    }

    static infos(s: List<string>): ValidationResult {
        return new ValidationResult(List(), List(), s);
    }

    static warn(s: string): ValidationResult {
        return new ValidationResult(List(), List.of(s));
    }

    static warnings(s: List<string>): ValidationResult {
        return new ValidationResult(List(), s);
    }

    // Add an error if boolean is true
    errorIf(b: boolean, s: string): ValidationResult {
        if (b) {
            return new ValidationResult(List(s));
        }

        return this;
    }

    // Add an error if boolean is true
    infoIf(b: boolean, s: string): ValidationResult {
        if (b) {
            return new ValidationResult(List(), List(), List.of(s));
        }

        return this;
    }

    isEmpty(): boolean {
        return this === ValidationResult.empty
            || (this.errors.isEmpty()
                && this.warnings.isEmpty()
                && this.info.isEmpty());
    }

    merge(other: ValidationResult): ValidationResult {
        if (this.isEmpty()) {
            return other;
        }

        if (other.isEmpty()) {
            return this;
        }

        return new ValidationResult(
            this.errors.concat(...other.errors.toArray()),
            this.warnings.concat(...other.warnings.toArray()),
            this.info.concat(...other.info.toArray()),
        );
    }

    // Add a warning if boolean is true
    warnIf(b: boolean, s: string): ValidationResult {
        if (b) {
            return new ValidationResult(List(), List.of(s));
        }

        return this;
    }

    wasFailure(): boolean {
        return !this.errors.isEmpty();
    }

    wasSuccess(): boolean {
        return this.errors.isEmpty();
    }
}
