export class Distance {

    constructor(
        readonly meters: number
    ) {
    }

    static empty = new Distance(0);

    static km(n: number): Distance {
        return new Distance(n * 1000);
    }

    static meters(n: number): Distance {
        return new Distance(n);
    }

    add(d: Distance): Distance {
        return new Distance(d.meters + this.meters);
    }

    getDistanceString(metric: boolean): string {
        if (metric) {
            return this.getKilometers().toFixed(2) + 'km';
        } else {
            return this.getMiles().toFixed(2) + 'mi';
        }
    }

    getKilometers(): number {
        return this.meters / 1000;
    }

    getMeters(): number {
        return this.meters;
    }

    getMiles(): number {
        return this.meters * 0.0006213712;
    }

    isWithin(d: Distance): boolean {
        return d.meters >= this.meters;
    }
}
