import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    idKey,
    JsonBuilder,
    OptionUtils,
    secondsKey,
    SimpleJsonSerializer,
    statusKey,
    templateKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {AutoImportProposals} from './auto-import-proposals';

export class DbAutoImportProposals implements Validatable {

    constructor(
        readonly seconds: Option<number> = None,
        readonly bookingStatus: string,
        readonly template: Option<number> = None,
        readonly agencyId: number,
    ) {
    }

    static buildFromAutoImportSettings(agency: number, autoImportSettings: AutoImportProposals): List<DbAutoImportProposals> {
        return autoImportSettings.getBookingStatus()
            .map(s => new DbAutoImportProposals(
                autoImportSettings.getSeconds(),
                s,
                autoImportSettings.getTemplate().flatMap(t => t.getId()),
                agency,
                ),
            );
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.seconds.map(n => ValidationUtils.validateInt('seconds', n.toString())),
            Some(ValidationUtils.validateVarchar('status', this.bookingStatus, 20)),
            this.template.map(n => ValidationUtils.validateInt('template', n.toString())),
            Some(ValidationUtils.validateInt('agency', this.agencyId.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbProposalAutoImportSettingsJsonSerializer extends SimpleJsonSerializer<DbAutoImportProposals> {
    static instance: DbProposalAutoImportSettingsJsonSerializer = new DbProposalAutoImportSettingsJsonSerializer();

    protected fromJsonImpl(json: any): DbAutoImportProposals {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(value: DbAutoImportProposals, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(secondsKey, value.seconds)
            .add(statusKey, value.bookingStatus)
            .addOptional(templateKey, value.template)
            .add(idKey, value.agencyId);
    }
}
