import {Option} from 'funfix-core';
import {Moment} from 'moment';
import {BasicComparator, Comparator, CustomComparator} from 'ts-comparators';

export class ComparisonUtils {

    static dayComparator: Comparator<Moment> =
        new CustomComparator<Moment>((a, b) =>
            a.clone().startOf('day').diff(b.clone().startOf('day'), 'days', false));

    static momentComparator: Comparator<Moment> =
        new CustomComparator<Moment>((a, b) => a.diff(b));

    static numberComparator: Comparator<number> = new BasicComparator<number>();

    static optionBooleanComparator: Comparator<Option<boolean>> =
        ComparisonUtils.getOptionComparator(new BasicComparator<boolean>());

    static optionDayComparator: Comparator<Option<Moment>> =
        ComparisonUtils.getOptionComparator(ComparisonUtils.dayComparator);

    static optionMomentComparator: Comparator<Option<Moment>> =
        ComparisonUtils.getOptionComparator(ComparisonUtils.momentComparator);

    static optionNumberComparator: Comparator<Option<number>> =
        ComparisonUtils.getOptionComparator(ComparisonUtils.numberComparator);

    static optionStringComparator: Comparator<Option<string>> =
        ComparisonUtils.getOptionComparator(new BasicComparator<string>());

    static stringComparator: Comparator<string> = new BasicComparator<string>();

    static getOptionComparator<T>(comp: Comparator<T>): Comparator<Option<T>> {
        return new CustomComparator<Option<T>>((value1, value2) => {
            if (!value1.isEmpty() && !value2.isEmpty()) {
                return Option.map2(value1, value2, (a, b) => comp.compare(a, b)).get();
            } else if (value1.isEmpty() && value2.isEmpty()) {
                return 0;
            } else if (value1.isEmpty()) {
                return 1;
            }
            return -1;
        });
    }

}
