import {Option, Some} from 'funfix-core';
import {Map, Set} from 'immutable';
import {BehaviorSubject, EMPTY, Observable, zip} from 'rxjs';
import {map, shareReplay, switchMap, take} from 'rxjs/operators';
import {CollectionUtils} from '../collection-utils';
import {triggerSingleEffect} from '../observable-utils';
import {Crud, Entry} from './crud';

export class InMemoryCache<K, V> extends Crud<K, V> {

    constructor() {
        super();
    }

    subj: BehaviorSubject<Map<K, V>> = new BehaviorSubject(Map());

    async delete(k: K): Promise<Option<V>> {
        const current = await this.getLast(k);
        const data = this.subj.getValue();
        this.subj.next(data.delete(k));
        return current;
    }

    async deleteAll(): Promise<void> {
        this.subj.next(Map());
    }

    async entries(): Promise<Set<Entry<K, V>>> {
        const data = this.subj.getValue();
        return data.entrySeq().map(e => new Entry(e[0], e[1])).toSet();
    }

    async keys(): Promise<Set<K>> {
        const data = this.subj.getValue();
        return data.keySeq().toSet();
    }

    observe(k: K): Observable<Option<V>> {
        return this.subj
            .pipe(map(x => CollectionUtils.safeGet(x, k)));
    }

    async set(k: K, v: V): Promise<Option<V>> {
        const data = this.subj.getValue();
        this.subj.next(data.set(k, v));
        return Some(v);
    }
}
