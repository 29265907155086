import {None, Option, Some} from 'funfix-core';
import {
    descriptionKey,
    JsonBuilder,
    labelKey,
    nameKey,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Module} from './modules';

export class DbEnum implements Validatable {

    constructor(
        readonly name: string,
        readonly description: Option<string> = None,
        readonly label: Option<string> = None,
    ) {
    }

    static fromModule(module: Module): DbEnum {
        return new DbEnum(
            module.getName().get(),
            module.getDescription(),
            None,
        );
    }

    getSummary(): string {
        return OptionUtils.toList<any>(
            this.description,
        ).reduce((a, b) => `${a} - ${b}`, '');
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateVarchar('name', this.name, 50)),
            this.description.map(d => ValidationUtils.validateVarchar('description', d, 200)),
            this.label.map(l => ValidationUtils.validateVarchar('label', l, 50)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbEnumJsonSerializer extends SimpleJsonSerializer<DbEnum> {
    static instance: DbEnumJsonSerializer = new DbEnumJsonSerializer();

    protected fromJsonImpl(json: any): DbEnum {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(value: DbEnum, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(nameKey, value.name)
            .addOptional(descriptionKey, value.description)
            .addOptional(labelKey, value.label);
    }
}
