import {Either, None, Option, Some} from 'funfix-core';
import {Image} from '../models';
import {EitherUtils} from './either-utils';
import {Url} from './url';

export type ImageType =
    'Product'
    | 'ProductOption'
    | 'ProductLogo'
    | 'ProductMap'
    | 'AgentPhoto'
    | 'ProposalCompanyLogo'
    | 'SupplierLogo'
    | 'Welcome';
export type ImageSize = 'Uploaded' | 'LowRes' | 'Thumb600' | 'Thumb100' | 'Thumb50';

export class ImageUtils {

    // Does not handle origins
    static buildFilePathForImage(image: Image, imageType: ImageType, size: ImageSize): Either<string, Url> {
        return EitherUtils.toEither(image.hash
                .flatMap(h => Url.parse(h))
                .flatMap(u => ImageUtils.fillOutPath(u, imageType, size))
                .map(x => x.withFileExtension('jpg')),
            'Failed to build file path for image');
    }

    // Does not handle origins
    static fillOutPath(url: Url, imageType: ImageType, size: ImageSize): Option<Url> {
        return Option.map2(
            this.getFolder(imageType, size),
            this.getSuffix(imageType, size),
            (f, s) => url.appendPath(f).extendFile(s));
    }

    static getFolder(imageType: ImageType, size: ImageSize): Option<string> {
        switch (imageType) {
            case 'Product':
            case 'ProductOption':
                return this.getFolderForCoreImage(size);
            case 'ProductLogo':
                return this.getFolderForDirectorySplitIntoUploadedAndThumbnails(size, 'ProductLogos');
            case 'ProductMap':
                return this.getFolderForNonThumb('Product_Maps', size);
            case 'AgentPhoto':
                return this.getFolderForDirectorySplitIntoUploadedAndThumbnails(size, 'ProposalAgentImages');
            case 'ProposalCompanyLogo':
                return this.getFolderForDirectorySplitIntoUploadedAndThumbnails(size, 'ProposalCompanyImages/Logos');
            case 'Welcome':
                return this.getFolderForDirectorySplitIntoUploadedAndThumbnails(size, 'ProposalImages/WelcomeImages');
            case 'SupplierLogo':
                return this.getFolderForDirectorySplitIntoUploadedAndThumbnails(size, 'ParticipantImages');
        }
    }

    static getFolderForCoreImage(size: ImageSize): Option<string> {
        switch (size) {
            case 'Uploaded':
                return Some('ProductImages/Uploaded');
            case 'LowRes':
            case 'Thumb600':
            case 'Thumb100':
            case 'Thumb50':
                return Some('ProductImages/Thumbnails');

        }
    }

    static getFolderForDirectorySplitIntoUploadedAndThumbnails(size: ImageSize, folder: string): Option<string> {
        switch (size) {
            case 'Uploaded':
                return Some(folder + '/Uploaded');
            case 'LowRes':
                return Some(folder + '/Thumbnails');
            case 'Thumb600':
            case 'Thumb100':
            case 'Thumb50':
                return None;
        }
    }

    // These are basically stored as one folder, there are no thumbnails
    static getFolderForNonThumb(type: string, size: ImageSize): Option<string> {
        switch (size) {
            case 'Uploaded':
                return Some(type);
            case 'LowRes':
            case 'Thumb600':
            case 'Thumb100':
            case 'Thumb50':
                return None;

        }
    }

    static getSuffix(imageType: ImageType, size: ImageSize): Option<string> {
        switch (imageType) {
            case 'Product':
            case 'ProductOption':
                return this.getSuffixForCoreImage(size);
            case 'ProductLogo':
            case 'ProductMap':
            case 'AgentPhoto':
            case 'ProposalCompanyLogo':
            case 'SupplierLogo':
            case 'Welcome':
                return this.getSuffixForNonCoreImage(size);
        }
    }

    static getSuffixForCoreImage(size: ImageSize): Option<string> {
        switch (size) {
            case 'Uploaded':
                return Some('');
            case 'LowRes':
                return Some('_thumblowres');
            case 'Thumb600':
                return Some('_thumb600_600');
            case 'Thumb100':
                return Some('_thumb100_100');
            case 'Thumb50':
                return Some('_thumb50_50');
        }
    }

    static getSuffixForNonCoreImage(size: ImageSize): Option<string> {
        switch (size) {
            case 'Uploaded':
                return Some('');
            case 'LowRes':
                return Some('_thumb');
            case 'Thumb600':
            case 'Thumb100':
            case 'Thumb50':
                return None;
        }
    }
}
