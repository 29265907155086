import {None, Option, Some} from 'funfix-core';
import {
    heroKey,
    JsonBuilder,
    notesKey,
    orderKey,
    parseBoolean,
    parseNumber,
    parseString,
    parseURL,
    SimpleJsonSerializer,
    uriKey,
    Url,
} from '../core';
import {Media} from './media';

export class Video extends Media {
    constructor(
        uri: Option<Url> = None,
        hero: Option<boolean> = None,
        order: Option<number> = None,
        notes: Option<string> = None) {
        super(uri, hero, order, notes);
    }

    getEmbedUrl(): Option<string> {
        return this.getVideoProvider().flatMap(provider => {
            switch (provider) {
                case 'youtube':
                    return this.getVideoLinkWithoutProvider().map(x => 'https://www.youtube.com/embed/' + x);
                case 'vimeo':
                    return this.getVideoLinkWithoutProvider().map(x => 'https://player.vimeo.com/video/' + x);
            }
        });
    }

    private getVideoLinkWithoutProvider(): Option<string> {
        return this.uri.flatMap(x => Option.of(x.getHref().split('/')[1]));
    }

    private getVideoProvider(): Option<'youtube' | 'vimeo'> {
        return this.uri.flatMap(x => Option.of(x.getHref().split('/')[0]))
            .flatMap(x => {
                switch (x) {
                    case 'youtube':
                    case 'vimeo':
                        return Some(x) as Option<'youtube' | 'vimeo'>;
                    default:
                        console.warn('Missing video provider: ' + x);
                        return None;
                }
            });
    }

    isVimeo(): boolean {
        return this.getVideoProvider().exists(x => x === 'vimeo');
    }

    isYoutube(): boolean {
        return this.getVideoProvider().exists(x => x === 'youtube');
    }
}

export class VideoJsonSerializer<T extends Media> extends SimpleJsonSerializer<Video> {
    static instance: VideoJsonSerializer<Media> = new VideoJsonSerializer();

    fromJsonImpl(obj: any): Video {
        return new Video(
            parseURL(obj[uriKey]),
            parseBoolean(obj[heroKey]),
            parseNumber(obj[orderKey]),
            parseString(obj[notesKey]));
    }

    protected toJsonImpl(value: Video, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptionalURI(uriKey, value.uri)
            .addOptional(heroKey, value.hero)
            .addOptional(orderKey, value.order)
            .addOptional(notesKey, value.notes);
    }
}
