import {Either, None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {
    ComparisonUtils,
    contactKey,
    defaultsKey,
    EitherUtils,
    financialsKey,
    idKey,
    JsonBuilder,
    JsonSerializer,
    Language,
    languageKey,
    locationKey,
    logoKey,
    nameKey,
    OptionUtils,
    parseListSerializable,
    parseNumber,
    parseString,
    proposalTemplatesKey,
    relationshipsKey,
    SimpleJsonSerializer,
    socialKey,
    StringSearchType,
    StringUtils,
    translationsKey,
    typeKey,
} from '../core';
import {
    CompanySummary,
    Image,
    PhraseTranslations,
    PhraseTranslationsJsonSerializer,
    ProductStringSearchField
} from '../models';
import {Contact, ContactJsonSerializer} from './contact';
import {Financials, FinancialsJsonSerializer} from './financials';
import {PhysicalLocation, PhysicalLocationJsonSerializer} from './physical-location';
import {ProposalTemplate, ProposalTemplateJsonSerializer} from './proposal-template';
import {ProposalTemplateDefaults, ProposalTemplateDefaultsJsonSerializer} from './proposal-template-defaults';
import {Relationships, RelationshipsJsonSerializer} from './relationships';
import {Social, SocialJsonSerializer} from './social';

export type CompanyStringSearchField = 'Name' | 'City' | 'Country' | 'State' | 'Type';

export class CompanyLike {

    static buildFromCompanies(companies: List<Company>): List<CompanySummary> {
        return companies.map(x => this.buildFromCompany(x));
    }

    static buildFromCompany(company: Company): CompanySummary {
        return new CompanySummary(
            company.getId(),
            company.getType(),
            company.getName(),
            company.getLogoHref(),
            company.getRelationships(),
            company.getLanguage(),
            company.getProposalTemplateDefaults(),
            company.getProposalTemplates(),
        );
    }

    buildAsSupplier(): Company {
        return new Company(
            this.getId(),
            Some('Supplier'),
            this.getName(),
            this.getLocation(),
            this.getContact(),
            this.getLogoHref(),
            this.getSocial(),
            this.getFinancials(),
            this.getRelationships(),
            this.getLanguage(),
            this.getProposalTemplateDefaults(),
            this.getProposalTemplates(),
        );
    }

    buildCompany(): Option<Company> {
        return Option.of(
            new Company(
                this.getId(),
                this.getType(),
                this.getName(),
                this.getLocation(),
                this.getContact(),
                this.getLogoHref(),
                this.getSocial(),
                this.getFinancials(),
                this.getRelationships(),
                this.getLanguage(),
                this.getProposalTemplateDefaults(),
                this.getProposalTemplates(),
            ),
        );
    }

    buildCompanyWithoutDefaults(): Option<Company> {
        return Option.of(
            new Company(
                this.getId(),
                this.getType(),
                this.getName(),
                this.getLocation(),
                this.getContact(),
                this.getLogoHref(),
                this.getSocial(),
                this.getFinancials(),
                this.getRelationships(),
                this.getLanguage(),
            ),
        );
    }

    buildMinimalCompany(): Option<Company> {
        return Option.of(
            new Company(
                this.getId(),
                this.getType(),
                this.getName(),
            ),
        );
    }

    getAccessibleMappingCompanies(): Set<number> {
        return this.getAccessibleProductCompanies();
    }

    getAccessibleProductCompanies(): Set<number> {
        return OptionUtils.toSet(this.getRelationships())
            .flatMap(x => x.accessAllProducts);
    }

    getAccessibleProposalCompanies(): Set<number> {
        return OptionUtils.toSet(this.getRelationships())
            .flatMap(x => x.accessAllProposals);
    }

    getContact(): Option<Contact> {
        return None;
    }

    getFinancials(): Option<Financials> {
        return None;
    }

    /**
     * Sorted Alphabetically to give some sanity
     */
    getFirstProposalTemplate(): Option<ProposalTemplate> {
        return Option.of(this.getProposalTemplates()
            .sort((a, b) => ComparisonUtils.optionStringComparator.compare(a.name, b.name))
            .first());
    }

    getId(): Option<number> {
        return None;
    }

    getLanguage(): Option<string> {
        return None;
    }

    getLocation(): Option<PhysicalLocation> {
        return None;
    }

    getLogo(): Option<Image> {
        return None;
    }

    getLogoHref(): Option<string> {
        return this.getLogo().flatMap(x => x.getHref());
    }

    getManagedCompanies(): Set<number> {
        return OptionUtils.toSet(this.getRelationships())
            .flatMap(x => x.manageCompanies);
    }

    getName(): Option<string> {
        return None;
    }

    getProposalTemplateById(id: number): Option<ProposalTemplate> {
        return Option.of(this.getProposalTemplates().find(x => x.id.contains(id)));
    }

    getProposalTemplateByIdEither(id: number): Either<string, ProposalTemplate> {
        return EitherUtils.toEither(this.getProposalTemplateById(id), `Missing template for proposal template id ${id}`);
    }

    getProposalTemplateCompanies(): Set<number> {
        return OptionUtils.toSet(this.getRelationships())
            .flatMap(x => x.accessAllTemplates);
    }

    getProposalTemplateDefaults(): Option<ProposalTemplateDefaults> {
        return None;
    }

    getProposalTemplateDefaultsById(id: number): Option<ProposalTemplateDefaults> {
        return this.getProposalTemplateById(id).flatMap(x => x.defaults);
    }

    getProposalTemplateDefaultsByIdEither(id: number): Either<string, ProposalTemplateDefaults> {
        return EitherUtils.toEither(this.getProposalTemplateDefaultsById(id), `Missing defaults for proposal template id ${id}`);
    }

    getProposalTemplates(): List<ProposalTemplate> {
        return List();
    }

    getRelationships(): Option<Relationships> {
        return None;
    }

    getSearchString(field: ProductStringSearchField): Option<string> {
        switch (field) {
            case 'Type':
                return this.getType();
            case 'Name':
                return this.getName();
            case 'City':
                return this.getLocation().flatMap(x => x.city);
            case 'Country':
                return this.getLocation().flatMap(x => x.country);
            case 'State':
                return this.getLocation().flatMap(x => x.state);
        }
    }

    getSharedApiCompanies(): Set<number> {
        return OptionUtils.toSet(this.getRelationships())
            .flatMap(x => x.accessAllApis);
    }

    getSocial(): Option<Social> {
        return None;
    }

    getTranslations(): Option<PhraseTranslations> {
        return None;
    }

    getType(): Option<string> {
        return None;
    }

    isEmpty(): boolean {
        return (
            this.getId().isEmpty() &&
            this.getType().isEmpty() &&
            this.getName().isEmpty() &&
            this.getLocation()
                .forAll(x => x.isEmpty()) &&
            this.getContact()
                .forAll(x => x.isEmpty()) &&
            this.getSocial()
                .forAll(x => x.isEmpty())
        );
    }

    isProposalCompany(): boolean {
        return this.getType().contains('Proposal Company');
    }

    isSame(c: Company): boolean {
        return OptionUtils.equals(this.getId(), c.getId());
    }

    isSupplier(): boolean {
        return this.getType().contains('Supplier');
    }

    matchesSearch(field: CompanyStringSearchField, caseSensitive: boolean, comparison: string, type: StringSearchType): boolean {
        return this.getSearchString(field)
            .exists(str => StringUtils.stringSearchMatch(caseSensitive, str, comparison, type));
    }

    translate(s: string, language: Language): Option<string> {
        return this.getTranslations().flatMap(x => x.translate(s, language));
    }

}

export class Company extends CompanyLike {
    constructor(
        readonly id: Option<number> = None,
        readonly type: Option<string> = None,
        readonly name: Option<string> = None,
        readonly location: Option<PhysicalLocation> = None,
        readonly contact: Option<Contact> = None,
        readonly logo: Option<string> = None,
        readonly social: Option<Social> = None,
        readonly financials: Option<Financials> = None,
        readonly relationships: Option<Relationships> = None,
        readonly language: Option<string> = None,
        readonly proposalTemplateDefaults: Option<ProposalTemplateDefaults> = None,
        readonly proposalTemplates: List<ProposalTemplate> = List(),
        readonly translations: Option<PhraseTranslations> = None,
    ) {
        super();
    }

    static buildForProposalCompany(id: number, name: string): Company {
        return new Company(Some(id), Some('Proposal Company'), Some(name));
    }

    getContact(): Option<Contact> {
        return this.contact;
    }

    getFinancials(): Option<Financials> {
        return super.getFinancials();
    }

    getId(): Option<number> {
        return this.id;
    }

    getLanguage(): Option<string> {
        return this.language;
    }

    getLocation(): Option<PhysicalLocation> {
        return this.location;
    }

    getLogo(): Option<Image> {
        return this.logo.map(x => Image.fromString(x));
    }

    getName(): Option<string> {
        return this.name;
    }

    getProposalTemplateDefaults(): Option<ProposalTemplateDefaults> {
        return this.proposalTemplateDefaults;
    }

    getProposalTemplates(): List<ProposalTemplate> {
        return this.proposalTemplates;
    }

    getRelationships(): Option<Relationships> {
        return this.relationships;
    }

    getSocial(): Option<Social> {
        return this.social;
    }

    getTranslations(): Option<PhraseTranslations> {
        return this.translations;
    }

    getType(): Option<string> {
        return this.type;
    }

    withoutDefaults(): Company {
        return new Company(
            this.getId(),
            this.getType(),
            this.getName(),
            this.getLocation(),
            this.getContact(),
            this.getLogoHref(),
            this.getSocial(),
            this.getFinancials(),
            this.getRelationships(),
            this.getLanguage(),
            None,
            List());
    }
}

export class CompanyJsonSerializer extends SimpleJsonSerializer<Company> {
    static instance: CompanyJsonSerializer = new CompanyJsonSerializer();

    protected constructor() {
        super();
    }

    fromJsonImpl(obj: any): Company {
        return new Company(
            parseNumber(obj[idKey]),
            parseString(obj[typeKey]),
            parseString(obj[nameKey]),
            PhysicalLocationJsonSerializer.instance.fromJson(obj[locationKey]),
            ContactJsonSerializer.instance.fromJson(obj[contactKey]),
            parseString(obj[logoKey]),
            SocialJsonSerializer.instance.fromJson(obj[socialKey]),
            FinancialsJsonSerializer.instance.fromJson(obj[financialsKey]),
            RelationshipsJsonSerializer.instance.fromJson(obj[relationshipsKey]),
            parseString(obj[languageKey]),
            ProposalTemplateDefaultsJsonSerializer.instance.fromJson(obj[defaultsKey]),
            parseListSerializable(obj[proposalTemplatesKey], ProposalTemplateJsonSerializer.instance),
            PhraseTranslationsJsonSerializer.instance.fromJson(obj[translationsKey]),
        );
    }

    protected toJsonImpl(company: Company, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, company.id)
            .addOptional(typeKey, company.type)
            .addOptional(nameKey, company.name)
            .addOptionalSerializable(locationKey, company.location, PhysicalLocationJsonSerializer.instance)
            .addOptionalSerializable(contactKey, company.contact, ContactJsonSerializer.instance)
            .addOptional(logoKey, company.logo)
            .addOptionalSerializable(socialKey, company.social, SocialJsonSerializer.instance)
            .addOptionalSerializable(financialsKey, company.financials, FinancialsJsonSerializer.instance)
            .addOptionalSerializable(relationshipsKey, company.relationships, RelationshipsJsonSerializer.instance)
            .addOptional(languageKey, company.language)
            .addOptionalSerializable(
                defaultsKey,
                company.proposalTemplateDefaults,
                ProposalTemplateDefaultsJsonSerializer.instance,
            )
            .addIterableSerializable(proposalTemplatesKey, company.proposalTemplates, ProposalTemplateJsonSerializer.instance)
            .addOptionalSerializable(translationsKey, company.translations, PhraseTranslationsJsonSerializer.instance);
    }
}

export class FilteredCompanyJsonSerializer<T extends Company> extends JsonSerializer<Company, T> {
    static instance: FilteredCompanyJsonSerializer<Company> = new FilteredCompanyJsonSerializer();

    protected constructor() {
        super();
    }

    fromJsonImpl(obj: any): Company {
        return new Company(
            parseNumber(obj[idKey]),
            parseString(obj[typeKey]),
            parseString(obj[nameKey]),
            None,
            None,
            parseString(obj[logoKey]),
        );
    }

    protected toJsonImpl(company: T, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(idKey, company.id)
            .addOptional(typeKey, company.type)
            .addOptional(nameKey, company.name)
            .addOptional(logoKey, company.logo);
    }
}
