import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    hashKey,
    imageAtUrlHashKey,
    JsonBuilder,
    OptionUtils,
    SimpleJsonSerializer,
    typeKey,
    uriKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Image} from './image';

// Website, Partial Match and Full Match
type ImageUrlType = 'W' | 'P' | 'F';

export class DbImageUrl implements Validatable {

    constructor(
        readonly sha256Hash: string,
        readonly url: string,
        readonly imageAtUrlsha256Hash: Option<string>, // Only relevant for F and P
        readonly type: ImageUrlType,
    ) { }

    static fromAnalysisImage(sha: string, image: Image, type: ImageUrlType): Option<DbImageUrl> {
        return Option.map2(
            image.getHref(),
            image.hash,
            (href, hash) => new DbImageUrl(sha, href, Some(hash), type));
    }

    static fromImage(image: Image): List<DbImageUrl> {
        return Option.map2(
            image.hash,
            image.analysis,
            (h, a) => {
                return OptionUtils.flattenList(a.fullMatches.map(m => DbImageUrl.fromAnalysisImage(h, m, 'F'))
                    .concat(a.partialMatches.map(m => DbImageUrl.fromAnalysisImage(h, m, 'P')))
                    .concat(a.websites.map(m => Option.of(new DbImageUrl(h, m, None, 'W')))));
            }).getOrElse(List());
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateVarchar('sha256Hash', this.sha256Hash, 64)),
            Some(ValidationUtils.validateNvarchar('url', this.url, 2000)),
            this.imageAtUrlsha256Hash.map(n => ValidationUtils.validateVarchar('imageAtUrlsha256Hash', n, 64)),
            Some(ValidationUtils.validateChar('type', this.type)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DbImageUrlJsonSerializer extends SimpleJsonSerializer<DbImageUrl> {
    static instance: DbImageUrlJsonSerializer = new DbImageUrlJsonSerializer();

    fromJsonImpl(obj: any): DbImageUrl {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(image: DbImageUrl, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(hashKey, image.sha256Hash)
            .add(uriKey, image.url)
            .add(typeKey, image.type)
            .addOptional(imageAtUrlHashKey, image.imageAtUrlsha256Hash);
    }
}
