import {Either, None, Option, Some} from 'funfix-core';
import {List, Set} from 'immutable';
import {
    agenciesKey,
    companyKey,
    EitherUtils,
    extraParam1Key,
    idKey,
    JsonBuilder,
    labelKey,
    parseListSerializable,
    parseNumber,
    parseString,
    parseURL,
    passwordKey,
    settingsKey,
    SimpleJsonSerializer,
    Url,
    urlKey,
    userKey,
} from '../core';
import {ApiAgency, ApiAgencyJsonSerializer} from './api-agency';
import {AutoImportProducts, AutoImportProductsJsonSerializer} from './auto-import-products';
import {AutoImportSettings} from './auto-import-settings';
import {Company, CompanyJsonSerializer} from './company';
import {ProposalTemplate} from './proposal-template';
import {ProposalTemplateDefaults} from './proposal-template-defaults';

export class ApiConnection {
    constructor(
        readonly id: Option<number> = None,
        readonly label: Option<string> = None,
        readonly company: Option<Company> = None,
        readonly url: Option<Url> = None,
        readonly agencies: List<ApiAgency> = List(),
        readonly productAutoImportSettings: List<AutoImportProducts> = List(),
        readonly user: Option<string> = None,
        readonly password: Option<string> = None,
        readonly extraParam1: Option<string> = None) {
    }

    filterAccessibleCredentials(companyId: number): ApiConnection {
        return this.withAgencies(this.agencies.filter(x => x.isAccessibleByCompany(companyId)));
    }

    getAgencies(): List<ApiAgency> {
        return this.agencies;
    }

    getAgency(): List<ApiAgency> {
        return this.agencies;
    }

    getAgencyById(id: number): Either<string, ApiAgency> {
        return EitherUtils.liftEither(this.getAgencies().find(x => x.id.contains(id)), `No agency with id ${id}`);
    }

    getAgencyByLogin(login: string): Either<string, ApiAgency> {
        return EitherUtils.liftEither(this.getAgencies().find(x => x.user.contains(login)), `No agency with login ${login}`);
    }

    getCompany(): Option<Company> {
        return this.company;
    }

    getCompanyDefaults(): Option<ProposalTemplateDefaults> {
        return this.company.flatMap(x => x.getProposalTemplateDefaults());
    }

    getCompanyIdEither(): Either<string, number> {
        return EitherUtils.toEither(this.company.flatMap(x => x.getId()), 'Missing company id');
    }

    getCompanyWithoutDefaults(): Option<Company> {
        return this.company.map(x => x.withoutDefaults());
    }

    getExtraParam1Either(): Either<string, string> {
        return EitherUtils.toEither(this.extraParam1, 'Connection requires global extra param 1');
    }

    getFirstProposalTemplate(): Option<ProposalTemplate> {
        return this.company.flatMap(x => x.getFirstProposalTemplate());
    }

    getId(): Option<number> {
        return this.id;
    }

    getIdEither(): Either<string, number> {
        return EitherUtils.toEither(this.id, 'Connection missing id');
    }

    getLabel(): Option<string> {
        return this.label;
    }

    getPasswordEither(): Either<string, string> {
        return EitherUtils.toEither(this.password, 'Connection requires global password');
    }

    getProductApiRequestSettingsForInterval(n: number, connection: ApiConnection): List<AutoImportSettings> {
        return this.productAutoImportSettings
            .filter(x => x.getSeconds().contains(n))
            .map(x => new AutoImportSettings(
                x.getAgency(),
                Some(connection),
                x.getTemplate(),
                List()));
    }

    getProductAutoImportSettings(): List<AutoImportProducts> {
        return this.productAutoImportSettings;
    }

    getProposalApiRequestSettingsForInterval(n: number): List<AutoImportSettings> {
        return this.agencies
            .flatMap(a => a.getApiRequestSettings(n, this));
    }

    getProposalTemplateById(id: number): Option<ProposalTemplate> {
        return this.company
            .flatMap(x => x.getProposalTemplateById(id));
    }

    getUrl(): Option<Url> {
        return this.url;
    }

    getUrlEither(): Either<string, Url> {
        return EitherUtils.toEither(this.url, 'Api Connection missing url');
    }

    getUserNameEither(): Either<string, string> {
        return EitherUtils.toEither(this.user, 'Connection requires global login');
    }

    hasGlobalLogin(): boolean {
        return !this.user.isEmpty();
    }

    hasOneAgency(): boolean {
        return this.agencies.size === 1;
    }

    isAccessibleBy(n: Set<number>): boolean {
        return this.company.exists(x => x.id.exists(i => n.contains(i)));
    }

    isEmpty(): boolean {
        return this.id.isEmpty()
            && this.label.isEmpty()
            && this.company.isEmpty()
            && this.url.isEmpty()
            && this.agencies.isEmpty();
    }

    withAgencies(agencies: List<ApiAgency>): ApiConnection {
        return new ApiConnection(
            this.id,
            this.label,
            this.company,
            this.url,
            agencies,
            this.productAutoImportSettings,
            this.user,
            this.password,
            this.extraParam1);
    }
}

export class ApiConnectionJsonSerializer extends SimpleJsonSerializer<ApiConnection> {
    static instance = new ApiConnectionJsonSerializer();

    protected fromJsonImpl(json: any): ApiConnection {
        return new ApiConnection(
            parseNumber(json[idKey]),
            parseString(json[labelKey]),
            CompanyJsonSerializer.instance.fromJson(json[companyKey]),
            parseURL(json[urlKey]),
            parseListSerializable(json[agenciesKey], ApiAgencyJsonSerializer.instance),
            parseListSerializable(json[settingsKey], AutoImportProductsJsonSerializer.instance),
            parseString(json[userKey]),
            parseString(json[passwordKey]),
            parseString(json[extraParam1Key]),
        );
    }

    protected toJsonImpl(value: ApiConnection, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, value.id)
            .addOptional(labelKey, value.label)
            .addOptionalSerializable(companyKey, value.company, CompanyJsonSerializer.instance)
            .addOptionalURI(urlKey, value.url)
            .addIterableSerializable(agenciesKey, value.agencies, ApiAgencyJsonSerializer.instance)
            .addIterableSerializable(settingsKey, value.productAutoImportSettings, AutoImportProductsJsonSerializer.instance)
            .addOptional(userKey, value.user)
            .addOptional(passwordKey, value.password)
            .addOptional(extraParam1Key, value.extraParam1);
    }
}
