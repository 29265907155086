import {None, Option} from 'funfix-core';
import {
    bytesKey,
    fileKey,
    JsonBuilder, linkKey,
    minKey,
    parseNumber,
    parseString,
    SimpleJsonSerializer,
    typeKey,
} from '../core';

export class Pdf {
    constructor(
        readonly file: Option<string> = None,
        readonly type: Option<string> = None,
        readonly bytes: Option<number> = None,
        readonly link: Option<string> = None) {
    }

    getBytes(): Option<number> {
        return this.bytes;
    }

    getFile(): Option<string> {
        return this.file;
    }

    getLink(): Option<string> {
        return this.link;
    }

    getSummary(): Option<Pdf> {
        return this.link.map(x => new Pdf(
            None,
            None,
            None,
            this.link,
        ));
    }

    getType(): Option<string> {
        return this.type;
    }

}

export class PdfJsonSerializer extends SimpleJsonSerializer<Pdf> {
    static instance: PdfJsonSerializer = new PdfJsonSerializer();

    fromJsonImpl(obj: any): Pdf {
        return new Pdf(
            parseString(obj[fileKey]),
            parseString(obj[typeKey]),
            parseNumber(obj[bytesKey]),
            parseString(obj[linkKey]));
    }

    protected toJsonImpl(pdf: Pdf, builder: JsonBuilder = new JsonBuilder()): JsonBuilder {
        return builder
            .addOptional(fileKey, pdf.getFile())
            .addOptional(minKey, pdf.getType())
            .addOptional(bytesKey, pdf.getBytes())
            .addOptional(linkKey, pdf.getLink());
    }
}
