import {None, Option} from 'funfix-core';
import {
    descriptionKey,
    dropoffKey,
    JsonBuilder,
    parseString,
    pickupKey,
    SimpleJsonSerializer,
    titleKey,
    typeKey,
} from '../core';
import {Waypoint, WaypointJsonSerializer} from './waypoint';

export class Transport {

    constructor(
        readonly type: Option<string> = None,
        readonly title: Option<string> = None,
        readonly description: Option<string> = None,
        readonly dropoff: Option<Waypoint> = None,
        readonly pickup: Option<Waypoint> = None,
    ) {
    }

    getDescription(): Option<string> {
        return this.description;
    }

    getDropoff(): Option<Waypoint> {
        return this.dropoff;
    }

    getPickup(): Option<Waypoint> {
        return this.pickup;
    }

    getTitle(): Option<string> {
        return this.title;
    }

    getType(): Option<string> {
        return this.type;
    }

    isEmpty(): boolean {
        return this.getType().isEmpty() || (this.getTitle().isEmpty() && this.getDescription().isEmpty());
    }
}

export class TransportJsonSerializer extends SimpleJsonSerializer<Transport> {
    static instance: TransportJsonSerializer = new TransportJsonSerializer();

    fromJsonImpl(obj: any): Transport {

        return new Transport(
            parseString(obj[typeKey]),
            parseString(obj[titleKey]),
            parseString(obj[descriptionKey]),
            WaypointJsonSerializer.instance.fromJson(obj[dropoffKey]),
            WaypointJsonSerializer.instance.fromJson(obj[pickupKey]));
    }

    protected toJsonImpl(value: Transport, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(typeKey, value.type)
            .addOptional(titleKey, value.title)
            .addOptional(descriptionKey, value.description)
            .addOptionalSerializable(dropoffKey, value.dropoff, WaypointJsonSerializer.instance)
            .addOptionalSerializable(pickupKey, value.pickup, WaypointJsonSerializer.instance);
    }
}
