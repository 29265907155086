// Since room and message data are retrieved independently this object acts as a union of the 2.
import { Option } from 'funfix-core';
import { List, Set } from 'immutable';
import { Moment } from 'moment';
import { ValueComparator } from 'ts-comparators';
import { ComparisonUtils } from '../core';
import { ChatMessage } from './chat';
import { RoomData } from './chat-room';
import { IdentifiablePerson } from './identifiable-person';

const chatMessageComparator =
    new ValueComparator<ChatMessage, Option<Moment>>(m => m.time, ComparisonUtils.optionMomentComparator);

export class RoomAndMessageData {
    constructor(
        readonly roomData: RoomData,
        readonly users: Set<IdentifiablePerson>,
        readonly messages: List<ChatMessage>) {
    }

    canEdit(u: Option<IdentifiablePerson>): boolean {
        return this.roomData.canEdit(u);
    }

    getLastMessageTime(): Option<Moment> {
        return Option.of(this.getSortedMessages().last()).flatMap(m => m.time);
    }

    getMessageCountAfter(moment: Moment, identity: string): number {
        return this.messages
            .filter(msg => !msg.from.contains(identity))
            .reduce((a, b) => {
                return a + (b.wasReceivedAfter(moment).contains(true) ? 1 : 0);
            }, 0);
    }

    getProposalId(): Option<string> {
        return this.roomData.getProposalId();
    }

    getSortedMessages(): List<ChatMessage> {
        return this.messages.sort((a, b) => chatMessageComparator.compare(a, b));
    }

    getType(user: Option<IdentifiablePerson>): string {
        if (this.roomData.isGeneralChat()) {
            return 'Traveller group chat';
        } else if (this.roomData.isAgentGeneralChat()) {
            return 'Agent announcements';
        } else if (this.isPrivateGroupChannel()) {
            const usersWithoutSelf = this.getUsersWithoutSelf(user);
            if (usersWithoutSelf.size === 1) {
                const firstUser = Option.of(usersWithoutSelf.first());
                return 'Private messages with ' + firstUser.flatMap(u => u.getFullName()).getOrElse('Unknown');
            } else if (usersWithoutSelf.size > 3) {
                const name = Option.of(usersWithoutSelf.first()).flatMap(u => u.getFullName()).getOrElse('Unknown');
                return 'Private group with ' + name + ' and ' + (this.users.size - 1).toString() + 'others';
            } else {
                return 'Private group with ' + this.getUsersString(user);
            }
        }
        return 'Unknown';
    }

    getUnreadMessageCount(identity: string): number {
        const lastActive = this.roomData.getLastActive(identity);
        return lastActive.map(a => this.getMessageCountAfter(a, identity)).getOrElse(this.messages.size);
    }

    getUserById(id: string): Option<IdentifiablePerson> {
        return Option.of(this.users.find(p => p.identity.exists(i => id === i)));
    }

    // Ef. John Doe and Jim Doe and Fred Dagg
    private getUsersString(user: Option<IdentifiablePerson>): string {
        return this.getUsersWithoutSelf(user).reduce((acc, u) => {
            const name = u.getFullName().getOrElse('Unknown');
            if (acc === '') {
                return name;
            } else {
                return acc + ' and ' + name;
            }
        }, '');
    }

    getUsersWithoutSelf(user: Option<IdentifiablePerson>): Set<IdentifiablePerson> {
        return this.users.filter(u => user.flatMap(self => self.identity).exists(s => !u.identity.contains(s)));
    }

    hasUnreadMessages(identity: string): boolean {
        return this.getUnreadMessageCount(identity) > 0;
    }

    isPMBetween(ids: Set<string>): boolean {
        return this.getProposalId().isEmpty()
            && this.roomData.joined.size === ids.size
            && this.roomData.joined.keySeq().every(i => ids.contains(i));
    }

    isPrivateGroupChannel(): boolean {
        return this.getProposalId().isEmpty();
    }

    shouldShowChannel(ip: Option<IdentifiablePerson>): boolean {
        if (ip.isEmpty()) {
            return false;
        } else if (ip.get().isAgent()) {
            return !this.roomData.isGeneralChat();
        } else if (ip.get().isTraveller()) {
            return !(this.roomData.isAgentGeneralChat() && this.messages.isEmpty());
        }

        return false;
    }
}
