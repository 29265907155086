import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    customDescriptionKey,
    customTitleKey,
    fakeIdxKey,
    JsonBuilder,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {Custom} from './custom';
import {ProposalEntry} from './proposal-entry';

export class DbCustomEntry implements Validatable {

    constructor(
        readonly fakeIdx: Option<number> = None,
        readonly customTitle: Option<string> = None,
        readonly customDescription: Option<string> = None,
    ) {
    }

    static fromCustom(custom: Custom, idx: number): Option<DbCustomEntry> {
        if (custom.isEmpty()) {
            return None;
        }
        return Some(new DbCustomEntry(Some(idx), custom.title, custom.description));
    }

    static fromEntries(entries: List<ProposalEntry>): List<DbCustomEntry> {
        return OptionUtils.flattenList(entries.map((e, idx) => DbCustomEntry.fromEntry(e, idx)));
    }

    static fromEntry(e: ProposalEntry, idx: number): Option<DbCustomEntry> {
        return e.getCustom().flatMap(c => DbCustomEntry.fromCustom(c, idx));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.fakeIdx.map(n => ValidationUtils.validateInt('fakeIdx', n.toString())),
            this.customTitle.map(n => ValidationUtils.validateTitle('customTitle', n)),
            this.customDescription.map(n => ValidationUtils.validateVarchar('customDescription', n, Number.MAX_VALUE)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBCustomEntryJsonSerializer extends SimpleJsonSerializer<DbCustomEntry> {
    static instance: DBCustomEntryJsonSerializer = new DBCustomEntryJsonSerializer();

    fromJsonImpl(obj: any): DbCustomEntry {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(customEntry: DbCustomEntry, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(fakeIdxKey, customEntry.fakeIdx)
            .addOptional(customTitleKey, customEntry.customTitle)
            .addOptional(customDescriptionKey, customEntry.customDescription);
    }
}
