import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    fakeIdxKey,
    JsonBuilder,
    OptionUtils,
    SimpleJsonSerializer,
    transportDescriptionKey,
    transportTitleKey,
    transportTypeKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {EnumConstantMap} from './enum-constant';
import {ProposalEntry} from './proposal-entry';
import {Transport} from './transport';

export class DBTransportEntry implements Validatable {

    constructor(
        readonly fakeIdx: Option<number> = None,
        readonly transportTitle: string,
        readonly transportDescription: Option<string> = None,
        readonly transportType: number,
    ) { }

    static fromEntries(entries: List<ProposalEntry>, transportTypeMap: EnumConstantMap): List<DBTransportEntry> {
        return OptionUtils.flattenList(entries.map((e, idx) => DBTransportEntry.fromEntry(e, idx, transportTypeMap)));
    }

    static fromEntry(e: ProposalEntry, idx: number, transportTypeMap: EnumConstantMap): Option<DBTransportEntry> {
        return e.getTransport().flatMap(c => DBTransportEntry.fromTransport(c, idx, transportTypeMap));
    }

    static fromTransport(transport: Transport, idx: number, transportTypeMap: EnumConstantMap): Option<DBTransportEntry> {
        if (transport.isEmpty()) {
            return None;
        }

        if (transport.type.isEmpty()) {
            console.error(`Missing transport type for idx ${idx}`);
            return None;
        }

        const transportTypeIdOpt = transportTypeMap.getIdByOptName(transport.type);

        if (transportTypeIdOpt.isEmpty()) {
            console.error(`Invalid Transport type ${transport.type.getOrElse('')}`);
            return None;
        }

        return Some(new DBTransportEntry(
            Some(idx),
            transport.title.getOrElse(''),
            transport.description,
            transportTypeIdOpt.get()));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.fakeIdx.map(n => ValidationUtils.validateInt('fakeIdx', n.toString())),
            Some(ValidationUtils.validateVarchar('transportTitle', this.transportTitle, 400)),
            this.transportDescription.map(n => ValidationUtils.validateVarchar('transportDescription', n, Number.MAX_VALUE)),
            Some(ValidationUtils.validateInt('transportType', this.transportType.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBTransportEntryJsonSerializer extends SimpleJsonSerializer<DBTransportEntry> {
    static instance: DBTransportEntryJsonSerializer = new DBTransportEntryJsonSerializer();

    fromJsonImpl(obj: any): DBTransportEntry {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(transportEntry: DBTransportEntry, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(fakeIdxKey, transportEntry.fakeIdx)
            .add(transportTitleKey, transportEntry.transportTitle)
            .addOptional(transportDescriptionKey, transportEntry.transportDescription)
            .add(transportTypeKey, transportEntry.transportType);
    }

}
