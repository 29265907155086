import {Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    classificationKey,
    descriptionKey,
    fakeIdxKey,
    JsonBuilder,
    noteIdxKey,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {EnumConstantMap} from './enum-constant';
import {Note} from './note';

export class DbCustomNote implements Validatable {

    constructor(
        readonly noteIdx: number,
        readonly fakeIdx: Option<number>,
        readonly classification_id: number,
        readonly description: string,
    ) { }

    private static fromNote(noteIdx: number, fakeidx: Option<number>, note: Note, classifications: EnumConstantMap): List<DbCustomNote> {
        const classificationIds = classifications.getIdsByNames(note.classifications);
        return classificationIds
            .map(id => new DbCustomNote(noteIdx, fakeidx, id, note.note.getOrElse('')));
    }

    static fromNotes(fakeidx: Option<number>, notes: List<Note>, classifications: EnumConstantMap): List<DbCustomNote> {
        return notes.flatMap((x, noteIdx) => DbCustomNote.fromNote(noteIdx, fakeidx, x, classifications));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            Some(ValidationUtils.validateInt('id', this.classification_id.toString())),
            Some(ValidationUtils.validateVarchar('description', this.description, Number.MAX_VALUE)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DBCustomNotesJsonSerializer extends SimpleJsonSerializer<DbCustomNote> {
    static instance: DBCustomNotesJsonSerializer = new DBCustomNotesJsonSerializer();

    fromJsonImpl(obj: any): DbCustomNote {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(customNotes: DbCustomNote, builder: JsonBuilder): JsonBuilder {

        return builder
            .addOptional(fakeIdxKey, customNotes.fakeIdx)
            .add(noteIdxKey, customNotes.noteIdx)
            .add(classificationKey, customNotes.classification_id)
            .add(descriptionKey, customNotes.description);

    }
}
