import {Option} from 'funfix-core';
import {Set} from 'immutable';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SimpleJsonSerializer} from '../json-serializer';
import {OptionUtils} from '../option-utils';
import {Crud, Entry} from './crud';

export class SerializationCrud<V> extends Crud<string, V> {

    constructor(
        readonly serializer: SimpleJsonSerializer<V>,
        readonly underlying: Crud<string, string>) {
        super();
    }

    async delete(k: string): Promise<Option<V>> {
        const deletion = await this.underlying.delete(k);
        return deletion.flatMap(i => this.deserialize(i));
    }

    deleteAll(): Promise<void> {
        return this.underlying.deleteAll();
    }

    private deserialize(i: string): Option<V> {
        return this.serializer.fromJsonString(i);
    }

    async entries(): Promise<Set<Entry<string, V>>> {
        const entries = await this.underlying.entries();
        return OptionUtils.toSetFromCollection(entries.map(e => this.deserialize(e.v).map(v => new Entry(e.k, v))));
    }

    async getLast(k: string): Promise<Option<V>> {
        const last = await this.underlying.getLast(k);
        return last.flatMap(i => this.deserialize(i));
    }

    keys(): Promise<Set<string>> {
        return this.underlying.keys();
    }

    observe(k: string): Observable<Option<V>> {
        return this.underlying.observe(k)
            .pipe(map(o => o.flatMap(i => this.deserialize(i))));
    }

    async set(k: string, v: V): Promise<Option<V>> {
        const data = await this.underlying.set(k, this.serializer.toJsonString(v));
        return data.flatMap(i => this.deserialize(i));
    }
}
