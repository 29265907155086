import {None, Option} from 'funfix-core';
import {
    colourKey,
    idKey,
    imageKey,
    JsonBuilder,
    mapLatitudeKey,
    mapLongitudeKey,
    markerLatitudeKey,
    markerLongitudeKey,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    zoomKey,
} from '../core';
import {GMap} from './gmap';
import {MapMarker} from './map-marker';
import {Product} from './product';

export class DbProductMap implements Validatable {

    constructor(
        readonly productId: number,
        readonly mapLatitude: number,
        readonly mapLongitude: number,
        readonly markerLatitude: number,
        readonly markerLongitude: number,
        readonly markerColour: string,
        readonly zoom: number,
        readonly image: string,
    ) {
    }

    static buildFromMap(id: Option<number>, m: GMap, image: Option<string> = None): Option<DbProductMap> {
        return OptionUtils.map8(
            id,
            m.latitude,
            m.longitude,
            Option.of(m.marker.first()).flatMap((x: MapMarker) => x.getLatitude()),
            Option.of(m.marker.first()).flatMap((x: MapMarker) => x.getLongitude()),
            Option.of(m.marker.first()).flatMap((x: MapMarker) => x.colour),
            m.zoom,
            image.orElse(m.image),
            (a, b, c, d, e, f, g, h) => new DbProductMap(a, b, c, d, e, f, g, h));
    }

    static buildFromProduct(product: Product, image: Option<string> = None): Option<DbProductMap> {
        return product.map
            .flatMap(x => DbProductMap.buildFromMap(product.getId(), x, image));
    }

    validate(): ValidationResult {
        return ValidationResult.empty;
    }
}

export class DbProductMapJsonSerializer extends SimpleJsonSerializer<DbProductMap> {
    static instance: DbProductMapJsonSerializer = new DbProductMapJsonSerializer();

    fromJsonImpl(obj: any): DbProductMap {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(mapping: DbProductMap, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(idKey, mapping.productId)
            .add(mapLatitudeKey, mapping.mapLatitude)
            .add(mapLongitudeKey, mapping.mapLongitude)
            .add(markerLatitudeKey, mapping.markerLatitude)
            .add(markerLongitudeKey, mapping.markerLongitude)
            .add(colourKey, mapping.markerColour)
            .add(zoomKey, mapping.zoom)
            .add(imageKey, mapping.image);
    }
}
