import {Option} from 'funfix-core';
import {Set} from 'immutable';
import {ComparisonUtils, now, OptionUtils} from '../core';
import {ChatMessage} from './chat';
import {IdentifiablePerson} from './identifiable-person';

// Time after sending message you are allowed to edit it
const EDIT_TIME = 5;

export class ChatMessageData {
    constructor(
        readonly message: ChatMessage,
        readonly sender: Option<IdentifiablePerson>,
        readonly joinedIdx: number,
        readonly joinedIdxOfLastSeen: Set<number>) {
    }

    canEdit(currentUser: Option<IdentifiablePerson>): boolean {
        return this.isSelf(currentUser) &&
            this.message.time.exists(m => now().diff(m, 'minutes') < EDIT_TIME);
    }

    private getBaseCssClass(currentUser: Option<IdentifiablePerson>): string {
        if (this.isSelf(currentUser)) {
            return 'my-chat-message';
        }
        return 'their-chat-message';
    }

    private getCornerCssClass(
        nextMsg: ChatMessageData | undefined,
        prevMsg: ChatMessageData | undefined,
        isSelf: boolean): string {
        const lastWasSame = Option.of(prevMsg).map(m => m.sender).contains(this.sender);
        const nextWasSame = Option.of(nextMsg).map(m => m.sender).contains(this.sender);
        if (lastWasSame && nextWasSame) {
            return !isSelf ? 'points-left' : 'points-right';
        } else if (lastWasSame) {
            return !isSelf ? 'point-top-left' : 'point-top-right';
        }
        return !isSelf ? 'point-bot-left' : 'point-bot-right';
    }

    getCssClass(
        currentUser: Option<IdentifiablePerson>,
        nextMsg: ChatMessageData | undefined,
        prevMsg: ChatMessageData | undefined): string {
        const base = this.getBaseCssClass(currentUser);
        const corners = this.getCornerCssClass(nextMsg, prevMsg, this.isSelf(currentUser));

        return `${base} ${corners}`;
    }

    getLastSeenNotShownCount(): number {
        return Math.max(this.joinedIdxOfLastSeen.size - 7, 0);
    }

    getLastSeenToShowIconsFor(): Set<number> {
        return this.joinedIdxOfLastSeen.take(7);
    }

    getName(): string {
        return this.sender.flatMap(s => s.person).flatMap(p => p.getFullName()).getOrElse('Anonymous');
    }

    // name - company
    getNameAndCompany(): string {
        const fullName = this.sender.flatMap(s => s.person).flatMap(p => p.getFullName());
        const company = this.sender.flatMap(s => s.company).flatMap(c => c.getName());
        const stringList = OptionUtils.toList(fullName, company);

        if (stringList.isEmpty()) {
            return 'Anonymous';
        }

        return stringList.reduce((a, b) => a + ' - ' + b);
    }

    hasTooManyLastSeen(): boolean {
        return this.joinedIdxOfLastSeen.size > 7;
    }

    isFirstMessageOfGroup(lastMessage: ChatMessageData | undefined): boolean {
        return !Option.of(lastMessage).map(m => m.sender).contains(this.sender);
    }

    isLastMessageOfGroup(nextMsg: ChatMessageData | undefined): boolean {
        return Option.of(nextMsg).map(m => m.sender).contains(this.sender);
    }

    isSelf(currentUser: Option<IdentifiablePerson>): boolean {
        return ComparisonUtils.optionStringComparator
            .compare(currentUser.flatMap(u => u.identity), this.sender.flatMap(s => s.identity)) === 0;
    }

    needsExtraPaddingBottom(nextMsg: ChatMessageData | undefined): boolean {
        return this.isLastMessageOfGroup(nextMsg);
    }

    shouldHideIcon(currentUser: Option<IdentifiablePerson>, nextMsg: ChatMessageData | undefined): boolean {
        return this.isSelf(currentUser) || this.isLastMessageOfGroup(nextMsg);
    }

    shouldShowLastSeen(nextMsg: ChatMessageData | undefined): boolean {
        return (!this.joinedIdxOfLastSeen.isEmpty() && !this.isLastMessageOfGroup(nextMsg));
    }

    shouldShowName(currentUser: Option<IdentifiablePerson>, lastMsg: ChatMessageData | undefined): boolean {
        return !this.isSelf(currentUser) && this.isFirstMessageOfGroup(lastMsg);
    }

    shouldShowTime(lastMessage: ChatMessageData | undefined): boolean {
        if (lastMessage === undefined) {
            return true;
        }
        return this.message.time.nonEmpty() &&
            Option.of(lastMessage)
                .flatMap(msg => msg.message.time)
                .exists(m => this.message.time.exists(t => t.diff(m, 'minute') > 5));
    }
}
