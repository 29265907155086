import {Either, Left, None, Option, Some} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {
    CollectionUtils,
    EitherUtils,
    MapUtils,
    OptionUtils,
    readAllProductsKey,
    readAllSupplierProductsKey,
    readOwnProductsKey,
    StringSearchType,
    StringUtils,
} from '../core';
import {ApiUser} from './api-user';
import {Distance} from './distance';
import {Product, ProductJsonSerializer, ProductLike, ProductStringSearchField} from './product';
import {ProductAndCompanySearchField, ProductSearchType} from './product-search';
import {ProposalEntry} from './proposal-entry';

export class ProductCache {

    constructor(
        readonly products: List<Product>,
        readonly byId: Map<number, Product> = MapUtils.buildMapFromListOptional(products, e => e.getId()),
    ) {
    }

    private checkFoundAndPopulate(prod: Product, cids: Set<number>): Option<Product> {
        if (prod.getProductName().isEmpty()) {
            // If it doesnt have a name, we should not have got that far
            console.error('Product to search for had no name');
            return None;
        }

        const found = this.findBy(cids, prod.getProductName().get(), 'Name', 'Levenshtein-90');
        return found
            .flatMap(x => x.getId())
            .map(id =>
                prod
                    .withId(Some(id))
                    .withMappings(prod.mapping.map(m => m.withProductId(id))));
    }

    // TODO: Multiple comparisons
    findBy(
        cids: Set<number>,
        comparison: string,
        field: ProductStringSearchField,
        type: StringSearchType,
        caseSensitive: boolean = false): Option<Product> {
        return Option.of(
            this.products.find(p =>
                p.isAccessibleToOneOfProposalCompanies(cids)
                && p.matchesSearch(field, caseSensitive, comparison, type)));
    }

    findDistanceBetweenProducts(prodId1: number, prodId2: number): Either<string, Distance> {
        return Either.map2(this.getByIdEither(prodId1), this.getByIdEither(prodId2), (p1, p2) => {
            return p1.getStraightLineDistance(p2.getLatLongLocation())
                .getOrElse(Distance.empty);
        });
    }

    findDistanceBetweenProductsForProduct(first: Product, second: Product): Option<Distance> {
        return first.getStraightLineDistance(second.getLatLongLocation());
    }

    findNearbyDestinations(
        companyIds: Set<number>,
        productId: number,
        maxDistance: Distance,
    ): Either<string, List<ProductLike>> {
        return this.getByIdEither(productId)
            .map(x => this.findNearbyDestinationsForProduct(companyIds, x, maxDistance));
    }

    findNearbyDestinationsForProduct(
        companyIds: Set<number>,
        product: Product,
        maxDistance: Distance,
    ): List<ProductLike> {
        const potentials = this.searchBy(companyIds, 'Destination', 'Type', 'Exact');
        return potentials
            .filter(x => x.isStandardDestination() && product.isNear(x, maxDistance).contains(true));
    }

    findNearbySelfDrives(
        companyIds: Set<number>,
        origin: number,
        to: number,
        maxDistance: Distance,
    ): Either<string, List<ProductLike>> {
        return EitherUtils.flatMap2(
            this.getByIdEither(origin),
            this.getByIdEither(to),
            (f, t) => this.findNearbySelfDrivesForProducts(companyIds, f, t, maxDistance));
    }

    findNearbySelfDrivesForProducts(
        companyIds: Set<number>,
        origin: ProductLike,
        to: ProductLike,
        maxDistance: Distance): Either<string, List<ProductLike>> {
        const selfDrives = this.getIsAccessibleToProposalCompanies(companyIds)
            .filter(x => x.isSelfDrive());

        if (selfDrives.isEmpty()) {
            return Left('No accessible self drives');
        }

        const selfDrivesStartingNear =
            selfDrives.filter(x => x.getDirections().exists(d => d.startsNear(origin.getLatLongLocation(), maxDistance)));

        if (selfDrivesStartingNear.isEmpty()) {
            return Left(`No self drives starting near ${origin.getProductName().getOrElse('?')}`);
        }

        const selfDrivesStartingEndingNear =
            selfDrivesStartingNear.filter(x => x.getDirections().exists(d => d.endsNear(to.getLatLongLocation(), maxDistance)));

        if (selfDrivesStartingEndingNear.isEmpty()) {
            return Left(`No self drives starting near ${origin.getProductName().getOrElse('?')} and ending near ${to.getProductName().getOrElse('?')}`);
        }

        return Either.right(selfDrivesStartingEndingNear);
    }

    findNearestDestination(
        requestCid: number,
        companyIds: Set<number>,
        productId: number,
        maxDistance: Distance,
    ): Either<string, ProductLike> {
        return this.getByIdEither(productId)
            .flatMap(x =>
                EitherUtils.toEither(
                    this.findNearestDestinationForProduct(requestCid, companyIds, x, maxDistance),
                    `Could not find any destinations within ${maxDistance.getKilometers()}km`));
    }

    findNearestDestinationForProduct(
        requestCid: number,
        companyIds: Set<number>,
        product: Product,
        maxDistance: Distance,
    ): Option<ProductLike> {
        const potentials = this.findNearbyDestinationsForProduct(companyIds, product, maxDistance);
        const mine = potentials.filter(x => x.isProposalCompanyOwner(requestCid));
        const mineWithoutTo = mine.filter(x =>
            !x.matchesSearch('Name', false, ' - ', 'Contains') &&
            !x.matchesSearch('Name', false, ' to ', 'Contains'));

        const proposalCompanyOwned = potentials.filter(x => x.isProposalCompanyOwned());

        const didgigoDestinationProductOwned = potentials.filter(op => op.isSupplierOwner(37724));

        const proposalCompanyOwnedWithoutTo = proposalCompanyOwned.filter(x =>
            !x.matchesSearch('Name', false, ' - ', 'Contains') &&
            !x.matchesSearch('Name', false, ' to ', 'Contains'));

        const potentialsWithoutTo = potentials.filter(x =>
            !x.matchesSearch('Name', false, ' - ', 'Contains') &&
            !x.matchesSearch('Name', false, ' to ', 'Contains'));

        return product.findNearest(mineWithoutTo)
            .orElseL(() => product.findNearest(proposalCompanyOwnedWithoutTo))
            .orElseL(() => product.findNearest(didgigoDestinationProductOwned))
            .orElseL(() => product.findNearest(potentialsWithoutTo))
            .orElseL(() => product.findNearest(mine))
            .orElseL(() => product.findNearest(proposalCompanyOwned))
            .orElseL(() => product.findNearest(potentials));
    }

    findNearestDestinationsIncremental(
        requestCid: number,
        companyIds: Set<number>,
        productId: number,
        e: ProposalEntry,
    ): Option<ProposalEntry> {
        let product: Option<ProposalEntry> = None;
        for (let distance = 25; distance <= 100; distance += 25) {
            if (this.findNearestDestination(requestCid, companyIds, productId, Distance.km(distance)).isLeft()) {
                continue;
            } else {
                product = this.findNearestDestination(requestCid, companyIds, productId, Distance.km(distance))
                    .toOption()
                    .flatMap(x => x.buildProduct())
                    .map(pr => ProposalEntry.quickBuildDestinationBefore(e, pr));
                break;
            }
        }
        // @ts-ignore
        return product;
    }

    findNearestSelfDrive(
        searcherCid: number,
        cids: Set<number>,
        from: number,
        to: number,
        maxDistance: Distance): Either<string, ProductLike> {
        return EitherUtils.flatMap2(
            this.getByIdEither(from),
            this.getByIdEither(to),
            (f, t) => this.findNearestSelfDrivesForProduct(searcherCid, cids, f, t, maxDistance));
    }

    findNearestSelfDriveIncremental(
        requestCid: number,
        companyIds: Set<number>,
        origin: number,
        to: number,
        e: ProposalEntry,
    ): Option<ProposalEntry> {
        let product: Option<ProposalEntry> = None;
        for (let distance = 25; distance <= 100; distance += 25) {
            if (this.findNearestSelfDrive(requestCid, companyIds, origin, to, Distance.km(distance)).isLeft()) {
                continue;
            } else {
                product = this.findNearestSelfDrive(requestCid, companyIds, origin, to, Distance.km(distance))
                    .toOption()
                    .flatMap(x => x.buildProduct())
                    .map(pr => ProposalEntry.quickBuildSelfDriveBefore(e, pr));

                break;
            }
        }
        // @ts-ignore
        return product;
    }

    findNearestSelfDrivesForProduct(
        requestCid: number,
        companyIds: Set<number>,
        origin: Product,
        to: Product,
        maxDistance: Distance): Either<string, ProductLike> {
        const potentials = this.findNearbySelfDrivesForProducts(companyIds, origin, to, maxDistance);

        if (potentials.isLeft()) {
            return potentials;
        }

        const mine = potentials.get().filter(x => x.isProposalCompanyOwner(requestCid));
        const proposalCompanyOwned = potentials.get().filter(x => x.isProposalCompanyOwned());

        const didgigoDirections = potentials.get().filter(x => x.isSupplierOwner(37723));

        const res = origin.findNearest(mine)
            .orElseL(() => origin.findNearest(proposalCompanyOwned))
            .orElseL(() => origin.findNearest(didgigoDirections))
            .orElseL(() => origin.findNearest(potentials.get()));

        return EitherUtils.toEither(res, `Could not find any self drive routes within ${maxDistance.getKilometers()}km`);
    }

    getByCityLower(s: string): ProductCache {
        return new ProductCache(
            this.products
                .filter(p => p.getCity().exists(c => StringUtils.equalsIgnoringCase(s, c))));
    }

    getByCountryLower(s: string): List<Product> {
        return this.products.filter(x => x.getCountry().exists(n => StringUtils.equalsIgnoringCase(n, s)));
    }

    getByIdEither(n: number): Either<string, Product> {
        return EitherUtils.toEither(Option.of(this.byId.get(n)), `Product does not exist ${n} or is inactive`);
    }

    getByLowerName(s: string): List<Product> {
        return this.products.filter(x => x.name.exists(n => StringUtils.equalsIgnoringCase(n, s)));
    }

    getByNameContainsLower(s: string): List<Product> {
        return this.products.filter(x => x.getProductName().exists(n => StringUtils.includesIgnoringCase(n, s)));
    }

    getByProductIdEither(id: number): Either<string, Product> {
        return EitherUtils.liftEither(
          this.products.find(p => p.getId().contains(id)),
          `Product ${id} does not exist or is inactive`,
          );
    }

    getByProposalCompanyId(n: number): ProductCache {
        return new ProductCache(this.products
            .filter(x => x.isProposalCompanyOwned() && x.getSupplierId().contains(n)));
    }

    getByProposalCompanyIds(s: Set<number>): List<Product> {
        return this.products
            .filter(x => x.isProposalCompanyOwned() && x.getSupplierId().exists(id => s.contains(id)));
    }

    getByStateLower(s: string): List<Product> {
        return this.products.filter(x => x.getState().exists(n => StringUtils.equalsIgnoringCase(n, s)));
    }

    getBySupplierCompanyIds(s: Set<number>): List<Product> {
        return this.products
            .filter(x => x.isSupplierOwned() && x.getSupplierId().exists(id => s.contains(id)));
    }

    getBySupplierId(n: number): ProductCache {
        return new ProductCache(this.products.filter(x => x.isSupplierOwned() && x.getSupplierId().contains(n)));
    }

    getFound(s: List<Product>, cid: Set<number>): List<Product> {
        return OptionUtils.flattenList(s.map(x => this.checkFoundAndPopulate(x, cid)));
    }

    getIsAccessibleToProposalCompanies(s: Set<number>): List<Product> {
        return this.products
            .filter(x =>
                x.isProposalCompanyOwned()
                && x.getSupplierId()
                    .exists(id => s.contains(id))
                || x.isSupplierOwned(),
            );
    }

    getLowercaseNames(): Set<string> {
        return this.getNames().map(x => x.toLowerCase());
    }

    // WARNING: High algorithmic complexity.
    // This algorithm can be improved for a high number of products being passed in.
    // tl;dr product must be accessible to one of the companies or number not be missing
    getMissing(s: List<Product>, cid: Set<number>): List<Product> {
        return s.filter(x => !x.isAccessibleToOneOfProposalCompanies(cid) || this.isMissing(x, cid));
    }

    // This method is required by product endpoints until the logic behind them is changed
    getMissingNames(s: Set<string>): Set<string> {
        return s.subtract(this.getNames());
    }

    getNames(): Set<string> {
        return CollectionUtils.collect(this.products, x => x.name).toSet();
    }

    getProposalCompanyOwned(): ProductCache {
        return new ProductCache(this.products.filter(x => x.isProposalCompanyOwned()));
    }

    getSupplierOwned(): ProductCache {
        return new ProductCache(this.products.filter(x => x.isSupplierOwned()));
    }

    /**
     * Checks whether or not the user has access the product either by relationships or supplier ownership
     */
    hasAccess(id: number, user: ApiUser): boolean {
        return this.getByIdEither(id)
            .map(x => user.hasPermission(readAllProductsKey)
                || this.hasAccessToProposalCompanyOwned(x, user)
                || this.hasAccessToSupplierOwned(x, user))
            .getOrElse(false);
    }


    hasAccessMultiple(ids: Set<number>, user: ApiUser): boolean {
        return ids.every(id => this.hasAccess(id, user));
    }

    private hasAccessToProposalCompanyOwned(x: Product, user: ApiUser): boolean {
        return x.isProposalCompanyOwned() && x.isProposalCompanyOwner(user.getCompanyId().get()) && user.hasPermission(readOwnProductsKey);
    }

    private hasAccessToSupplierOwned(x: Product, user: ApiUser): boolean {
        return x.isSupplierOwned() && user.hasPermission(readAllSupplierProductsKey);
    }

    private isMissing(x: Product, cids: Set<number>): boolean {
        if (x.getProductName().isEmpty()) {
            // If it doesnt have a name, we should not have got that far
            console.error('Product to search for had no name');
            return false;
        }

        if (x.getLatitude().nonEmpty() && x.getLongitude().nonEmpty()) {
            console.log('Searching near lat/long');
            return this.searchBy(cids, x.getProductName().get(), 'Name', 'Levenshtein-90')
                .filter(v => v.isNear(x.getLatLongLocation(), Distance.km(2)))
                .isEmpty();
        }

        const productOption = this.findBy(cids, x.getProductName().get(), 'Name', 'Levenshtein-90');
        return productOption.isEmpty();
    }

    merge(other: ProductCache): ProductCache {
        return new ProductCache(
            this.products.concat(other.products),
            this.byId.concat(other.byId),
        );
    }

    searchBy(
        cids: Set<number>,
        comparison: string,
        field: ProductAndCompanySearchField,
        type: ProductSearchType,
        caseSensitive: boolean = false): List<Product> {
        return this.products.filter(p =>
            p.isAccessibleToOneOfProposalCompanies(cids)
            && p.matchesSearch(field, caseSensitive, comparison, type));
    }

    toJsonArray(): ReadonlyArray<object> {
        return ProductJsonSerializer.instance.toJsonArray(this.products);
    }

    update(prodsToUpdate: List<Product>): ProductCache {
        if (prodsToUpdate.isEmpty()) {
            return this;
        }
        return new ProductCache(prodsToUpdate).merge(this);
    }
}
