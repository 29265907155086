import { Option } from 'funfix-core';

export class StatusAnalysis {
    constructor(
        readonly apiStatus: Option<string>,
        readonly didgigoStatus: Option<string>,
        readonly bookingCount: Option<number>,
    ) {
    }
}
