import {Either, Option} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {CollectionUtils, EitherUtils, MapUtils, OptionUtils} from '../core';
import {Api, ApiJsonSerializer} from './api';
import {ApiAgency} from './api-agency';
import {ApiConnection} from './api-connection';
import {ApiTemplate} from './api-template';
import {AutoImportSettings} from './auto-import-settings';

export class ApiCache {

    constructor(readonly apis: List<Api>) {
    }

    private byId: Map<number, Api> = MapUtils.buildMapFromListOptional(this.apis, e => e.id);

    getAccessibleBy(companyId: number, accessible: Set<number>): List<Api> {
        return OptionUtils.flattenList(
            this.apis.map(x => x.filterAccessibleBy(companyId, accessible)),
        );
    }

    getAgencyById(n: number): Option<ApiAgency> {
        return Option.of(this.apis
            .flatMap(x => x.getConnections())
            .flatMap(x => x.getAgencies())
            .find(x => x.id.contains(n)));
    }

    getAgencyByIdEither(agencyId: number): Either<string, ApiAgency> {
        return EitherUtils.toEither(this.getAgencyById(agencyId), `Agency ${agencyId} does not exist`);
    }

    getApiByTemplateId(tid: number): Option<Api> {
        return Option.of(this.apis.find(x => x.getTemplates().some(t => t.id.contains(tid))))
            .map(x => x.withConnections(List()));
    }

    getApiByTemplateIdEither(tid: number): Either<string, Api> {
        return EitherUtils.toEither(this.getApiByTemplateId(tid), `${tid} non existent on any Apis`);
    }

    getApiForConnection(conn: ApiConnection): Option<Api> {
        return Option.of(this.apis.find(x => x.connections.some(c => OptionUtils.equals(conn.id, c.id))));
    }

    getApis(): List<Api> {
        return this.apis;
    }

    getApiTemplateById(tid: number): Option<ApiTemplate> {
        return Option.of(
            this.apis.flatMap(x => x.getTemplates())
                .find(t => t.id.contains(tid)));
    }

    getApiTemplateByIdEither(tid: number): Either<string, ApiTemplate> {
        return EitherUtils.toEither(this.getApiTemplateById(tid), 'Template does not exist');
    }

    // Present As => Owner of Agency => Owner of Connection => Anything else
    // TODO: Could fallback to logged in user company????
    getBestTemplateForAgency(conn: ApiConnection, agency: ApiAgency): Option<ApiTemplate> {
        const templates = this.getTemplatesForConnection(conn);

        const presentAsTemplates = templates.filter(x => OptionUtils.exists2(x.company, agency.presentAsCompany, (a, b) => a.isSame(b)));
        if (!presentAsTemplates.isEmpty()) {
            return Option.of(presentAsTemplates.first());
        }

        const companyTemplates = templates.filter(x => OptionUtils.exists2(x.company, agency.company, (a, b) => a.isSame(b)));

        if (!companyTemplates.isEmpty()) {
            return Option.of(companyTemplates.first());
        }

        const connectionTemplates = templates.filter(x => OptionUtils.exists2(x.company, conn.company, (a, b) => a.isSame(b)));

        if (!connectionTemplates.isEmpty()) {
            return Option.of(connectionTemplates.first());
        }

        return Option.of(templates.first());

    }

    getByConnectionIdEither(n: number): Either<string, ApiConnection> {
        return EitherUtils.toEither(
            this.getConnectionById(n),
            'Api Connection does not exist',
        );
    }

    getById(n: number): Option<Api> {
        return Option.of(this.byId.get(n));
    }

    getConnectionById(n: number): Option<ApiConnection> {
        return Option.of(this.listConnections().find(x => x.id.contains(n)));
    }

    getConnectionByIdEither(n: number): Either<string, ApiConnection> {
        return EitherUtils.toEither(this.getConnectionById(n), `Missing connection ${n}`);
    }

    getConnectionForAgency(agencyId: number): Option<ApiConnection> {
        return Option.of(this.listConnections().find(x => x.getAgencies().some(a => a.id.contains(agencyId))));
    }

    getConnectionForAgencyEither(agencyId: number): Either<string, ApiConnection> {
        return EitherUtils.toEither(this.getConnectionForAgency(agencyId), 'Agency does not exist');
    }

    getConnectionsAccessibleBy(companyId: number, accessible: Set<number>): List<ApiConnection> {
        return this.apis
            .flatMap(x => x.connections.filter(c => c.isAccessibleBy(accessible.add(companyId))))
            .map(x => x.filterAccessibleCredentials(companyId));
    }

    getProductApiRequestSettings(n: number): List<AutoImportSettings> {
        return this.apis.flatMap(x => x.getProductApiRequestSettings(n));
    }

    getProposalApiRequestSettings(n: number): List<AutoImportSettings> {
        return this.apis.flatMap(x => x.getProposalApiRequestSettings(n));
    }

    getTemplatesForConnection(conn: ApiConnection): List<ApiTemplate> {
        return OptionUtils.toList(this.getApiForConnection(conn))
            .flatMap(x => CollectionUtils.distinctOptionList(x.templates, t => t.shortCode));
    }

    listAgenciesForConnection(n: number): List<ApiAgency> {
        return OptionUtils.toList(this.getConnectionById(n))
            .flatMap(x => x.agencies);
    }

    listConnections(): List<ApiConnection> {
        return this.apis.flatMap(x => x.connections);
    }

    merge(other: ApiCache): ApiCache {
        return new ApiCache(
            this.apis.concat(other.apis),
        );
    }

    toJsonArray(): ReadonlyArray<object> {
        return ApiJsonSerializer.instance.toJsonArray(this.apis);
    }

    update(apisToUpdate: List<Api>): ApiCache {
        if (apisToUpdate.isEmpty()) {
            return this;
        }
        return new ApiCache(apisToUpdate).merge(this);
    }
}
