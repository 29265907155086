import {Either, Left, None, Option, Right, Some} from 'funfix-core';
import {List, Set} from 'immutable';

export class EitherUtils {

    static applyOrReturnNonEmpty<A, B>(ea: Either<B, A>, eb: Either<B, A>, f: (x: A, y: A) => A): Either<B, A> {
        return EitherUtils.orElse(EitherUtils.orElse(Either.map2(ea, eb, (a, b) => f(a, b)), ea), eb);
    }

    static flatMap2<A, B, C, D>(
        a: Either<D, A>,
        b: Either<D, B>,
        f: (a: A, b: B) => Either<D, C>,
    ): Either<D, C> {
        if (a.isRight() && b.isRight()) {
            return f(a.get(), b.get());
        } else if (a.isLeft()) {
            return a;
        } else if (b.isLeft()) {
            return b;
        }
        throw new Error('Error');
    }

    static flatMap3<A, B, C, D, E>(
        a: Either<E, A>,
        b: Either<E, B>,
        c: Either<E, C>,
        f: (a: A, b: B, c: C) => Either<E, D>,
    ): Either<E, D> {
        if (a.isRight() && b.isRight() && c.isRight()) {
            return f(a.get(), b.get(), c.get());
        } else if (a.isLeft()) {
            return a;
        } else if (b.isLeft()) {
            return b;
        } else if (c.isLeft()) {
            return c;
        }
        throw new Error('Error');
    }

    static flatMap4<A, B, C, D, E, F>(
        a: Either<F, A>,
        b: Either<F, B>,
        c: Either<F, C>,
        d: Either<F, D>,
        f: (a: A, b: B, c: C, d: D) => Either<F, E>,
    ): Either<F, E> {
        if (a.isRight() && b.isRight() && c.isRight() && d.isRight()) {
            return f(a.get(), b.get(), c.get(), d.get());
        } else if (a.isLeft()) {
            return a;
        } else if (b.isLeft()) {
            return b;
        } else if (c.isLeft()) {
            return c;
        } else if (d.isLeft()) {
            return d;
        }
        throw new Error('Error');
    }

    static flatMap5<A, B, C, D, E, F, G>(
      a: Either<F, A>,
      b: Either<F, B>,
      c: Either<F, C>,
      d: Either<F, D>,
      e: Either<F, E>,
      f: (a: A, b: B, c: C, d: D, e: E) => Either<F, G>,
    ): Either<F, G> {
        if (a.isRight() && b.isRight() && c.isRight() && d.isRight() && e.isRight()) {
            return f(a.get(), b.get(), c.get(), d.get(), e.get());
        } else if (a.isLeft()) {
            return a;
        } else if (b.isLeft()) {
            return b;
        } else if (c.isLeft()) {
            return c;
        } else if (d.isLeft()) {
            return d;
        } else if (e.isLeft()) {
            return e;
        }
        throw new Error('Error');
    }

    static flatMap6<A, B, C, D, E, F, G, H>(
      a: Either<F, A>,
      b: Either<F, B>,
      c: Either<F, C>,
      d: Either<F, D>,
      e: Either<F, E>,
      g: Either<F, G>,
      f: (a: A, b: B, c: C, d: D, e: E, g: G) => Either<F, H>,
    ): Either<F, H> {
        if (a.isRight() && b.isRight() && c.isRight() && d.isRight() && e.isRight() && g.isRight()) {
            return f(a.get(), b.get(), c.get(), d.get(), e.get(), g.get());
        } else if (a.isLeft()) {
            return a;
        } else if (b.isLeft()) {
            return b;
        } else if (c.isLeft()) {
            return c;
        } else if (d.isLeft()) {
            return d;
        } else if (e.isLeft()) {
            return e;
        } else if (g.isLeft()) {
            return g;
        }
        throw new Error('Error');
    }

    static flattenList<A, B>(list: List<Either<B, A>>): List<A> {
        return list
            .filter(x => x.isRight())
            .map(x => x.get());
    }

    static flattenSet<A, B>(set: Set<Either<B, A>>): Set<A> {
        return set
            .filter(x => x.isRight())
            .map(x => x.get());
    }

    static fromErrorOpt<A, B>(val: B, error: Option<A>): Either<A, B> {
        return error.isEmpty() ? Right(val) : Left(error.get());
    }

    /**
     * Converts an option to either.
     */
    static leftMap<A, B, C>(e: Either<B, A>, f: (b: B) => C): Either<C, A> {
        if (e.isLeft()) {
            return Left(f(e.value));
        }
        return Right(e.get());
    }

    /**
     * Executes a method when left
     */
    static leftTap<A, B>(e: Either<B, A>, f: (b: B) => void): Either<B, A> {
        if (e.isLeft()) {
            f(e.value);
        }
        return e;
    }

    /**
     * Converts potentially null or undefined value to either
     * Note: This is sub-optimal for readability
     */
    static liftEither<A, B>(a: A | null | undefined, b: B): Either<B, A> {
        return EitherUtils.toEither(Option.of(a), b);
    }

    static orElse<A, B>(a: Either<B, A>, b: Either<B, A>): Either<B, A> {
        if (a.isLeft()) {
            return b;
        }
        return a;
    }

    static sequenceList<A, B>(collection: List<Either<B, A>>): Either<B, List<A>> {
        return Option.of<Either<B, any>>(collection.find(x => x.isLeft()))
            .getOrElseL(() => Either.right(collection.map(x => x.get())));
    }

    /**
     * Converts an option to either.
     */
    static toEither<A, B>(opt: Option<A>, b: B): Either<B, A> {
        return opt.map(x => Right(x))
            .getOrElse(Left(b));
    }

    static toList<A, B>(...items: Either<B, A>[]): List<A> {
        return List(items.filter(x => x.isRight())
            .map(x => x.get()));
    }

    // Returns an optional left
    // Either.toOption except left associative.
    static toOptionLeft<A, B>(either: Either<A, B>): Option<A> {
        return either.isLeft() ? Some(either.value) : None;
    }
}
