import {JsonBuilder, nameKey, SimpleJsonSerializer, Validatable, ValidationResult, ValidationUtils} from '../core';

export class DbCountry implements Validatable {
    static toBeDefinedCountryId = 197;

    constructor(readonly name: string) {
    }

    validate(): ValidationResult {
        return ValidationUtils.validateNvarchar('name', this.name, 50);
    }
}

export class DbCountryJsonSerializer extends SimpleJsonSerializer<DbCountry> {
    static instance: DbCountryJsonSerializer = new DbCountryJsonSerializer();

    fromJsonImpl(obj: any): DbCountry {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(destination: DbCountry, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(nameKey, destination.name);
    }
}
