import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    agencyKey,
    connectionKey,
    JsonBuilder,
    OptionUtils,
    secondsKey,
    SimpleJsonSerializer,
    templateKey,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';
import {AutoImportProducts} from './auto-import-products';

export class DbAutoImportProducts implements Validatable {

    constructor(
        readonly seconds: Option<number> = None,
        readonly template: Option<number> = None,
        readonly agencyId: number,
        readonly connectionId: number,
    ) {
    }

    static buildFromAutoImportSettings(
        agency: number,
        connectionid: number,
        autoImportSettings: AutoImportProducts): List<DbAutoImportProducts> {
        return List.of(new DbAutoImportProducts(
            autoImportSettings.getSeconds(),
            autoImportSettings.getTemplate().flatMap(t => t.getId()),
            agency,
            connectionid,
        ));
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.seconds.map(n => ValidationUtils.validateInt('seconds', n.toString())),
            this.template.map(n => ValidationUtils.validateInt('template', n.toString())),
            Some(ValidationUtils.validateInt('agency', this.agencyId.toString())),
            Some(ValidationUtils.validateInt('connection', this.connectionId.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbProductAutoImportSettingsJsonSerializer extends SimpleJsonSerializer<DbAutoImportProducts> {
    static instance: DbProductAutoImportSettingsJsonSerializer = new DbProductAutoImportSettingsJsonSerializer();

    protected fromJsonImpl(json: any): DbAutoImportProducts {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(value: DbAutoImportProducts, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(secondsKey, value.seconds)
            .addOptional(templateKey, value.template)
            .add(agencyKey, value.agencyId)
            .add(connectionKey, value.connectionId);
    }
}
