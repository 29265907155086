import {Either, Left, Right} from 'funfix-core';
import {List, Map, Set} from 'immutable';
import {from, of} from 'rxjs';
import {flatMap, reduce} from 'rxjs/operators';
import {EitherUtils} from './either-utils';
import {MapUtils} from './map-utils';

export class PromiseUtils {

    // This is literally sequence... tl;dr where are the monads
    static async allList<T>(promises: List<Promise<T>>): Promise<List<T>> {
        return List(await Promise.all(promises));
    }

    static async allListThrottled<A, B>(inputs: List<A>, f: (a: A) => Promise<B>, limit: number): Promise<List<B>> {
        return of(...inputs.toArray())
            .pipe(flatMap(x => from(f(x)), limit))
            .pipe(reduce((a, b) => a.concat(b), List()))
            .toPromise();
    }

    // This is literally sequence... tl;dr where are the monads
    static async allSet<T>(promises: Set<Promise<T>>): Promise<Set<T>> {
        return Set(await Promise.all(promises));
    }

    static async allSetThrottled<A, B>(inputs: Set<A>, f: (a: A) => Promise<B>, limit: number): Promise<Set<B>> {
        return of(...inputs.toArray())
            .pipe(flatMap(x => from(f(x)), limit))
            .pipe(reduce((a, b) => a.add(b), Set()))
            .toPromise();
    }

    // Skips any errors
    static async flattenListEitherPromise<A, B>(either: Either<A, Promise<List<Either<A, B>>>>): Promise<Either<A, List<B>>> {
        if (either.isLeft()) {
            return Promise.resolve(Left(either.value));
        } else {
            const result = await either.get();
            return Right(EitherUtils.flattenList(result));
        }
    }

    static async sequenceAllListEitherPromise<A, B>(list: List<Promise<Either<A, B>>>): Promise<Either<A, List<B>>> {
        const all = await PromiseUtils.allList(list);
        return EitherUtils.sequenceList(all);
    }

    static async sequenceAllMapEntriesEitherPromise<A, B, C>(list: List<Promise<Either<A, readonly [B, C]>>>): Promise<Either<A, Map<B, C>>> {
        const all = await PromiseUtils.allList(list);
        return EitherUtils.sequenceList(all)
            .map(x => MapUtils.buildMapFromPairs(x));
    }

    // This is literally sequence... tl;dr where are the monads
    static async sequenceEither<A, B>(either: Either<A, Promise<B>>): Promise<Either<A, B>> {
        if (either.isLeft()) {
            return Promise.resolve(Left(either.value));
        } else {
            return either.get().then(x => Right(x));
        }
    }

    static async sequenceEitherAllListEitherPromise<A, B>(list: Either<A, List<Promise<Either<A, B>>>>): Promise<Either<A, List<B>>> {
        const extracted: Either<A, Promise<Either<A, List<B>>>> = list.map(x => PromiseUtils.sequenceAllListEitherPromise(x));
        return PromiseUtils.sequenceEitherPromise(extracted);
    }

    static async sequenceEitherAllListPromise<A, B>(list: Either<A, List<Promise<B>>>): Promise<Either<A, List<B>>> {
        const extracted: Either<A, Promise<List<B>>> = list.map(x => PromiseUtils.allList(x));
        return PromiseUtils.sequenceEither(extracted);
    }

    // This is literally sequence... tl;dr where are the monads
    static async sequenceEitherPromise<A, B>(either: Either<A, Promise<Either<A, B>>>): Promise<Either<A, B>> {
        if (either.isLeft()) {
            return Promise.resolve(Left(either.value));
        } else {
            return either.get();
        }
    }

    // Returns if any error
    static async sequenceListEitherPromise<A, B>(either: Either<A, Promise<List<Either<A, B>>>>): Promise<Either<A, List<B>>> {
        if (either.isLeft()) {
            return Promise.resolve(Left(either.value));
        } else {
            const result = await either.get();
            return EitherUtils.sequenceList(result);
        }
    }
}
