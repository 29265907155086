import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    bytesKey,
    dpiKey,
    fileExtensionKey,
    folderKey,
    hashKey,
    heightKey,
    heroKey,
    imageKey,
    JsonBuilder,
    optFakeIdxKey,
    OptionUtils,
    orderKey,
    prodFakeIdxKey,
    SimpleJsonSerializer,
    titleKey,
    Validatable,
    ValidationResult,
    widthKey,
} from '../core';
import {Image} from './image';
import {Product} from './product';
import {ProductOption} from './product-option';

export class DbCoreImage implements Validatable {

    constructor(
        readonly image: string,
        readonly fakeProductIdx: Option<number> = None,
        readonly fakeOptionIdx: Option<number> = None,
        readonly hero: boolean = true,
        readonly sha256Hash: Option<string> = None,
        readonly bytes: Option<number> = None,
        readonly width: Option<number> = None,
        readonly height: Option<number> = None,
        readonly dpi: Option<number> = None,
        readonly folder: string = 'ProductImages//Uploaded//',
        readonly fileExtension: string = '.jpg',
        readonly title: Option<string> = None,
        readonly order: Option<number> = None,
    ) {
    }

    static fromImage(prodFakeIdx: Option<number>, optFakeIdx: Option<number>, image: Image): Option<DbCoreImage> {
        return Option.of(image)
            .filter(x => x.getUri().exists(u => u.isFileNameOnly()))
            .flatMap(x => x.getHref())
            .map(x => new DbCoreImage(x));
    }

    static fromImageForAssignment(
        prodFakeIdx: Option<number> = None,
        optFakeIdx: Option<number> = None,
        image: Image,
        order: Option<number> = None,
    ): Option<DbCoreImage> {
        return Option.of(image)
            .filter(x => x.uri.exists(u => u.isFileNameOnly()))
            .flatMap(x => x.getHref())
            .map(x => new DbCoreImage(
                x,
                prodFakeIdx,
                optFakeIdx,
                image.getHero().getOrElse(false),
                image.getHash(),
                image.getBytes(),
                image.getWidth(),
                image.getHeight(),
                image.getDpi(),
                'ProductImages//Uploaded//',
                '.jpg',
                image.getTitle(),
                order, // Should also provide the right hero order
            ));
    }

    static fromProduct(product: Product, prodFakeIdx: Option<number>): List<DbCoreImage> {
        return OptionUtils.flattenList(product.getImages().map(x => DbCoreImage.fromImage(prodFakeIdx, None, x)))
            .concat(DbCoreImage.fromProductOptions(product.getOptions(), prodFakeIdx))
            .concat(DbCoreImage.fromProducts(product.getSubproducts(), prodFakeIdx));
    }

    static fromProductOption(option: ProductOption, prodFakeIdx: Option<number>, optionFakeIdx: Option<number>): List<DbCoreImage> {
        return OptionUtils.flattenList(option.getImages().map(x => DbCoreImage.fromImage(prodFakeIdx, optionFakeIdx, x)));
    }

    static fromProductOptions(options: List<ProductOption>, prodFakeIdx: Option<number>): List<DbCoreImage> {
        return options.flatMap((x, idx) => this.fromProductOption(x, prodFakeIdx, Some(idx)));
    }

    static fromProducts(
        products: List<Product>,
        parentFakeIdx: Option<number> = None,
    ): List<DbCoreImage> {
        return products.flatMap((x, idx) => DbCoreImage.fromProduct(
            x,
            OptionUtils.applyOrReturnNonEmpty(parentFakeIdx, Some(idx), (a, b) => a * 1000 + b)));
    }

    validate(): ValidationResult {
        return new ValidationResult();
    }
}

export class DbCoreImageJsonSerializer extends SimpleJsonSerializer<DbCoreImage> {
    static instance: DbCoreImageJsonSerializer = new DbCoreImageJsonSerializer();

    fromJsonImpl(obj: any): DbCoreImage {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(image: DbCoreImage, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(imageKey, image.image)
            .addOptional(prodFakeIdxKey, image.fakeProductIdx)
            .addOptional(optFakeIdxKey, image.fakeOptionIdx)
            .add(heroKey, image.hero)
            .add(folderKey, image.folder)
            .add(fileExtensionKey, image.fileExtension)
            .addOptional(hashKey, image.sha256Hash)
            .addOptional(bytesKey, image.bytes)
            .addOptional(heightKey, image.height)
            .addOptional(widthKey, image.width)
            .addOptional(dpiKey, image.dpi)
            .addOptional(titleKey, image.title)
            .addOptional(orderKey, image.order);
    }
}
