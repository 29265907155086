import { None, Option } from 'funfix-core';
import { Map, Set } from 'immutable';
import { Moment } from 'moment';
import * as _s from 'underscore.string';
import {
    agentPrefix,
    ComparisonUtils, idKey, joinedKey, JsonBuilder,
    lastActiveKey,
    nameKey, parseDate, parseMap,
    parseString,
    SimpleJsonSerializer,
    travellerPrefix,
} from '../core';
import { IdentifiablePerson } from './identifiable-person';

export const proposalTravellerRoomPrefix = 'PRT';
export const proposalAgentRoomPrefix = 'PRA';

export class RoomData {
    constructor(
        readonly id: Option<string>,
        readonly name: Option<string>,
        readonly last_active: Map<string, Moment> = Map(),
        readonly joined: Map<string, Moment> = Map()) {
    }

    canEdit(user: Option<IdentifiablePerson>): boolean {
        return (this.isAgentGeneralChat() && user.exists(u => u.isAgent()))
            || (this.isGeneralChat() && user.exists(u => u.isTraveller()))
            || (this.isCreator(user));
    }

    getAgentsJoinedCount(): number {
        return this.getJoinedCount(agentPrefix);
    }

    getJoinedCount(prefix: string): number {
        return this.joined.keySeq().reduce((a, b) => _s.startsWith(b, prefix) ? a + 1 : a, 0);
    }

    // Can be used as a unique identifier for every person in this channel
    // Counting from 0. This method prevents anyone from having their id change while using the app.
    getJoinedIdx(id: string): Option<number> {
        const time = Option.of(this.joined.get(id));
        const sortedJoinTimes = this.joined.valueSeq().sort((a, b) => ComparisonUtils.momentComparator.compare(a, b));
        return time.map(t => sortedJoinTimes.indexOf(t));
    }

    getLastActive(identity: string): Option<Moment> {
        return Option.of(this.last_active.get(identity));
    }

    getLeaveMessage(user: Option<IdentifiablePerson>): string {
        if (this.isGroupPrivateMessage()) {
            return 'Note: It is not possible to rejoin private messages once you leave';
        } else if (this.isAgentGeneralChat() && user.exists(u => u.isAgent())) {
            return 'Note: You can rejoin this channel via the Contact Page for this proposal, or via the Agent Page';
        } else if (this.isAgentGeneralChat() && !user.exists(u => u.isAgent())) {
            return 'Note: You cannot rejoin this channel once you leave. You may miss important travel announcements.';
        } else if (this.isGeneralChat()) {
            return 'Note: You can rejoin this channel at anytime via the Contact Page';
        }

        return 'Note: It is not possible to rejoin this channel';
    }

    getProposalId(): Option<string> {
        if (this.isGeneralChat()) {
            return this.id.map(i => _s.ltrim(i, proposalTravellerRoomPrefix)).filter(s => s.length !== 0);
        } else if (this.isAgentGeneralChat()) {
            return this.id.map(i => _s.ltrim(i, proposalAgentRoomPrefix)).filter(s => s.length !== 0);
        }
        return None;
    }

    getTravellersJoinedCount(): number {
        return this.getJoinedCount(travellerPrefix);
    }

    getUserIds(): Set<string> {
        return this.joined.keySeq().toSet();
    }

    isAgentGeneralChat(): boolean {
        return this.id.exists(i => _s.startsWith(i, proposalAgentRoomPrefix));
    }

    // Private message channels are of the format Identity:CreationTimeStamp
    isCreator(user: Option<IdentifiablePerson>): boolean {
        return this.isGroupPrivateMessage()
            && Option.map2(user.flatMap(u => u.identity), this.id, (u, i) => _s.startsWith(i, u)).contains(true);
    }

    isGeneralChat(): boolean {
        return this.id.exists(i => _s.startsWith(i, proposalTravellerRoomPrefix));
    }

    isGroupPrivateMessage(): boolean {
        return !(this.isGeneralChat() || this.isAgentGeneralChat());
    }
}

export class RoomDataJsonSerializer extends SimpleJsonSerializer<RoomData> {
    static instance: RoomDataJsonSerializer = new RoomDataJsonSerializer();

    protected fromJsonImpl(json: any): RoomData {
        return new RoomData(
            parseString(json[idKey]),
            parseString(json[nameKey]),
            parseMap(json[lastActiveKey], parseDate),
            parseMap(json[joinedKey], parseDate));
    }

    protected toJsonImpl(value: RoomData, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(idKey, value.id)
            .addMap(joinedKey, value.last_active, d => d.toISOString())
            .addMap(lastActiveKey, value.last_active, d => d.toISOString());
    }
}
