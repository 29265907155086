import {Some} from 'funfix-core';
import {List} from 'immutable';
import {imagesKey, JsonBuilder, parseListSerializable, videosKey} from '../core';
import {SimpleJsonSerializer} from '../core/json-serializer';
import {Image, ImageJsonSerializer} from './image';
import {Rating, RatingUtils} from './rating';
import {Video, VideoJsonSerializer} from './video';

export class MediaLibrary {

    constructor(
        readonly images: List<Image> = List(),
        readonly videos: List<Video> = List(),
    ) {
    }

    static fromUrls(images: List<string>): MediaLibrary {
        return new MediaLibrary(
            images.map(uri => Image.fromString(uri, Some('imported via url'))),
        );
    }

    getImageCountRating(): Rating {
        if (this.getImages().size >= 10) {
            return 'Gold';
        } else if (this.getImages().size >= 5) {
            return 'Silver';
        } else if (this.getImages().size >= 3) {
            return 'Bronze';
        }
        return 'Black';
    }

    getImageRating(): Rating {
        return RatingUtils.average(this.getImages().map(x => x.getRating()));
    }

    getImages(): List<Image> {
        return this.images;
    }

    getVideoRating(): Rating {
        if (this.getVideos().size > 1) {
            return 'Gold';
        } else if (this.getVideos().size === 1) {
            return 'Silver';
        }
        return 'Black';
    }

    getVideos(): List<Video> {
        return this.videos;
    }

    isEmpty(): boolean {
        return this.getImages().isEmpty() && this.getVideos().isEmpty();
    }
}

export class MediaLibraryJsonSerializer extends SimpleJsonSerializer<MediaLibrary> {
    static instance: MediaLibraryJsonSerializer = new MediaLibraryJsonSerializer();

    fromJsonImpl(obj: any): MediaLibrary {
        return new MediaLibrary(
            parseListSerializable(obj[imagesKey], ImageJsonSerializer.instance),
            parseListSerializable(obj[videosKey], VideoJsonSerializer.instance));
    }

    protected toJsonImpl(value: MediaLibrary, builder: JsonBuilder): JsonBuilder {
        return builder
            .addIterableSerializable(imagesKey, value.getImages(), ImageJsonSerializer.instance)
            .addIterableSerializable(videosKey, value.getVideos(), VideoJsonSerializer.instance);
    }
}
