import { Option } from 'funfix-core';
import { Moment } from 'moment';

export class PotentialProposalToImport {
    constructor(
        readonly ref: string,
        readonly externalModified: Option<Moment>,
        readonly didgigoModified: Option<Moment>,
    ) { }
}
