import {None, Option, Some} from 'funfix-core';
import {
    activeKey,
    currencyIdKey,
    currencyKey,
    JsonBuilder,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
} from '../core';

export class DbCurrency implements Validatable {

    constructor(
        readonly currencyId: Option<number> = None,
        readonly currency: string,
        readonly active: string,
    ) {
    }

    validate(): ValidationResult {
        return OptionUtils.toList(
            this.currencyId.map(n => ValidationUtils.validateInt('currencyId', n.toString())),
            Some(ValidationUtils.validateNvarchar('currency', this.currency, 50)),
            Some(ValidationUtils.validateChar('active', this.active)),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }
}

export class DBCurrencyJsonSerializer extends SimpleJsonSerializer<DbCurrency> {
    static instance: DBCurrencyJsonSerializer = new DBCurrencyJsonSerializer();

    fromJsonImpl(obj: any): DbCurrency {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(currency: DbCurrency, builder: JsonBuilder): JsonBuilder {
        return builder
            .addOptional(currencyIdKey, currency.currencyId)
            .add(currencyKey, currency.currency)
            .add(activeKey, currency.active);
    }

}
