import {Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {Moment} from 'moment';
import {
    bytesKey,
    captureDateKey,
    copyrightKey,
    dpiKey,
    hashKey,
    heightKey,
    JsonBuilder,
    latitudeKey,
    longitudeKey,
    OptionUtils,
    SimpleJsonSerializer,
    Validatable,
    ValidationResult,
    ValidationUtils,
    widthKey,
} from '../core';
import {Image} from './image';

export class DbImageData implements Validatable {

    constructor(
        readonly sha256Hash: string,
        readonly bytes: number,
        readonly width: number,
        readonly height: number,
        readonly dpi: number,
        readonly copyright: Option<string>,
        readonly latitude: Option<number>,
        readonly longitude: Option<number>,
        readonly captureDate: Option<Moment>) {
    }

    static fromImage(image: Image, includeAnalysisImages: boolean = false): List<DbImageData> {
        const thisImage = Option.map5(
            image.hash,
            image.bytes,
            image.width,
            image.height,
            image.dpi,
            (a: string, b: number, c: number, d: number, e: number) =>
                new DbImageData(
                    a,
                    b,
                    c,
                    d,
                    e,
                    image.copyright,
                    image.latitude,
                    image.longitude,
                    image.captureDate));

        if (includeAnalysisImages) {
            const analysisImages =
                OptionUtils.toList(image.analysis)
                    .flatMap(x => x.getAllImages())
                    .flatMap(x => DbImageData.fromImage(x, false));

            return OptionUtils.toList(thisImage).concat(analysisImages);
        }

        return OptionUtils.toList(thisImage);
    }

    validate(): ValidationResult {

        return OptionUtils.toList(
            Some(ValidationUtils.validateNvarchar('sha256Hash', this.sha256Hash, 64)),
            Some(ValidationUtils.validateInt('bytes', this.bytes.toString())),
            Some(ValidationUtils.validateInt('width', this.width.toString())),
            Some(ValidationUtils.validateInt('height', this.height.toString())),
            Some(ValidationUtils.validateInt('dpi', this.dpi.toString())),
        ).reduce((a, b) => a.merge(b), ValidationResult.empty);
    }

}

export class DbImageDataJsonSerializer extends SimpleJsonSerializer<DbImageData> {
    static instance: DbImageDataJsonSerializer = new DbImageDataJsonSerializer();

    fromJsonImpl(obj: any): DbImageData {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    protected toJsonImpl(image: DbImageData, builder: JsonBuilder): JsonBuilder {
        return builder
            .add(hashKey, image.sha256Hash)
            .add(bytesKey, image.bytes)
            .add(widthKey, image.width)
            .add(heightKey, image.height)
            .add(dpiKey, image.dpi)
            .addOptional(copyrightKey, image.copyright)
            .addOptional(latitudeKey, image.latitude)
            .addOptional(longitudeKey, image.longitude)
            .addOptionalDate(captureDateKey, image.captureDate);
    }
}
