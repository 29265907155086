import {None, Option, Some} from 'funfix-core';
import {List} from 'immutable';
import {
    CollectionUtils,
    descriptionsKey,
    extraContentKey,
    imagesKey,
    imagesToProcessKey,
    JsonBuilder,
    mappingsKey,
    optionsKey,
    optionSubproductsKey,
    productsKey,
    SimpleJsonSerializer,
    subproductsKey,
    ValidationResult,
} from '../core';
import {
    DbContentCollectionSubmissionsText,
    DbContentCollectionSubmissionsTextJsonSerializer,
    DbCoreDescription,
    DbCoreDescriptionJsonSerializer,
    DbCoreImage,
    DbCoreImageJsonSerializer,
    DbImageToProcess,
    DbImageToProcessJsonSerializer,
    DBProduct,
    DBProductJsonSerializer,
    DbProductMapping,
    DbProductMappingJsonSerializer,
    DbProductOption,
    DbProductOptionJsonSerializer,
    DbProductOptionSubproductMapping,
    DbProductOptionSubproductMappingJsonSerializer,
    DbSubproductMapping,
    DbSubproductMappingJsonSerializer,
    LocationIds,
    Mapping,
    PhysicalLocation,
    Product, ProductOption,
} from '../models';

export class DbProductResultSet {

    constructor(
        readonly products: List<DBProduct> = List(),
        readonly options: List<DbProductOption> = List(),
        readonly descriptions: List<DbCoreDescription> = List(),
        readonly mappings: List<DbProductMapping> = List(),
        readonly imagesToProcess: List<DbImageToProcess> = List(),
        readonly images: List<DbCoreImage> = List(),
        readonly subproductMappings: List<DbSubproductMapping> = List(),
        readonly productOptionSubproductMappings: List<DbProductOptionSubproductMapping> = List(),
        readonly contentSubmissionsText: List<DbContentCollectionSubmissionsText> = List(),
    ) {
    }

    static empty: DbProductResultSet = new DbProductResultSet();

    static fromProduct(
        prod: Product,
        productIdx: number = 1,
        locationIdFinder: (l: PhysicalLocation) => LocationIds): DbProductResultSet {
        return new DbProductResultSet(
            DBProduct.buildFromProduct(prod, locationIdFinder, Some(productIdx)),
            DbProductOption.buildFromProduct(prod, locationIdFinder, Some(productIdx)),
            DbCoreDescription.buildFromProduct(prod, Some(productIdx)),
            DbProductMapping.buildFromProduct(prod, Some(productIdx)),
            DbImageToProcess.buildFromProduct(prod),
            DbCoreImage.fromProduct(prod, Some(productIdx)),
            DbSubproductMapping.buildFromProduct(prod, productIdx),
            DbProductOptionSubproductMapping.buildFromProduct(prod, productIdx),
            DbContentCollectionSubmissionsText.fromProduct(prod, Some(productIdx)));
    }

    static fromProductForUpdate(
        prod: Product,
        productIdx: number = 1,
        locationIdFinder: (l: PhysicalLocation) => LocationIds,
        cachedProduct: Product,
    ): DbProductResultSet {
        return new DbProductResultSet(
            DBProduct.buildFromProductForUpdate(prod, locationIdFinder, cachedProduct),
            DbProductOption.buildFromProduct(prod, locationIdFinder, Some(productIdx)),
            DbCoreDescription.buildFromProduct(prod, Some(productIdx)),
            DbProductMapping.buildFromProduct(prod, Some(productIdx)),
            DbImageToProcess.buildFromProduct(prod),
            DbCoreImage.fromProduct(prod, Some(productIdx)),
            DbSubproductMapping.buildFromProduct(prod, productIdx),
            DbProductOptionSubproductMapping.buildFromProduct(prod, productIdx),
            DbContentCollectionSubmissionsText.fromProduct(prod, Some(productIdx)));
    }

    static fromProducts(
        prods: List<Product>,
        locationIdFinder: (l: PhysicalLocation) => LocationIds): DbProductResultSet {
        return new DbProductResultSet(
            DBProduct.buildFromProducts(prods, locationIdFinder),
            DbProductOption.buildFromProducts(prods, locationIdFinder),
            DbCoreDescription.buildFromProducts(prods),
            DbProductMapping.buildFromProducts(prods),
            List(), // DbImageToProcess.buildFromProducts(prods),
            DbCoreImage.fromProducts(prods),
            DbSubproductMapping.buildFromProducts(prods),
            DbProductOptionSubproductMapping.buildFromProducts(prods),
            DbContentCollectionSubmissionsText.fromProducts(prods),
        );
    }

    static fromProductsMappingsOnly(prods: List<Product>): DbProductResultSet {
        return new DbProductResultSet(
            List(),
            List(),
            List(),
            DbProductMapping.buildFromProducts(prods, None),
            List(),
            List(),
            List(),
            List(),
            List());
    }

    getMappingsToExistingProducts(): List<Mapping> {
        return this.mappings
            .filter(x => x.isMappingToExistingProduct())
            .map(x => x.buildAsMapping());
    }

    // IMPORTANT: We assume during one import two products with the same name cannot exist!
    merge(other: DbProductResultSet): DbProductResultSet {
        return new DbProductResultSet(
            CollectionUtils.distinctList(this.products.concat(other.products), x => x.name),
            CollectionUtils.distinctOptionList(
                this.options.concat(other.options),
                x => Option.map2(x.name, x.product.flatMap(p => p.name), (a, b) => a + ' - ' + b)),
            this.descriptions.concat(other.descriptions),
            this.mappings.concat(other.mappings),
            this.imagesToProcess.concat(other.imagesToProcess),
            this.images.concat(other.images),
            this.subproductMappings.concat(other.subproductMappings),
            this.productOptionSubproductMappings.concat(other.productOptionSubproductMappings),
            this.contentSubmissionsText.concat(other.contentSubmissionsText),
        );
    }

    validate(): ValidationResult {
        return this.products.reduce((acc, x) => acc.merge(x.validate()), ValidationResult.empty);
    }
}

export class DbProductResultSetJsonSerializer extends SimpleJsonSerializer<DbProductResultSet> {
    static instance: DbProductResultSetJsonSerializer = new DbProductResultSetJsonSerializer();

    fromJsonImpl(obj: any): DbProductResultSet {
        throw new Error(`DB Classes are write only. You should always read to the generic classes like 'Company' or 'Proposal' etc.`);
    }

    // Ignoring Destinations for now
    protected toJsonImpl(results: DbProductResultSet, builder: JsonBuilder): JsonBuilder {
        return builder
            .addIterableSerializable(productsKey, results.products, DBProductJsonSerializer.instance)
            .addIterableSerializable(descriptionsKey, results.descriptions, DbCoreDescriptionJsonSerializer.instance)
            .addIterableSerializable(optionsKey, results.options, DbProductOptionJsonSerializer.instance)
            .addIterableSerializable(mappingsKey, results.mappings, DbProductMappingJsonSerializer.instance)
            .addIterableSerializable(imagesToProcessKey, results.imagesToProcess, DbImageToProcessJsonSerializer.instance)
            .addIterableSerializable(imagesKey, results.images, DbCoreImageJsonSerializer.instance)
            .addIterableSerializable(subproductsKey, results.subproductMappings, DbSubproductMappingJsonSerializer.instance)
            .addIterableSerializable(
                optionSubproductsKey,
                results.productOptionSubproductMappings,
                DbProductOptionSubproductMappingJsonSerializer.instance,
            )
            .addIterableSerializable(
                extraContentKey,
                results.contentSubmissionsText,
                DbContentCollectionSubmissionsTextJsonSerializer.instance,
            );
    }
}
